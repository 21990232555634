import
{
	useRef,
	useState,
	RefObject,
	useReducer,
	useEffect,
	KeyboardEvent,
	FocusEvent,
	FormEvent,
	FC
}
	from "react"
import
{
	Tab,
	Tabs,
	Button,
	Modal
}
	from "react-bootstrap"
import
{
	Navigate,
	useNavigate
}
	from "react-router-dom"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	setSessionToken,
	setCurrentUserRole,
	setCampusRef,
	setCurrentUserCampus,
	setCurrentUserCampusId,

	setUserMenu,
	setMenuRef,
	setNavigation
}
	from "./../../globals"
import
{
	baseMenu,
	baseNavigation,
	basicSetup,
	buttonClickHandling,
	keyHandling,
	generateSectionMap,
	isEmpty,
	useForm,
	validateField
}
	from "./../../common"
import AuthService
	from "./../../services/auth.service"

import "./style.css"

type LoginState =
	{
		isLoginButtonDisabled: boolean
	}

type RegisterState =
	{
		isRegisterButtonDisabled: boolean
	}

type PwdResetState =
	{
		isPwdResetButtonDisabled: boolean
	}

interface User
{
	firstName          : string;
	lastName           : string;
	email              : string;
	password           : string;
	passwordConfirm    : string;
	regEmail           : string;
	regConfirmPassword : string;
}

const initialLoginState: LoginState =
{
	isLoginButtonDisabled: true
}

const initialRegisterState: RegisterState =
{
	isRegisterButtonDisabled: true
}

const initialPwdResetState: PwdResetState =
{
	isPwdResetButtonDisabled: true
}

type LoginAction    = {type : "setIsLoginButtonDisabled"    , payload : boolean}
type RegisterAction = {type : "setIsRegisterButtonDisabled" , payload : boolean}
type PwdResetAction = {type : "setIsPwdResetButtonDisabled" , payload : boolean}
const loginReducer   = (state : LoginState, action : LoginAction) : LoginState=>
{
	switch (action.type)
	{
		case "setIsLoginButtonDisabled":
			return { ...state, isLoginButtonDisabled : action.payload}
		break;
	}
}

const registerReducer = (state: RegisterState, action: RegisterAction): RegisterState => {
	switch (action.type) {
		case "setIsRegisterButtonDisabled":
			return {...state, isRegisterButtonDisabled: action.payload}
		break;
	}
}

const pwdResetReducer = (state : PwdResetState, action : PwdResetAction) : PwdResetState=>
{
	switch (action.type)
	{
		case "setIsPwdResetButtonDisabled":
			return {...state, isPwdResetButtonDisabled: action.payload}
		break;
	}
}

const Access : FC = ()=>
{
	const [loginState, loginTrigger]          = useReducer(loginReducer, initialLoginState)
	const [registerState, registerTrigger]    = useReducer(registerReducer, initialRegisterState)
	const [pwdResetState, pwdResetTrigger]    = useReducer(pwdResetReducer, initialPwdResetState)
	const dispatch                            = useDispatch()
	const navigate                            = useNavigate()
	const authenticated : boolean             = useSelector((state: RootState) => state.authenticated.value)
	const userMenu : any                      = useSelector((state: RootState) => state.userMenu.value)
	const navigation : any                    = useSelector((state: RootState) => state.navigation.value)
	const idleLocked : any                    = useSelector((state: RootState) => state.idleLockSwitch.value)
	const basicInfo : any                     = useSelector((state: RootState) => state.basicInfo.value)
	let   focusInputEmail                     = useRef<HTMLDivElement>(null)
	let   focusInputRecover                   = useRef<HTMLDivElement>(null)
	let   focusInputPass                      = useRef<HTMLDivElement>(null)
	let   focusInputPassConfirm               = useRef<HTMLDivElement>(null)
	let   focusInputFirstName                 = useRef<HTMLDivElement>(null)
	let   focusInputLastName                  = useRef<HTMLDivElement>(null)
	let   focusLabelEmail                     = useRef<HTMLLabelElement>(null)
	let   focusLabelFirstName                 = useRef<HTMLLabelElement>(null)
	let   focusLabelLastName                  = useRef<HTMLLabelElement>(null)
	let   focusLabelRecover                   = useRef<HTMLLabelElement>(null)
	let   focusLabelPass                      = useRef<HTMLLabelElement>(null)
	let   focusLabelPassConfirm               = useRef<HTMLLabelElement>(null)
	let   divUserNoExist                      = useRef<HTMLDivElement>(null)
	let   lastNameRef                         = useRef<HTMLInputElement>(null)
	let   firstNameRef                        = useRef<HTMLInputElement>(null)
	let   emailRef                            = useRef<HTMLInputElement>(null)
	let   emailRegRef                         = useRef<HTMLInputElement>(null)
	let   recoverRef                          = useRef<HTMLInputElement>(null)
	let   passwordRef                         = useRef<HTMLInputElement>(null)
	let   passwordConfirmRef                  = useRef<HTMLInputElement>(null)
	let   loginRef                            = useRef<HTMLInputElement>(null)
	let   registerRef                         = useRef<HTMLInputElement>(null)
	let   pwdResetRef                         = useRef<HTMLButtonElement>(null)
	let   loginFormRef                        = useRef<HTMLFormElement>(null)
	let   registerFormRef                     = useRef<HTMLFormElement>(null)
	let   pwdResetFormRef                     = useRef<HTMLFormElement>(null)
	const [focusedElement, setFocusedElement] = useState<any>(null)
	const [show, setShow]                     = useState<boolean>(false)
	const [currentTab, setCurrentTab]         = useState<string | null>("login")

	const access = (data: any)=>
	{
		let assignedNavigation
		let menu
		let menuMap: any = {}
		let campusIndex: any = {}
		let user = data.user

		if(user.roles && user.roles.length > 0)
		{
			user.currentRole                                   = user.roles[0].code
			const [complementaryMenu, complementaryNavigation] = generateSectionMap(user)
			menu                                               = [...baseMenu, ...complementaryMenu]
			assignedNavigation                                 = [...baseNavigation, ...complementaryNavigation]

			if(user.roleMap[user.currentRole].campuses && user.roleMap[user.currentRole].campuses.length > 0)
			{
				let refIndex: string

				user.roleMap[user.currentRole].campuses.map
				(
					(campus : any)=>
					{
						refIndex = "C_" + campus.id
						campusIndex[refIndex] = {...campus, refIndex: refIndex}

						return
					}
				)

				user.roleMap[user.currentRole].campusRef = campusIndex
			}
		}
		else
		{
			menu               = baseMenu
			assignedNavigation = baseNavigation

			if(user.student)
			{
				let refIndex: string
				user.currentRole = "student"

				user.student.campuses.map
				(
					(campus: any)=>
					{
						refIndex              = "C_" + campus.id
						campusIndex[refIndex] = { ...campus, refIndex: refIndex }
					}
				)

				user.student.campusRef = campusIndex
			}
		}

		if(user.currentRole)
		{
			dispatch(setCurrentUserRole(user.currentRole))
			localStorage.setItem("currentRole", user.currentRole)

			if(user.currentRole == "student")
			{
				user.currentCampus = "C_" + user.student.campuses[0].id

				localStorage.setItem("currentCampus", user.currentCampus)
			}
			else
			{
				if(user.roleMap[user.currentRole].campuses.length > 0)
				{
					user.currentCampus = "C_" + user.roleMap[user.currentRole].campuses[0].id

					localStorage.setItem("currentCampus", user.currentCampus)
				}
			}
		}
		else
		{
			if(localStorage.getItem("currentRole") != null)
			{
				localStorage.removItem("currentRole")
			}

			if(localStorage.getItem("currentCampus") != null)
			{
				localStorage.removItem("currentCampus")
			}
		}

		dispatch(setCurrentUserRole(user.currentRole))
		dispatch(setCurrentUserCampus(user.currentCampus))
		dispatch(setCurrentUserCampusId(user.currentCampus ? campusIndex[user.currentCampus].id : null))
		dispatch(setCampusRef(campusIndex))

		menu?.forEach
		(
			(element : any, index : number)=>
			{
				element.sections.forEach
				(
					(item : any, subIndex : number)=>
					{
						menuMap[item.route] = element.block.group
					}
				)
			}
		)

		dispatch(setUserMenu(menu))
		dispatch(setMenuRef(menuMap))
		dispatch(setNavigation(assignedNavigation))
		dispatch(setSessionToken(data.accessToken))
		localStorage.setItem("accessToken", data.accessToken)
		basicSetup(data, dispatch)
		navigate("/")
	}

	const procedureComplaint = (message: string) => {
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{ color: "#FF0000", display: "inline-block" }}>
					Error
				</h3>
			})
		)
	}

	const {handleSubmit, handleChange, data: user, errors } = useForm<User>
	({
		validations : {},
		onSubmit    : () =>
		{
			switch(currentTab)
			{
				case "login":
					if(!loginFormRef?.current?.checkValidity())
					{
						loginFormRef?.current?.reportValidity()
					}
					else
					{
						dispatch(enableIdleLockSwitch())
						AuthService
							.login(user.email, user.password)
							.then
							(
								(result: any) => { access(result.data) },
								(error: any) => {
									console.log(error)

									procedureComplaint
										(
											error.response.status == 401
												? "Las credenciales proporcionadas no son válidas"
												: "Revise el estado de la red y refresque la vista para volver a intentar"
										)
								}
							)
							.finally
							(
								() => {
									dispatch(disableIdleLockSwitch())
								}
							)
					}
				break;
				case "register":
					if (!registerFormRef?.current?.checkValidity())
					{
						registerFormRef?.current?.reportValidity()
					}
					else
					{
						dispatch(enableIdleLockSwitch())
						AuthService
							.register(user.email, user.password, user.firstName, user.lastName)
							.then
							(
								(result: any) => access(result.data),
								(error: any)  => procedureComplaint
								(
									error.response.status == 422
										? "Esta cuenta de email ya se encuentra registrada. Restaure contraseña o contacte a soporte técnico"
										: "Revise el estado de la red y refresque la vista para volver a intentar"
									)
							)
							.finally(() => dispatch(disableIdleLockSwitch()))
					}
				break;
				case "pwdReset":
					if(!pwdResetFormRef?.current?.checkValidity())
					{
						pwdResetFormRef?.current?.reportValidity()
					}
					else {
						dispatch(disableIdleLockSwitch())
					}
				break;
			}
		}
	})

	useEffect
	(
		()=>
		{
			loginTrigger
			({
				type    : "setIsLoginButtonDisabled",
				payload : idleLocked
			})
		},
		[user.email, user.password]
	)

	useEffect
	(
		()=>
		{
			registerTrigger
			({
				type: "setIsRegisterButtonDisabled",
				payload: !(registerFormRef?.current?.checkValidity() && !idleLocked) || (user.password != user.passwordConfirm)
			})
		},
		[user.email, user.password, user.passwordConfirm]
	)

	useEffect
	(
		()=>
		{
			pwdResetTrigger
			({
				type: "setIsPwdResetButtonDisabled",
				payload: !(pwdResetFormRef?.current?.checkValidity() && !idleLocked)
			})
		},
		[user.email]
	)

	const handleClose  = () => setShow(false)
	const focusElement = (event: FocusEvent)=>
	{
		if(loginRef != null && loginRef.current != null)
		{
			const elementId: string = event.currentTarget.id
			setFocusedElement(elementId)

			validateField(event)
		}
	}

	const selectInput = (focusInput: RefObject<HTMLDivElement>, focusLabel: RefObject<HTMLLabelElement>)=>
	{
		if (divUserNoExist.current != null)
		{
			divUserNoExist.current.className = "displayNone hidden";
		}

		if(focusLabel.current != null)
		{
			focusLabel.current.classList.remove("hidden")
		}

		if(focusInput.current != null)
		{
			focusInput.current.className = "selectInput focusInputBar";
		}
	}

	const deselectInput = (focusInput: RefObject<HTMLDivElement>, focusLabel: RefObject<HTMLLabelElement>)=>
	{
		if(divUserNoExist.current != null)
		{
			divUserNoExist.current.className = "displayNone hidden";
		}

		if(focusLabel.current != null)
		{
			focusLabel.current.classList.toggle("hidden");
		}

		if(focusInput.current != null)
		{
			focusInput.current.className = "focusInputBar";
		}
	}

	const formSubmission = (event : FormEvent)=>
	{
		event.preventDefault()
		handleSubmit()
	}

	if(authenticated)
	{
		return <Navigate to="/" />
	}

	return <div className="login">
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Advertencia</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Hubo un problema con extraer la información, intente refrescar la página
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose} type="button">
					Cerrar
				</Button>
				<Button className="btnModal" variant="primary" onClick={handleClose} type="button">
					Aceptar
				</Button>
			</Modal.Footer>
		</Modal>
		<div className="loginContent">
			<div className="loginForm">
				<div className="loginFormLeft">
					<div className="logo" />
					<Tabs defaultActiveKey="login" activeKey={currentTab!!} onSelect={setCurrentTab}
						id="uncontrolledTabExample" className="mb-3 hidden displayNone"
					>
						<Tab eventKey="login" title="">
							<form ref={loginFormRef} className="form" onSubmit={formSubmission}>
								<h1 className="pageHeader">
									Bienvenido
								</h1>
								<br />
								<br />
								<div ref={divUserNoExist} className="displayNone hidden"><span>
									Verifica que tu correo o contraseña sean correctos
								</span></div>
								<div className="divInput">
									<div ref={focusInputEmail} className={focusedElement == "email" ? "focusInputBar" : ""}>
									</div>
									<label className={focusedElement != "email" ? "hidden" : ""} htmlFor="email"
										ref={focusLabelEmail}
									>
										Correo
									</label>
									<input
									  name="email"
									  ref={emailRef}
									  id="email"
									  type="email"
									  placeholder="Correo"
									  onChange={handleChange("email")} value={user.email || ""}
									  disabled={idleLocked}
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, loginRef)}
									  onFocus={focusElement}
									  onBlur={() => deselectInput(focusInputEmail, focusLabelEmail)}
									  required
									>
									</input>
								</div>
								<div className="divInput">
									<div ref={focusInputPass} className={focusedElement == "password" ? "focusInputBar" : ""} />
									<label htmlFor="password" ref={focusLabelPass}
									  className={(focusedElement != "password" && isEmpty(passwordRef?.current?.value)) ? "hidden" : ""}
									>
										Contraseña
									</label>
									<input
									  name="password"
									  ref={passwordRef}
									  value={user.password || ""}
									  disabled={idleLocked}
									  placeholder="Contraseña"
									  onChange={handleChange("password")} id="password"
									  type="password"
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, loginRef)}
									  onFocus={focusElement}
									  required
									/>
								</div>
								<br />
								{/*<div className="forgotOrRemember">
								<div className="formCheck">
									<input id="checkbox" type="checkbox" />
									<label htmlFor="">
										Recordarme
									</label>
								</div>
								<div className="forgotPassword"><a onClick={() => {!idleLocked && setCurrentTab("pwdReset")}}>
									Olvide mi contraseña
								</a></div>
							</div>*/}
								<div className="loginButtons">
									<input className="btn btnPurple rounded-pill" value="Iniciar sesión" disabled={idleLocked}
									  style={{width : "auto"}} type="submit" ref={loginRef}
									/>
									<div style={{ display: "inline-block" }}>
										<div className="btn btn-outline-dark rounded-pill">
											<a onClick={() => {!idleLocked && setCurrentTab("register")}}>
												Registrarse
											</a>
										</div>
									</div>
								</div>
							</form>
						</Tab>
						<Tab eventKey="register" title="">
							<form ref={registerFormRef} className="form" onSubmit={formSubmission}>
								<h1 className="h1">
									Registrate
								</h1>
								<div className="divInput">
									<div className={focusedElement == "firstName" ? "focusInputBar" : ""} ref={focusInputFirstName} />
									<label ref={focusLabelFirstName} htmlFor="firstName"
									  className={(focusedElement != "firstName" && isEmpty(firstNameRef?.current?.value)) ? "hidden" : ""}
									>
										Nombres
									</label>
									<input
									  name="firstName"
									  ref={firstNameRef}
									  id="firstName"
									  value={user.firstName || ""}
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, registerRef)}
									  onFocus={focusElement}
									  disabled={idleLocked}
									  onChange={handleChange("firstName")}
									  placeholder="nombres"
									  type="text"
									  required
									/>
								</div>
								<div className="divInput">
									<div className={focusedElement == "lastName" ? "focusInputBar" : ""}
										ref={focusInputLastName}
									>
									</div>
									<label ref={focusLabelPass} htmlFor="lastName"
										className={(focusedElement != "lastName" && isEmpty(lastNameRef?.current?.value)) ? "hidden" : ""}
									>
										Apellidos
									</label>
									<input
									  disabled={idleLocked}
									  value={user.lastName || ""}
									  onChange={handleChange("lastName")}
									  name="lastName"
									  ref={lastNameRef}
									  id="lastName"
									  placeholder="apellidos"
									  onFocus={focusElement}
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, registerRef)}
									  type="text"
									  required
									/>
								</div>
								<div className="divInput">
									<div ref={focusInputEmail} className={focusedElement == "email" ? "focusInputBar" : ""} />
									<label htmlFor="password" ref={focusLabelEmail}
										className={(focusedElement != "email" && isEmpty(emailRef?.current?.value)) ? "hidden" : ""}
									>
										Correo
									</label>
									<input
									  name="email"
									  ref={emailRegRef}
									  id="email"
									  onChange={handleChange("email")}
									  type="email"
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, registerRef)}
									  value={user.email || ""}
									  onFocus={(e) => setFocusedElement(e.target.id)}
									  disabled={idleLocked}
									  placeholder="e-mail"
									  required
									/>
								</div>
								<div className="divInput">
									<div ref={focusInputPass} className={focusedElement == "password" ? "focusInputBar" : ""} />
									<label ref={focusLabelPass} htmlFor="password"
										className={(focusedElement != "password" && isEmpty(passwordRef?.current?.value)) ? "hidden" : ""}
									>
										Contraseña
									</label>
									<input
									  disabled={idleLocked}
									  onFocus={(e) => setFocusedElement(e.target.id)}
									  ref={passwordRef}
									  onChange={handleChange("password")}
									  id="password"
									  name="password"
									  placeholder="Contraseña"
									  type="password"
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, registerRef)}
									  required
									/>
								</div>
								<div className="divInput">
									<div className={focusedElement == "passwordConfirm" ? "focusInputBar" : ""}
										ref={focusInputPass}
									/>
									<label ref={focusLabelPassConfirm} htmlFor="passwordConfirm"
										className={(focusedElement != "passwordConfirm" && isEmpty(passwordConfirmRef?.current?.value)) ? "hidden" : ""}
									>
										Confirmar Contraseña
									</label>
									<input
									  name="passwordConfirm"
									  ref={passwordConfirmRef}
									  onChange={handleChange("passwordConfirm")}
									  placeholder="Confirme contraseña"
									  id="passwordConfirm"
									  value={user.passwordConfirm || ""}
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, registerRef)}
									  disabled={idleLocked}
									  onFocus={(e) => setFocusedElement(e.target.id)}
									  type="password"
									  required
									/>
								</div>
								<br />
								<div className="loginButtons" style={{ marginTop: "unset" }}>
									<div style={{ display: "inline-block" }}>
										<div className="btn btn-outline-dark rounded-pill">
											<a onClick={() => { !idleLocked && setCurrentTab("login") }}>
												Iniciar sesión
											</a>
										</div>
									</div>
									<input
									  className="btn btnPurple rounded-pill"
									  disabled={idleLocked}
									  ref={registerRef}
									  style={{width: "auto"}}
									  type="submit"
									  value="Registrarme"
									/>
								</div>
							</form>
						</Tab>
						<Tab eventKey="pwdReset" title="">
							<form ref={pwdResetFormRef} onSubmit={formSubmission} className="form">
								<h2 className="h1">
									¿Ha olvidado su contraseña?
								</h2>
								<p>
									Escriba su dirección de correo electrónico registrada para cambiar la contraseña de su cuenta.
								</p>
								<div ref={divUserNoExist} className="displayNone hidden">
									<span>
										Verifica que tu correo o contraseña sean correctos
									</span>
								</div>
								<div className="divInput">
									<div ref={focusInputRecover} className="focusInputBar" />
									<label ref={focusLabelRecover} className="hidden" htmlFor="">
										Correo
									</label>
									<input
									  ref={recoverRef}
									  placeholder="Correo"
									  id="emailForgot"
									  disabled={idleLocked}
									  onBlur={() => deselectInput(focusInputRecover, focusLabelRecover)}
									  onKeyPress={(event : any) => keyHandling(!loginState.isLoginButtonDisabled && event, pwdResetRef)}
									  required
									  onFocus={focusElement}
									  type="email"
									/>
								</div>
								<div className="forgotButton">
									<button
									  className="buttonLoginSubmit"
									  disabled={idleLocked}
									  ref={pwdResetRef}
									  type="submit"
									>
										Enviar Correo
									</button>
								</div>
								<div className="logInLink">
									<a onClick={() => { !idleLocked && setCurrentTab("login") }}>
										Iniciar Sesión
									</a>
								</div>
							</form>
						</Tab>
					</Tabs>
				</div>
				<div className="loginFormRight" />
			</div>
		</div>
	</div>
}

export default Access;
