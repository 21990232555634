import {useState, useEffect, useCallback}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import _
	from "lodash"
import _debounce
	from "lodash/debounce"
import {sprintf}
	from "sprintf-js"
import FileSaver
	from "file-saver"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice, dismissNotice
}
	from "./../../globals"
import
{
	pageGrouping, toAscendingDate, getAccessPermissions, isEmpty, isNotEmpty, monthRef, operators, operatorList, validateField,
	UIState
}
	from "./../../common"
import SectionContainer
	from "./../../components/sectionContainer"
import CustomIcon
	from "./../../components/customIcon"
import LeDatePicker
	from "../../components/leDatePicker"
import {SelectReact}
	from "../../components/select"
import FinancePrompt
	from "./financePrompt"
import CatalogService
	from "./../../services/catalog.service"
import SimpleDataService
	from "./../../services/simpleData.service"
import StudentsService
	from "./../../services/campus/students.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import TaxesService
	from "./../../services/campus/taxes.service"
import PaymentsService
	from "./../../services/campus/payments.service"
import RecipientAccountsService
	from "./../../services/recipientAccounts.service"
import PaymentMethodsService
	from "./../../services/paymentMethods.service"
import Constants, {paymentStatus}
	from "./../../constants"
import {styles}
	from "./style"

import "./../../components/dropdown/style.css"
import Multiselect from "multiselect-react-dropdown"

const Payments = ()=>
{
	const PAYMENT_STATUSES   = 0
	const PAYMENT_METHODS    = 1
	const RECIPIENT_ACCOUNTS = 2
	const TAXES              = 3
	const ENDING             = 4

	const promptWidth : any=
	{
		checkout : "446px",
		payment  : "583px",
		invoice  : "648px"
	}

	const periods      : any[]                                    = []
	const dispatch                                                = useDispatch()
	const records      : any[]                                    = periods.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                         = useState<number>(records.length)
	const [data, setData]                                         = useState<any[]>(records)
	const [warningReport, listWarnings]                           = useState<any>({})
	const idleLocked   : boolean                                  = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                            = useSelector((state : RootState) => state.sessionToken.value)
	const basicInfo    : any                                      = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                                 = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                                 = useSelector((state : RootState) => state.campusRef.value)
	const [currencySymbol, setCurrencySymbol]                     = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
	const currentUser  : any                                      = useSelector((state : RootState) => state.userProfile.value)
	const [promptDisplayQueue, setPromptDisplayQueue]             = useState<string[]>([])
	const [searchError, setSearchError]                           = useState<string | null>(null)
	const [studentListSearch, setStudentListSearch]               = useState<string>("")
	const [employeeListSearch, setEmployeeListSearch]             = useState<string>("")
	const [studentsList, setStudentsList]                         = useState<any>([])
	const [employeesList, setEmployeesList]                       = useState<any>([])
	const [pagination, setPagination]                             = useState<any>(null)
	const [UIStatus, setUIStatus]                                 = useState<any>(UIState.NORMAL)
	const [sectionLoaded, setSectionLoaded]                       = useState<boolean>(false)
	const [studentPopoverOpen, setStudentPopoverOpen]             = useState<boolean>(false)
	const [employeePopoverOpen, setEmployeePopoverOpen]           = useState<boolean>(false)
	const [userCampus, setUserCampus]                             = useState<number>(currentUserCampus)
	const [clearSearchRequested, setClearSearchRequested]         = useState<boolean>(false)
	const [extendedFilterShown, setExtendedFilterShown]           = useState<boolean>(false)
	const [studentSearchInProgress, setStudentSearchInProgress]   = useState<boolean>(false)
	const [employeeSearchInProgress, setEmployeeSearchInProgress] = useState<boolean>(false)
	const [selectedRecords, setSelectedRecords]                   = useState<number>(0)
	const [selectedInvoice, setSelectedInvoice]                   = useState<any>(null)
	const [selectedPayment, setSelectedPayment]                   = useState<any>(null)
	const [defaultDate, setDefaultDate]                           = useState<any>(null)
	const [recipientAccountRef, setRecipientAccountRef]           = useState<any>(null)
	const [taxRef, setTaxRef]                                     = useState<any>(null)
	const [paymentMethodRef, setPaymentMethodRef]                 = useState<any>(null)
	const [paymentStatusRef, setPaymentStatusRef]                 = useState<any>(null)
	const [invoiceStatusRef, setInvoiceStatusRef]         		  = useState<any>(null)
	const [availableAccounts, setAvailableAccounts]       		  = useState<any>(null)
	const [initSetup, setInitSetup]                               = useState<any>(null)
	const allowed    : any                                        = getAccessPermissions(currentUser)
	const [defaultSearch, setDefaultSearch]                       = useState<any>
	({
		q              : "",
		studentQuery   : "",
		employeeQuery  : "",
		amountBalanceA : "",
		amountBalanceB : "",
		tags           : []
	})
	const [customSearch, setCustomSearch]                         = useState<any>(defaultSearch)

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const showInvoicePrompt = (invoiceToDisplay : any)=>
	{
		let invoice : any=
		{
			...invoiceToDisplay, notes : invoiceToDisplay.notes || ""
		}

		setSelectedInvoice({...invoice})

		setPromptDisplayQueue(["invoice"])
	}

	const mapAvailableAccountsRefs = (response: any) => {
		const mappedAccounts: any = {};

		response.forEach((paymentMethod: any) => {
			paymentMethod.accounts.forEach((account: any) => {
				mappedAccounts[`RA_${account.id}`] = {
					...account,
					label: `${account.name} (${account.account_number})`
				};
			});
		});

		return mappedAccounts;
	};

	const showPaymentPrompt = (paymentToDisplay : any)=>
	{
		let payment : any = {...paymentToDisplay, notes : paymentToDisplay.notes || ""}

		setSelectedPayment({...payment})
		setPromptDisplayQueue(["payment"])
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const initFn = async()=>
					{
						const taxRetrievalError : string = "Los impuestos no pudieron ser extraídos," +
							"por lo que el acceso a este modulo quedará deshabilitado"

						try
						{
							dispatch(enableIdleLockSwitch)

							const invoiceStatuses: any = await CatalogService.getInvoiceStatuses(sessionToken)
							if (invoiceStatuses.status == 200 || invoiceStatuses.status == 204) {
								let invoiceStatusRef: any = {}
		
								invoiceStatuses.data.map
									((record: any) => invoiceStatusRef["IS_" + record.id] = { ...record, label: record.name })
								setInvoiceStatusRef(invoiceStatusRef);
							}

							const paymentMethods: any = await PaymentMethodsService.getPaymentMethodsByCountry(campusRef[currentUserCampus].company.country_id)

							if (paymentMethods.status == 200 || paymentMethods.status == 204) {
								let paymentMethodRef: any = {};
								let availableAccounts;

								paymentMethods.data.map((record: any) => paymentMethodRef["PM_" + record.id] = { ...record, label: record.name })
								// let recipientAccounts = paymentMethods.data[0];
								let recipientAccountRef: any = []
								if (paymentMethods.data.length > 0)
								{
									paymentMethods.data[0].accounts.map((record: any) => 
										recipientAccountRef["RA_" + record.id] = { ...record, label: record.name + " (" + record.account_number + ")" })
									availableAccounts = mapAvailableAccountsRefs(paymentMethods.data)
									setAvailableAccounts(availableAccounts)
								}
							}
							const result : any = await TaxesService.getTaxes(campusRef[currentUserCampus].id)
							let   taxMap : any = {}

							if(result.status == 200 || result.status == 204)
							{
								result.data.map
								(
									(record : any)=>
									{
										taxMap["T_" + record.id] = record
									}
								)

								setTaxRef(taxMap)
								setInitSetup(TAXES)
							}
							else
							{
								procedureComplaint(taxRetrievalError)
								dispatch(disableIdleLockSwitch)
							}
						}
						catch(error : any)
						{
							console.log(error)
							procedureComplaint(taxRetrievalError)
							dispatch(disableIdleLockSwitch)
						}
					}

					initFn()
				}
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case TAXES:
						if(Object.keys(taxRef).length < 1)
						{
							procedureComplaint
							(
								"No se encontraron impuestos en el registro, " +
									"por lo que el acceso a este módulo quedará deshabilitado"
							)
						}
						else
						{
							const statusRetrievalError : string = "Los estatuses de pago no pudieron ser extraídos," + 
								"por lo que no se podrán emitir pagos para las facturas."

							try
							{
								dispatch(enableIdleLockSwitch)

								const result           : any = await CatalogService.getPaymentStatuses(sessionToken)
								let   paymentStatusMap : any = {}

								if(result.status == 200 || result.status == 204)
								{
									result.data.map
									(
										(record : any)=>
										{
											paymentStatusMap["PS_" + record.id] = record
										}
									)

									setPaymentStatusRef(paymentStatusMap)
									setInitSetup(PAYMENT_STATUSES)
								}
								else
								{
									procedureComplaint(statusRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error : any)
							{
								console.log(error)
								dispatch(disableIdleLockSwitch())
								procedureComplaint(statusRetrievalError)
							}
						}
					break;
					case PAYMENT_STATUSES:
						const methodRetrievalError : string = "No se pudieron extraer los métodos de pago, " +
							"por lo que no se podrán emitir pagos para las facturas"

						try
						{
							const result           : any = await SimpleDataService.getRecords("paymentMethods")
							let   paymentMethodMap : any = {}

							if(result.status == 200 || result.status == 204)
							{
								result.data.map
								(
									(record : any)=>
									{
										paymentMethodMap["PM_" + record.id] = {...record, label : record.name}
									}
								)

								setPaymentMethodRef(paymentMethodMap)
								setInitSetup(PAYMENT_METHODS)
							}
							else
							{
								dispatch(disableIdleLockSwitch())
								procedureComplaint(methodRetrievalError)
							}
						}
						catch(error : any)
						{
							console.log(error)

							dispatch(disableIdleLockSwitch())
							procedureComplaint(methodRetrievalError)
						}
					break;
					case PAYMENT_METHODS:
						if(Object.keys(paymentMethodRef).length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay métodos de pago en el registro, " + 
									"por lo que no se podrán emitir pagos para las facturas "
							)
						}
						else
						{
							try
							{
								const result              : any = await RecipientAccountsService.getRecipientAccounts()
								let   recipientAccountMap : any = {}

								if(result.status == 200 || result.status == 204)
								{
									result.data.map
									(
										(record : any)=>
										{
											recipientAccountMap["RA_" + record.id] = {...record, label : record.name + " [" +
												record.account_number + "]"}
										}
									)

									setRecipientAccountRef(recipientAccountMap)
									setInitSetup(RECIPIENT_ACCOUNTS)
								}
								else
								{
									listWarnings
									({
										...warningReport, recipientAccounts : "No se pudueron extraer las cuentas receptoras " + 
											"por lo que no se podrán emitir pagos para las facturas "
									})

									setInitSetup(ENDING)
								}
							}
							catch(error : any)
							{
								console.log(error)

								listWarnings
								({
									...warningReport, recipientAccounts : "No se pudueron extraer los métodos de pago " + 
										"por lo que no se podrán emitir pagos para las facturas "
								})

								setInitSetup(ENDING)
							}
						}
					break;
					case RECIPIENT_ACCOUNTS:
						if(Object.keys(recipientAccountRef).length < 1)
						{
							listWarnings
							({
								...warningReport, paymentMethods : "No hay cuentas receptoras en el registro, " + 
									"por lo que no se podrán emitir pagos para las facturas "
							})
						}

						setInitSetup(ENDING)
					break;
					case ENDING:
						setSectionLoaded(true)
						search()
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				setStudentsList([])
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setCurrencySymbol(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
				setPromptDisplayQueue([])
				setSelectedInvoice(null)
				clearSearch()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			setCustomSearch
			({
				...customSearch, amountBalanceB : "",
				amountQuantityB : ""
			})
		},
		[customSearch.amountBalanceA]
	)

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const studentDebounceFn  : any = useCallback(_debounce(handleStudentDebounceFn , 1000), [])
	const employeeDebounceFn : any = useCallback(_debounce(handleEmployeeDebounceFn, 1000), [])

	async function handleStudentDebounceFn(campusId : number, term : string)
	{
		setStudentSearchInProgress(true)

		try
		{
			let result : any = await StudentsService.searchStudents(campusId, {q : term}, undefined, 100)

			if(result.status == 200 || result.status == 204)
			{
				setStudentsList(result.data)
			}
			else
			{
				setSearchError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			setSearchError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setStudentSearchInProgress(false)
		}
	}

	async function handleEmployeeDebounceFn(campusId : number, term : string)
	{
		setEmployeeSearchInProgress(true)

		try
		{
			let result : any = await EmployeesService.searchEmployees(campusId, {q : term}, undefined, 100)

			if(result.status == 200 || result.status == 204)
			{
				setEmployeesList(result.data)
			}
			else
			{
				setSearchError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			setSearchError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setEmployeeSearchInProgress(false)
		}
	}

	const searchStudents = (term : string)=>
	{
		setCustomSearch({...customSearch, studentSearchQuery : term})
		studentDebounceFn(campusRef[currentUserCampus].id, term)
	}

	const searchEmployees = (term : string)=>
	{
		setCustomSearch({...customSearch, employeeSearchQuery : term})
		employeeDebounceFn(campusRef[currentUserCampus].id, term)
	}

	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async (page? : any, reporting? : boolean)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		if(reporting == true)
		{
			PaymentsService.searchPayments(campusRef[currentUserCampus].id, customSearch, 0, reporting).then
			(
				(result : any)=>
				{
					FileSaver.saveAs(new Blob([result.data], {type : "application/octet-stream"}), "Reporte.xlsx")
				},
				(error : any)=>
				{
					console.log(error);

					procedureComplaint
					(
						error.response.status != 404
							? "El reporte no pudo ser generado."
								: "No se encontraron registros que cumplan con los filtros especificados."
					)
				}
			)
		}
		else
		{
			try
			{
				if(extendedFilterShown)
				{
					setExtendedFilterShown(false)
				}

				dispatch(enableIdleLockSwitch())

				const result = await PaymentsService.searchPayments(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

				if(result.status == 200 || result.status == 204)
				{
					setPagination(pageGrouping(result.data.data.current_page, result.data.data.last_page))

					setData
					(
						result.data.data.data.map
						(
							(record : any)=>
							{
								let tagRef       : any      = {}
								let conceptNames : string[] = ["owedTuitions", "owedArticles"]

								conceptNames.map
								(
									(concept : string)=>
									{
										if(isNotEmpty(record.invoice[concept]))
										{
											record.invoice[concept].map
											(
												(concept : any)=>
												{
													if(concept.schedule == null)
													{
														concept.article.tags
														(
															(tag : any)=>
															{
																if(tagRef["T_" + tag.id] == null)
																{
																	tagRef["T_" + tag.id] = tag
																}
															}
														)
													}
													else
													{
														concept.schedule.level.course.tags.map
														(
															(tag : any)=>
															{
																if(tagRef["T_" + tag.id] == null)
																{
																	tagRef["T_" + tag.id] = tag
																}
															}
														)
													}
												}
											)
										}
									}
								)

								return {...record, tags : Object.values(tagRef)}
							}
						)
					)

					setSectionLoaded(true)
				}
				else
				{
					procedureComplaint(searchError)
				}
			}
			catch(error)
			{
				console.log(error)

				procedureComplaint(searchError)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
				setClearSearchRequested(false)
			}
		}
	}


	const confirmRemoval = (record : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string = "No se pudo eliminar el registro seleccionado. " +
						"Puede que la información en otra sección dependa de estos datos, por lo que no será posible realizar por completo la operación"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await PaymentsService.removePayment(campusRef[currentUserCampus].id, record.student_id, record)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded || pagination == null ? "" : <>
		<SectionContainer
		  searchFn={search}
		  pagination={pagination}
		  clearSearchFn={clearSearch}
		  customSearch={customSearch}
		  defaultSearch={defaultSearch}
		  searchChangeFn={handleSearchChange}
		  extendedFilterShown={extendedFilterShown}
		  clearSearchRequested={clearSearchRequested}
		  setExtendedFilterShown={setExtendedFilterShown}
		  generalPlaceHolder={"Título, notas, referencia"}
		  searchIcon={<FontAwesomeIcon icon={solid("money-check-dollar")} />}
		  additionalClasses={"compactableSection" + (promptDisplayQueue[0] == null ? "" : " sidePrompt")}
		  managementOverride={promptDisplayQueue[0] == null ? <div className="fluentBlockSm"></div> : <></>}
		  additionalOptions=
		  {<button style={{width : "100%", textAlign : "left"}} onClick={() => search(0, true)} className="btn btn-success"
			  disabled={idleLocked} type="button"
			>
				<FontAwesomeIcon icon={solid("file-excel")} />
				<span style={{paddingLeft : "10px"}}>
					Descargar reporte (.xlsx)
				</span>
		  </button>} additionalStyle=
		  {{
			float : "left", height : "100%", overflow : "auto", width : "calc(100%" + 
				(promptDisplayQueue[0] == null ? ")" : " - " + promptWidth[promptDisplayQueue[0]] + ")")
		  }} filterSummary=
		  {
			[
				isEmpty(customSearch.creationValueA) && isEmpty(customSearch.creationValueB) ? null :
				{
					label   : "Creación",
					display :
						(
							(customSearch.creationValueA == customSearch.creationValueB) ||
								(customSearch.creationValueA != null && customSearch.creationValueB == null)
						)
							? toAscendingDate(customSearch.creationValueA)
							:
					(
						customSearch.creationValueA == null ? toAscendingDate(customSearch.creationValueB) :
						(
							customSearch.creationValueA > customSearch.creationValueB
								?
							("[" + toAscendingDate(customSearch.creationValueB) + " - " + toAscendingDate(customSearch.creationValueA) + "]")
								:
							("[" + toAscendingDate(customSearch.creationValueA) + " - " + toAscendingDate(customSearch.creationValueB) + "]")
						)
					)
				},
				isEmpty(customSearch.paymentValueA) && isEmpty(customSearch.paymentValueB) ? null :
				{
					label   : "Pago",
					display :
					(
						(customSearch.paymentValueA == customSearch.paymentValueB) ||
						(customSearch.paymentValueA != null && customSearch.paymentValueB == null)
					)
						? toAscendingDate(customSearch.paymentValueA)
							:
					(
						customSearch.paymentValueA == null ? toAscendingDate(customSearch.paymentValueB) :
						(
							customSearch.paymentValueA > customSearch.paymentValueB
								?
							("[" + toAscendingDate(customSearch.paymentValueB) + " - " + toAscendingDate(customSearch.paymentValueA) + "]")
								:
							("[" + toAscendingDate(customSearch.paymentValueA) + " - " + toAscendingDate(customSearch.paymentValueB) + "]")
						)
					)
				},
				customSearch.amountBalanceA == "" && customSearch.amountBalanceB == "" ? null :
				{
					label   : "Monto",
					display : operators[customSearch.amountBalanceA].display + " " + customSearch.amountQuantityA +
					(
						customSearch.amountBalanceB == "" ? "" : " & " + operators[customSearch.amountBalanceB].display + " " +
							customSearch.amountQuantityB
					)
				},
				customSearch.student == null ? null :
				{
					label   : "Alumno",
					display : customSearch.student. first_name + " " + customSearch.student.last_name
				},
				customSearch.employee == null ? null :
				{
					label   : "Empleado",
					display : customSearch.employee. first_name + " " + customSearch.employee.last_name
				},
				customSearch.method == null ? null :
				{
					label   : "Método de pago",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{customSearch.method.name}
					</p>
				},
				customSearch.account == null ? null :
				{
					label   : "Cuenta receptora",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>{
						customSearch.account.name +
							"[" + customSearch.account.account_number + "]"
					}</p>
				},
				customSearch.tags.length < 1 ? null : 
				{
					label   : "Etiquetas",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				},
			].filter((setting) => setting)
		  } extendedFilterViewClosing={() => setExtendedFilterShown(false)} advancedSearchForm=
		  {<>
			<div className="container">
				<fieldset>
					<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
						Alumno
					</label></legend>
					<div>{
						customSearch.student != null
								?
							<div style={{display : "flex", alignItems : "stretch", border : "1px solid #C7C7C7", borderRadius : "15px", padding : "10px"}}>
								<p style={{flexGrow	 : 1, wordBreak : "break-word", margin :"unset"}}>
									{customSearch.student.first_name + " " + customSearch.student.last_name}
								</p>
								<button onClick={() => {setCustomSearch({...customSearch, student : null})}}
								  style={{...styles.btnCloseModal, flexGrow : 0}}
								>
									<FontAwesomeIcon style={{ height: "100%", position : "relative", bottom : "1px"}}
									  icon={solid("times")}
									/>
								</button>
							</div>
								:
							<Popover isOpen={studentPopoverOpen} onClickOutside={() => setStudentPopoverOpen(false)}
							  positions={["bottom"]} content=
							  {<div style=
								{{
									overflowY     : "auto",
									maxHeight     : "40vh",
									background    : "var(--main-bg)",
									width         : "440px",
									display       : "flex",
									flexDirection : "column"
								}}
							  >
								{
									(studentsList == null || studentsList.length < 1) ? "" : studentsList.filter((student : any) => student).map
									(
										(student : any, index : number) => <div key={"T_" + index}
										  style={{display : "inline-grid", padding : "5px", width : "100%"}}
										><div style={{display : "grid", margin : "0px 5px"}}><button key={"T_" + index}
										  onClick={() => setCustomSearch({...customSearch, student : student})} type="button"
										  style=
										  {{
											whiteSpace   : "nowrap",
											overflow     : "hidden",
											textOverflow : "ellipsis",
											textAlign    : "left"
										  }}
										><span style={{paddingLeft : "10px"}}>
											{student.first_name + " " + student.last_name}
										</span></button></div></div>
									)
								}
								<div className="text-center" style={{color : "#C7C7C7"}}>
									No hay más resultados.
								</div>
							  </div>}
							>
							<div>
								{!studentSearchInProgress ? "" : <div className="loader"></div>}
								<input disabled={idleLocked || clearSearchRequested} name="studentSearchQuery" maxLength={50}
								  style={{flexGrow : 1, background : "#F3F8FF", width : "100%", height : "44px", border : "1px solid #8F91DA"}}
								  onClick={() => setStudentPopoverOpen(!studentPopoverOpen)} type="text" id="studentSearchQuery"
								  onChange={(e : any) => searchStudents(e.target.value)} placeholder="Nombre, teléfono, etc."
								  value={customSearch.studentSearchQuery || ""}
								/>
							</div>
						</Popover>
					}</div>
				</fieldset>
				<fieldset>
					<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
						Empleado
					</label></legend>
					<div>
						{
							customSearch.employee != null
									?
								<div style={{display : "flex", alignItems : "stretch", border : "1px solid #C7C7C7", borderRadius : "15px", padding : "10px"}}>
									<p style={{flexGrow	 : 1, wordBreak : "break-word", margin : "unset"}}>
										{customSearch.employee.first_name + " " + customSearch.employee.last_name}
									</p>
									<button onClick={() => {setCustomSearch({...customSearch, employee : null})}}
									  style={{...styles.btnCloseModal, flexGrow : 0}} type="button"
									>
										<FontAwesomeIcon style={{ height: "100%", position : "relative", bottom : "1px"}}
										  icon={solid("times")}
										/>
									</button>
								</div>
									:
								<Popover isOpen={employeePopoverOpen} onClickOutside={() => setEmployeePopoverOpen(false)}
								  positions={["bottom"]} content=
								  {<div style=
									{{
										overflowY     : "auto",
										maxHeight     : "40vh",
										background    : "var(--main-bg)",
										width         : "440px",
										display       : "flex",
										flexDirection : "column"
									  }}
								  >
									{
										(employeesList == null || employeesList.length < 1) ? "" : employeesList.filter((employee : any) => employee).map
										(
											(employee : any, index : number) => <div key={"T_" + index}
											  style={{display : "inline-grid", padding : "5px", width : "100%"}}
											><div style={{display : "grid", margin : "0px 5px"}}><button key={"T_" + index}
											  onClick={() => setCustomSearch({...customSearch, employee : employee})} type="button"
											  style=
											  {{
												whiteSpace   : "nowrap",
												overflow     : "hidden",
												textOverflow : "ellipsis",
												textAlign    : "left"
											  }}
											><span style={{paddingLeft : "10px"}}>
												{employee.first_name + " " + employee.last_name}
											</span></button></div></div>
										)
									}
									<div className="text-center" style={{color : "#C7C7C7"}}>
										No hay más resultados.
									</div>
								  </div>}
								>
								<div>
									{!employeeSearchInProgress ? "" : <div className="loader"></div>}
									<input disabled={idleLocked || clearSearchRequested} placeholder="Nombre, teléfono, etc."
									  style={{flexGrow : 1, background : "#F3F8FF", width : "100%", height : "44px", border : "1px solid #8F91DA"}}
									  value={customSearch.employeeSearchQuery || ""} id="employeeSearchQuery" maxLength={50}
									  name="employeeSearchQuery" onChange={(e : any) => searchEmployees(e.target.value)}
									  onClick={() => setEmployeePopoverOpen(!employeePopoverOpen)} type="text"
									/>
								</div>
							</Popover>
						}
					</div>
				</fieldset>
				<fieldset>
					<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
						Fecha(s) de creación
					</label></legend>
					<div className="row">
						<div className="col-sm-6">
							<LeDatePicker id="creationValueA" years={basicInfo.futureRange} name="creationValueA" months={monthRef}
							  value={customSearch.creationValueA} portalId="creationValueA" onChange={(e : any) => handleSearchChange(e)}
							/>
						</div>
						<div className="col-sm-6">
							<LeDatePicker id="creationValueB" years={basicInfo.futureRange} name="creationValueB" months={monthRef}
							  value={customSearch.creationValueB} portalId="creationValueB" onChange={(e : any) => handleSearchChange(e)}
							/>
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
						Fecha(s) de pago
					</label></legend>
					<div className="row">
						<div className="col-sm-6">
							<LeDatePicker id="paymentValueA" years={basicInfo.futureRange} name="paymentValueA" months={monthRef}
							  value={customSearch.paymentValueA} portalId="paymentValueA" onChange={(e : any) => handleSearchChange(e)}
							/>
						</div>
						<div className="col-sm-6">
							<LeDatePicker id="paymentValueB" years={basicInfo.futureRange} name="paymentValueB" months={monthRef}
							  value={customSearch.paymentValueB} portalId="paymentValueB" onChange={(e : any) => handleSearchChange(e)}
							/>
						</div>
					</div>
				</fieldset>
				<div className="row">
					<div className="col-sm-6"><fieldset>
						<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
							Método de pago
						</label></legend>
						<SelectReact value={customSearch.method} children={Object.values(paymentMethodRef)} disabled={idleLocked}
						  placeholder={Constants.Screens.Finances.modalPayments.payment.method} style={{height : "30px"}}
						  className="btn btn-primary text-start" id="method" onChange={handleSearchChange} name="method"
						/>
					</fieldset></div>
					<div className="col-sm-6"><fieldset>
						<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
							Cuenta receptora
						</label></legend>
						<SelectReact placeholder="Cuenta receptora" className="btn btn-primary text-start" name="account"
						  children={Object.values(recipientAccountRef)} value={customSearch.account} disabled={idleLocked}
						  onChange={handleSearchChange} id="account" style={{height : "30px"}}
						/>
					</fieldset></div>
				</div>
				<fieldset>
					<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
						Monto
					</label></legend>
					<div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
						<div style={{flexGrow : 3}}>
							<div style={{display : "flex", padding : "5px"}}>
								<div style={{paddingTop : "15px"}}>
									<div className="operatorOptions" style={{display : "inline-flex"}}><select id="amountBalanceA"
									  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
									  className="btn btnVeryPeri" onChange={handleSearchChange} name="amountBalanceA"
									  disabled={idleLocked} value={customSearch.amountBalanceA}
									>{
										operatorList.map
										(
											(key: any) => <option key={key.code} value={key.code}>
												{key.display}
											</option>
										)
									}</select></div>
								</div>
								<div className="inputContainer" style={{flexGrow : 1}}>
									<input onChange={handleSearchChange} style={{width : "100%", textAlign : "right"}}
									  disabled={idleLocked} onBlur={validateField} type="number"
									  value={customSearch.amountQuantityA} onFocus={validateField}
									  maxLength={50} placeholder="Monto" name="amountQuantityA" id="amountQuantityA" required
									/>
								</div>
							</div>
						</div>
						{
							(customSearch.amountBalanceA == "" || customSearch.amountBalanceA == "equals") ||
								(
									customSearch.amountQuantityA == 0 &&
										(customSearch.amountBalanceA == "lt" || customSearch.amountBalanceA == "lte")
								) ? "" : <>
									<div style={{flexGrow : 2, textAlign : "center", paddingTop : "15px"}}><div
									  style={{fontWeight : 900, width : "50px", height : "50px", display : "inline-block", borderRadius : "100%", color : "#FFFFFF", background : "#808080", fontSize : "18px", paddingTop : "12px"}}
									>
										&amp;
									</div></div>
									<div style={{flexGrow : 3}}><div style={{display : "flex", padding : "5px"}}>
										<div style={{paddingTop : "15px"}}>
											<div className="operatorOptions" style={{display : "inline-flex"}}><select
											  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
											  disabled={idleLocked} value={customSearch.amountBalanceB} id="amountBalanceB"
											  className="btn btnVeryPeri" onChange={handleSearchChange}
											  name="amountBalanceB"
											>{
												operatorList.filter
												(
													(operator : any) => operator.code != "equals" && operator.code.charAt(0)
														!= customSearch.amountBalanceA.charAt(0)
												).map
												(
													(key : any) => <option key={key.code} value={key.code}>
														{key.display}
													</option>
												)
											}</select></div>
										</div>
										<div className="inputContainer" style={{flexGrow : 1}}>
											<input type="number" onFocus={validateField}
											  disabled={idleLocked} onBlur={validateField}
											  maxLength={50} placeholder="Monto" name="amountQuantityB" id="amountQuantityB"
											  onChange={handleSearchChange} style={{width : "100%", textAlign : "right"}}
											  value={customSearch.amountQuantityB} required
											/>
										</div>
									</div></div>
							</>
						}
					</div>
				</fieldset>
				<fieldset style={{height : "100%"}}>
					<legend><label style={{position : "relative", top : "4px"}}>
						Etiquetas
					</label></legend>
					<Multiselect emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
					  placeholder="Seleccione" onSelect={(tags : any) => setCustomSearch({...customSearch, tags : tags})}
					  onRemove={(tags : any) => setCustomSearch({...customSearch, tags : tags})} displayValue="name"
					/>
				</fieldset>
			</div>
		  </>}
		>{
			(data.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					Sin resultados para este plantel
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="periodsHeader">
					<th ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #000000", "important")}}>
						ID
					</th>
					<th>
						Referencia
					</th>
					<th>
						Correlativo
					</th>
					<th>
						Nombres
					</th>
					<th>
						Apellidos
					</th>
					<th>
						ID Factura
					</th>
					<th>
						Información
					</th>
					<th>
						Cantidad de Pago
					</th>
					<th>
						Método de Pago
					</th>
					<th>
						Cuenta Receptora
					</th>
					<th>
						Etiquetas
					</th>
					<th>
						Creación
					</th>
					<th>
						Fecha de Pago
					</th>
					<th />
				</tr></thead>
				<tbody>{
					data.map
					(
						(record : any, index : number) => <tr key={"period" + index}>
							<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
								{record.id}
							</td>
							<td>
								{record.reference_number}
							</td>
							<td>
								{record.student.student.identity_code}
							</td>
							<td>
								{record.student.first_name}
							</td>
							<td>
								{record.student.last_name}
							</td>
							<td>
								{record.invoice_id}
							</td>
							<td>
								{record.title}
								<div style={{whiteSpace : "unset", wordBreak : "break-all"}}>{
									isEmpty(record.notes) ? "-" : <>
										<hr style={{border : "1px solid  #C7C7C7"}} />
										{record.notes}
										<br />
										{isEmpty(record.invoice.notes) ? "-" : record.invoice.notes}
									</>
								}</div>
							</td>
							<td style={{textAlign : "right"}}><div style={{display : "flex", alignItems : "stretch"}}>
								<span style={{paddingRight : "5px"}}>
									{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
								</span>
								<span style={{flexGrow : 1}}>
									{sprintf(" %.02f", +record.amount)}
								</span>
							</div></td>
							<td>{
								paymentMethodRef["PM_" + record.method_id] &&
									paymentMethodRef["PM_" + record.method_id].name
							}</td>
							<td>{
								recipientAccountRef["RA_" + record.account_id] &&
									recipientAccountRef["RA_" + record.account_id].label
							}</td>
							<td>{
								isEmpty(record.tags) ? "-" : record.tags.map
								(
									(item : any, index : number) => <div className="badge rounded-pill bg-primary" key={"crc_" + item.id}>
										{item.name}
									</div>
								)
							}</td>
							<td>
								{toAscendingDate(new Date(record.created_at))}
							</td>
							<td>
								{toAscendingDate(new Date(record.payment_date + " 23:00:00"))}
							</td>
							<td><div style={{display : "flex"}}>
								<button disabled={idleLocked} className="button btn btn-default"
								  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
								  type="button" onClick=
								  {
									()=>
									{
										let amount               : number
										let taxId                : number
										let taxInPercentage      : boolean
										let discountInPercentage : boolean
										let taxValue             : number
										let discountValue        : number
										let taxAmount            : number
										let discountAmount       : number
										let subTotal             : number
										let pendingPaidTotal     : number = 0
										let approvedPaidTotal    : number = 0
										let total                : number = 0

										let invoice : any=
										{
											...record.invoice, notes : record.invoice.notes || "",
											fromPayment              : record.id,
											total                    : +record.invoice.total,
											paid_amount              : +record.invoice.paid_amount,
											owed_tuitions            : record.invoice.owed_tuitions.length < 1 ? [] : record.invoice.owed_tuitions.map
											(
												(tuition : any)=>
												{
													amount               = +tuition.amount
													taxId                = tuition.tax_id || 0
													taxInPercentage      = tuition.tax_value.substr(-1) == "%"
													discountInPercentage = tuition.discount.substr(-1) == "%"
													taxValue             = taxId < 1 ? 0 : +(tuition.tax_value.replace("%", ""))
													discountValue        = +(tuition.discount.replace("%", ""))
													taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
													discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
													subTotal             = tuition.amount + taxAmount - discountAmount
													total                = 0

													return {
														...tuition, tax_id   : taxId,
														amount               : amount,
														taxInPercentage      : taxInPercentage,
														discountInPercentage : discountInPercentage,
														taxValue             : taxValue,
														discountValue        : discountValue,
														taxAmount            : taxAmount,
														discountAmount       : discountAmount,
														subTotal             : subTotal,
														quantity             : 1,
														price                : amount
													}
												}
											),
											owed_articles             : record.invoice.owed_articles.length < 1 ? [] : record.invoice.owed_articles.map
											(
												(article : any)=>
												{
													amount               = +article.amount
													taxId                = article.tax_id || 0
													taxInPercentage      = article.tax_value.substr(-1) == "%"
													discountInPercentage = article.discount.substr(-1) == "%"
													taxValue             = taxId < 1 ? 0 : +(article.tax_value.replace("%", ""))
													discountValue        = +(article.discount.replace("%", ""))
													taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
													discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
													subTotal             = article.amount + taxAmount - discountAmount
													total                = 0

													return {
														...article, tax_id   : taxId,
														amount               : amount,
														taxInPercentage      : taxInPercentage,
														discountInPercentage : discountInPercentage,
														taxValue             : taxValue,
														discountValue        : discountValue,
														taxAmount            : taxAmount,
														discountAmount       : discountAmount,
														subTotal             : subTotal
													}
												}
											),
											payments : record.invoice.payments.map
											(
												(payment : any)=>
												{
													if(payment.status_id != paymentStatus.REJECTED)
													{
														if(payment.status_id == paymentStatus.APPROVED)
														{
															approvedPaidTotal += +payment.amount
														}
														else
														{
															pendingPaidTotal += +payment.amount
														}
													}

													return payment
												}
											)
										}

										showInvoicePrompt
										({
											...invoice, total : total,
											student           : record.student,
											approvedPaidTotal : approvedPaidTotal,
											pendingPaidTotal  : pendingPaidTotal
										})
									}
								  }
								>
									<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
								</button>
								{
									!allowed.delete ? "" : <button className="button btn btn-default" disabled={idleLocked}
									  onClick={() => confirmRemoval(record)} type="button"
									  style={{display : "table-column"}}
									>
										<CustomIcon name="bTrash" />
									</button>
								}
							</div></td>
						</tr>
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			(
				(promptDisplayQueue == null || promptDisplayQueue.length < 1) || (selectedInvoice == null && selectedPayment == null)
			) ? "" : <FinancePrompt paymentMethodRef={paymentMethodRef} setPromptDisplayQueue={setPromptDisplayQueue}
			  selectedInvoice={selectedInvoice} paymentStatusRef={paymentStatusRef} payment={selectedPayment} taxRef={taxRef}
			  defaultDate={defaultDate} student={selectedInvoice ? selectedInvoice.student : selectedPayment.student}
			  invoiceStatusRef={invoiceStatusRef} availableAccounts={availableAccounts}
			  recipientAccountRef={recipientAccountRef} promptDisplayQueue={promptDisplayQueue} resetFn=
			  {
				()=>
				{
					setCustomSearch(defaultSearch)
					setPromptDisplayQueue([])
					search()
				}
			  } promptDismissal=
			  {
				()=>
				{
					setPromptDisplayQueue([])
					setSelectedInvoice(null)
				}
			  }
			/>
		}
	</>
}

export default Payments
