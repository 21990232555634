import
{
	useState,
	useRef,
	useReducer,
	useEffect
}
	from "react"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import {useNavigate}
	from "react-router-dom"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import Multiselect
	from "multiselect-react-dropdown"
import {Tooltip}
	from "react-bootstrap"
import OverlayTrigger
	from "react-bootstrap/OverlayTrigger"
import Hashids
	from "hashids"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"

import
{
	UIState,
	pageGrouping,
	validateField,
	hashCode,
	getAccessPermissions,
	isEmpty,
	operators,
	isNotEmpty,
	operatorList
}
	from "./../../common"
import Constants, {studentStatus}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer
	from "./../../components/sectionContainer"
import StudentInfoModal
	from "./studentInfoModal"
import CatalogService
	from "./../../services/catalog.service"
import StudentsService
	from "./../../services/campus/students.service"

import "./../../components/dropdown/style.css"
import "./../../components/input/style.css"

type  State                = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action=
{
	type    : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch (action.type){case "setIsButtonDisabled" : return{...state, isButtonDisabled : action.payload}}
}

const Students = ()=>
{
	const ACADEMIC_DEGREES : number = 0

	let   fileReader : any
	const [state, trigger]                                  = useReducer(reducer, initialState)
	const dispatch                                          = useDispatch()
	const [academicDegrees, setAcademicDegrees]             = useState<any>([])
	const [totalRecords, setTotalRecords]                   = useState<number>(0)
	const [initSetup, setInitSetup]                         = useState<number | null>(null)
	const [data, setData]                                   = useState<any[]>([])
	const [readOnlyData, setReadOnlyData]                   = useState<any[]>([])
	const currentUser       : any                           = useSelector((state : RootState) => state.userProfile.value)
	const idleLocked        : boolean                       = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null                 = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean                       = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo         : any                           = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                           = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                           = useSelector((state : RootState) => state.campusRef.value)
	const [userCampus, setUserCampus]                       = useState<number>(currentUserCampus)
	const studentStatuses  : any                            = Object.values(basicInfo.studentStatusRef)
	const [sectionLoaded, setSectionLoaded]                 = useState<boolean>(false)
	const [moduleReady, setModuleReady]                     = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested]   = useState<boolean>(false)
	const [currentTab, setCurrentTab]                       = useState<string | null>("mainInfo")
	const [UIStatus, setUIStatus]                           = useState<number>(UIState.NORMAL)
	const [newStudent, setNewStudent]                       = useState<any>({})
	const [extendedFilterShown, setExtendedFilterShown]     = useState<boolean>(false)
	const [selectedStudent, setSelectedStudent]             = useState<any>(null)
	const [pagination, setPagination]                       = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                     = useState<boolean>(false)
	const [saveError, setSaveError]                         = useState<any>(null)
	const [selectedRecords, setSelectedRecords]             = useState<number>(0)
	const [selectableRecords, setSelectableRecords]         = useState<number>(0)
	const [selectedRef, setSelectedRef]                     = useState<any>({})
	const count : number[]                                  = [-1, 1]
	const allowed               : any                       = getAccessPermissions(currentUser)
	let   searchFormRef                                     = useRef<HTMLFormElement>(null)

	const activeScheduleSearch = ["No", "Sí", "Mostrar todo"]
	const defaultSearch=
	{
		q               : "",
		statusId        : studentStatus.ACTIVE,
		tags            : [],
		activeSchedule  : 2,
		monthlyBalance  : operatorList[0].code,
		kitsBalance     : operatorList[0].code,
		monthlyQuantity : 0,
		kitsQuantity    : 0
	}

	const [customSearch, setCustomSearch] = useState<any>(defaultSearch)
	const navigate                        = useNavigate()
	const navigateTo                      = (params : any) => navigate("/statement", {state : params})

	const showPrompt = (recordData : any)=>
	{
		let   refIndex      : string
		let   sessionIndex  : any
		let   formattedData : any
		let   agenda        : any = {}
		const hashIds       : any = new Hashids()

		let student=
		{
			...recordData, imagePreview : recordData.image,
			enrollments                 : recordData.enrollments.map
			(
				(enrollment : any)=>
				{
					refIndex     = "S_" + enrollment.schedule.id
					sessionIndex = {}

					enrollment.schedule.sessions.map
					(
						(session : any)=>
						{
							let currentHours = session.start_hours

							sessionIndex["S_" + session.week_day] = session

							while(currentHours < session.end_hours)
							{
								agenda[sprintf("DH%d%03d", session.week_day, currentHours)] = refIndex

								currentHours += 5
							}
						}
					)

					formattedData=
					{
						...enrollment.schedule, refIndex : refIndex,
						sessionRef                       : sessionIndex,
						//RGB code generation based on hashing the distinct parenting ids (schedule, level and course)------
						color                            : sprintf
						(
							"#%02X%02X%02X",
							(hashCode(hashIds.encode(enrollment.schedule.id)) & 255),
							(hashCode(enrollment.schedule.level.name        ) & 255),
							(hashCode(enrollment.schedule.level.course.name ) & 255)
						)
					}

					return formattedData
				}
			)
		}

		setUIStatus(UIState.NORMAL)
		setSaveError(null)
		setCurrentTab("mainInfo")
		setSelectedStudent({...student, city : student.city_id == null ? null : {...student.city, label : student.city.name}})
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					dispatch(enableIdleLockSwitch())

					const degreeRetrievalError : string = "Los grados académicos no pudieron ser extraídos"

					try
					{
						const expand = true
						const result = await CatalogService.getAcademicDegrees(sessionToken)

						if(result.status == 200 || result.status == 204)
						{
							setAcademicDegrees(result.data)
							setInitSetup(ACADEMIC_DEGREES)
						}
						else
						{
							procedureComplaint(degreeRetrievalError)
						}
					}
					catch(error)
					{
						procedureComplaint(degreeRetrievalError)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case ACADEMIC_DEGREES:
						if(academicDegrees.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay grados académicos en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							setNewStudent
							({
								country_id               : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
								nationality_id           : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
								status_id                : studentStatus.ACTIVE,
								gender_id                : basicInfo.genders[0].id,
								academic_degree_id       : academicDegrees[0].id,
								identification_type_id   : basicInfo.identificationTypes[0].id,
								birthday                 : null,
								birthdayValue            : "",
								tags                     : [],
								contacts                 : [],
								enrollments              : [],
								identification_code      : "",
								identity_code            : "",
								specific_academic_degree : "",
								last_school_name         : "",
								current_occupation       : "",
								city                     : "",
								address                  : "",
								zipcode                  : "",
								first_name               : "",
								last_name                : "",
								password                 : "",
								phone1                   : "",
								phone2                   : "",
								email                    : "",
								facebook                 : "",
								instagram                : "",
								observations             : ""
							})

							search()
						}
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => setTotalRecords(data.length), [data])

	useEffect
	(
		()=>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			if(moduleReady)
			{
				if(basicInfo.countries.length < 1)
				{
					procedureComplaint("No hay países registrados, por lo que el acceso a este módulo permanecerá deshabilitado")
				}
				else
				{
					search()
				}
			}
		},
		[moduleReady]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				setNewStudent
				({
					...newStudent, country_id : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
					nationality_id            : basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id
				})
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				clearSearch()
			}
		},
		[currentUserCampus, sectionLoaded]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		dispatch(enableIdleLockSwitch())

		if(extendedFilterShown)
		{
			setExtendedFilterShown(false)
		}

		try
		{
			const result = await StudentsService.searchStudents(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))

				let normalData       : any    = []
				let disabledData     : any    = []
				let selectablesCount : number = 0
				let date             : any
				let dataToPush       : any

				result.data.data.map//records separation for read-only and normal ones.--------------------------------------
				(
					(record : any)=>
					{
						date = record.birthday == null ? undefined : record.birthday.replaceAll("-", "/")

						dataToPush=
						{
							...record, birthday : date || "",
							status_id           : !record.bool_enabled ? 0  : record.student.status_id,
							birthdayValue       : date == null         ? "" : new Date(date + " 23:00:00")
						}

						if(record.id == currentUser.id)
						{
							disabledData.push(dataToPush)
						}
						else
						{
							selectablesCount += +
							(
								(
									((+record.campuses[0].tuition_debt - +record.campuses[0].tuition_debt_settlement) == 0)
										&&
									((+record.campuses[0].article_debt - +record.campuses[0].article_debt_settlement) == 0)
								)
									&&
								(+record.campuses[0].positive_balance == 0 && record.enrollments.length < 1)
							)

							if(dataToPush.state_id == null)
							{
								dataToPush=
								{
									...dataToPush, city : null,
									state_id            : ""
								}
							}
							else
							{
								dataToPush.state_name = ""

								if(dataToPush.city_id == null)
								{
									dataToPush.city = null
								}
								else
								{
									dataToPush=
									{
										...dataToPush, city : {...dataToPush.city, label : dataToPush.city.name},
										city_name           : ""
									}
								}
							}

							//adding boolean data type to toggle checking used by batch operations (multiple updating/deletion)--------------------------
							normalData.push({...dataToPush, refIndex : "S_" + record.id})
						}
					}
				)

				setSelectedRef({})
				setReadOnlyData(disabledData)
				//adding boolean data type to toggle checking used by batch operations (multiple updating/deletion)----------
				setData(normalData)
				setSelectableRecords(selectablesCount)
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch (error)
		{
			console.log(error)
			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (record : any)=>
	{
		let selectedMap : any = {...selectedRef}

		if(selectedMap[record.refIndex])
		{
			delete selectedMap[record.refIndex]
		}
		else
		{
			selectedMap[record.refIndex] = record.id
		}

		setSelectedRef({...selectedMap})
		setSelectedRecords(Object.keys(selectedMap).length)
	}

	const toggleSelectAll = ()=>
	{
		const selected    : boolean = selectedRecords < selectableRecords
		let   selectedMap :     any = !selected ? {} : {...selectedRef}

		if(selected)
		{
			data.map
			(
				(record : any)=>
				{
					if
					(
						(
							((+record.campuses[0].tuition_debt - +record.campuses[0].tuition_debt_settlement) == 0)
								&&
							((+record.campuses[0].article_debt - +record.campuses[0].article_debt_settlement) == 0)
						)
							&&
						(+record.campuses[0].positive_balance == 0 && record.enrollments.length < 1)
					)
					{
						selectedMap[record.refIndex] = true
					}
				}
			)
		}

		setSelectedRef(selectedMap)
		setSelectedRecords(selectableRecords * (+selected))
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || Object.keys(selectedRef).length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async () =>
				{
					const recordIds    : number[] = !isNaN(recordId) ? [recordId] : Object.values(selectedRef)
					const removalError : string   = "El comando no pudo ser procesado"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try {
						const result = await StudentsService.removeStudents(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							clearSearch()
						}
						else
						{
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						procedureComplaint(removalError)
					}
					finally {
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const paintingEnrollments = (enrollments : any)=>
	{
		let activeEnrollments   = enrollments.filter((enrollment : any) => enrollment.status_id === 1).length
		let inactiveEnrollments = enrollments.filter((enrollment : any) => enrollment.status_id === 0).length

		return <div style={{display : "flex", justifyContent : "center", gap : "10px"}}>
			<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Cursos Activos</Tooltip>}><div style={{cursor : "pointer"}}
			  className="badge rounded-pill bg-success" key={"active_enrollment_" + activeEnrollments}
			>
				{activeEnrollments}
			</div></OverlayTrigger>
			<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Cursos Inactivos</Tooltip>}><div style={{cursor : "pointer"}}
			  className="badge rounded-pill bg-danger" key={"in_active_enrollment" + inactiveEnrollments}
			>
				{inactiveEnrollments}
			</div></OverlayTrigger>
		</div>
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  clearSearchFn={clearSearch}
		  customSearch={customSearch}
		  defaultSearch={defaultSearch}
		  searchFormRef={searchFormRef}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  extendedFilterShown={extendedFilterShown}
		  searchIcon={<CustomIcon name="student" />}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newStudent)}
		  setExtendedFilterShown={setExtendedFilterShown}
		  generalPlaceHolder={"Nombre, teléfono, observaciones, etc."}
		  filterSummary=
		  {
			[
				{
					label   : "Estatus",
					display : <p style={{fontSize : "16px", margin : 0, textAlign : "center"}}>
						{customSearch.statusId < 0 ? "Mostrar todo" : basicInfo.studentStatusRef["SS_" + customSearch.statusId].name}
					</p>
				},
				customSearch.tags.length < 1 ? null : 
				{
					label : "Intereses",
					value : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				},
				customSearch.monthlyBalance == "" || isNaN(customSearch.monthlyQuantity) ? null :
				{
					label   : "Saldo mensualidades",
					display : <div style={{display : "flex", flexFlow : "wrap", gap : 5, justifyContent : "center"}}><p
					  style={{fontSize : "16px", margin : 0}}
					>
						{operators[customSearch.monthlyBalance].display}
						{customSearch.monthlyQuantity}
					</p></div>
				},
				!customSearch.kitsBalance || isNaN(customSearch.kitsQuantity) ? null :
				{
					label   : "Saldo Kits",
					display : <div style={{display : "flex", flexFlow : "wrap", gap : 5, justifyContent : "center"}}><p
					  style={{fontSize : "16px", margin : 0}}
					>
						{operators[customSearch.kitsBalance].display}
						{customSearch.kitsQuantity}
					</p></div>
				},
				{
					label   : "Horario Activo",
					display : <p style={{fontSize : "16px", margin : 0, textAlign : "center"}}>
						{activeScheduleSearch[customSearch.activeSchedule]}
					</p>
				},
			].filter((setting : any) => setting)
		  }
		  advancedSearchForm=
		  {<div className="container">
			<div className="row">
				<div className="col-md-4"><fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Estatus
					</label></legend>
					<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
					  onClick={() => setCustomSearch({...customSearch, statusId : -1})} style={{textAlign : "left"}}
					  type="button"
					>
						<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
						<span style={{paddingLeft : "5px"}}>
							Mostrar todo
						</span>
					</button>
					{
						studentStatuses.map
						(
							(status : any) => <button disabled={idleLocked || customSearch.statusId === status.id} type="button"
							  className="form-control btn btn-default" key={"S_" + status.id} style={{textAlign : "left"}}
							  onClick={() => setCustomSearch({...customSearch, statusId : status.id})}
							>
								<FontAwesomeIcon icon={customSearch.statusId === status.id ? solid("circle-dot") : solid("circle")} />
								<span style={{paddingLeft : "5px"}}>
									{status.name}
								</span>
							</button>
						)
					}
				</fieldset></div>
				<div className="col-md-4"><fieldset style={{height : "100%"}}>
					<legend><label style={{position : "relative", top : "4px"}}>
						Intereses
					</label></legend>
					<Multiselect emptyRecordMsg="No hay más opciones" options={basicInfo.tags} placeholder="Seleccione"
					  onSelect={(tags) => setCustomSearch({...customSearch, tags : tags})} selectedValues={customSearch.tags}
					  onRemove={(tags) => setCustomSearch({...customSearch, tags : tags})} displayValue="name"
					/>
				</fieldset></div>
				<div className="col-md-4">
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Con horario activo
						</label></legend>
						<div className="selectContainer">
							<select onChange={handleSearchChange} id="activeSchedule" value={customSearch.activeSchedule}
							  name="activeSchedule"
							>{
								activeScheduleSearch.map
								(
									(key : any, index : number) => <option key={"select_" + key + "_schedule"} value={index}>
										{key}
									</option>
								)
							}</select>
							<div className="iconRight">
								<CustomIcon name={"rowDown"} />
							</div>
						</div>
					</fieldset>
					<fieldset style={{padding : "0px 15px"}}>
						<legend><label style={{position : "relative", top : "4px"}}>
							Saldos
						</label></legend>
						<div style={{display : "flex", width : "100%", alignItems : "stretch", flexWrap : "wrap"}}>
							<div style={{minWidth : "100px", flexGrow : 1}}>
								<label htmlFor="name" style={{color : "#000000", paddingLeft : "25px"}}>
									Mensualidades
								</label>
								<div style={{display : "flex", padding : "5px"}}>
									<div style={{paddingTop : "15px"}}>
										<div className="operatorOptions" style={{display : "inline-flex"}}><select id="monthlyBalance"
										  className="btn btnVeryPeri" name="monthlyBalance" onChange={handleSearchChange}
										  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
										  disabled={idleLocked} value={customSearch.monthlyBalance}
										>{
											operatorList.map
											(
												(key : any) => <option key={key.code} value={key.code}>
													{key.display}
												</option>
											)
										}</select></div>
									</div>
									<div className="inputContainer" style={{flexGrow : 1}}>
										<input onFocus={validateField} disabled={idleLocked}
										  maxLength={50} placeholder="Monto Mensualidad" name="monthlyQuantity" type="number"
										  value={customSearch.monthlyQuantity} style={{width : "100%", textAlign : "right"}}
										  onChange={handleSearchChange} onBlur={validateField}
										  id="monthlyQuantity" required
										/>
									</div>
								</div>
							</div>
							<div style={{minWidth : "100px", flexGrow : 1}}>
								<label htmlFor="name" style={{color : "#000000", paddingLeft : "25px"}}>
									Kits
								</label>
								<div style={{display : "flex", padding : "5px"}}>
									<div style={{paddingTop : "15px"}}><div className="operatorOptions" style={{display : "inline-flex"}}>
										<select name="kitsBalance" value={customSearch.kitsBalance} className="btn btnVeryPeri"
										  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
										  onChange={handleSearchChange} disabled={idleLocked} id="kitsBalance"
										>{
											operatorList.map
											(
												(key : any) => <option key={key.code} value={key.code}>
													{key.display}
												</option>
											)
										}</select>
									</div></div>
									<div className="inputContainer" style={{flexGrow : 1}}>
										<input onChange={handleSearchChange} value={customSearch.kitsQuantity} maxLength={50}
										  onFocus={validateField} name="kitsQuantity" type="number"
										  style={{width : "100%", textAlign : "right"}} disabled={idleLocked} placeholder="Monto Kits"
										  id="kitsQuantity" onBlur={validateField} required
										/>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		  </div>}
		>{
			(data.length < 1 && readOnlyData.length < 1)
					?
				<>
					<hr />
					<div style={{padding : "25px"}}>
						{Constants.noCampusSearchResults}
					</div>
					<hr />
				</>
					:
				<table className="commonTable">
					<thead><tr key="usersHeader">
						{
							(selectableRecords < 1 || !allowed.delete)? <th /> : <th><button className="btn btn-default"
							  disabled={idleLocked || saveSuccess} type="button" onClick={toggleSelectAll}
							>
								{<CustomIcon name={selectedRecords == selectableRecords ? "bCheck" : "bUncheck"} />}
							</button></th>
						}
						<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}
						  style={{width : "1px"}}
						>
							Correlativo
						</th>
						<th>
							Estatus
						</th>
						<th>
							Nombres
						</th>
						<th>
							Apellidos
						</th>
						<th>
							Teléfonos
						</th>
						<th>
							Intereses
						</th>
						<th>
							Saldo mensualidades
						</th>
						<th>
							Saldo kits
						</th>
						<th>
							Crédito a favor
						</th>
						<th>
							Estatus Cursos
						</th>
						<th />
					</tr></thead>
					<tbody>
						{
							readOnlyData.map
							(
								(record : any, index : number) => <tr key={"user" + index}>
									{(data.length < 1 || !allowed.delete)? "" : <td />}
									<td ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}>
										{record.student.identity_code}
									</td>
									<td>
										{basicInfo.studentStatusRef["SS_" + record.status_id].name}
									</td>
									<td>
										{record.first_name || "-"}
									</td>
									<td>
										{record.last_name || "-"}
									</td>
									<td>
										{[record.phone1, record.phone2].filter((phone : string) => isNotEmpty(phone)).join(", ") || "-"}
									</td>
									<td>{
										isEmpty(record.tags) ? "-" : record.tags.map
										(
											(item : any, index : number) => <div className="badge rounded-pill bg-primary" key={"crc_" + item.id}>
												{item.name}
											</div>
										)
									}</td>
									<td style={{textAlign : "right"}}><div style={{display : "flex", alignItems : "stretch"}}>
										<span style={{paddingRight : "5px"}}>
											{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
										</span>
										<span style={{flexGrow : 1}}>
											{sprintf(" %.02f", +record.campuses[0].tuition_debt - +record.campuses[0].tuition_debt_settlement)}
										</span>
									</div></td>
									<td style={{textAlign : "right"}}><div style={{display : "flex", alignItems : "stretch"}}>
										<span style={{paddingRight : "5px"}}>
											{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
										</span>
										<span style={{flexGrow : 1}}>
											{sprintf(" %.02f", +record.campuses[0].article_debt - +record.campuses[0].article_debt_settlement)}
										</span>
									</div></td>
									<td style={{textAlign : "right"}}><div style={{display : "flex", alignItems : "stretch"}}>
										<span style={{paddingRight : "5px"}}>
											{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
										</span>
										<span style={{flexGrow : 1}}>
											{sprintf(" %.02f", +record.campuses[0].positive_balance)}
										</span>
									</div></td>
									<td>
										{paintingEnrollments(record.enrollments)}
									</td>
									<td>
										<div style={{display : "flex"}}>
											<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>} >
												<button
												  type="button"
												  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
												  className="button btn btn-default"
												  disabled={idleLocked}
												  onClick=
												  {
													() => showPrompt
													({
														...record.data, phone    : record.phone || "",
														specific_academic_degree : record.student.specific_academic_degree || "",
														id_clase_365             : record.id_clase_365 || "",
														zipcode                  : record.zipcode || "",
														correlativo_clase_365    : record.student.correlativo_clase_365 || "",
														current_occupation       : record.student.current_occupation || "",
														academic_degree          : record.student.academic_degree,
														academic_degree_id       : record.student.academic_degree?.id,
														observations             : record.student.observations || "",
														last_school_name         : record.student.last_school_name || "",
														identity_code            : record.student.identity_code,
														bool_read_only           : 1
													})
												 }
												>
													<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
												</button>
											</OverlayTrigger>
										</div>
									</td>
								</tr>
							)
						}
						{
							data.map
							(
								(record : any) => <tr key={record.refIndex}>
									{
										!allowed.delete ? "" : <td>{
											(
												(
													((+record.campuses[0].tuition_debt - +record.campuses[0].tuition_debt_settlement) > 0) ||
													((+record.campuses[0].article_debt - +record.campuses[0].article_debt_settlement) > 0)
												)
													||
												(record.campuses[0].positive_balance > 0 ||record.enrollments.length > 0)
											) ? "" : <button className="btn btn-default" disabled={idleLocked} type="button"
											  onClick={() => toggleRecordSelection(record)}
											>
												{<CustomIcon name={selectedRef[record.refIndex] ? "bCheck" : "bUncheck"} />}
											</button>
										}</td>
									}
									<td>
										{record.student.identity_code}
									</td>
									<td>
										{basicInfo.studentStatusRef["SS_" + record.status_id].name}
									</td>
									<td>
										{record.first_name || "-"}
									</td>
									<td>
										{record.last_name || "-"}
									</td>
									<td>
										{[record.phone1, record.phone2].filter((phone : string) => isNotEmpty(phone)).join(", ") || "-"}
									</td>
									<td>{
										isEmpty(record.tags) ? "-" : record.tags.map
										(
											(item : any, index : number) => <div className="badge rounded-pill bg-primary" key={"crc_" + item.id}>
												{item.name}
											</div>
										)
									}</td>
									<td style={{textAlign : "right"}}><div style={{display : "flex", alignItems : "stretch"}}>
										<span style={{paddingRight : "5px"}}>
											{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
										</span>
										<span style={{flexGrow : 1}}>{
											sprintf
											(
												" %.02f",
												+record.campuses[0].tuition_debt - +record.campuses[0].tuition_debt_settlement
											)
										}</span>
									</div></td>
									<td>
										<div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>{
												sprintf
												(
													" %.02f",
													+record.campuses[0].article_debt - +record.campuses[0].article_debt_settlement
												)
											}</span>
										</div>
									</td>
									<td>
										<div style={{display : "flex", alignItems : "stretch"}}>
											<span style={{paddingRight : "5px"}}>
												{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
											</span>
											<span style={{flexGrow : 1}}>
												{sprintf(" %.02f", +record.campuses[0].positive_balance)}
											</span>
										</div>
									</td>
									<td>
										{paintingEnrollments(record.enrollments)}
									</td>
									<td><div style={{display : "flex"}}>
										<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>}><button
										  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
										  disabled={idleLocked} className="button btn btn-default" type="button" onClick=
										  {
											() => showPrompt
											({
												...record, phone         : record.phone || "",
												observations             : record.student.observations || "",
												id_clase_365             : record.id_clase_365 || "",
												zipcode                  : record.zipcode || "",
												correlativo_clase_365    : record.student.correlativo_clase_365 || "",
												identity_code            : record.student.identity_code || "",
												academic_degree          : record.student.academic_degree,
												academic_degree_id       : record.student.academic_degree?.id,
												specific_academic_degree : record.student.specific_academic_degree || "",
												last_school_name         : record.student.last_school_name || "",
												current_occupation       : record.student.current_occupation || "",
												password                 : "",
												contacts:
													((record.student.contacts && JSON.parse(record.student.contacts)) || [])
											})
										 }
										>
											<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
										</button></OverlayTrigger>
										<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Ir a Estado de Cuenta</Tooltip>}>
											<button type="button" disabled={idleLocked} onClick={() => navigateTo(record)}
											  className="button btn btn-default"
											>
												<CustomIcon name="finances" />
											</button>
										</OverlayTrigger>
										{
											(
												(
													(
														((+record.campuses[0].tuition_debt - +record.campuses[0].tuition_debt_settlement) > 0) ||
														((+record.campuses[0].article_debt - +record.campuses[0].article_debt_settlement) > 0)
													)
														|| (record.campuses[0].positive_balance > 0 || record.enrollments.length > 0)
												)
													|| !allowed.delete
											) ? "" : <OverlayTrigger
											  overlay={<Tooltip id="tooltip-activos">Borrar</Tooltip>}><button type="button"
											  onClick={() => confirmRemoval(record.id)} style={{display : "table-column"}}
											  disabled={idleLocked} className="button btn btn-default"
											>
												<CustomIcon name="bTrash" />
											</button></OverlayTrigger>
										}
									</div></td>
								</tr>
							)
						}
					</tbody>
				</table>
		}</SectionContainer>
		{selectedStudent == null ? "" : <StudentInfoModal selectedStudent={selectedStudent} allowedUpdate={allowed.update}
		  academicDegrees={academicDegrees} closeFn={setSelectedStudent} afterCloseFn={search}
		/>}
	</>
}

export default Students
