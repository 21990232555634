import axios
	from "axios"
import store
	from "./../../globals"

import {isNotEmpty, removeRecords}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class MentorsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getLectures(campusId : number, search? : any, page? : number)
	{
		return axios.get
		(
			RESOURCE_URL + campusId + "/employees/_lectures",
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async countMentors(campusId : number, search? : any)
	{
		let params = []

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/mentors/_count" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		);
	}

	async searchMentors(campusId : number, search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/mentors" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		)
	}

	async getMentorById(campusId : number, mentorId : number)
	{
		try
		{
			let res = await axios.get
			(
				RESOURCE_URL + campusId + "/mentors/" + mentorId,
				{
					headers:
					{
						"Content-Type"  : "application/json",
						"Authorization" : "Bearer " + this.sessionToken
					}
				}
			)
			return res;
		}
		catch(err)
		{
			console.log(err)
		}
	}

	async removeMentors(recordIds : any)
	{
		return removeRecords(RESOURCE_URL, recordIds)
	}
}

export default new MentorsService()