import {useState}
	from "react"
import dayjs, {Dayjs}
	from "dayjs"
import {Portal}
	from "react-overlays"
import DatePicker
	from "react-datepicker"
import {getYear, getMonth}
	from "date-fns"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid, regular}
	from "@fortawesome/fontawesome-svg-core/import.macro"

import "react-datepicker/dist/react-datepicker.css"

const LeDatePicker = (props : any)=>
{
	const {onFocus, required, customInput, onBlur, minDate, maxDate, filter, onChange, name, id, value, disabled} = props

	const localDay : any=
	{
		Monday    : "Lu",
		Tuesday   : "Ma",
		Wednesday : "Mi",
		Thursday  : "Ju",
		Friday    : "Vi",
		Saturday  : "Sá",
		Sunday    : "Do",
	}

	const CalendarContainer = ({children} : any)=>
	{
		const el = document.getElementById("calendar-portal")

		return <Portal container={el}>{children}</Portal>;
	}

	return <DatePicker onBlur={onBlur} onFocus={onFocus} required={required} customInput={customInput} calendarStartDay={1}
	  formatWeekDay={day => localDay[day]} selected={value} popperContainer={CalendarContainer} minDate={minDate} name={name}
	  maxDate={maxDate} popperProps={{strategy : "fixed"}} onChange={(x : any) => onChange({target : {name : name, value :x}})}
	  placeholderText="Especifique..." filterDate={filter || null} id={id} disabled={disabled} dateFormat="dd/MM/yyyy"
	  renderCustomHeader=
	  {
		({date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled} : any)=>
			<div style={{margin : 10, display : "flex", justifyContent : "center"}}>
				<button disabled={prevMonthButtonDisabled} onClick={decreaseMonth} className="btn btn-default"
				  type="button"
				>
					<FontAwesomeIcon icon={solid("chevron-left")}/>
				</button>
				<select value={getMonth(date)} onChange={({target : {value}} : any) => changeMonth(value)}>{
					props.months.map
					(
						(option : any, index : number) => <option key={option} value={index}>
							{option}
						</option>
					)
				}</select>
				<select value={getYear(date)} onChange={({target : {value}} : any) => changeYear(value)}>{
					props.years.map
					(
						(option : any) => <option key={option} value={option}>
							{option}
						</option>
					)
				}</select>
				<button disabled={nextMonthButtonDisabled} onClick={increaseMonth} className="btn btn-default"
				  type="button"
				>
					<FontAwesomeIcon icon={solid("chevron-right")}/>
				</button>
			</div>
	  }
	/>
}

export default LeDatePicker
