import {actions}
	from "../actions"
import {AnyAction}
	from "redux";
import {PayloadAction}
	from "@reduxjs/toolkit";

const initialState=
{
	loading        : false,
	courses        : <any>[],
	kits           : <any>[],
	others         : <any>[],
	history        : <any>[],
	invoices       : <any>[],
	totalItemsCart : 0,
	total          : 0,
	error          : "",
	cartModal:
	{
		courses : <any>[],
		kits    : <any>[],
		others  : <any>[],
	},
}

const financesReducer = (state = initialState, action: AnyAction | PayloadAction) : any=>
{
	switch (action.type)
	{
		case actions.finances.GET_COURSES : action.payload.courses.map
		(
			(course: any, parentIndex: number)=>
			{
				course.parentIndex = parentIndex

				course.charges.map
				(
					(item: any, childIndex: number)=>
					{
						item.parentIndex = parentIndex
						item.childIndex = childIndex
					}
				)
			}
		)

		return {...state, courses: [...action.payload.courses], loading: true}
		case actions.finances.GET_KITS : action.payload.kits.map
		(
			(kit: any, childIndex: number)=>
			{
				kit.childIndex = childIndex
			}
		)

		return{...state, kits: action.payload.kits}
		case actions.finances.GET_OTHERS : action.payload.others.map
		(
			(other: any, childIndex: number)=>
			{
				other.childIndex = childIndex
			}
		)

		return{...state, others: action.payload.others}
		case actions.finances.GET_DATA_HISTORY : action.payload.history.map
		(
			(item: any, childIndex: number)=>
			{
				item.childIndex = childIndex
			}
		)

		return{...state, history: action.payload.history}
		case actions.finances.SET_SELECTED_COURSE:
			const {row,index} = action.payload
			const course      = state.courses.find((course: any) => course.parentIndex === row.parentIndex)
			const newCharges  = course.charges.map
			(
				(charge: any, j: number)=>
				{
					if (charge.childIndex === row.childIndex) {
						return { ...charge, selected: !charge.selected }
					}
					return { ...charge }
				}
			)

			const newCourses = state.courses.map
			(
				(course: any, index: number)=>
				{
					return {
						...course,
						charges: course.parentIndex === row.parentIndex ? newCharges : course.charges
					}
				}
			)

			const coursesCopy         = structuredClone(newCourses)
			const newCartModalCourses = coursesCopy.filter
			(
				(course: any)=>
				{
					const hasSelected: boolean = course.charges.some((charge: any) => charge.selected)
					if (hasSelected === true && course !== undefined && course !== null) {
						const selectedCharges = course.charges.filter((charge: any) => charge.selected)
						course.charges = selectedCharges
						return course
					}
				}
			)

		return{
			...state,
			courses  : newCourses,
			cartModal: {...state.cartModal, courses : newCartModalCourses}
		}
		case actions.finances.SET_SELECTED_ARTICLE:
			const {articleType, rowData, rowIndex} = action.payload

			if(articleType === 1)
			{
				const newKits = state.kits.map
				(
					(kit: any, index: number)=>
					{
						if(kit.sku === rowData.sku)
						{
							return { ...kit, selected: !kit.selected }
						}

						return {...kit}
					}
				)

				const existsKit   = state.cartModal.kits.find((kit: any) => kit.sku === rowData.sku)
				const newCartKits = newKits.filter((kit: any) => kit.selected)

				if(existsKit === undefined || existsKit === null)
				{
					return{
						...state,
						kits      : newKits,
						cartModal : {...state.cartModal, kits: newCartKits}
					}
				}
				else
				{
					const removeKits = state.cartModal.kits
						.filter((kit: any) => kit.sku !== rowData.sku)
					return{
						...state,
						kits: newKits,
						cartModal: {
							...state.cartModal,
							kits: removeKits
						}
					}
				}
			}
			else
			{
				if(articleType === 2)
				{
					const newOthers = state.others.map
					(
						(other : any, index : number)=>
						{
							if (other.sku === rowData.sku) {
								return { ...other, selected: !other.selected }
							}
							return { ...other }
						}
					)

					const existsOther   = state.cartModal.others.find((other: any) => other.sku === rowData.sku)
					const newCartOthers = newOthers.filter((other: any) => other.selected)

					if(existsOther === undefined || existsOther === null)
					{
						return{
							...state,
							others    : newOthers,
							cartModal : {...state.cartModal, others : newCartOthers}
						}
					}
					else
					{
						const removeOthers = state.cartModal.others.filter((other: any) => other.sku !== rowData.sku)
						return{
							...state,
							others    : newOthers,
							cartModal : {...state.cartModal, others: removeOthers}
						}
					}
				}
			}

		return {...state}
		case actions.finances.SET_MODAL_TOTAL:
			let sum = 0

			if(state.cartModal.courses.length > 0)
			{
				state.cartModal.courses.map
				(
					(course: any)=>
					{
						course.charges.map
						(
							(charge: any)=>
							{
								let total = Number.parseInt(charge.total.replaceAll("$", "").replaceAll(",", ""))
								sum      += total

								if(charge.surcharges && charge.surcharges.length > 0)
								{
									charge.surcharges.map
									(
										(surcharge: any)=>
										{
											let surchargeTotal = Number.parseInt(surcharge.total.replaceAll("$", "").replaceAll(",", ""))
											sum += surchargeTotal
										}
									)
								}
							}
						)
					}
				)
			}

			if(state.cartModal.kits.length > 0)
			{
				state.cartModal.kits.map
				(
					(kit: any)=>
					{
						let total = Number.parseInt(kit.total.replaceAll("$", "").replaceAll(",", ""))
						sum      += total
					}
				)
			}

			if(state.cartModal.others.length > 0)
			{
				state.cartModal.others.map
				(
					(other : any)=>
					{
						let total = Number.parseInt(other.total.replaceAll("$", "").replaceAll(",", ""))
						sum += total
					}
				)
			}

			if(state.cartModal.courses.length === 0 && state.cartModal.kits.length === 0 && state.cartModal.others.length === 0)
			{
				sum = 0
			}

			return{...state, total : sum}
		case actions.finances.SET_MODAL_ITEMS_TOTAL:
			let totalItems = state.cartModal.courses.length + state.cartModal.kits.length + state.cartModal.others.length

			return{...state, totalItemsCart: totalItems}
		default: return state;
	}
}

export default financesReducer