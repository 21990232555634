import Constants from "../../../../constants"

const boldText=
{
	font: Constants.Styles.text.fonts.robotoBold16,
	marginBottom: 0
}

const boldInfoText=
{
	font         : Constants.Styles.text.fonts.robotoBold24,
	color        : Constants.Styles.text.colors.gray,
	marginBottom : 0,
	marginRight  : "10px"
}

const boldPendingPaymentText=
{
	font         : Constants.Styles.text.fonts.robotoBold24,
	color        : Constants.Styles.text.colors.red,
	marginBottom : 0,
	marginRight  : "10px"
}

const subHeader=
{
	display      : "flex",
	gap          : 60,
	marginBottom : "10px"
}

const subtitleContainer=
{
	display        : "flex",
	alignItems     : "baseline",
	justifyContent : "center",
	gap            : 10
}

const subtitle=
{
	font         : Constants.Styles.text.fonts.robotoNormal12,
	color        : Constants.Styles.text.colors.veryPeri,
	opacity      : 1,
	marginBottom : 0
}

const generalSubtitle=
{
	font         : Constants.Styles.text.fonts.robotoNormal14,
	color        : Constants.Styles.text.colors.gray,
	opacity      : 1,
	marginBottom : 0
}

const pendientPaymentText=
{
	color : Constants.Styles.text.colors.red,
	font  : Constants.Styles.text.fonts.robotoBold16
}

const generalBalanceContainer=
{
	width          : "28%",
	display        : "flex",
	alignItems     : "baseline",
	justifyContent : "center",
	gap            : 20
}

const generalSubContainer=
{
	width          : "100%",
	display        : "flex",
	alignItems     : "baseline",
	justifyContent : "space-between",
	gap            : 10,
	borderBottom   : `2px solid ${Constants.Styles.text.colors.gray}`
}

const generalBalanceText=
{
	color        : Constants.Styles.text.colors.blackPrimary,
	font         : Constants.Styles.text.fonts.robotoBold20,
	marginBottom : 0
}

export const styles=
{
	boldText, subtitle, subHeader, boldInfoText, generalSubtitle, subtitleContainer, pendientPaymentText, generalBalanceText,
	generalSubContainer, generalBalanceContainer, boldPendingPaymentText
}