import { useNavigate }
	from "react-router-dom"
import { useSelector }
	from "react-redux"
import { solid }
	from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon }
	from "@fortawesome/react-fontawesome"

import Constants
	from "../../../../constants"
import { RootState }
	from "./../../../../globals"
import { styles }
	from "./style"


const Header = (props: any) => {
	const { generalData } = props
	const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value)
	const navigate: any = useNavigate()

	return <div style={{ padding: "30px 30px 0px 30px" }}>
		<h3 className="pageHeader">
			<button type="button" className="btn btn-default" disabled={idleLocked} onClick={() => navigate("/students")}>
				<FontAwesomeIcon icon={solid("chevron-left")} size="2x" />
			</button>
			{Constants.Screens.Finances.accountStatement.header}
		</h3>
		<div style={{ ...styles.subHeader, display: "flex", flexFlow: "wrap", flexWrap: "wrap", gap: "15px" }}>
			<div style={styles.subtitleContainer}>
				<label style={{ ...styles.subtitle, position: "relative" }}>
					{Constants.Common.name}:
				</label>
				<span style={styles.boldText}>
					{generalData.student.first_name}
				</span>
			</div>
			<div style={styles.subtitleContainer}>
				<label style={{ ...styles.subtitle, position: "relative" }}>
					{Constants.Common.lastName}:
				</label>
				<span style={styles.boldText}>
					{generalData.student.last_name}
				</span>
			</div>
			<div style={styles.subtitleContainer}>
				<label style={{ ...styles.subtitle, position: "relative" }}>
					{Constants.Common.correlative}:
				</label>
				<span style={styles.boldText}>
					{generalData.student.student.identity_code}
				</span>
			</div>
		</div>
		<span style={styles.generalSubtitle}>
			{Constants.Screens.Finances.accountStatement.subtitle}
		</span>
		<table style={{ width: "max-content" }}>
			<tbody>
				<tr style={{ borderBottom: "2px solid #8F91DA" }}>
					<td style={{ ...styles.generalBalanceText, paddingRight: "15px" }}>
						{Constants.Screens.Finances.accountStatement.dueMonthlyBalance}:
					</td>
					<td style={{ ...styles.boldPendingPaymentText, textAlign: "right", verticalAlign: "bottom" }}>{
						generalData.balance.expiredMonthlyBalance != null ? generalData.balance.expiredMonthlyBalance : "-"
					}</td>
				</tr>
				<tr style={{ borderBottom: "2px solid #8F91DA" }}>
					<td style={{ ...styles.generalBalanceText, paddingRight: "15px" }}>
						<br />
						{Constants.Screens.Finances.accountStatement.monthlyBalance}:
					</td>
					<td style={{ ...styles.boldText, font: Constants.Styles.text.fonts.robotoBold24, textAlign: "right", verticalAlign: "bottom" }}>{
						generalData.balance.monthlyBalance != null ? generalData.balance.monthlyBalance : "-"
					}</td>
				</tr>
				<tr style={{ borderBottom: "2px solid #8F91DA" }}>
					<td style={{ ...styles.generalBalanceText, paddingRight: "15px" }}>
						<br />
						{Constants.Screens.Finances.accountStatement.kitBalance}:
					</td>
					<td style={{ ...styles.boldPendingPaymentText, textAlign: "right", verticalAlign: "bottom" }}>{
						generalData.balance.kitsBalance != null ? generalData.balance.kitsBalance : "-"
					}</td>
				</tr>
				<tr style={{ borderBottom: "2px solid #8F91DA" }}>
					<td style={{ ...styles.generalBalanceText, paddingRight: "15px" }}>
						<br />
						{Constants.Screens.Finances.accountStatement.otherBalance}:
					</td>
					<td style={{ ...styles.boldPendingPaymentText, textAlign: "right", verticalAlign: "bottom" }}>{
						generalData.balance.othersBalance != null ? generalData.balance.othersBalance : "-"
					}</td>
				</tr>
				<tr style={{ borderBottom: "2px solid #8F91DA" }}>
					<td style={{ ...styles.generalBalanceText, paddingRight: "15px" }}>
						<br />
						{Constants.Screens.Finances.accountStatement.positiveBalance}:
					</td>
					<td style={{ ...styles.boldInfoText, color: Constants.Styles.text.colors.green, textAlign: "right", verticalAlign: "bottom" }}>{
						generalData.balance.positiveBalance != null ? generalData.balance.positiveBalance : "-"
					}</td>
				</tr>
			</tbody>
		</table>
	</div>
}

export default Header