import axios
	from "axios"
import store
	from "./../globals"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/catalog/"

class CatalogService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	commonRetrieval(catalog  : string)
	{
		return axios.get
		(
			RESOURCE_URL + catalog,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	specialAccessRetrieval(catalog  : string)
	{
		return axios.get
		(
			process.env.REACT_APP_API_URL + "/" + catalog,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	getCurrencies(expand? : boolean)
	{
		return this.commonRetrieval("currency")
	}

	getCountries(expand? : boolean)
	{
		return this.commonRetrieval("countries" + (expand ? "?expand=1" : ""))
	}

	getGenders(sessionToken : string | null)
	{
		return this.commonRetrieval("gender")
	}

	getCourseModes(sessionToken : string | null)
	{
		return this.commonRetrieval("courseMode")
	}

	getAcademicDegrees(sessionToken : string | null)
	{
		return this.commonRetrieval("academicDegree")
	}

	getAssessmentTypes(sessionToken : string | null)
	{
		return this.commonRetrieval("assessmentType")
	}

	getEmployeeTypes(sessionToken : string | null)
	{
		return this.commonRetrieval("employeeType")
	}

	getScheduleTypes(sessionToken : string | null)
	{
		return this.commonRetrieval("scheduleType")
	}

	getIdentificationTypes(sessionToken : string | null)
	{
		return this.commonRetrieval("identificationType")
	}

	getCampusStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("campusStatus")
	}

	getAssessmentStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("assessmentStatus")
	}

	getOwedArticleStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("owedArticleStatus")
	}

	getOwedTuitionStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("owedTuitionStatus")
	}

	getArticleStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("articleStatus")
	}

	getAssignmentStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("assignmentStatus")
	}

	getCourseStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("courseStatus")
	}

	getLevelStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("levelStatus")
	}

	getScheduleStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("scheduleStatus")
	}

	getClassroomStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("classroomStatus")
	}

	getAttendanceStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("attendanceStatus")
	}

	getStudentStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("studentStatus")
	}

	getEmployeeStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("employeeStatus")
	}

	getPaymentStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("paymentStatus")
	}

	getInvoiceStatuses(sessionToken : string | null)
	{
		return this.commonRetrieval("invoiceStatus")
	}
}

export default new CatalogService()