import {useEffect, useState}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {OverlayTrigger, Tooltip} 
	from "react-bootstrap"
import {useSelector}
	from "react-redux"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import CustomIcon
	from "./../../../components/customIcon"
import UnderlineHeading
	from "./../../../components/underlineHeading"
import FinancePrompt
	from "./../financePrompt"
import Constants, {paymentStatus, invoiceStatus}
	from "./../../../constants"
import Header
	from "./header"
import {styles}
	from "./../style"
import {RootState}
	from "./../../../globals"
import {getLatestInvoices, isDueInvoice} from "../../../common"

const AccountStament = (props : any)=>
{
	const {generalData, financeData, resetFn, setSelectedPaymentMethod, errorPrompt} = props
	const taxRegistryRequirement : string                  = "Es necesario primero registrar algunos tipos de impuesto en el catálogo del sistema"

	const titles=
	[
		Constants.Screens.Finances.accountStatement.kitBalance,
		Constants.Screens.Finances.accountStatement.otherBalance
	]

	const [articleRef, setArticleRef]                     = useState<any>({})
	const [scheduleChargeAddRef, setScheduleChargeAddRef] = useState<any>({})
	const [scheduleChargeRef, setScheduleChargeRef]       = useState<any>({})
	const [scheduleKitRef, setScheduleKitRef]             = useState<any>({})
	const [kitRef, setKitRef]                             = useState<any>({})
	const [otherRef, setOtherRef]                         = useState<any>({})
	const [dangerReport, setDangerReport]                 = useState<any>({})
	const [total, setTotal]                               = useState<number>(0)
	const [totalToPaid, setTotalToPaid]                   = useState<number>(total)
	const [conceptsCount, setConceptsCount]               = useState<number>(0)
	const [promptDisplayQueue, setPromptDisplayQueue]     = useState<string[]>([])
	const basicInfo         : any                         = useSelector((state : RootState) => state.basicInfo.value)
	const idleLocked        : boolean                     = useSelector((state : RootState) => state.idleLockSwitch.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const [currencySymbol, setCurrencySymbol]             = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
	const [selectedInvoice, setSelectedInvoice]           = useState<any>(null)
	const [userCampus, setUserCampus]                     = useState<number>(currentUserCampus)
	const articleListing : any                            = [kitRef, otherRef]
	const [latestInvoices, setLatestInvoices]             = useState<any>([])

	const promptWidth : any=
	{
		checkout : "648px",
		payment  : "583px",
		invoice  : "648px"
	}

	/*
		A useEffect hook that calculates the total to be paid based on charge discounts.
		Iterates through scheduleChargeRef, kitRef, and otherRef objects to calculate the total discount.
		Updates the total to be paid state accordingly.
		@param {number} total - The total amount before discounts.
		@param {Object} scheduleChargeRef - The reference object for schedule charges.
		@param {Object} kitRef - The reference object for kit charges.
		@param {Object} otherRef - The reference object for other charges.
		@returns {void}
	*/
	useEffect
	(
		()=>
		{
			let totalSum = 0// Initialize totalSum

			Object.keys(scheduleChargeRef).forEach// Iterate through scheduleChargeRef and add up discounts
			(
				(chargeKey)=>
				{
					const charge = scheduleChargeRef[chargeKey]

					if(charge.discountInPercentage)// Check if discount is in percentage-------------------------------------
					{
						totalSum += charge.percentageCalculated
					}
					else
					{
						totalSum += charge.discountValue
					}
				}
			)

			Object.keys(kitRef).forEach// Iterate through kitRef and add up discounts----------------------------------------
			(
				(chargeKey)=>
				{
					const charge = kitRef[chargeKey]

					if(charge.discountInPercentage)// Check if discount is in percentage-------------------------------------
					{
						totalSum += charge.percentageCalculated
					}
					else
					{
						totalSum += charge.discountValue
					}
				}
			)

			
			Object.keys(otherRef).forEach// Iterate through otherRef and add up discounts------------------------------------
			(
				(chargeKey)=>
				{
					const charge = otherRef[chargeKey]

					if(charge.discountInPercentage)// Check if discount is in percentage-------------------------------------
					{
						totalSum += charge.percentageCalculated
					}
					else
					{
						totalSum += charge.discountValue
					}
				}
			)

			// Set the total to be paid by subtracting the total discount from the original total
			setTotalToPaid(total - totalSum)
		},
		[total, scheduleChargeRef, kitRef, otherRef]
	)
	/*
		Handle the type of discount for a charge and update relevant maps.
		This function toggles between percentage and flat rate discounts.
		@param {Object} charge - The charge object to handle the type of discount for.
		@returns {void}
	*/
	const handleTypeOfDiscount = (charge : any) : void=>
	{
		// Clone map objects-------------------------------------------------------------------------------------------------
		let scheduleChargeMap    = {...scheduleChargeRef}
		let scheduleChargeAddMap = {...scheduleChargeAddRef}
		let kitRefMap            = {...kitRef}
		let otherMap             = {...otherRef}

		// Toggle discount type and reset values-----------------------------------------------------------------------------
		charge.discountInPercentage = !charge.discountInPercentage
		charge.discountValue        = 0
		charge.percentageCalculated = 0

		if(scheduleChargeMap[charge.refIndex])// Update relevant maps if the charge exists-----------------------------------
		{
			scheduleChargeMap[charge.refIndex] = {...charge}
		}

		if(charge.type_id === 1 && !scheduleChargeAddMap[charge.scheduleKey])
		{
			scheduleChargeAddMap[charge.scheduleKey]=
			{
				chargeRef : scheduleChargeMap,
				kitRef    : {}
			}
		}

		if(kitRefMap[charge.refIndex])
		{
			kitRefMap[charge.refIndex] = {...charge}
		}

		if(otherMap[charge.refIndex])
		{
			otherMap[charge.refIndex] = {...charge}
		}

		// Set updated maps to state-----------------------------------------------------------------------------------------
		setScheduleChargeRef(scheduleChargeMap)
		setScheduleChargeAddRef(scheduleChargeAddMap)
		setKitRef(kitRefMap)
		setOtherRef(otherMap)
	}
	/*
		Handle discount for a charge and update relevant maps.
		@param {Object} charge - The charge object to handle discount for.
		@param {Event} event - The event object containing information about the discount input.
		@returns {void}
	*/
	const handleDiscount = (charge : any, event : any) : void=>
	{
		// Clone map objects-------------------------------------------------------------------------------------------------
		let scheduleChargeMap       = {...scheduleChargeRef}
		let scheduleChargeAddMap    = {...scheduleChargeAddRef}
		let kitRefMap               = {...kitRef}
		let otherMap                = {...otherRef}
		let inputValue              = +event.target.value// Retrieve the current input value
		// Calculate max based on whether the discount is a percentage or a flat rate
		const max                   = charge.discountInPercentage ? 100 : +charge.amount
		const min                   = 0; // Assuming the minimum discount should be zero
		// Validate and constrain the input value within the min and max limits
		inputValue                  = Math.max(Math.min(inputValue, max), min)
		// Calculate percentage discount if applicable
		let percentageCalculated    = charge.discountInPercentage ? (inputValue / 100) * charge.amount : 0
		charge.percentageCalculated = percentageCalculated// Update charge properties
		charge.discountValue        = inputValue

		// Update scheduleChargeMap if the charge exists
		if(scheduleChargeMap[charge.refIndex])
		{
			scheduleChargeMap[charge.refIndex] = {...charge}
		}

		// Add charge to scheduleChargeAddMap if it's of type 1 and scheduleChargeAddMap doesn't exist-----------------------
		if(charge.type_id === 1 && !scheduleChargeAddMap[charge.scheduleKey])
		{
			scheduleChargeAddMap[charge.scheduleKey]=
			{
				chargeRef : scheduleChargeMap,
				kitRef    : {}
			}
		}

		if(kitRefMap[charge.refIndex])// Update kitRefMap if the charge exists-----------------------------------------------
		{
			kitRefMap[charge.refIndex] = {...charge}
		}

		
		if(otherMap[charge.refIndex])// Update otherMap if the charge exists-------------------------------------------------
		{
			otherMap[charge.refIndex] = {...charge}
		}
		// Set updated maps to state-----------------------------------------------------------------------------------------
		setScheduleChargeRef(scheduleChargeMap)
		setScheduleChargeAddRef(scheduleChargeAddMap)
		setKitRef(kitRefMap)
		setOtherRef(otherMap)
	}
	/*
		Determine the status of an charge based on its properties.
		@param {Object} charge - The charge object containing relevant properties.
		@returns {JSX.Element} JSX element representing the status of the charge.
	*/
	const handleStatusCharge = (charge : any) : JSX.Element=>
	{
		// if (!charge.hasOwnProperty("article") && +charge.sub_total === 0) {
		// 	return <span style={styles.statusText}>
		// 		Pagada
		// 	</span>
		// }
		// Determine the status based on the invoice properties article------------------------------------------------------
		const status = charge.hasOwnProperty("article") // if is article doesn't apply
				? 
			generalData.owedArticleStatusRef["OAS_" + charge.status_id].name //? generalData.invoiceStatusRef["IS_" + charge.status_id].name // return status from catalog
				 : 
			(
				(charge.invoice_id === null && isDueInvoice(charge.dueDate))
					? Constants.Common.expired + "/" + generalData.owedTuitionStatusRef["OTS_" + charge.status_id].name// If charge is overdue and not paid, set status to 'Expired'
						 : generalData.owedTuitionStatusRef["OTS_" + charge.status_id].name// Otherwise, get the status name from reference data
			)

		// Return a JSX element representing the status
		return <span style={status.startsWith(Constants.Common.expired) ? styles.statusRedText : styles.statusText}>
			{status}
		</span>
	}

	// /*
	// 	Determine the status of an invoice based on its properties.
	// 	@returns {JSX.Element} JSX element representing the status of the invoice.
	// */
	// const handleStatusInvoiceById = (invoiceStatusId : any) : JSX.Element =>
	// {
	// 	// Determine the status based on the invoice properties article
	// 	const status = generalData.invoiceStatusRef["IS_" + invoiceStatusId].name//return status from catalog
	// 	// Return a JSX element representing the status
	// 	return<span style={status === Constants.Common.expired ? styles.statusRedText : styles.statusText}>
	// 		{status}
	// 	</span>
	// }

	const handleTotal = (ref : any, prefix : string, ids : any)=>
	{
		let sum : number = 0

		ids.map
		(
			(id : any)=>
			{
				if(ref[prefix + id].invoice_id == null)
				{
					sum += (+ref[prefix + id].sub_total)
				}
				else
				{
					if
					(
						(
							financeData.invoiceRef["I_" + ref[prefix + id].invoice_id].owedArticleIds.length + 
							financeData.invoiceRef["I_" + ref[prefix + id].invoice_id].owedTuitionIds.length
						) > 1
					)
					{
						if
						(
							(
								(+financeData.invoiceRef["I_" + ref[prefix + id].invoice_id].total) -
									(+financeData.invoiceRef["I_" + ref[prefix + id].invoice_id].paid_amount)
							) > 0
						)
						{
							sum += +ref[prefix + id].sub_total
						}
					}
					else
					{
						sum += (+financeData.invoiceRef["I_" + ref[prefix + id].invoice_id].total) -
							(+financeData.invoiceRef["I_" + ref[prefix + id].invoice_id].paid_amount)
					}
				}
			}
		)

		return <div style={{...styles.totalTextNumber, paddingLeft : "15px", color : sum == 0 ? "#000000" : "#C32722"}}>
			{(sum == 0 ? "" : "-") + currencySymbol + sprintf(" %.02f", sum)}
		</div>
	}

	const toggleCourseChargeSelection = (charge : any)=>
	{
		// Initialize variables----------------------------------------------------------------------------------------------
		let conceptCounting      : number = conceptsCount
		let totalValue           : number = +total
		let scheduleChargeMap    : any    = {...scheduleChargeRef}
		let scheduleChargeAddMap : any    = {...scheduleChargeAddRef}
		let displayQueue         : any    = [...promptDisplayQueue]
		const traverseCharge              = (currentCharge : any)=>
		{
			// Check if the current charge exists and has no associated invoice----------------------------------------------
			if(currentCharge && currentCharge.invoice_id === null)
			{
				currentCharge.discountInPercentage = true
				currentCharge.discountValue        = 0
				currentCharge.percentageCalculated = 0

				if(currentCharge !== charge)//Toggle the disabled state of the current charge if it's not the target charge
				{
					let nextItem              = scheduleChargeMap[currentCharge.nextRef]
					let currentChargeDisabled = nextItem?.disabled ? true : !currentCharge.disabled
					currentCharge.disabled    = currentChargeDisabled
				}

				if(scheduleChargeMap[currentCharge.refIndex])// Update scheduleChargeMap based on the current charge's presence
				{
					if(!scheduleChargeMap[currentCharge.nextRef])
					{
						delete scheduleChargeMap[currentCharge.refIndex]

						totalValue -= +currentCharge.sub_total

						conceptCounting--
					}
				}
				else
				{
					scheduleChargeMap[currentCharge.refIndex] = currentCharge
					totalValue                               += +currentCharge.sub_total
					conceptCounting++
				}

				if(scheduleChargeAddMap[currentCharge.scheduleKey] == null)// Update scheduleChargeAddMap with the current charge
				{
					scheduleChargeAddMap[currentCharge.scheduleKey]=
					{
						chargeRef : {},
						kitRef    : {}
					}

					scheduleChargeAddMap[currentCharge.scheduleKey].chargeRef[currentCharge.refIndex] = currentCharge
				}
				else
				{
					if(scheduleChargeAddMap[currentCharge.scheduleKey].chargeRef[currentCharge.refIndex] == null)
					{
						scheduleChargeAddMap[currentCharge.scheduleKey].chargeRef[currentCharge.refIndex] = currentCharge
					}
					else
					{
						if(!scheduleChargeMap[currentCharge.nextRef]) {
							delete scheduleChargeAddMap[currentCharge.scheduleKey].chargeRef[currentCharge.refIndex]

							if
							(
								Object.keys(scheduleChargeAddMap[currentCharge.scheduleKey].kitRef).length < 1 &&
								Object.keys(scheduleChargeAddMap[currentCharge.scheduleKey].chargeRef).length < 1
							)
							{
								delete scheduleChargeAddMap[currentCharge.scheduleKey]
							}
						}
					}
				}

				 // Update displayQueue based on data presence---------------------------------------------------------------
				displayQueue = ((scheduleChargeAddMap == null || Object.keys(scheduleChargeAddMap).length < 1) && Object.keys(articleRef).length < 1)
					? [] : ["checkout"]

				traverseCharge(financeData.tuitionRef[currentCharge.prevRef])// Recursively traverse to the previous charge--
			}
			else
			{
				return// Exit recursion if the current charge is not applicable----------------------------------------------
			}
		}

		const updateState : any = ()=>// Update the state with the modified data.--------------------------------------------
		{
			setTotal(totalValue)
			setConceptsCount(conceptCounting)
			setScheduleChargeRef(scheduleChargeMap)
			setScheduleChargeAddRef(scheduleChargeAddMap)
			setSelectedInvoice(displayQueue.length < 1 ? null : {})
			setPromptDisplayQueue(displayQueue)
		}

		traverseCharge(charge)// Start traversing the charge tree------------------------------------------------------------
		updateState()// Update the state with the modified data--------------------------------------------------------------
	}

	const toggleKitSelection = (article : any)=>
	{
		let conceptCounting      : number = conceptsCount
		let articleMap           : any    = {...articleRef}
		let scheduleChargeAddMap : any    = {...scheduleChargeAddRef}
		let totalValue           : number = +total
		let displayQueue         : any    = [...promptDisplayQueue]
		article.discountInPercentage      = true
		article.discountValue             = 0
		article.percentageCalculated      = 0

		if(articleMap[article.refIndex])
		{
			delete articleMap[article.refIndex]

			totalValue -= +article.sub_total
			conceptCounting--
		}
		else
		{
			articleMap[article.refIndex] = article
			totalValue                  += +article.sub_total
			conceptCounting++
		}

		if(article.schedule_id == null)
		{
			let kitMap : any = {...kitRef}

			if(kitMap[article.refIndex])
			{
				delete kitMap[article.refIndex]
			}
			else
			{
				kitMap[article.refIndex] = article
			}

			setKitRef(kitMap)
		}
		else
		{
			let scheduleKitMap : any = {...scheduleKitRef}

			if(scheduleKitMap[article.refIndex])
			{
				delete scheduleKitMap[article.refIndex]
			}
			else
			{
				scheduleKitMap[article.refIndex] = article
			}

			if(scheduleChargeAddMap["S_" + article.schedule_id] == null)
			{
				scheduleChargeAddMap["S_" + article.schedule_id]=
				{
					chargeRef : {},
					kitRef    : {}
				}

				scheduleChargeAddMap["S_" + article.schedule_id].kitRef[article.refIndex] = article
			}
			else
			{
				if(scheduleChargeAddMap["S_" + article.schedule_id].kitRef[article.refIndex])
				{
					delete scheduleChargeAddMap["S_" + article.schedule_id].kitRef[article.refIndex]

					if
					(
						Object.keys(scheduleChargeAddMap["S_" + article.schedule_id].kitRef).length < 1 &&
						Object.keys(scheduleChargeAddMap["S_" + article.schedule_id].chargeRef).length < 1
					)
					{
						delete scheduleChargeAddMap["S_" + article.schedule_id]
					}
				}
				else
				{
					scheduleChargeAddMap["S_" + article.schedule_id].kitRef[article.refIndex] = article
				}
			}

			setScheduleKitRef(scheduleKitMap)
			setScheduleChargeAddRef(scheduleChargeAddMap)
		}

		displayQueue = ((scheduleChargeAddMap == null || Object.keys(scheduleChargeAddMap).length < 1) && Object.keys(articleMap).length < 1)
			? [] : ["checkout"]

		setTotal(totalValue)
		setArticleRef(articleMap)
		setConceptsCount(conceptCounting)
		setSelectedInvoice(displayQueue.length < 1 ? null : {})
		setPromptDisplayQueue(displayQueue)
	}

	const toggleOtherSelection = (article : any)=>
	{
		let conceptCounting : number = conceptsCount
		let articleMap      : any    = {...articleRef}
		let otherMap        : any    = {...otherRef}
		let totalValue      : number = +total
		let displayQueue    : any    = [...promptDisplayQueue]

		article.discountInPercentage = true
		article.discountValue        = 0
		article.percentageCalculated = 0

		if(articleMap[article.refIndex])
		{
			delete otherMap[article.refIndex]
			delete articleMap[article.refIndex]

			totalValue -= +article.sub_total
			conceptCounting--
		}
		else
		{
			otherMap[article.refIndex]   = article
			articleMap[article.refIndex] = article
			totalValue                  += +article.sub_total
			conceptCounting++
		}

		displayQueue = ((scheduleChargeAddRef == null || Object.keys(scheduleChargeAddRef).length < 1) && Object.keys(articleMap).length < 1)
			? [] : ["checkout"]

		setTotal(totalValue)
		setOtherRef(otherMap)
		setArticleRef(articleMap)
		setConceptsCount(conceptCounting)
		setSelectedInvoice(displayQueue.length < 1 ? null : {})
		setPromptDisplayQueue(displayQueue)
	}

	const articleHandling    : any = [toggleKitSelection, toggleOtherSelection]

	const showInvoicePrompt = (invoiceToDisplay : any)=>
	{
		let amount               : number
		let taxId                : number
		let taxInPercentage      : boolean
		let discountInPercentage : boolean
		let taxValue             : number
		let discountValue        : number
		let taxAmount            : number
		let discountAmount       : number
		let subTotal             : number
		let total                : number = 0
		let pendingPaidTotal     : number = 0
		let approvedPaidTotal    : number = 0
		let nextRef              : string | null
		let prevRef              : string | null
		let disabled             : boolean

		let invoice : any=
		{
			...invoiceToDisplay, notes : invoiceToDisplay.notes || "",
			total                      : +invoiceToDisplay.total,
			paid_amount                : +invoiceToDisplay.paid_amount,
			owed_tuitions              : invoiceToDisplay.owedTuitionIds.length < 1 ? [] : invoiceToDisplay.owedTuitionIds.map
			(
				(id : number, index : number)=>
				{
					amount               = +financeData.tuitionRef["OT_" + id].amount
					taxId                = financeData.tuitionRef["OT_" + id].tax_id || 0
					taxInPercentage      = financeData.tuitionRef["OT_" + id].tax_value.substr(-1) == "%"
					discountInPercentage = financeData.tuitionRef["OT_" + id].discount.substr(-1) == "%"
					taxValue             = taxId < 1 ? 0 : +(financeData.tuitionRef["OT_" + id].tax_value.replace("%", ""))
					discountValue        = +(financeData.tuitionRef["OT_" + id].discount.replace("%", ""))
					taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
					discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
					subTotal             = financeData.tuitionRef["OT_" + id].amount + taxAmount - discountAmount
					nextRef              = index === invoiceToDisplay.owedTuitionIds.length - 1 ? null : "OT_" + invoiceToDisplay.owedTuitionIds[index + 1]
					prevRef              = index === 0 ? null : "OT_" + invoiceToDisplay.owedTuitionIds[index - 1]
					disabled             = invoiceToDisplay.owedTuitionIds.length === 0 ? false
						 : index === invoiceToDisplay.owedTuitionIds.length - 1 ? false : true 

					return{
						...financeData.tuitionRef["OT_" + id], tax_id : taxId,
						amount                                        : amount,
						taxInPercentage                               : taxInPercentage,
						discountInPercentage                          : discountInPercentage,
						taxValue                                      : taxValue,
						discountValue                                 : discountValue,
						percentageCalculated                          : 0,
						taxAmount                                     : taxAmount,
						discountAmount                                : discountAmount,
						subTotal                                      : subTotal,
						quantity                                      : 1,
						price                                         : amount,
						nextRef                                       : nextRef,
						prevRef                                       : prevRef,
						disabled                                      : disabled,
						invoice_item:
						{
							invoice_id      : financeData.tuitionRef["OT_" + id].invoice_id,
							owed_tuition_id : financeData.tuitionRef["OT_" + id].id,
							owed_article_id : null,
							title           : financeData.tuitionRef["OT_" + id].title,
							description     : financeData.tuitionRef["OT_" + id].description,
							units           : 1,
							price           : amount,
							total           : subTotal,
							refunded_amount : 0,
							currency_id     : financeData.tuitionRef["OT_" + id].currency_id
						}
					}
				}
			),
			owed_articles             : invoiceToDisplay.owedArticleIds.length < 1 ? [] : invoiceToDisplay.owedArticleIds.map
			(
				(id : number)=>
				{
					amount               = +financeData.articleRef["A_" + id].amount
					taxId                = financeData.articleRef["A_" + id].tax_id || 0
					taxInPercentage      = financeData.articleRef["A_" + id].tax_value.substr(-1) == "%"
					discountInPercentage = financeData.articleRef["A_" + id].discount.substr(-1) == "%"
					taxValue             = taxId < 1 ? 0 : +(financeData.articleRef["A_" + id].tax_value.replace("%", ""))
					discountValue        = +(financeData.articleRef["A_" + id].discount.replace("%", ""))
					taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
					discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
					subTotal             = financeData.articleRef["A_" + id].amount + taxAmount - discountAmount
					total               += subTotal

					return {
						...financeData.articleRef["A_" + id], tax_id : taxId,
						amount                                       : amount,
						taxInPercentage                              : taxInPercentage,
						discountInPercentage                         : discountInPercentage,
						taxValue                                     : taxValue,
						discountValue                                : discountValue,
						taxAmount                                    : taxAmount,
						discountAmount                               : discountAmount,
						subTotal                                     : subTotal,
						invoice_item:
						{
							invoice_id      : financeData.articleRef["A_" + id].invoice_id,
							owed_tuition_id : null,
							owed_article_id : financeData.articleRef["A_" + id].id,
							title           : financeData.articleRef["A_" + id].article.name,
							description     : financeData.articleRef["A_" + id].description,
							units           : financeData.articleRef["A_" + id].quantity,
							price           : financeData.articleRef["A_" + id].price,
							total           : subTotal,
							refunded_amount : 0,
							currency_id     : financeData.articleRef["A_" + id].currency_id
						}
					}
				}
			),
			payments : invoiceToDisplay.payments.map
			(
				(payment : any)=>
				{
					if(payment.status_id != paymentStatus.REJECTED)
					{
						if(payment.status_id == paymentStatus.APPROVED)
						{
							approvedPaidTotal += +payment.amount
						}
						else
						{
							pendingPaidTotal += +payment.amount
						}
					}

					return payment
				}
			)
		}

		setSelectedInvoice
		({
			...invoice, pendingPaidTotal : pendingPaidTotal,
			approvedPaidTotal : approvedPaidTotal
		})


		setPromptDisplayQueue(["invoice"])
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	/*
		Perform actions based on changes to the financeData object.
		Fetches the latest invoice IDs for each schedule and updates state accordingly.
		@param {Object} financeData - The finance data object.
	*/
	useEffect
	(
		()=>
		{
			if(Object.keys(financeData.scheduleRef).length > 0)// Initialize an array to store the latest invoice IDs
			{
				const latestInvoiceIds : number[] = []

				Object.keys(financeData.scheduleRef).map// Iterate through each schedule in financeData
				(
					(schedule : any)=>
					{
						// Filter tuitionRef objects based on the current schedule
						const tuitionRefArray = Object.values(financeData.tuitionRef).filter((ot : any) => ot.scheduleKey === schedule)

						if(Object.keys(tuitionRefArray).length > 0)// Get the latest invoice object for the current schedule-----
						{
							const latestInvoice : any = getLatestInvoices(tuitionRefArray)

							if(latestInvoice.status_id === invoiceStatus.FULLY_PAID)// Check if the latest invoice is in paid status FULLY_PAID
							{
								latestInvoiceIds.push(latestInvoice.invoice_id)// Add the invoice ID to the latestInvoiceIds array
							}
						}
					}
				)

				setLatestInvoices(latestInvoiceIds)// Update state with the latest invoice IDs-------------------------------
			}
		},[financeData]
	)
	/*
		Perform actions based on changes to the financeData object.
		Fetches the latest invoice IDs for each schedule and updates state accordingly.
		@param {Object} financeData - The finance data object.
	*/
	useEffect
	(
		()=>
		{
			if(Object.keys(financeData.scheduleRef).length > 0)// Initialize an array to store the latest invoice IDs
			{
				const latestInvoiceIds : number[] = []

				Object.keys(financeData.scheduleRef).map// Iterate through each schedule in financeData
				(
					(schedule : any)=>
					{
						// Filter tuitionRef objects based on the current schedule
						const tuitionRefArray = Object.values(financeData.tuitionRef).filter((ot : any) => ot.scheduleKey === schedule)

						if(Object.keys(tuitionRefArray).length > 0)// Get the latest invoice object for the current schedule-----
						{
							const latestInvoice : any = getLatestInvoices(tuitionRefArray)

							if(latestInvoice.status_id === invoiceStatus.FULLY_PAID)// Check if the latest invoice is in paid status FULLY_PAID
							{
								latestInvoiceIds.push(latestInvoice.invoice_id)// Add the invoice ID to the latestInvoiceIds array
							}
						}
					}
				)

				setLatestInvoices(latestInvoiceIds)// Update state with the latest invoice IDs-------------------------------
			}
		},[financeData]
	)


	useEffect
	(
		()=>
		{
			let reportMap = {...dangerReport}

			if(financeData)
			{
				if(Object.values(financeData.taxRef).length < 1)
				{
					reportMap.noTaxessProvided=
						"No hay conceptos de impuesto registrados en el sistema, por lo que no se podrá acceder al detalle de facturas."
				}
			}

			if(generalData)
			{
				if(Object.values(generalData.paymentMethodRef).length < 1)
				{
					reportMap.noPaymentMethodsProvided=
						"No hay métodos de pago registrados en el sistema, por lo que no se podrán registrar pagos."
				}

				if(Object.values(generalData.recipientAccountRef).length < 1)
				{
					reportMap.noRecipentAccountsProvided=
						"No hay cuentas receptoras registradas en el sistema, por lo que no se podrán registrar pagos."
				}
			}

			setDangerReport(reportMap)
		},
		[]
	)

	useEffect
	(
		()=>
		{
			if((currentUserCampus) && (currentUserCampus != userCampus))
			{
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setPromptDisplayQueue([])
				setSelectedInvoice(null)
				setCurrencySymbol(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
				setTotal(0)
				setKitRef({})
				setOtherRef({})
				setArticleRef({})
				setScheduleKitRef({})
				setScheduleChargeRef({})
				setScheduleChargeAddRef({})
				resetFn()
			}
		},
		[currentUserCampus]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	return userCampus == null ? <></> : <>
		<div className={"compactableSection" + (promptDisplayQueue[0] == null ? "" : " sidePrompt")} style=
		  {{
			float : "left", height : "98%", overflow : "auto", paddingBottom : "30px", width : "calc(100%" + 
				(promptDisplayQueue[0] == null ? ")" : " - " + promptWidth[promptDisplayQueue[0]] + ")")
		 }}
		>
			{generalData == null ? "" : <Header generalData={generalData} />}
			{
				Object.keys(dangerReport).map
				(
					(code : string)=>
					(
						<div className="callout callout-danger" key={"warn_" + code} style={{padding : "unset"}}><table
						  style={{width : "100%"}}><tbody><tr>
							<td style={{padding : "16px", verticalAlign : "middle"}}>
								<FontAwesomeIcon icon={solid("triangle-exclamation")} />
							</td>
							<td style={{width : "100%"}}>
								{dangerReport[code]}
							</td>
						</tr></tbody></table></div>
					)
				)
			}
			{
				(Object.keys(financeData.scheduleRef) == null || Object.keys(financeData.scheduleRef).length < 1)
					?
				(idleLocked ? "Cargando..." : "")
					:
				Object.values(financeData.scheduleRef).filter((schedule : any) => (schedule.owedTuitionIds.length > 0)).map
				(
					(schedule : any, parentIndex : number) => <div style={styles.container} key={"CPI" + parentIndex}><div
					  className="card" style={{...styles.card}}
					>
						<div className="cardBody">
							<section style={{...styles.header, flexDirection : "column"}}>
								<p style={styles.subtitle}>
									{Constants.Common.course}:
								</p>
								<h3 style={styles.courseText}>
									{schedule.level.course.name}
								</h3>
							</section>
							<section style=
							  {{
								marginBottom : 10, marginLeft : "25px", display : "inline-flex", alignItems : "stretch",
								flexFlow     : "wrap"
							 }}
							>
								<div style={{flexGrow : 1}}><div style={{display : "flex", alignItems : "stretch", flexFlow : "wrap"}}>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.level}:
										</div>
										<div style={{...styles.boldText}}>
											{schedule.level.name}
										</div>
									</div></div>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.period}:
										</div>
										<div style={{...styles.periodText}}>
											{schedule.period.name}
										</div>
									</div></div>
								</div></div>
								<div style={{flexGrow : 1}}><div style={{display : "flex", alignItems : "stretch", flexFlow : "wrap"}}>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"	}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.scheduleCode}:
										</div>
										<div style={{...styles.boldText}}>
											{schedule.code}
										</div>
									</div></div>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.schedule}:
										</div>
										<div style={{...styles.boldText}}>
											{schedule.sessionWeek}
										</div>
									</div></div>
								</div></div>
							</section>
							<div style={{overflow : "auto", margin : "0px 15px"}}><table className="commonTable">
								<thead><tr key="chargesHeader" style={{color : "#8F91D8"}}>
									<th />
									<th style={{borderBottom : "none"}} />
									<th>
										{Constants.Common.concept}
									</th>
									<th>
										{Constants.Common.endDate}
									</th>
									<th>
										{Constants.Common.status}
									</th>
									<th>
										{Constants.Common.totalAmount}
									</th>
									<th>
										{Constants.Common.paidAmount}
									</th>
									<th>
										{Constants.Common.pendingAmount}
									</th>
									<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
										{Constants.Common.invoice}
									</th>
								</tr></thead>
								<tbody>{
									schedule.owedTuitionIds.map
									(
										(tuitionId : any) => <tr key={"OT_" + tuitionId}>
											<td>{
												(
													(
														Object.keys(generalData.paymentMethodRef).length < 1
															|| Object.keys(generalData.recipientAccountRef).length < 1
													)
														|| financeData.tuitionRef["OT_" + tuitionId].invoice_id
												) ? "" :
												<button className="btn btn-default" type="button"
												  disabled={idleLocked || financeData.tuitionRef["OT_" + tuitionId].disabled}
												  onClick={() => toggleCourseChargeSelection(financeData.tuitionRef["OT_" + tuitionId])}
												>
													<CustomIcon
													  name={scheduleChargeRef["OT_" + tuitionId] ? "bCheck" : "bUncheck"}
													/>
												</button>
											}</td>
											<td style={{borderBottom : "none"}}>
												{financeData.tuitionRef["OT_" + tuitionId].tuition_number}
											</td>
											<td>
												{financeData.tuitionRef["OT_" + tuitionId].title}
											</td>
											<td>
												{financeData.tuitionRef["OT_" + tuitionId].dueDate}
											</td>
											<td>
												{handleStatusCharge(financeData.tuitionRef["OT_" + tuitionId])}
											</td>
											<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
												{sprintf(currencySymbol + " %.02f", financeData.tuitionRef["OT_" + tuitionId].sub_total)}
											</td>
											<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
												{sprintf(currencySymbol + " %.02f", financeData.tuitionRef["OT_" + tuitionId].paid_amount)}
											</td>
											<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
												{
													sprintf(currencySymbol + " %.02f", 
													financeData.tuitionRef["OT_" + tuitionId].sub_total - financeData.tuitionRef["OT_" + tuitionId].paid_amount)
												}
											</td>
											<td ref=
											  {
												el=>
												{
													if(el)
													{
														el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")
														el.style.setProperty("text-align", "right", "important")
													}
												}
											 }
											>{
												financeData.invoiceRef
													["I_" + financeData.tuitionRef["OT_" + tuitionId].invoice_id] == null
														? "-" :
												<OverlayTrigger overlay={<Tooltip id="tooltip-activos">{Constants.Common.invoiceDetail}</Tooltip>}>
													<u style={{cursor : "pointer"}} onClick=
													{
														()=>
														{
															if(Object.keys(financeData.taxRef).length < 1)
															{
																errorPrompt(taxRegistryRequirement)
															}
															else
															{
																if(!idleLocked)
																{
																	showInvoicePrompt
																	(
																		financeData.invoiceRef
																			["I_" + financeData.tuitionRef["OT_" + tuitionId].invoice_id]
																	)
																}
															}
														}
													}
													>{
														financeData
															.invoiceRef["I_" + financeData.tuitionRef["OT_" + tuitionId].invoice_id]
																.invoice_number
													}</u>
												</OverlayTrigger>
											}</td>
										</tr>
									)
								}</tbody>
							</table></div>
						</div>
						<div className="cardFooter"><section style={styles.totalSection}><div
						  style={{display : "inline-flex", alignItems : "stretch", flexFlow : "wrap"}}
						>
							<div style={styles.totalText}>
								{Constants.Screens.Finances.accountStatement.total}:
							</div>
							{handleTotal(financeData.tuitionRef, "OT_", schedule.owedTuitionIds)}
						</div></section></div>
					</div></div>
				)
			}
			<div style={{padding : "0px 30px"}}>
				<div className="row">{
					[financeData.kitIds, financeData.otherIds].map
					(
						(articleData : any, partIndex : number) => <div key={"AT_" + partIndex} style={{paddingTop : "30px"}}
						  className="col-lg-6"
						><div style={{...styles.container, width : "100%", padding : 0}}><div className="card" style={styles.card}>
							<section className="cardHeader" style={{...styles.header, flexDirection : "column"}}><h3
							  style={{...styles.courseText, textTransform : "capitalize"}}
							>
								{titles[partIndex]}
							</h3></section>
							{
								articleData.length < 1
									?
								<div style={{overflow : "auto", margin : "0px 15px"}}>
									{idleLocked ? "Cargando..." : "Sin registros"}
								</div>
									:
								<>
									<div style={{overflow : "auto", margin : "0px 15px"}}><table className="commonTable">
										<thead><tr key="oaedArticlesHeader" style={{color : "#8F91D8"}}>
											<th />
											<th style={{borderBottom : "none"}} />
											<th>
												{Constants.Common.concept}
											</th>
											<th>
												{Constants.Common.date}
											</th>
											<th>
												{Constants.Common.status}
											</th>
											<th>
												{Constants.Common.totalAmount}
											</th>
											<th>
												{Constants.Common.paidAmount}
											</th>
											<th>
												{Constants.Common.pendingAmount}
											</th>
											<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
												{Constants.Common.invoice}
											</th>
										</tr></thead>
										<tbody>{
											articleData.map
											(
												(id : number, articleIndex : number) => <tr key={"OA_" + id}>
													<td>{
														(
															(
																Object.keys(generalData.paymentMethodRef).length < 1
																	|| Object.keys(generalData.recipientAccountRef).length < 1
															)
																||
															(financeData.articleRef["A_" + id].invoice_id)
														)
															? "" : <button className="btn btn-default" disabled=
														  {
															idleLocked ||
															(
																promptDisplayQueue[0] != null &&
																	promptDisplayQueue[0] != "checkout"
															)
														 } onClick=
														  {
															()=>
															{
																articleHandling[partIndex]
																	(financeData.articleRef["A_" + id])
															}
														 }
														>
															<CustomIcon name=
															  {
																(articleListing[partIndex]["A_" + id] || scheduleKitRef["A_" + id])
																	? "bCheck"
																		 : "bUncheck"
															 }
															/>
														</button>
													}</td>
													<td style={{borderBottom : "none"}}>
														{articleIndex + 1}
													</td>
													<td>
														{financeData.articleRef["A_" + id].article.name}
													</td>
													<td>
														{financeData.articleRef["A_" + id].date}
													</td>
													<td>
														{handleStatusCharge(financeData.articleRef["A_" + id])}
													</td>
													<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
														{sprintf(currencySymbol + " %.02f", financeData.articleRef["A_" + id].sub_total)}
													</td>
													<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
														{sprintf(currencySymbol + " %.02f", financeData.articleRef["A_" + id].paid_amount)}
													</td>
													<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
														{
															sprintf(currencySymbol + " %.02f", 
															financeData.articleRef["A_" + id].sub_total - financeData.articleRef["A_" + id].paid_amount)
														}
													</td>
													<td ref=
													  {
														el=>
														{
															if(el)
															{
																el.style.setProperty
																	("border-bottom", "1px solid #C7C7C7", "important")
																el.style.setProperty
																	("text-align", "right", "important")
															}
														}
													 }
													>{
														financeData.invoiceRef
															["I_" + financeData.articleRef["A_" + id].invoice_id]
																== null ? "-" :
														<OverlayTrigger overlay={<Tooltip id="tooltip-activos">{Constants.Common.invoiceDetail}</Tooltip>}>
															<u style={{cursor : "pointer"}} onClick=
															  {
																()=>
																{
																	if(Object.keys(financeData.taxRef).length < 1)
																	{
																		errorPrompt(taxRegistryRequirement)
																	}
																	else
																	{
																		if(!idleLocked)
																		{showInvoicePrompt
																		(
																			financeData
																				.invoiceRef["I_" + financeData.articleRef["A_" + id]
																				.invoice_id]
																		)
																		}
																	}
																}
															 }
															>{
																financeData
																	.invoiceRef["I_" + financeData.articleRef["A_" + id].invoice_id]
																	.invoice_number
															}</u>
														</OverlayTrigger>
													}</td>
												</tr>
											)
										}</tbody>
									</table></div>
									<div className="cardFooter"><section style={styles.totalSection}><div style=
									  {{display : "inline-flex", alignItems : "stretch", flexFlow : "wrap"}}
									>
										<div style={styles.totalText}>
											{Constants.Screens.Finances.accountStatement.total}:
										</div>
										{handleTotal(financeData.articleRef, "A_", articleData)}
									</div></section></div>
								</>
							}
						</div></div></div>
					)
				}</div>
			</div>
			<br />
			<br />
			<br />
			<UnderlineHeading name={"Historial"} />
			{
				(Object.keys(financeData.scheduleRef) == null || Object.keys(financeData.scheduleRef).length < 1)
					?
				(idleLocked ? "Cargando..." : "")
					:
				Object.values(financeData.scheduleRef).filter((schedule : any) => (schedule.owedTuitionIds.length > 0)).map
				(
					(schedule : any, parentIndex : number) => <div style={styles.container} key={"CPI" + parentIndex}><div
					  className="card" style={{...styles.card}}
					>
						<div className="cardBody">
							<section style={{...styles.header, flexDirection : "column"}}>
								<p style={styles.subtitle}>
									{Constants.Common.course}:
								</p>
								<h3 style={styles.courseText}>
									{schedule.level.course.name}
								</h3>
							</section>
							<section style=
							  {{
								marginBottom : 10, marginLeft : "25px", display : "inline-flex", alignItems : "stretch",
								flexFlow     : "wrap"
							 }}
							>
								<div style={{flexGrow : 1}}><div style={{display : "flex", alignItems : "stretch", flexFlow : "wrap"}}>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.level}:
										</div>
										<div style={{...styles.boldText}}>
											{schedule.level.name}
										</div>
									</div></div>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.period}:
										</div>
										<div style={{...styles.periodText}}>
											{schedule.period.name}
										</div>
									</div></div>
								</div></div>
								<div style={{flexGrow : 1}}><div style={{display : "flex", alignItems : "stretch", flexFlow : "wrap"}}>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"	}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.scheduleCode}:
										</div>
										<div style={{...styles.boldText}}>
											{schedule.code}
										</div>
									</div></div>
									<div style={{flexGrow : 1}}><div style={{...styles.subtitleContainer, alignItems : "stretch"}}>
										<div style={{...styles.subtitle}}>
											{Constants.Common.schedule}:
										</div>
										<div style={{...styles.boldText}}>
											{schedule.sessionWeek}
										</div>
									</div></div>
								</div></div>
							</section>
							<div style={{overflow : "auto", margin : "0px 15px"}}><table className="commonTable">
								<thead><tr key="chargesHeader" style={{color : "#8F91D8"}}>
									<th />
									<th style={{borderBottom : "none"}} />
									<th>
										{Constants.Common.concept}
									</th>
									<th>
										{Constants.Common.endDate}
									</th>
									<th>
										{Constants.Common.status}
									</th>
									<th>
										{Constants.Common.totalAmount}
									</th>
									<th>
										{Constants.Common.paidAmount}
									</th>
									<th>
										{Constants.Common.pendingAmount}
									</th>
									<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
										{Constants.Common.invoice}
									</th>
								</tr></thead>
								<tbody>{
									schedule.owedTuitionIds.map
									(
										(tuitionId : any) => <tr key={"OT_" + tuitionId}>
											<td>{
												(
													(
														Object.keys(generalData.paymentMethodRef).length < 1
															|| Object.keys(generalData.recipientAccountRef).length < 1
													)
														|| financeData.tuitionRef["OT_" + tuitionId].invoice_id
												) ? "" :
												<button  disabled={idleLocked || financeData.tuitionRef["OT_" + tuitionId].disabled}
												  onClick={() => toggleCourseChargeSelection(financeData.tuitionRef["OT_" + tuitionId])}
												  className="btn btn-default" type="button"
												>
													<CustomIcon
													  name={scheduleChargeRef["OT_" + tuitionId] ? "bCheck" : "bUncheck"}
													/>
												</button>
											}</td>
											<td style={{borderBottom : "none"}}>
												{financeData.tuitionRef["OT_" + tuitionId].tuition_number}
											</td>
											<td>
												{financeData.tuitionRef["OT_" + tuitionId].title}
											</td>
											<td>
												{financeData.tuitionRef["OT_" + tuitionId].dueDate}
											</td>
											<td>
												{handleStatusCharge(financeData.tuitionRef["OT_" + tuitionId])}
											</td>
											<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
												{sprintf(currencySymbol + " %.02f", financeData.tuitionRef["OT_" + tuitionId].sub_total)}
											</td>
											<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
												{sprintf(currencySymbol + " %.02f", financeData.tuitionRef["OT_" + tuitionId].paid_amount)}
											</td>
											<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
												{
													sprintf(currencySymbol + " %.02f", 
													financeData.tuitionRef["OT_" + tuitionId].sub_total - financeData.tuitionRef["OT_" + tuitionId].paid_amount)
												}
											</td>
											<td ref=
											  {
												el=>
												{
													if(el)
													{
														el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")
														el.style.setProperty("text-align", "right", "important")
													}
												}
											 }
											>{
												financeData.invoiceRef
													["I_" + financeData.tuitionRef["OT_" + tuitionId].invoice_id] == null
														? "-" :
												<OverlayTrigger overlay={<Tooltip id="tooltip-activos">{Constants.Common.invoiceDetail}</Tooltip>}>
													<u style={{cursor : "pointer"}} onClick=
													  {
														()=>
														{
															if(Object.keys(financeData.taxRef).length < 1)
															{
																errorPrompt(taxRegistryRequirement)
															}
															else
															{
																if(!idleLocked)
																{
																	showInvoicePrompt
																	(
																		financeData.invoiceRef
																			["I_" + financeData.tuitionRef["OT_" + tuitionId].invoice_id]
																	)
																}
															}
														}
													 }
													>{
														financeData
															.invoiceRef["I_" + financeData.tuitionRef["OT_" + tuitionId].invoice_id]
																.invoice_number
													}</u>
												</OverlayTrigger>
											}</td>
										</tr>
									)
								}</tbody>
							</table></div>
						</div>
						<div className="cardFooter"><section style={styles.totalSection}><div
						  style={{display : "inline-flex", alignItems : "stretch", flexFlow : "wrap"}}
						>
							<div style={styles.totalText}>
								{Constants.Screens.Finances.accountStatement.total}:
							</div>
							{handleTotal(financeData.tuitionRef, "OT_", schedule.owedTuitionIds)}
						</div></section></div>
					</div></div>
				)
			}
			<div style={{padding : "0px 30px"}}><div className="row">{
				[financeData.kitIds, financeData.otherIds].map
				(
					(articleData : any, partIndex : number) => <div key={"AT_" + partIndex} style={{paddingTop : "30px"}}
					  className="col-lg-6"><div style={{...styles.container, width : "100%", padding : 0}}><div className="card"
					  style={styles.card}
					>
						<section className="cardHeader" style={{...styles.header, flexDirection : "column"}}><h3
						  style={{...styles.courseText, textTransform : "capitalize"}}
						>
							{titles[partIndex]}
						</h3></section>
						{
							articleData.length < 1
								?
							<div style={{overflow : "auto", margin : "0px 15px"}}>
								{idleLocked ? "Cargando..." : "Sin registros"}
							</div>
								:
							<>
								<div style={{overflow : "auto", margin : "0px 15px"}}><table className="commonTable">
									<thead>
										<tr key="oaedArticlesHeader" style={{color : "#8F91D8"}}>
											<th />
											<th style={{borderBottom : "none"}} />
											<th>
												{Constants.Common.concept}
											</th>
											<th>
												{Constants.Common.date}
											</th>
											<th>
												{Constants.Common.status}
											</th>
											<th>
												{Constants.Common.totalAmount}
											</th>
											<th>
												{Constants.Common.paidAmount}
											</th>
											<th>
												{Constants.Common.pendingAmount}
											</th>
											<th ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #000000", "important")}}>
												{Constants.Common.invoice}
											</th>
										</tr>
									</thead>
									<tbody>{
										articleData.map
										(
											(id : number, articleIndex : number) => <tr key={"OA_" + id}>
												<td>{
													(
														(
															Object.keys(generalData.paymentMethodRef).length < 1
																|| Object.keys(generalData.recipientAccountRef).length < 1
														)
															||
														(financeData.articleRef["A_" + id].invoice_id)
													)
														? "" : <button className="btn btn-default" disabled=
													  {
														idleLocked ||
														(
															promptDisplayQueue[0] != null &&
																promptDisplayQueue[0] != "checkout"
														)
													 } onClick=
													  {
														()=>
														{
															articleHandling[partIndex]
																(financeData.articleRef["A_" + id])
														}
													 }
													>
														<CustomIcon name=
														  {
															(articleListing[partIndex]["A_" + id] || scheduleKitRef["A_" + id])
																? "bCheck"
																	 : "bUncheck"
														 }
														/>
													</button>
												}</td>
												<td style={{borderBottom : "none"}}>
													{articleIndex + 1}
												</td>
												<td>
													{financeData.articleRef["A_" + id].article.name}
												</td>
												<td>
													{financeData.articleRef["A_" + id].date}
												</td>
												<td>
													{handleStatusCharge(financeData.articleRef["A_" + id])}
												</td>
												<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
													{sprintf(currencySymbol + " %.02f", financeData.articleRef["A_" + id].sub_total)}
												</td>
												<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
													{sprintf(currencySymbol + " %.02f", financeData.articleRef["A_" + id].paid_amount)}
												</td>
												<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
														{
															sprintf(currencySymbol + " %.02f", 
															financeData.articleRef["A_" + id].sub_total - financeData.articleRef["A_" + id].paid_amount)
														}
													</td>
												<td ref=
												  {
													el=>
													{
														if(el)
														{
															el.style.setProperty
																("border-bottom", "1px solid #C7C7C7", "important")
															el.style.setProperty
																("text-align", "right", "important")
														}
													}
												 }
												>{
													financeData.invoiceRef
														["I_" + financeData.articleRef["A_" + id].invoice_id]
															== null ? "-" :
													<OverlayTrigger overlay={<Tooltip id="tooltip-activos">{Constants.Common.invoiceDetail}</Tooltip>}>
														<u style={{cursor : "pointer"}} onClick=
														  {
															()=>
															{
																if(Object.keys(financeData.taxRef).length < 1)
																{
																	errorPrompt(taxRegistryRequirement)
																}
																else
																{
																	if(!idleLocked)
																	{
																		showInvoicePrompt
																		(
																			financeData
																				.invoiceRef["I_" + financeData.articleRef["A_" + id]
																				.invoice_id]
																		)
																	}
																}
															}
														 }
														>{
															financeData.invoiceRef["I_" + financeData.articleRef["A_" + id].invoice_id].invoice_number
														}</u>
													</OverlayTrigger>
												}</td>
											</tr>
										)
									}</tbody>
								</table></div>
								<div className="cardFooter"><section style={styles.totalSection}><div style=
								  {{display : "inline-flex", alignItems : "stretch", flexFlow : "wrap"}}
								>
									<div style={styles.totalText}>
										{Constants.Screens.Finances.accountStatement.total}:
									</div>
									{handleTotal(financeData.articleRef, "A_", articleData)}
								</div></section></div>
							</>
						}
					</div></div></div>
				)
			}</div></div>
		</div>
		{
			((promptDisplayQueue == null || promptDisplayQueue.length < 1) || selectedInvoice == null) ? "" : <FinancePrompt
			  scheduleChargeRef={scheduleChargeRef} scheduleChargeAddRef={scheduleChargeAddRef} student={generalData.student}
			  toggleCourseChargeSelection={toggleCourseChargeSelection} scheduleRef={financeData.scheduleRef} kitRef={kitRef}
			  recipientAccountRef={generalData.recipientAccountRef} toggleOtherSelection={toggleOtherSelection} total={total}
			  promptDisplayQueue={promptDisplayQueue} setPromptDisplayQueue={setPromptDisplayQueue} articleRef={articleRef}
			  paymentStatusRef={generalData.paymentStatusRef} taxRef={financeData.taxRef} selectedInvoice={selectedInvoice}
			  setSelectedInvoice={setSelectedInvoice} latestInvoices={latestInvoices} invoiceStatusRef={generalData.invoiceStatusRef}
			  paymentMethodRef={generalData.paymentMethodRef} toggleKitSelection={toggleKitSelection} setTotal={setTotal}
			  defaultDate={generalData.date} otherRef={otherRef} conceptsCount={conceptsCount} totalToPaid={totalToPaid} 
			  availableAccounts={generalData.availableAccounts} resetFn=
			  {
				()=>
				{
					setPromptDisplayQueue([])
					setTotal(0)
					setKitRef({})
					setOtherRef({})
					setArticleRef({})
					setConceptsCount(0)
					setScheduleKitRef({})
					setScheduleChargeRef({})
					setScheduleChargeAddRef({})
					resetFn()
				}
			 } promptDismissal=
			  {
				()=>
				{
					setPromptDisplayQueue([])
					setSelectedInvoice(null)
				}
			 }
			  handleTypeOfDiscount={handleTypeOfDiscount}
			  handleDiscount={handleDiscount}
			  setSelectedPaymentMethod={setSelectedPaymentMethod}
			/>
		}
	</>
}

export default AccountStament