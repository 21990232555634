import { useEffect, useState }
	from "react"
import AccountStament
	from "./accountStatement"
import { useLocation, useNavigate }
	from "react-router-dom"
import { useDispatch, useSelector }
	from "react-redux"
import { sprintf }
	from "sprintf-js"
import { articleType, owedTuitionStatus }
	from "../../../constants"

import {
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice
}
	from "./../../../globals"
import { toAscendingDate, monthRef, weekDays, isDueInvoice, getErrorMessage }
	from "./../../../common"
import CatalogService
	from "./../../../services/catalog.service"
import StudentsService
	from "./../../../services/campus/students.service"
import TaxesService
	from "./../../../services/campus/taxes.service"
import PaymentMethodsService
	from "./../../../services/paymentMethods.service"
import FinancesService
	from "./../../../services/campus/finances.service"
import "./../../../components/dropdown/style.css"
import "./helper.css"

const AccountStamentScreen = (props: any, route: any) => {
	const location = useLocation()
	const [generalData, setGeneralData] = useState<any>({})
	const [sectionLoaded, setSectionLoaded] = useState<boolean>(false)
	const [financeData, setFinanceData] = useState<any>({})
	const dispatch = useDispatch()
	const sessionToken: string | null = useSelector((state: RootState) => state.sessionToken.value)
	const currentUserCampus: any = useSelector((state: RootState) => state.currentUserCampus.value)
	const campusRef: any = useSelector((state: RootState) => state.campusRef.value)
	const [userCampus, setUserCampus] = useState<number>(currentUserCampus)
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null)
	const navigate = useNavigate()
	const navigateTo = (params: any) => navigate("/statement", { state: params })

	const procedureComplaint = (message: string) => {
		dispatch
			(
				displayNotice
					({
						cornerClose: false,
						message: message,
						heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
							Error
						</h3>
					})
			)
	}

	const mapAvailableAccountsRefs = (response: any) => {
		const mappedAccounts: any = {};

		response.forEach((paymentMethod: any) => {
			paymentMethod.accounts.forEach((account: any) => {
				mappedAccounts[`RA_${account.id}`] = {
					...account,
					label: `${account.name} (${account.account_number})`
				};
			});
		});

		return mappedAccounts;
	};

	const dueMonthlyBalance = () => {
		let sum = 0;
		if (financeData && financeData.tuitionRef) {
			Object.values(financeData.tuitionRef).map((tuition: any) => {
				sum += tuition.invoice_id === null && isDueInvoice(tuition.dueDate) ? +tuition.amount : 0
				if (tuition.status_id === owedTuitionStatus.PARTLY_PAID) {
					let pendientAmmount = +tuition.amount - +tuition.paid_amount
					sum += pendientAmmount;
				}
			});
		}
		return sum;
	}

	const dataRequest = async () => {
		const infoRetrievalError: string = "No se pudo extraer la información financiera del alumno seleccionado"

		dispatch(enableIdleLockSwitch())

		if (userCampus) {
			try {
				let balance = await getStudentGeneralBalance();
				const paymentStatuses: any = await CatalogService.getPaymentStatuses(sessionToken)

				if (paymentStatuses.status == 200 || paymentStatuses.status == 204) {
					let paymentStatusRef: any = {}

					paymentStatuses.data.map
						(
							(record: any) => paymentStatusRef["PS_" + record.id] = { ...record, label: record.name }
						)

					const invoiceStatuses: any = await CatalogService.getInvoiceStatuses(sessionToken)

					if (invoiceStatuses.status == 200 || invoiceStatuses.status == 204) {
						let invoiceStatusRef: any = {}

						invoiceStatuses.data.map
							(
								(record: any) => invoiceStatusRef["IS_" + record.id] = { ...record, label: record.name }
							)

						const paymentMethods: any = await PaymentMethodsService.getPaymentMethodsByCountry(campusRef[currentUserCampus].company.country_id)

						if (paymentMethods.status == 200 || paymentMethods.status == 204) {
							let paymentMethodRef: any = {};
							let availableAccounts;

							paymentMethods.data.map((record: any) => paymentMethodRef["PM_" + record.id] = { ...record, label: record.name })
							setSelectedPaymentMethod(paymentMethods.data[0]);
							// let recipientAccounts = paymentMethods.data[0];
							let recipientAccountRef: any = []
							if (paymentMethods.data.length > 0) {
								paymentMethods.data[0].accounts.map((record: any) =>
									recipientAccountRef["RA_" + record.id] = { ...record, label: record.name + " (" + record.account_number + ")" })
								availableAccounts = mapAvailableAccountsRefs(paymentMethods.data)
							}

							const owedArticleStatuses: any = await CatalogService.getOwedArticleStatuses(sessionToken)

							if (owedArticleStatuses.status == 200 || owedArticleStatuses.status == 204) {
								let owedArticleStatusRef: any = {}

								owedArticleStatuses.data.map
									(
										(record: any) => {
											owedArticleStatusRef["OAS_" + record.id] = record
										}
									)

								const owedTuitionStatuses: any = await CatalogService.getOwedTuitionStatuses(sessionToken)

								if (owedTuitionStatuses.status == 200 || owedTuitionStatuses.status == 204) {
									const owedTuitionStatusRef: any = {}

									owedTuitionStatuses.data.map
										(
											(record: any) => {
												owedTuitionStatusRef["OTS_" + record.id] = record
											}
										)

									const taxes: any = await TaxesService.getTaxes(campusRef[currentUserCampus].id)

									if (taxes.status == 200 || taxes.status == 204) {
										const taxRef: any = {}

										taxes.data.map
											(
												(record: any) => {
													taxRef["T_" + record.id] = record
												}
											)

										const studentInfo: any = await StudentsService.getStudentInfo
											(campusRef[currentUserCampus].id, location.state.id)

										if (studentInfo.status == 200 || studentInfo.status == 204) {
											let dueDate: Date
											let period: string
											let amount: number = 0
											let kitsDebt: number = 0
											let othersDebt: number = 0
											let invoiceRef: any = {}
											let scheduleRef: any = {}
											let articleRef: any = {}
											let tuitionRef: any = {}
											let kitIds: any = []
											let otherIds: any = []
											let invoicedKitIds: any = []
											let invoicedOtherIds: any = []
											let recordKey: string

											studentInfo.data.data.enrollments.map
												(
													(enrollment: any) => {
														recordKey = "S_" + enrollment.schedule_id

														scheduleRef[recordKey] =
														{
															...enrollment.schedule, refIndex: recordKey,
															owedTuitionIds: [],
															invoicedTuitionIds: [],
															periodName: monthRef[parseInt(enrollment.schedule.period.start.split("-")[1]) - 1] +
																" - " + monthRef[parseInt(enrollment.schedule.period.end.split("-")[1]) - 1],
															sessionWeek: enrollment.schedule
																.sessions
																.map
																(
																	(session: any) => {
																		let startMinutes = (session.start_hours % 10)
																		let startHours = (session.start_hours - startMinutes) / 10
																		let endMinutes = (session.end_hours % 10)
																		let endHours = (session.end_hours - endMinutes) / 10

																		return weekDays[session.week_day].substr(0, 2) + sprintf
																			(
																				"%02d:%02d-%02d:%02d",
																				startHours, startMinutes, endHours, endMinutes * 6
																			)
																	}
																)
																.join(", ")
														}
													}
												)

											studentInfo.data.data.invoices.map
												(
													(invoice: any) => {
														recordKey = "I_" + invoice.id

														invoiceRef[recordKey] =
														{
															...invoice, refIndex: recordKey,
															owedTuitionIds: [],
															owedArticleIds: []
														}
													}
												)

											studentInfo.data.data.tuitions.map
												(
													(record: any, index: number, array: any) => {
														let recordKey: string = "OT_" + record.id
														let scheduleKey: string = "S_" + record.schedule_id
														let prevScheduleKey = index > 0 ? array[index - 1].schedule_id : null
														let nextScheduleKey = index < array.length - 1 ? array[index + 1].schedule_id : null
														let prevRef = prevScheduleKey !== record.schedule_id ? null : "OT_" + (array[index - 1]?.id ?? null)
														let nextRef = nextScheduleKey !== record.schedule_id ? null : "OT_" + (array[index + 1]?.id ?? null)

														if (record.invoice_id !== null) {
															prevRef = null;
															nextRef = null;
														}

														tuitionRef[recordKey] =
														{
															...record, date: toAscendingDate(new Date(record.created_at)),
															refIndex: recordKey,
															prevRef: prevRef,
															nextRef: nextRef,
															disabled: false,
															childIndex: 0,
															parentIndex: 0,
															scheduleKey: scheduleKey,
															concept: record.name,
															dueDate: toAscendingDate(new Date(record.due_date + " 23:00:00")),
															selected: false,
															status: owedTuitionStatusRef["OTS_" + record.status_id].name,
															pivot: record.currency_id,
															period: monthRef[parseInt(record.start_date.split("-")[1]) - 1] +
																" - " + monthRef[parseInt(record.end_date.split("-")[1]) - 1],
														}

														scheduleRef[scheduleKey].owedTuitionIds.push(record.id)

														if (record.invoice_id) {
															scheduleRef[scheduleKey].invoicedTuitionIds.push(record.id)
															invoiceRef["I_" + record.invoice_id].owedTuitionIds.push(record.id)
														}
													}
												)

											studentInfo.data.data.articles.map
												(
													(record: any, index: number) => {
														amount = record.sub_total
														dueDate = new Date(record.created_at.split("T")[0].substring(0, -2) + "01 23:00:00")

														dueDate.setMonth(dueDate.getMonth() + 1)
														dueDate.setDate(dueDate.getDate() - 1)

														recordKey = "A_" + record.id

														articleRef[recordKey] =
														{
															...record, date: toAscendingDate(new Date(record.created_at)),
															refIndex: recordKey,
															childIndex: index,
															concept: record.title,
															consecutiveInvoice: index,
															dueDate: dueDate,
															invoiceNumber: record.invoice ? record.invoice.invoice_number : "-",
															status: owedArticleStatusRef["OAS_" + record.status_id].name,
															total: sprintf("%.02f", 0)
														}

														if (record.invoice_id) {
															invoiceRef["I_" + record.invoice_id].owedArticleIds.push(record.id)
														}

														if (record.article.type_id > articleType.PREMIUM_KIT) {
															othersDebt += (+record.sub_total) - (+record.paid_amount)

															if (record.invoice_id) {
																invoicedOtherIds.push(record.id)
															}

															otherIds.push(record.id)
														}
														else {
															kitsDebt += (+record.sub_total) - (+record.paid_amount)

															if (record.invoice_id) {
																invoicedKitIds.push(record.id)
															}

															kitIds.push(record.id)
														}
													}
												)

											setFinanceData
												({
													...financeData, taxRef: taxRef,
													kitIds: kitIds,
													otherIds: otherIds,
													invoicedKitIds: invoicedKitIds,
													invoicedOtherIds: invoicedOtherIds,
													tuitionRef: tuitionRef,
													articleRef: articleRef,
													scheduleRef: scheduleRef,
													invoiceRef: invoiceRef,
												})

											setGeneralData
												({
													invoiceStatusRef: invoiceStatusRef,
													paymentStatusRef: paymentStatusRef,
													paymentMethodRef: paymentMethodRef,
													recipientAccountRef: recipientAccountRef,
													availableAccounts: availableAccounts,
													student: location.state,
													owedTuitionStatusRef: owedTuitionStatusRef,
													owedArticleStatusRef: owedArticleStatusRef,
													currencyId: campusRef[currentUserCampus].company.currency_id,
													date: studentInfo.data.date,
													balance: balance
												})

											dispatch(disableIdleLockSwitch())
											setSectionLoaded(true)
										}
										else {
											procedureComplaint(infoRetrievalError)
											dispatch(disableIdleLockSwitch())
										}
									}
									else {
										procedureComplaint(infoRetrievalError)
										dispatch(disableIdleLockSwitch())
									}
								}
							}
							else {
								procedureComplaint(infoRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
						else {
							procedureComplaint(infoRetrievalError)
							dispatch(disableIdleLockSwitch())
						}
					}
					else {
						procedureComplaint(infoRetrievalError)
						dispatch(disableIdleLockSwitch())
					}
				}
				else {
					procedureComplaint(infoRetrievalError)
					dispatch(disableIdleLockSwitch())
				}
			}
			catch (error: any) {
				dispatch(disableIdleLockSwitch())

				if (error.status == 404) {
					navigate("/students")
				}
				else {
					console.log(error)
					procedureComplaint(infoRetrievalError)
				}
			}
		}
	}

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect(() => {
		if (selectedPaymentMethod) {
			let recipientAccountRef: any = [];
			let accounts = selectedPaymentMethod.accounts.map(
				(record: any) => recipientAccountRef["RA_" + record.id] = { ...record, label: record.name + " (" + record.account_number + ")" }
			)
			setGeneralData({ ...generalData, recipientAccountRef: accounts })
		}
	}, [selectedPaymentMethod])

	const getStudentGeneralBalance = async () => {
		try {
			let response = await FinancesService.getStudentGeneralBalance(campusRef[currentUserCampus].id, location.state.id);
			let balance = response.data;
			return balance;
		} catch (error) {
			console.log(error)
			procedureComplaint(getErrorMessage(error))
		}
	}

	useEffect
		(
			() => {
				if (sessionToken) {
					if (currentUserCampus == null) {
						procedureComplaint
							(
								"No hay planteles registrados o asignados al rol en uso por el usuario, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado."
							)
					}
					else {
						dataRequest()
					}
				}
			},
			[]
		)

	useEffect
		(
			() => {
				if ((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus)) {
					localStorage.setItem("currentCampus", currentUserCampus)
					setUserCampus(currentUserCampus)
					dataRequest()
				}
			},
			[currentUserCampus, sectionLoaded]
		)
	//-----------------------------------------------------------------------------------------------------------------------
	return !sectionLoaded
		? <></>
		: <AccountStament
			financeData={financeData}
			resetFn={dataRequest}
			generalData={generalData}
			setSelectedPaymentMethod={setSelectedPaymentMethod}
			errorPrompt={procedureComplaint}
			selectedPaymentMethod={selectedPaymentMethod}
		/>
}

export default AccountStamentScreen;