import {useState,useEffect}
	from "react"
import {useDispatch, useSelector}
	from "react-redux"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice, dismissNotice
}
	from "./../../globals"
import {pageGrouping, getAccessPermissions, revertStringDate}
	from "./../../common"
import SectionContainer
	from "./../../components/sectionContainer"
import FinancePrompt
	from "./financePrompt"
import CatalogService
	from "./../../services/catalog.service"
import SimpleDataService
	from "./../../services/simpleData.service"
import TaxesService
	from "./../../services/campus/taxes.service"
import PaymentsService
	from "./../../services/campus/payments.service"
import FinancesService
	from "./../../services/campus/finances.service"
import RecipientAccountsService
	from "./../../services/recipientAccounts.service"
import Constants, {paymentStatus, owedTuitionType}
	from "./../../constants"

import "./../../components/dropdown/style.css"

const OwedTuitions = ()=>
{
	const OWED_TUITION_STATUSES = 0
	const TAXES                 = 1
	const PAYMENT_STATUSES      = 2
	const PAYMENT_METHODS       = 3
	const RECIPIENT_ACCOUNTS    = 4
	const ENDING                = 5

	const promptWidth : any=
	{
		checkout : "446px",
		payment  : "583px",
		invoice  : "648px"
	}

	const periods     : any[]                             = []
	const dispatch                                        = useDispatch()
	const records      : any[]                            = periods.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [data, setData]                                 = useState<any[]>(records)
	const [warningReport, listWarnings]                   = useState<any>({})
	const idleLocked   : boolean                          = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                    = useSelector((state : RootState) => state.sessionToken.value)
	const basicInfo    : any                              = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const [currencySymbol, setCurrencySymbol]             = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
	const [currencyCode, setCurrencyCode]                 = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code)
	const currentUser  : any                              = useSelector((state : RootState) => state.userProfile.value)
	const [promptDisplayQueue, setPromptDisplayQueue]     = useState<string[]>([])
	const [pagination, setPagination]                     = useState<any>(null)
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [userCampus, setUserCampus]                     = useState<number>(currentUserCampus)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [selectedInvoice, setSelectedInvoice]           = useState<any>(null)
	const [selectedPayment, setSelectedPayment]           = useState<any>(null)
	const [defaultDate, setDefaultDate]                   = useState<any>(null)
	const [recipientAccountRef, setRecipientAccountRef]   = useState<any>(null)
	const [taxRef, setTaxRef]                             = useState<any>(null)
	const [statusRef, setStatusRef]                       = useState<any>(null)
	const [paymentMethodRef, setPaymentMethodRef]         = useState<any>(null)
	const [paymentStatusRef, setPaymentStatusRef]         = useState<any>(null)
	const [initSetup, setInitSetup]                       = useState<any>(null)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [selectableRecords, setSelectableRecords]       = useState<number>(0)
	const [selectedRef, setSelectedRef]                   = useState<any>({})
	const allowed    : any                                = getAccessPermissions(currentUser)

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const showInvoicePrompt = (invoiceToDisplay : any)=>
	{
		let invoice : any=
		{
			...invoiceToDisplay,
			notes : invoiceToDisplay.notes || ""
		}

		setSelectedInvoice({...invoice})

		setPromptDisplayQueue(["invoice"])
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const initFn = async()=>
					{
						const statusRetrievalError : string = "Los estatuses no pudieron ser extraídos," +
							"por lo que el acceso a este modulo quedará deshabilitado"

						try
						{
							dispatch(enableIdleLockSwitch)

							const result    : any = await CatalogService.getOwedTuitionStatuses(sessionToken)
							let   statusMap : any = {}

							if(result.status == 200 || result.status == 204)
							{
								result.data.map
								(
									(record : any)=>
									{
										statusMap["OTS_" + record.id] = record
									}
								)

								setStatusRef(statusMap)
								setInitSetup(OWED_TUITION_STATUSES)
							}
							else
							{
								procedureComplaint(statusRetrievalError)
								dispatch(disableIdleLockSwitch)
							}
						}
						catch(error : any)
						{
							console.log(error)
							procedureComplaint(statusRetrievalError)
							dispatch(disableIdleLockSwitch)
						}
					}

					initFn()
				}
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case OWED_TUITION_STATUSES:
						const taxRetrievalError : string = "Los impuestos no pudieron ser extraídos," +
							"por lo que el acceso a este modulo quedará deshabilitado"

						try
						{
							dispatch(enableIdleLockSwitch)

							const result : any = await TaxesService.getTaxes(campusRef[currentUserCampus].id)
							let   taxMap : any = {}

							if(result.status == 200 || result.status == 204)
							{
								result.data.map
								(
									(record : any)=>
									{
										taxMap["T_" + record.id] = record
									}
								)

								setTaxRef(taxMap)
								setInitSetup(TAXES)
							}
							else
							{
								procedureComplaint(taxRetrievalError)
								dispatch(disableIdleLockSwitch)
							}
						}
						catch(error : any)
						{
							console.log(error)
							procedureComplaint(taxRetrievalError)
							dispatch(disableIdleLockSwitch)
						}
					break;
					case TAXES:
						if(Object.keys(taxRef).length < 1)
						{
							procedureComplaint
							(
								"No se encontraron impuestos en el registro, " +
									"por lo que el acceso a este módulo quedará deshabilitado"
							)
						}
						else
						{
							const statusRetrievalError : string = "Los estatuses de pago no pudieron ser extraídos," + 
								"por lo que no se podrán emitir pagos para las facturas."

							try
							{
								dispatch(enableIdleLockSwitch)

								const result           : any = await CatalogService.getPaymentStatuses(sessionToken)
								let   paymentStatusMap : any = {}

								if(result.status == 200 || result.status == 204)
								{
									result.data.map
									(
										(record : any)=>
										{
											paymentStatusMap["PS_" + record.id] = record
										}
									)

									setPaymentStatusRef(paymentStatusMap)
									setInitSetup(PAYMENT_STATUSES)
								}
								else
								{
									procedureComplaint(statusRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error : any)
							{
								console.log(error)
								dispatch(disableIdleLockSwitch())
								procedureComplaint(statusRetrievalError)
							}
						}
					break;
					case PAYMENT_STATUSES:
						const methodRetrievalError : string = "No se pudieron extraer los métodos de pago, " +
							"por lo que no se podrán emitir pagos para las facturas"

						try
						{
							const result           : any = await SimpleDataService.getRecords("paymentMethods")
							let   paymentMethodMap : any = {}

							if(result.status == 200 || result.status == 204)
							{
								result.data.map
								(
									(record : any)=>
									{
										paymentMethodMap["PM_" + record.id] = {...record, label : record.name}
									}
								)

								setPaymentMethodRef(paymentMethodMap)
								setInitSetup(PAYMENT_METHODS)
							}
							else
							{
								dispatch(disableIdleLockSwitch())
								procedureComplaint(methodRetrievalError)
							}
						}
						catch(error : any)
						{
							console.log(error)

							dispatch(disableIdleLockSwitch())
							procedureComplaint(methodRetrievalError)
						}
					break;
					case PAYMENT_METHODS:
						if(Object.keys(paymentMethodRef).length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay métodos de pago en el registro, " + 
									"por lo que no se podrán emitir pagos para las facturas "
							)
						}
						else
						{
							try
							{
								const result              : any = await RecipientAccountsService.getRecipientAccounts()
								let   recipientAccountMap : any = {}

								if(result.status == 200 || result.status == 204)
								{
									result.data.map
									(
										(record : any)=>
										{
											recipientAccountMap["RA_" + record.id] = {...record, label : record.name + " [" +
												record.account_number + "]"}
										}
									)

									setRecipientAccountRef(recipientAccountMap)
									setInitSetup(RECIPIENT_ACCOUNTS)
								}
								else
								{
									listWarnings
									({
										...warningReport,
										recipientAccounts : "No se pudueron extraer las cuentas receptoras " + 
											"por lo que no se podrán emitir pagos para las facturas "
									})

									setInitSetup(ENDING)
								}
							}
							catch(error : any)
							{
								console.log(error)

								listWarnings
								({
									...warningReport,
									recipientAccounts : "No se pudueron extraer los métodos de pago " + 
										"por lo que no se podrán emitir pagos para las facturas "
								})

								setInitSetup(ENDING)
							}
						}
					break;
					case RECIPIENT_ACCOUNTS:
						if(Object.keys(recipientAccountRef).length < 1)
						{
							listWarnings
							({
								...warningReport, paymentMethods : "No hay cuentas receptoras en el registro, " + 
									"por lo que no se podrán emitir pagos para las facturas "
							})
						}

						setInitSetup(ENDING)
					break;
					case ENDING:
						setSectionLoaded(true)
						search()
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setCurrencySymbol(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
				setCurrencyCode(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].code)
				setPromptDisplayQueue([])
				setSelectedInvoice(null)
				clearSearch()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)
	//-----------------------------------------------------------------------------------------------------------------------

	const toggleSelectAll = ()=>
	{
		const selected    : boolean = selectedRecords < selectableRecords
		let   selectedMap :     any = !selected ? {} : {...selectedRef}

		if(selected)
		{
			data.map
			(
				(record : any)=>
				{
					if(record.invoice_id == null)
					{
						selectedMap[record.refIndex] = true
					}
				}
			)
		}

		setSelectedRef(selectedMap)
		setSelectedRecords(selectableRecords * (+selected))
	}

	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await FinancesService.searchOwedTuitions(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.data.current_page, result.data.data.last_page))
				let selectablesCount     : number = 0
				let amount               : number
				let taxInPercentage      : boolean
				let discountInPercentage : boolean
				let taxId                : number
				let taxValue             : number
				let discountValue        : number
				let taxAmount            : number
				let discountAmount       : number
				let subTotal             : number

				setDefaultDate(result.data.date)
				setSectionLoaded(true)
				setData
				(
					result.data.data.data.map
					(
						(record : any)=>
						{
							amount               = +record.amount
							taxInPercentage      = record.tax_value.substr(-1) == "%"
							discountInPercentage = record.discount.substr(-1) == "%"
							taxId                = record.tax_id == null ? 0 : record.tax_id
							taxValue             = record.tax_id < 1 ? 0 : +(record.tax_value.replace("%", ""))
							discountValue        = +(record.discount.replace("%", ""))
							taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
							discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
							subTotal             = record.amount + taxAmount - discountAmount
							selectablesCount    += +( +(record.invoice_id == null && record.type_id != owedTuitionType.SUBSCRIPTION_FEE))

							return {
								...record, taxId     : taxId,
								amount               : amount,
								refIndex             : "OA_" + record.id,
								taxInPercentage      : taxInPercentage,
								discountInPercentage : record.discount.substr(-1) == "%",
								taxValue             : taxValue,
								discountValue        : discountValue,
								taxAmount            : taxAmount,
								discountAmount       : discountAmount,
								subTotal             : subTotal
							}
						}
					)
				)

				setSelectedRef({})
				//adding boolean data type to toggle checking used by batch operations (multiple updating/deletion)----------
				setSelectedRecords(0)
				setSelectableRecords(selectablesCount)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}


	const confirmRemoval = (record : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string = "No se pudo eliminar el registro seleccionado. " +
						"Puede que la información en otra sección dependa de estos datos, por lo que no será posible realizar por completo la operación"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await PaymentsService.removePayment(campusRef[currentUserCampus].id, record.student_id, record)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded || pagination == null ? "" : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  searchChangeFn={handleSearchChange}
		  clearSearchRequested={clearSearchRequested}
		  additionalClasses={"compactableSection" + (promptDisplayQueue[0] == null ? "" : " sidePrompt")}
		  managementOverride={promptDisplayQueue[0] == null ? <div className="fluentBlockSm"></div> : <></>}
		  additionalStyle=
		  {{
			float : "left", height : "100%", overflow : "auto", width : "calc(100%" + 
				(promptDisplayQueue[0] == null ? ")" : " - " + promptWidth[promptDisplayQueue[0]] + ")")
		  }}
		>{
			(data.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					Sin resultados para este plantel
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="owedTuitionsHeader">
					<th />
					<th ref={el=>{if(el)el.style.setProperty("border-bottom", "1px solid #000000", "important")}}>
						id
					</th>
					<th>
						Alumno
					</th>
					<th>
						Factura
					</th>
					<th>
						Fecha
					</th>
					<th>
						Concepto
					</th>
					<th>
						{Constants.Common.price}
					</th>
					<th>
						Descuento
					</th>
					<th>
						{Constants.Common.tax}
					</th>
					<th>
						Sub-total
					</th>
					<th>
						Pagado
					</th>
					<th ref={el=>{if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
						Estatus
					</th>
				</tr></thead>
				<tbody>{
					data.map
					(
						(record : any, index : number)=>
						{
							let recordAmount               : number  = +record.amount
							let recordTaxId                : number  = record.tax_id || 0
							let recordTaxInPercentage      : boolean = record.tax_value.substr(-1) == "%"
							let recordDiscountInPercentage : boolean = record.discount.substr(-1) == "%"
							let recordTaxValue             : number  = recordTaxId < 1 ? 0 : +(record.tax_value.replace("%", ""))
							let recordDiscountValue        : number  = +(record.discount.replace("%", ""))
							let recordTaxAmount            : number  = recordTaxValue == 0 ? 0 : (!recordTaxInPercentage ? recordTaxValue : recordAmount * (recordTaxValue / 100))
							let recordDiscountAmount       : number  = (!recordDiscountInPercentage ? recordDiscountValue : recordAmount * (recordDiscountValue / 100))
							let recordSubTotal             : number  = record.amount + recordTaxAmount - recordDiscountAmount

							return <tr key={"period" + index}>
								<td />
								<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
									{record.id}
								</td>
								<td>
									{record.student && (record.student.first_name + " " + record.student.last_name)}
								</td>
								<td style={{textAlign : "right"}}>{
									record.invoice_id == null ? "-" : <u style={{cursor : "pointer"}} onClick=
									  {
										()=>
										{
											if(!idleLocked)
											{
												let amount               : number
												let taxId                : number
												let taxInPercentage      : boolean
												let discountInPercentage : boolean
												let taxValue             : number
												let discountValue        : number
												let taxAmount            : number
												let discountAmount       : number
												let subTotal             : number
												let total                : number = 0
												let pendingPaidTotal     : number = 0
												let approvedPaidTotal    : number = 0

												let invoice : any=
												{
													...record.invoice, notes : record.invoice.notes || "",
													total                    : +record.invoice.total,
													paid_amount              : +record.invoice.paid_amount,
													owed_tuitions            : record.invoice.owed_tuitions.length < 1 ? [] : record.invoice.owed_tuitions.map
													(
														(tuition : any)=>
														{
															amount               = +tuition.amount
															taxId                = tuition.tax_id || 0
															taxInPercentage      = tuition.tax_value.substr(-1) == "%"
															discountInPercentage = tuition.discount.substr(-1) == "%"
															taxValue             = taxId < 1 ? 0 : +(tuition.tax_value.replace("%", ""))
															discountValue        = +(tuition.discount.replace("%", ""))
															taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
															discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
															subTotal             = tuition.amount + taxAmount - discountAmount
															total               += subTotal

															return {
																...tuition, tax_id   : taxId,
																amount               : amount,
																taxInPercentage      : taxInPercentage,
																discountInPercentage : discountInPercentage,
																taxValue             : taxValue,
																discountValue        : discountValue,
																taxAmount            : taxAmount,
																discountAmount       : discountAmount,
																subTotal             : subTotal,
																quantity             : 1,
																price                : amount,
																invoice_item:
																{
																	invoice_id      : tuition.invoice_id,
																	owed_tuition_id : tuition.id,
																	owed_article_id : null,
																	title           : tuition.title,
																	description     : tuition.description,
																	units           : 1,
																	price           : amount,
																	total           : subTotal,
																	refunded_amount : 0,
																	currency_id     : tuition.currency_id
																}
															}
														}
													),
													owed_articles             : record.invoice.owed_articles.length < 1 ? [] : record.invoice.owed_articles.map
													(
														(article : any)=>
														{
															amount               = +article.amount
															taxId                = article.tax_id || 0
															taxInPercentage      = article.tax_value.substr(-1) == "%"
															discountInPercentage = article.discount.substr(-1) == "%"
															taxValue             = taxId < 1 ? 0 : +(article.tax_value.replace("%", ""))
															discountValue        = +(article.discount.replace("%", ""))
															taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
															discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
															subTotal             = article.amount + taxAmount - discountAmount
															total               += subTotal

															return {
																...article, tax_id   : taxId,
																amount               : amount,
																taxInPercentage      : taxInPercentage,
																discountInPercentage : discountInPercentage,
																taxValue             : taxValue,
																discountValue        : discountValue,
																taxAmount            : taxAmount,
																discountAmount       : discountAmount,
																subTotal             : subTotal,
																invoice_item:
																{
																	invoice_id      : article.invoice_id,
																	owed_tuition_id : null,
																	owed_article_id : article.id,
																	title           : article.title,
																	description     : article.description,
																	units           : article.quantity,
																	price           : article.price,
																	total           : subTotal,
																	refunded_amount : 0,
																	currency_id     : article.currency_id
																}
															}
														}
													),
													payments : record.invoice.payments.map
													(
														(payment : any)=>
														{
															if(payment.status_id != paymentStatus.REJECTED)
															{
																if(payment.status_id == paymentStatus.APPROVED)
																{
																	approvedPaidTotal += +payment.amount
																}
																else
																{
																	pendingPaidTotal += +payment.amount
																}
															}

															return payment
														}
													)
												}

												showInvoicePrompt
												({
													...invoice, total : total,
													student           : record.student,
													approvedPaidTotal : approvedPaidTotal,
													pendingPaidTotal  : pendingPaidTotal
												})
											}
										}
									  }
									>
										{record.invoice_id}
									</u>
								}</td>
								<td>
									{revertStringDate(record.created_at.split(" ")[0], "-", "/")}
								</td>
								<td>
									{record.title}
									{
										record.description == null ? "" : <>
											<div style={{padding : "0px 25px"}}>
												<hr style={{margin : "unset"}} />
											</div>
											<label style={{position : "relative"}}>
												{record.description}
											</label>
										</>
									}
								</td>
								<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
									<span style={{paddingRight : "5px"}}>
										{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									</span>
									<span style={{flexGrow : 1}}>
										{sprintf(" %.02f", +record.amount)}
									</span>
								</div></td>
								<td style={{textAlign : "right", verticalAlign : "bottom"}}>
									{
										recordDiscountAmount > 0 && recordDiscountInPercentage
											?
											<div style={{textAlign : "right"}}>
												{"(" + sprintf("%.02f", +recordDiscountValue) + " " + (recordDiscountInPercentage ? "%" : currencyCode) + ")"}
											</div>
												:
											""
									}
									<div style={{display : "flex", alignItems : "stretch"}}>
										<span style={{paddingRight : "5px"}}>
											{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
										</span>
										<span style={{flexGrow : 1}}>
											{sprintf(" %.02f", +recordDiscountAmount)}
										</span>
									</div>
								</td>
								<td style={{textAlign : "right", verticalAlign : "bottom"}}>
									{
										recordTaxAmount > 0 && recordTaxInPercentage
												?
											<div style={{textAlign : "right", display : "flex"}}>
												{"(" + sprintf("%.02f", +recordTaxValue) + " " + (recordTaxInPercentage ? "%" : currencyCode) + ")"}
											</div>
												:
											""
									}
									<div style={{display : "flex", alignItems : "stretch"}}>
										<span style={{paddingRight : "5px"}}>
											{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
										</span>
										<span style={{flexGrow : 1}}>
											{sprintf(" %.02f", +recordTaxAmount)}
										</span>
									</div>
								</td>
								<td style={{verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
									<span style={{paddingRight : "5px"}}>
										{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									</span>
									<span style={{flexGrow : 1}}>
										{sprintf(" %.02f", +recordSubTotal)}
									</span>
								</div></td>
								<td style={{textAlign : "right", verticalAlign : "bottom"}}><div style={{display : "flex", alignItems : "stretch"}}>
									<span style={{paddingRight : "5px"}}>
										{basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol}
									</span>
									<span style={{flexGrow : 1}}>
										{sprintf(" %.02f", +record.paid_amount)}
									</span>
								</div></td>
								<td ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}
								  style={{textAlign : "right", verticalAlign : "bottom"}}
								>
									{statusRef["OTS_" + record.status_id].name}
								</td>
							</tr>
						}
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			(promptDisplayQueue == null || promptDisplayQueue.length < 1) || (selectedInvoice == null && selectedPayment == null)
					?
				""
					:
				<FinancePrompt student={selectedInvoice ? selectedInvoice.student : selectedPayment.student} taxRef={taxRef}
				  defaultDate={defaultDate} paymentMethodRef={paymentMethodRef} setPromptDisplayQueue={setPromptDisplayQueue}
				  selectedInvoice={selectedInvoice} payment={selectedPayment} recipientAccountRef={recipientAccountRef}
				  promptDisplayQueue={promptDisplayQueue} paymentStatusRef={paymentStatusRef} resetFn=
				  {
					()=>
					{
						setCustomSearch(defaultSearch)
						setPromptDisplayQueue([])
						search()
					}
				  } promptDismissal=
				  {
					()=>
					{
						setPromptDisplayQueue([])
						setSelectedInvoice(null)
					}
				  }
				/>
		}
	</>
}

export default OwedTuitions
