import
{
	FormEvent,
	useEffect,
	useReducer,
	useRef,
	useState
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import Multiselect
	from "multiselect-react-dropdown"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"
import
{
	buttonClickHandling,
	getAccessPermissions,
	imageTypes,
	operatorList,
	operators,
	pageGrouping,
	UIState,
	validateField
}
	from "./../../common"
import Constants,
{
	tunning,
	commonStatus
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import UnderlineHeading
	from "./../../components/underlineHeading"
import {SelectReact}
	from "../../components/select"
import CatalogService
	from "./../../services/catalog.service"
import InventoryService
	from "./../../services/campus/inventory.service"
import SimpleDataService
	from "./../../services/simpleData.service"
import defaultArticleImage
	from "./../../assets/images/article.png"

import "./../../components/dropdown/style.css"

type State                 = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}
type Action                = {type : "setIsButtonDisabled", payload : boolean}
const reducer              = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return{...state, isButtonDisabled : action.payload}
		break;
	}
}

const Inventory = ()=>
{
	const ARTICLE_STATUSES        = 0
	const ARTICLE_CLASSIFICATIONS = 1
	const ARTICLE_TYPES           = 2

	let   fileReader : any
	const [state, trigger]                                        = useReducer(reducer, initialState)
	const dispatch                                                = useDispatch()
	const [articleClassifications, setArticleClassifications]     = useState<any>([])
	const [articleClassificationRef, setArticleClassificationRef] = useState<any>({})
	const [articleTypes, setArticleTypes]                         = useState<any>([])
	const [articleTypeRef, setArticleTypeRef]                     = useState<any>([])
	const [articleStatuses, setArticleStatuses]                   = useState<any>([])
	const [articleStatusRef, setArticleStatusRef]                 = useState<any>([])
	const [categories, setCategories]                             = useState<any>([])
	const [categoryRef, setCategoryRef]                           = useState<any>({})
	const [initSetup, setInitSetup]                               = useState<number | null>(null)
	const [totalRecords, setTotalRecords]                         = useState<number>(0)
	const [data, setData]                                         = useState<any[]>([])
	const idleLocked   : boolean                                  = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                            = useSelector((state : RootState) => state.sessionToken.value)
	const currentUserCampus : any                                 = useSelector((state : RootState) => state.currentUserCampus.value)
	const currentUser       : any                                 = useSelector((state : RootState) => state.userProfile.value)
	const themeToggle  : boolean                                  = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any                                      = useSelector((state : RootState) => state.basicInfo.value)
	const campusRef         : any                                 = useSelector((state : RootState) => state.campusRef.value)
	const [noTagsProvided, setNoTagsProvided]                     = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                     = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]                   = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]                       = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested]         = useState<boolean>(false)
	const [extendedFilterShown, setExtendedFilterShown]           = useState<boolean>(false)
	const [gridViewOnDisplay, setGridViewOnDisplay]               = useState<boolean>(false)
	const [UIStatus, setUIStatus]                                 = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]                   = useState<number>(0)
	const [newArticle, setNewArticle]                             = useState<any>(null)
	const [selectedArticle, setSelectedArticle]                   = useState<any>(null)
	const [editableArticle, setEditableArticle]                   = useState<any>(null)
	const [pagination, setPagination]                             = useState<any>(null)
	const [userCampus, setUserCampus]                             = useState<number>(currentUserCampus)
	const [saveSuccess, setSaveSuccess]                           = useState<boolean>(false)
	const [noClassificationProvided, setNoClassificationProvided] = useState<boolean>(false)
	const [saveError, setSaveError]                               = useState<any>(null)
	const [recordsSelection, setRecordsSelection]                 = useState<any>([])
	const count        : number[]                                 = [-1, 1]
	const allowed      : any                                      = getAccessPermissions(currentUser)
	let   imageRef                                                = useRef<HTMLInputElement>(null)
	let   recordFormRef                                           = useRef<HTMLFormElement >(null)
	let   nameRef                                                 = useRef<HTMLInputElement>(null)
	let   stockUnitsRef                                           = useRef<HTMLInputElement>(null)
	let   countableUnitsRef                                       = useRef<HTMLInputElement>(null)
	let   basePriceRef                                            = useRef<HTMLInputElement>(null)
	let   descriptionRef                                          = useRef<HTMLTextAreaElement>(null)
	const [defaultSearch, setDefaultSearch]                       = useState<any>
	({
		q                : "",
		statusId         : commonStatus.ENABLED,
		typeId           : 0,
		categoryId       : 0,
		classificationId : 0,
		priceComparisonA : "",
		priceComparisonB : "",
		tags             : []
	})
	const [customSearch, setCustomSearch]                         = useState<any>(defaultSearch)

	const unedit = ()=>
	{
		if(editableArticle && editableArticle.id == null)
		{
			setSelectedArticle(null)
		}

		setEditableArticle(null)
	}

	const hidePrompt = ()=>
	{
		if(editableArticle && editableArticle.id == null)
		{
			setSelectedArticle(null)
		}

		setEditableArticle(null)
	}

	const showPrompt = (recordData : any)=>
	{
		let article = {...recordData, imagePreview : recordData.image}

		setUIStatus(UIState.NORMAL)
		setSaveError(null)

		if(recordData.id == null)
		{
			setEditableArticle(article)
		}

		setSelectedArticle(article)
	}

	const editRecord = ()=>
	{
		setEditableArticle({...selectedArticle, description : selectedArticle.description || ""})
	}

	const saveRecord = async()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() =>
						{
							setUnfilledFields(false)
						},
						3000
					)
				}
			}
			else
			{
				if(editableArticle.tags.length < 1)
				{
					if(!unfilledFields && !noTagsProvided)
					{
						setUnfilledFields(true)
						setNoTagsProvided(true)

						setTimeout
						(
							() =>
							{
								setUnfilledFields(false)
								setNoTagsProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(editableArticle.classification == null)
					{
						if(!unfilledFields && !noClassificationProvided)
						{
							setUnfilledFields(true)
							setNoClassificationProvided(true)

							setTimeout
							(
								() =>
								{
									setUnfilledFields(false)
									setNoClassificationProvided(false)
								},
								3000
							)
						}
					}
					else
					{
						setUIStatus(UIState.LOCKED)
						dispatch(enableIdleLockSwitch())

						try
						{
							const result = await InventoryService.saveArticle(campusRef[currentUserCampus].id, editableArticle)

							if(result.status == 200 || result.status == 204)
							{
								setSaveSuccess(true)
								setUIStatus(UIState.SUCCESS)

								if(saveError != null)
								{
									setSaveError(null)
								}

								setTimeout
								(
									()=>
									{
										setSelectedArticle(null)
										setEditableArticle(null)
										setSaveSuccess(false)
										setUIStatus(UIState.NORMAL)
										search()
									},
									tunning.MODAL_DISMISS_DELAY
								)
							}
							else
							{
								setSaveError
								(
									result.status != 409
										? "La información no pudo ser guardada"
											: "Hay conflictos en la información proporcionada (nombre). " +
												"Revise que los valores sean únicos en comparación con los registros existentes"
								)
								setUIStatus(UIState.ERROR)
							}
						}
						catch(error)
						{
							setSaveError("La información no pudo ser guardada")
							setUIStatus(UIState.ERROR)
							console.log(error)
						}
						finally
						{
							dispatch(disableIdleLockSwitch())
						}
					}
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableArticle({...editableArticle,[e.target.name] : e.target.value})
		}
	}

	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(<>
					Sólo se permiten imágenes con las siguientes características:
					<br />
					&emsp;
					Tamaño &lt;= 5MB
					<br />
					&emsp;Formato : [BMP, GIF, JPEG, PNG, WEBP]
				</>)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let article : any    = {...editableArticle, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any)=>
				{
					setEditableArticle({...article, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if(imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		setEditableArticle
		({
			...editableArticle, image : null,
			imagePreview              : null,
		})
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					dispatch(disableIdleLockSwitch())
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const statusRetrievalError : string = "El catálogo de estatuses de artículo no pudo ser extraído"

					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await CatalogService.getArticleStatuses(sessionToken)

						if(result.status == 200 || result.status == 204)
						{
							if(result.data.length < 1)
							{
								dispatch(disableIdleLockSwitch())
								procedureComplaint
								(
									"No se encontraron estatuses de artículo registrados, " +
										"por lo que el acceso a este módulo permanecerá deshabilitado"
								)
							}
							else
							{
								let articleStatusIndex : any = {}

								setArticleStatuses
								(
									result.data.map
									(
										(record : any)=>
										{
											articleStatusIndex["AS_" + record.id] = record

											return record
										}
									)
								)

								setArticleStatusRef(articleStatusIndex)
								setInitSetup(ARTICLE_STATUSES)
							}
						}
						else
						{
							procedureComplaint(statusRetrievalError)
							dispatch(disableIdleLockSwitch())
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(statusRetrievalError)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case ARTICLE_STATUSES:
						if(articleStatuses.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay estatuses de artículo en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const classificationRetrievalError : string = "El catálogo de clasificaciones de artículo no pudo ser extraído"

							try
							{
								let   classificationIndex : any = {}
								const result              : any = await SimpleDataService.getRecords("articleClassifications")

								if(result.status == 200 || result.status == 204)
								{
									setArticleClassifications
									(
										result.data.map
										(
											(record : any)=>
											{
												classificationIndex["AC_" + record.id] = {...record, label : record.name}

												return classificationIndex["AC_" + record.id]
											}
										)
									)

									setArticleClassificationRef(classificationIndex)
									setInitSetup(ARTICLE_CLASSIFICATIONS)
								}
								else
								{
									procedureComplaint(classificationRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(classificationRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ARTICLE_CLASSIFICATIONS:
						if(articleClassifications.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay clasificaciones de artículo en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const typeRetrievalError : string = "El catálogo de tipos de artículo no pudo ser extraído"

							try
							{
								const result = await SimpleDataService.getRecords("articleTypes")

								if(result.status == 200 || result.status == 204)
								{
									let articleTypeIndex : any = {}

									setArticleTypes
									(
										result.data.map
										(
											(record : any)=>
											{
												articleTypeIndex["AT_" + record.id] = record

												return record
											}
										)
									)

									setArticleTypeRef(articleTypeIndex)
									setInitSetup(ARTICLE_TYPES)
								}
								else
								{
									procedureComplaint(typeRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(typeRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ARTICLE_TYPES:
						if(articleTypes.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No se encontraron tipos de artículo registrados, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const categoryRetrievalError : string = "No se pudo extraer el catélogo de tipos de artículo, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado"

							try
							{
								const result = await SimpleDataService.getRecords("categories")

								if(result.status == 200 || result.status == 204)
								{
									let categoryIndex : any = {}

									setCategories
									(
										result.data.map
										(
											(record : any)=>
											{
												categoryIndex["C_" + record.id] = record
												return categoryIndex["C_" + record.id]
											}
										)
									)

									setCategoryRef(categoryIndex)
									setNewArticle
									({
										name            : "",
										status_id       : commonStatus.ENABLED,
										type_id         : articleTypes[0].id,
										sku             : "",
										base_price      : 0,
										stock_units     : 0,
										countable_units : 0,
										description     : "",
										category_id     : result.data[0].id,
										tags            : [],
									})

									search()
								}
								else
								{
									procedureComplaint(categoryRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(categoryRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(()=>{setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				clearSearch()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError = "La búsqueda no pudo completarse"

		try
		{
			if(extendedFilterShown)
			{
				setExtendedFilterShown(false)
			}

			dispatch(enableIdleLockSwitch())

			const result : any = await InventoryService.searchArticles
				(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setData(result.data.data.map
					((record : any, index : number) => ({selected : false, data : {...record, classification : articleClassificationRef["AC_" + record.classification_id]}})))
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		);
	};

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData((current : any) => current.map((record : any, index : number) => ({...record, selected : selected})))
		setSelectedRecords(totalRecords * (+selected))
	};

	const closePrompt = ()=>
	{
		setSelectedArticle(null)
		setEditableArticle(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading   : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure : async ()=>
				{
					const removalError : string = "No se pudieron eliminar algunos registros. " +
						"Puede que la información en otra sección dependa de estos datos (módulos, matrículas, finanzas de alumno, entre otras), " +
							"por lo que no será posible realizar por completo la operación"

					const recordIds : number[] = !isNaN(recordId) ? [recordId] :
						data.filter
							((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await InventoryService.removeArticles
							(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded || pagination == null ? <></> : <><SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  extendedFilterShown={extendedFilterShown}
		  clearSearchRequested={clearSearchRequested}
		  generalPlaceHolder={"Nombre, SKU, descripción"}
		  setExtendedFilterShown={setExtendedFilterShown}
		  addingPromptFn={() => {showPrompt(newArticle)}}
		  searchIcon={<FontAwesomeIcon icon={solid("boxes-packing")} />}
		  extendedFilterViewClosing={() => setExtendedFilterShown(false)}
		  handleSearchChangeFn={handleSearchChange} defaultSearch={defaultSearch}
		  additionalOptions=
		  {<button style={{width : "100%", textAlign : "left", color : "#FFFFFF"}} className="btn btn-info" type="button"
			  disabled={idleLocked} onClick={() => setGridViewOnDisplay(!gridViewOnDisplay)}
			>
				<FontAwesomeIcon icon={!gridViewOnDisplay ? solid("table-cells") : solid("table-list")} />
				<span style={{paddingLeft : "10px"}}>
					{!gridViewOnDisplay ? "Vista de cuadrícula" :"Vista de lista"}
				</span>
		  </button>} filterSummary=
		  {
			[
				{
					label   : "Estatus",
					display : <p style={{fontSize : "16px", margin : 0, textAlign : "center"}}>
						{customSearch.statusId < 0 ? "Mostrar todo" : articleStatusRef["AS_" + customSearch.statusId].name}
					</p>
				},
				customSearch.categoryId < 1 ? null :
				{
					label   : "Categoría",
					display : <p style={{fontSize : "16px", margin : 0, textAlign : "center"}}>
						{categoryRef["C_" + customSearch.categoryId].name}
					</p>
				},
				customSearch.typeId < 1 ? null :
				{
					label   : "Tipo",
					display : <p style={{fontSize : "16px", margin : 0, textAlign : "center"}}>
						{articleTypeRef["AT_" + customSearch.typeId].name}
					</p>
				},
				customSearch.classification == null ? null :
				{
					label   : "Clasificación",
					display : <p style={{fontSize : "16px", margin : 0, textAlign : "center"}}>
						{customSearch.classification.name}
					</p>
				},
				customSearch.priceComparisonA == "" && customSearch.priceComparisonB == "" ? null :
				{
					label   : "Precio",
					display : operators[customSearch.priceComparisonA].display + " " + customSearch.priceValueA +
					(
						customSearch.priceComparisonB == "" ? "" : " & " + operators[customSearch.priceComparisonB].display + " " +
							customSearch.priceValueB
					)
				},
				customSearch.tags.length < 1 ? null : 
				{
					label   : "Etiquetas",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				}
			].filter((setting : any) => setting)
		  } advancedSearchForm=
		  {<div className="container"><div className="row">
			<div className="col-sm-6">
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Estatus
					</label></legend>
					<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
					  type="button" onClick={() => setCustomSearch({...customSearch, statusId : -1})} style={{textAlign : "left"}}
					>
						<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
						<span style={{paddingLeft : "5px"}}>
							Mostrar todo
						</span>
					</button>
					{
						articleStatuses.map
						(
							(status : any) => <button onClick={() => setCustomSearch({...customSearch, statusId : status.id})}
							  disabled={idleLocked || customSearch.statusId == status.id} className="form-control btn btn-default"
							  type="button" key={"CS_" + status.id} style={{textAlign : "left"}}
							>
								<FontAwesomeIcon icon={customSearch.statusId == status.id ? solid("circle-dot") : solid("circle")} />
								<span style={{paddingLeft : "5px"}}>
									{status.name}
								</span>
							</button>
						)
					}
				</fieldset>
				<fieldset>
					<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
						Precio
					</label></legend>
					<div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
						<div style={{flexGrow : 3}}>
							<div style={{display : "flex", padding : "5px"}}>
								<div style={{paddingTop : "15px"}}>
									<div className="operatorOptions" style={{display : "inline-flex"}}><select id="priceComparisonA"
									  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
									  className="btn btnVeryPeri" onChange={handleSearchChange} name="priceComparisonA"
									  disabled={idleLocked} value={customSearch.priceComparisonA}
									>{
										operatorList.map
										(
											(key : any) => <option key={key.code} value={key.code}>
												{key.display}
											</option>
										)
									}</select></div>
								</div>
								<div className="inputContainer" style={{flexGrow : 1}}>
									<input onChange={handleSearchChange} style={{width : "100%", textAlign : "right"}}
									  disabled={idleLocked} onBlur={validateField} type="number"
									  value={customSearch.priceValueA} onFocus={validateField}
									  maxLength={50} placeholder="Monto" name="priceValueA" id="priceValueA" required
									/>
								</div>
							</div>
						</div>
						{
							(customSearch.priceComparisonA == "" || customSearch.priceComparisonA == "equals") ||
								(
									customSearch.priceValueA == 0 &&
										(customSearch.priceComparisonA == "lt" || customSearch.priceComparisonA == "lte")
								) ? "" : <>
									<div style={{flexGrow : 2, textAlign : "center", paddingTop : "15px"}}><div
									  style={{fontWeight : 900, width : "50px", height : "50px", display : "inline-block", borderRadius : "100%", color : "#FFFFFF", background : "#808080", fontSize : "18px", paddingTop : "12px"}}
									>
										&amp;
									</div></div>
									<div style={{flexGrow : 3}}><div style={{display : "flex", padding : "5px"}}>
										<div style={{paddingTop : "15px"}}>
											<div className="operatorOptions" style={{display : "inline-flex"}}><select
											  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
											  disabled={idleLocked} value={customSearch.amountComparisonB} id="amountComparisonB"
											  className="btn btnVeryPeri" onChange={handleSearchChange}
											  name="amountComparisonB"
											>{
												operatorList.filter
												(
													(operator : any) => operator.code != "equals" && operator.code.charAt(0)
														!= customSearch.priceComparisonA.charAt(0)
												).map
												(
													(key : any) => <option key={key.code} value={key.code}>
														{key.display}
													</option>
												)
											}</select></div>
										</div>
										<div className="inputContainer" style={{flexGrow : 1}}>
											<input disabled={idleLocked} onBlur={validateField}
											  maxLength={50} placeholder="Monto" name="priceValueB" value={customSearch.priceValueB}
											  onChange={handleSearchChange} style={{width : "100%", textAlign : "right"}}
											  type="number" onFocus={validateField}
											  id="priceValueB"required
											/>
										</div>
									</div></div>
							</>
						}
					</div>
				</fieldset>
			</div>
			<div className="col-sm-6">
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Categoría
					</label></legend>
					<div className="selectContainer" style={{marginBottom : "unset", height : "32px"}}>
						<select value={customSearch.categoryId} disabled={idleLocked} name="categoryId"
						  className="btn btn-primary text-start" onChange={handleSearchChange} id="categoryId"
						  style={{height : "30px", marginTop : "unset"}}
						>
							<option value={-1}>
								Todas
							</option>
							{
								categories.map
								(
									(record : any, i : any) => <option key={i} value={record.id}>
										{record.name}
									</option>
								)
							}
						</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name="rowDown" />
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Tipo
					</label></legend>
					<div className="selectContainer" style={{marginBottom : "unset", height : "32px"}}>
						<select className="btn btn-primary text-start" style={{height : "30px", marginTop : "unset"}} name="typeId"
						  id="categoryId" value={customSearch.typeId} onChange={handleSearchChange} disabled={idleLocked}
						>
							<option value={-1}>
								Todos
							</option>
							{
								articleTypes.map
								(
									(record : any, i : any) => <option key={i} value={record.id}>
										{record.name}
									</option>
								)
							}
						</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name="rowDown" />
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Clasificación
					</label></legend>
					<div>
						<SelectReact value={customSearch.classification} className="btn btn-primary text-start" id="classification"
						  onChange={handleSearchChange} disabled={idleLocked} style={{height : "30px"}} clearable={true}
						  children={articleClassifications} placeholder="Clasificación" name="classification"
						/>
					</div>
				</fieldset>
			</div>
			<fieldset style={{height : "100%"}}>
				<legend><label style={{position : "relative", top : "4px"}}>
					Etiquetas
				</label></legend>
				<Multiselect emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
				  onRemove={(tags) => handleSearchChange({target : {name : "tags", value : tags}})} displayValue="name"
				  placeholder="Seleccione" onSelect={(tags) => setCustomSearch({...customSearch, tags : tags})}
				/>
			</fieldset>
		  </div></div>}
		>{
			(data.length < 1)
					?
				<>
					<hr />
					<div style={{padding : "25px"}}>
						{Constants.noCampusSearchResults}
					</div>
					<hr />
				</>
					:
				(
					gridViewOnDisplay
							?
						<div className="container">
							<label style={{position : "relative"}}><b>
								<button className="btn btn-default" onClick={toggleSelectAll}
								  disabled={idleLocked || saveSuccess} type="button"
								>
									{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
								</button>
								Seleccionar Todo
							</b></label>
							<div className="row">{
								data.map
								(
									(record : any, index : number) => <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
										<div className="card card-default" style={{height : "100%", margin : "unset"}}>
											{!allowed.delete ? "" : <>
												<button disabled={idleLocked} onClick={() => toggleRecordSelection(index)}
												  style={{position : "absolute", top : 0, left : 0, zIndex : 2}} type="button"
												  className="btn btn-default"
												>
													{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
												</button>
												<button onClick={() => toggleRecordSelection(index)} className="btn btn-danger"
												  style={{position : "absolute", top : 0, right: 0, zIndex : 2}} type="button"
												  disabled={idleLocked}
												>
													<CustomIcon name="bTrash" style={{fill : "#FFFFFF"}} />
												</button>
											</>}
											<button type="button" disabled={idleLocked}  onClick={() => showPrompt(record.data)}
											  className="btn btn-default"
											>
												<img src={record.data.image || defaultArticleImage} style=
												  {{
													width        :  "100%",
													margin       :  "auto",
													maxWidth     : "250px",
													maxHeight    : "250px",
													minHeight    : "125px",
													minWidth     : "125px",
													border       : "3px solid #8F91DA"
												  }}
												/>
												<h5>{record.data.name}</h5>
												<b style={{color : "#5154AE"}}>{
													sprintf(campusRef[currentUserCampus].company.currency.symbol + " %.02f " + campusRef[currentUserCampus].company.currency.code, record.data.base_price)
												}</b>
												<label style={{position : "relative", whiteSpace : "unset", textAlign : "left"}}>
													{record.data.description || "-"}
												</label>
											</button>
										</div>
									</div>
								)
							}</div>
						</div>
							:
						<table className="commonTable">
							<thead><tr key="inventoryHeader">
								{
									!allowed.delete ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
									  disabled={idleLocked || saveSuccess} type="button"
									>
										{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
									</button></th>
								}
								<th style={{borderBottom : "none"}}>
								</th>
								<th>
									Nombre
								</th>
								<th>
									Tipo
								</th>
								<th>
									Clasificación
								</th>
								<th>
									<div style={{width : "max-content"}}>
										Precio U
									</div>
								</th>
								<th>
									Estatus
								</th>
								<th>
									<div style={{width : "max-content"}}>
										Inv. fis.
									</div>
								</th>
								<th>
									<div style={{width : "max-content"}}>
										Inv. cont.
									</div>
								</th>
								<th>
									Descripción
								</th>
								<th>
									Categoría
								</th>
								<th>
									SKU
								</th>
								<th />
							</tr></thead>
							<tbody>{
								data.map
								(
									(record : any, index : number) => <tr key={"campus" + index}>
										{
											!allowed.delete ? "" : <td><button onClick={() => toggleRecordSelection(index)}
											  disabled={idleLocked} className="btn btn-default" type="button"
											>
												{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
											</button></td>
										}
										<td style={{borderBottom : "none"}}>
											<img src={record.data.image != null ? record.data.image : defaultArticleImage} style=
											  {{
												margin    : "auto",
												maxWidth  : "50px",
												maxHeight : "50px",
												minHeight : "50px",
												minWidth  : "50px"
											  }}
											/>
										</td>
										<td>
											{record.data.name}
										</td>
										<td>
											{articleTypeRef["AT_" + record.data.type_id].name}
										</td>
										<td>
											{record.data.classification_id == null ? "-" : articleClassificationRef["AC_" + record.data.classification_id].name}
										</td>
										<td><div style={{display : "flex", alignItems : "stretch"}}>
											<div style={{flexGrow : 0}}>
												{campusRef[currentUserCampus].company.currency.symbol}
											</div>
											<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "center"}}>{
												(record.data == null || record.data.base_price == null) ? "" :
													sprintf("%.02f", record.data.base_price)
											}</div>
											<div style={{flexGrow : 0}}>
												{campusRef[currentUserCampus].company.currency.code}
											</div>
										</div></td>
										<td>
											{articleStatusRef["AS_" + record.data.status_id].name}
										</td>
										<td style={{textAlign : "right"}}>
											{record.data.stock_units}
										</td>
										<td style={{textAlign : "right"}}>
											{record.data.countable_units}
										</td>
										<td>
											{record.data.description || "-"}
										</td>
										<td>
											{categoryRef["C_" + record.data.category_id].name}
										</td>
										<td>
											{record.data.sku}
										</td>
										<td><div style={{display : "flex"}}>
											<button style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
											  disabled={idleLocked} onClick={() => showPrompt(record.data)}
											  className="button btn btn-default" type="button"
											>
												<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
											</button>
											{
												!allowed.delete ? "" : <button className="button btn btn-default" style={{display : "table-column"}}
												  onClick={() => confirmRemoval(record.data.id)} disabled={idleLocked}
												>
													<CustomIcon name="bTrash" />
												</button>
											}
										</div></td>
									</tr>
								)
							}</tbody>
						</table>
				)
		}</SectionContainer>
		{
			selectedArticle == null ? "" : <DataDisplayModal saveSuccess={saveSuccess} promptCloseFn={closePrompt}
			  processingError={saveError} editableRecord={editableArticle} allowedUpdate={allowed.update} uneditFn={unedit}
			  saveDataFn={saveRecord} headIcon={<FontAwesomeIcon icon={solid("boxes-packing")} />} form={recordFormRef}
			  UIStatus={UIStatus} dataEditInit={editRecord} entityName="Artículo" saveLock={state.isButtonDisabled}
			  unfilledFields={unfilledFields} unchangedRecord={unchangedRecord}
			><form ref={recordFormRef} className="form"  onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			><div className="container"><div className="row">
				<div className="col-md-4 text-center">
					<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
						<img src=
						  {
							(editableArticle == null ? selectedArticle.imagePreview : editableArticle.imagePreview)
								|| defaultArticleImage
						  } style=
						  {{
							width        :  "100%",
							borderRadius :  "100%",
							margin       :  "auto",
							maxWidth     : "250px",
							maxHeight    : "250px",
							minHeight    : "125px",
							minWidth     : "125px",
							border       : "3px solid #8F91DA"
						  }}
						/>
						{
							editableArticle == null ? "" : <>
								<input accept="image/*" type="file" style={{display : "none"}} name="image" ref={imageRef}
								  onChange={imageLoad} id="image"
								/>
								{
									editableArticle.image == null ? "" : <button className="btn btn-danger rounded-pill"
									  disabled={idleLocked} onClick={imageDisposal} type="button" style=
									  {{
										position : "relative",
										left     :  "15%",
										bottom   : "40px" 
									  }}
									>
										<FontAwesomeIcon icon={solid("times")} size="2x" fill="unset"/>
									</button>
								}
								<button onClick={() => buttonClickHandling(imageRef)} className="btn rounded-pill" type="button"
								  disabled={idleLocked} style=
								  {{
									border     : "1px solid var(--main-bg-color)",
									color      : "var(--secondary-txt-color)",
									position   : "relative",
									background : "#C7C7C7",
									height     : "56px",
									bottom     : "40px",
									left       : "16%"
								  }}
								>
									<FontAwesomeIcon icon={solid("camera")} style={{color : "#000000"}} fill="unset" size="2x"/>
								</button>
							</>
						}
					</div>
					<div className="selectContainer">
						<label htmlFor="status_id">
							Estatus
						</label>
						{
							editableArticle == null
								?
							<div className="roView">
								<br />
								{articleStatusRef["AS_" + selectedArticle.status_id].name}
							</div>
								:
							<>
								<select className="btn btn-primary text-start" value={editableArticle.status_id} id="status_id"
								  onChange={handleRegistryChange} disabled={idleLocked} name="status_id"
								  style={{height : "30px"}}
								>{
									articleStatuses.map
									(
										(record : any, i : number) => <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}</select>
								<div className="iconRight" style={{pointerEvents : "none"}}>
									<CustomIcon name="rowDown" />
								</div>
							</>
						}
					</div>
					<div className="selectContainer">
						<label htmlFor="type_id">
							Tipo
						</label>
						{
							editableArticle == null
								?
							<div className="roView">
								<br />
								{articleTypeRef["AT_" + selectedArticle.type_id].name}
							</div>
								:
							<>
								<select value={editableArticle.type_id} id="type_id" style={{height : "30px"}} name="type_id"
								  className="btn btn-primary text-start" onChange={handleRegistryChange}
								  disabled={idleLocked}
								>{
									articleTypes.map
									(
										(record : any, i : any) => <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}</select>
								<div className="iconRight" style={{pointerEvents : "none"}}>
									<CustomIcon name="rowDown" />
								</div>
							</>
						}
					</div>
					<div style={{textAlign : "left"}}>
						<label htmlFor="classification" style={{position : "relative"}}>
							{editableArticle == null ? "" : "*"}
							Clasificación
						</label>
						{
							editableArticle == null
								?
							<div className="roView" style={{borderBottom : "2px solid #8F91DA"}}>
								{selectedArticle.classification == null ? "-" : selectedArticle.classification.name}
							</div>
								:
							<Popover positions={["bottom"]} isOpen={noClassificationProvided} content=
							  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
								<div className="requirementAlert">
									<FontAwesomeIcon icon={solid("exclamation")}/>
								</div>
								&nbsp;
								Requerido : Elija una opción
							  </div>}
							><div>
								<SelectReact value={editableArticle.classification} className="btn btn-primary text-start"
								  placeholder="Clasificación" onChange={handleRegistryChange} style={{height : "30px"}}
								  children={articleClassifications} disabled={idleLocked} name="classification"
								  id="classification"
								/>
							</div></Popover>
						}
					</div>
				</div>
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableArticle == null ? "" : "*"}
								Nombre
							</label>
							{
								editableArticle == null
									?
								<div className="roView">
									<br />
									{selectedArticle.name}
								</div>
									:
								<input onFocus={validateField} type="text" ref={nameRef}
								  onChange={handleRegistryChange} name="name" id="name" maxLength={50}
								  onBlur={validateField} placeholder="Nombre"
								  style={{width : "100%"}} value={editableArticle.name} required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="sku">
								{editableArticle == null ? "" : "*"}
								SKU
							</label>
							{
								editableArticle == null
									?
								<div className="roView">
									<br />
									{selectedArticle.sku}
								</div>
									:
								<input onBlur={validateField} value={editableArticle.sku}
								  maxLength={50} onChange={handleRegistryChange} placeholder="SKU"
								  type="text" minLength={1} onFocus={validateField}
								  disabled={idleLocked} name="sku" id="sku" ref={nameRef} style={{width : "100%"}} required
								 
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="category_id">
								Categoría
							</label>
							{
								editableArticle == null
									?
								<div className="roView">
									<br />
									{categoryRef["C_" + selectedArticle.category_id].name}
								</div>
									:
								<>
									<select className="btn btn-primary text-start" id="category_id" style={{height : "30px"}}
									  value={editableArticle.category_id} name="category_id" disabled={idleLocked}
									  onChange={handleRegistryChange}
									 
									>
										{
											categories.map
											(
												(record : any, i : any)=>
												<option key={i} value={record.id}>
													{record.name}
												</option>
											)
										}
									</select>
									<div className="iconRight" style={{pointerEvents : "none"}}>
										<CustomIcon name="rowDown" />
									</div>
								</>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="name">
								{editableArticle == null ? "" : "*"}
								Precio base
							</label>
							<br />
							<div style={{display : "flex", alignItems : "stretch"}}>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.symbol}
								</div>
								<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
									editableArticle == null
										?
									<div className="roView" style={{textAlign : "right"}}>
										{sprintf("%.02f", selectedArticle.base_price)}
									</div>
										:
									<input ref={basePriceRef} onChange={handleRegistryChange} min={0}
									  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
									  onFocus={validateField} placeholder="Precio base"
									  id="base_price" name="base_price" type="number" value={editableArticle.base_price}
									  onBlur={validateField} disabled={idleLocked}
									  required
									/>
								}</div>
								<div style={{flexGrow : 0}}>
									{campusRef[currentUserCampus].company.currency.code}
								</div>
							</div>
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="name">
								{editableArticle == null ? "" : "*"}
								Inventario físico
							</label>
							{
								editableArticle == null
									?
								<div className="roView">
									<br />
									{selectedArticle.stock_units}
								</div>
									:
								<input ref={stockUnitsRef} name="stock_units" id="stock_units" disabled={idleLocked} step={1}
								  onFocus={validateField} placeholder="Inventario físico"
								  value={editableArticle.stock_units} style={{width : "100%", textAlign : "right"}} min={0}
								  onBlur={validateField} type="number"
								  onChange={handleRegistryChange} required
								 
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
							<label htmlFor="name">
								{editableArticle == null ? "" : "*"}
								Inventario contable
							</label>
							{
								editableArticle == null
									?
								<div className="roView">
									<br />
									{selectedArticle.countable_units}
								</div>
									:
								<input onChange={handleRegistryChange} name="countable_units" min={0}
								  onBlur={validateField} ref={countableUnitsRef} step={1}
								  onFocus={validateField} placeholder="Inventario contable"
								  style={{width : "100%", textAlign : "right"}} value={editableArticle.countable_units}
								  type="number" disabled={idleLocked} id="countable_units" required
								/>
							}
						</div></div>
					</div>
					<div className="card card-default" style={{padding : "15px"}}>
						<div className="underlineHeadingContainer" style={{marginBottom : "unset"}}>
							<Popover positions={["bottom"]} isOpen={noTagsProvided} content=
							  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
								<div className="requirementAlert">
									<FontAwesomeIcon icon={solid("exclamation")}/>
								</div>
								&nbsp;
								Requerido : Elija al menos una opción
							  </div>}
							><div>
								{editableArticle == null ? "" : "*"}
								Etiquetas
							</div></Popover>
						</div>
						{
							editableArticle == null
								?
							<div style={{textAlign : "left", display : "flex", flexFlow : "wrap"}}>{
								selectedArticle.tags.length < 1
										?
									"<Ninguna>"
										:
								selectedArticle.tags.map
								(
									(record : any, index : number) => <div className="badge rounded-pill bg-primary prompt"
									  style={{display : "inline-block", padding : "5px", margin : "5px"}} key={"cr_" + record.id}
									>
										{record.name}
									</div>
								)
							}</div>
								:
							<>
								<label htmlFor="categories" style={{position : "unset"}}>
									Actualice
								</label>
								<Multiselect onSelect={(tags) => setEditableArticle({...editableArticle, tags : tags})}
								  emptyRecordMsg="No hay más opciones" options={basicInfo.tags} displayValue="name"
								  onRemove={(tags) => setEditableArticle({...editableArticle, tags : tags})}
								  selectedValues={editableArticle.tags} placeholder="Seleccione..."
								/>
							</>
						}
					</div>
					<div style={{padding : "15px 0px 15px 15px"}}>
						<UnderlineHeading name={"Descripción"} />
						{
							editableArticle == null ? (selectedArticle.description || "-") : <textarea ref={descriptionRef}
							  onBlur={validateField} disabled={idleLocked} maxLength={255}
							  id="description" value={editableArticle.description} className="notesField form-control"
							  onChange={handleRegistryChange} name="description"
							/>
						}
					</div>
				</div>
			</div></div></form></DataDisplayModal>
		}
	</>
}

export default Inventory
