import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice, dismissNotice
}
	from "./../../globals"
import
{
	UIState,
	isEmpty,
	validateField,
	getAccessPermissions
}
	from "./../../common"
import Constants, {tunning}
	from "./../../constants"
import UnderlineHeading
	from "./../../components/underlineHeading"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import TaxesService
	from "./../../services/campus/taxes.service"

import "./../../components/dropdown/style.css"

type State                = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action=
{
	type    : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{ ...state, isButtonDisabled : action.payload}
		break;
	}
}

const Taxes = ()=>
{
	const periods     : any[]                             = []
	const [state, trigger]                                = useReducer(reducer, initialState)
	const dispatch                                        = useDispatch()
	const records      : any[]                            = periods.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [data, setData]                                 = useState<any[]>(records)
	const idleLocked   : boolean                          = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                    = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean                          = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any                              = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const currentUser       : any                         = useSelector((state : RootState) => state.userProfile.value)
	const [wrongValueDetail, setWrongValueDetail]         = useState<string | null>(null)
	const [wrongValueOnFields, setWrongValueOnFields]     = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]           = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]             = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus]                         = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [newTax, setNewTax]                             = useState<any>(null)
	const [selectedTax, setSelectedTax]                   = useState<any>(null)
	const [editableTax, setEditableTax]                   = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                   = useState<boolean>(false)
	const [saveError, setSaveError]                       = useState<any>(null)
	const [recordsSelection, setRecordsSelection]         = useState<any>([])
	const count        : number[]                         = [-1, 1]
	const allowed      : any                              = getAccessPermissions(currentUser)
	let   recordFormRef                                         = useRef<HTMLFormElement >(null)
	let   descriptionRef                                  = useRef<HTMLTextAreaElement>(null)

	const editPeriod = ()=>
	{
		setEditableTax({...selectedTax, description : selectedTax.description || ""})
	}

	const hidePrompt = ()=>
	{
		if(editableTax && editableTax.id == null)
		{
			setSelectedTax(null)
		}

		setEditableTax(null)
	}

	const unedit = ()=>
	{
		if(editableTax && editableTax.id == null)
		{
			setSelectedTax(null)
		}

		setEditableTax(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)

		if(recordData.id == null)
		{
			setEditableTax(recordData)
		}

		setSelectedTax(recordData)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				const dateDiff : number = (editableTax.endValue - editableTax.startValue) / 86400000

				if(editableTax.startValue >= editableTax.endValue || dateDiff < 6)
				{
					if(!wrongValueOnFields && wrongValueDetail == null)
					{
						setWrongValueOnFields(true)
						setWrongValueDetail
						(
							dateDiff < 6
									?
								"Un periodo válido debe contener al menos 6 días de diferencia entre inicio y fin"
									: "La fecha de Inicio no puede ser mayor a la fecha final"
						)

						setTimeout
						(
							()=>
							{
								setWrongValueOnFields(false)
								setWrongValueDetail(null)
							},
							3000
						)
					}
				}
				else
				{
					if(saveError)
					{
						setSaveError(null)
					}

					setUIStatus(UIState.LOCKED)
					dispatch(enableIdleLockSwitch());

					TaxesService.saveTax(campusRef[currentUserCampus].id, editableTax).then
					(
						()=>
						{
							setSaveSuccess(true)
							setUIStatus(UIState.SUCCESS)

							setTimeout
							(
								()=>
								{
									setCustomSearch(defaultSearch)
									setSelectedTax(null)
									setEditableTax(null)
									setSaveSuccess(false)
									setUIStatus(UIState.NORMAL)
									search()
								},
								tunning.MODAL_DISMISS_DELAY
							)
						},
						(error : any)=>
						{
							console.log(error)
							setUIStatus(UIState.ERROR)
							dispatch(disableIdleLockSwitch())
							setSaveError
							(
								error.response.status != 409 ? "La información no pudo ser guardada" :
									"Hay conflictos en la información proporcionada (nombre, número de cuenta). " +
										"Revise que los valores sean únicos en comparación con los registros existentes"
							)
						}
					)
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableTax({...editableTax, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					setNewTax
					({
						code        : "",
						name        : "",
						description : ""
					})

					search()
				}
			}
		},
		[]
	)

	useEffect(()=>{setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await TaxesService.getTaxes(campusRef[currentUserCampus].id)

			if(result.status == 200 || result.status == 204)
			{
				setData
				(
					result.data.map
					(
						(record : any)=>
						({
							selected : false,
							data     : record
						})
					)
				)

				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						setSelectedRecords(selectedRecords + count[ +!record.selected ])

						return {...record, selected : !record.selected};
					}

					return record;
				}
			)
		);
	};

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		);

		setSelectedRecords(totalRecords * (+selected))
	};

	const closePrompt = ()=>
	{
		setSelectedTax(null)
		setEditableTax(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string   = "No se pudieron eliminar algunos registros. " +
						"Puede que la información en otra sección dependa de estos datos, " +
							"por lo que no será posible realizar por completo la operación"
					const recordIds    : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await TaxesService.removeTaxes(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded ? "" : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  allowedUpdate={allowed.update}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  addingPromptFn={() => showPrompt(newTax)}
		  clearSearchRequested={clearSearchRequested}
		>{
			(data.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					{Constants.noCampusSearchResults}
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="periodsHeader">
					{
						!allowed.delete ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
						  disabled={idleLocked || saveSuccess} type="button"
						>
							{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
						</button></th>
					}
					<th>
						id
					</th>
					<th>
						Código
					</th>
					<th>
						Nombre
					</th>
					<th>
						Descripción
					</th>
					<th>
					</th>
				</tr></thead>
				<tbody>{
					data.filter
					(
						(record : any) => (record && isEmpty(customSearch.q)) ||
						(
							record.data.name.indexOf(customSearch.q) >= 0 ||
							(
								record.data.code.indexOf(customSearch.q) >= 0 ||
								(record.data.description && record.data.description.indexOf(customSearch.q) >= 0)
							)
						)
					).map
					(
						(record : any, index : number) => <tr key={"period" + index}>
							{
								!allowed.delete ? "" : <td><button disabled={idleLocked} className="btn btn-default" type="button"
								  onClick={() => toggleRecordSelection(index)}
								>
									{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
								</button></td>
							}
							<td>
								{record.data.id}
							</td>
							<td>
								{record.data.code}
							</td>
							<td>
								{record.data.name}
							</td>
							<td>
								{record.data.description || "-"}
							</td>
							<td><div style={{display : "flex"}}>
								<button disabled={idleLocked} className="button btn btn-default"
								  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
								  type="button" onClick={() => showPrompt(record.data)}
								>
									<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
								</button>
								{
									!allowed.delete ? "" : <button onClick={() => confirmRemoval(record.data.id)} type="button"
									  className="button btn btn-default" style={{display : "table-column"}}
									  disabled={idleLocked}
									>
										<CustomIcon name="bTrash" />
									</button>
								}
							</div></td>
						</tr>
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			selectedTax == null ? "" : <DataDisplayModal editableRecord={editableTax} wrongValueOnFields={wrongValueOnFields}
			  unchangedRecord={unchangedRecord} saveLock={state.isButtonDisabled} promptCloseFn={closePrompt} uneditFn={unedit}
			  headIcon={<FontAwesomeIcon icon={solid("hand-holding-dollar")} />} entityName="Inpuesto" saveSuccess={saveSuccess}
			  saveDataFn={saveRecord} unfilledFields={unfilledFields} form={recordFormRef} allowedUpdate={allowed.update}
			  UIStatus={UIStatus} dataEditInit={editPeriod} processingError={saveError}
			><form ref={recordFormRef} className="form" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			>
				<div className="container"><div className="row">
					<div className="col-md-6"><div className="inputContainer">
						<label htmlFor="code">
							Código
						</label>
						{
							editableTax == null
								?
							<div className="roView">
								<br />
								{selectedTax.code}
							</div>
								:
							<input onBlur={validateField} placeholder="Código" type="text"
							  onChange={handleRegistryChange} maxLength={50} style={{width : "100%"}}
							  onFocus={validateField} value={editableTax.code} name="code"
							  id="code" disabled={idleLocked} required
							/>
						}
					</div></div>
					<div className="col-md-6"><div className="inputContainer">
						<label htmlFor="name">
							Nombre
						</label>
						{
							editableTax == null
								?
							<div className="roView">
								<br />
								{selectedTax.name}
							</div>
								:
							<input onBlur={validateField} name="name" placeholder="Nombre"
							  onFocus={validateField} disabled={idleLocked} maxLength={255}
							  onChange={handleRegistryChange} value={editableTax.name} type="text"
							  id="name" style={{width : "100%"}} required
							/>
						}
					</div></div>
				</div></div>
				<div style={{paddingTop : "25px"}}>
					<UnderlineHeading name={"Descripción"} />
					<div style={{paddingTop : "3px"}}>{
						editableTax == null
								?
							(selectedTax.description || "-")
								:
							<textarea onChange={handleRegistryChange} name="description" disabled={idleLocked}
							  value={editableTax.description} ref={descriptionRef} className="notesField form-control"
							  id="description" maxLength={255}
							/>
					}</div>
				</div>
				<br />
				<br />
				<br />
				<br />
				<br />
			</form></DataDisplayModal>
		}
	</>
}

export default Taxes
