import axios
	from "axios"
import store
	from "./../globals"

import {isNotEmpty, removeRecords}
	from "./../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/recipientAccounts";

class RecipientAccountsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getRecipientAccounts(countryId?: number)
	{
		let params = []

		if(countryId)
		{
			params.push("countryId=" + countryId)
		}

		return axios.get
		(
			RESOURCE_URL + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveRecipientAccount(recipientAccount : any)
	{
		const saveFunction = recipientAccount.id == null ? this.registerRecipientAccount : this.updateRecipientAccount

		let record : any=
		{
			name          : recipientAccount.name + "",
			accountNumber : recipientAccount.account_number + "",
			countries     : recipientAccount.countryIds
		}

		if(isNotEmpty(recipientAccount.description))
		{
			record.description = recipientAccount.description
		}

		return saveFunction
		(
			record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			recipientAccount.id
		)
	}

	async registerRecipientAccount(registry : any, options : any)
	{
		return axios.post(RESOURCE_URL, registry, options)
	}

	async updateRecipientAccount(registry : any, options : any, id : number)
	{
		return axios.put(RESOURCE_URL + "/" + id, registry, options)
	}

	async removeRecipientAccounts(recordIds : any)
	{
		return removeRecords(RESOURCE_URL, recordIds)
	}
}

export default new RecipientAccountsService()