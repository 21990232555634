import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,
	setBasicInfo,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice
}
	from "./../../globals"
import
{
	UIState,
	isEmpty,
	validateField,
	keyHandling,
	getAccessPermissions
}
	from "./../../common"
import {tunning}
	from "../../constants"
import CustomIcon
	from "./../../components/customIcon"
import Dropdown
	from "./../../components/dropdown"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import SimpleDataService
	from "./../../services/simpleData.service"

import "./../../components/dropdown/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action   = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{...state, isButtonDisabled : action.payload}
		break
	}
}

export const SimpleDataSection = (props : any)=>
{
	const {icon, APISuffix, entityName} = props

	const dataRecords     : any[] = []
	const [state, trigger]                                = useReducer(reducer, initialState)
	const dispatch                                        = useDispatch()
	const records      : any[]                            = dataRecords.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [data, setData]                                 = useState<any[]>([])
	const [readOnlyData, setReadOnlyData]                 = useState<any[]>([])
	const basicInfo    : any                              = useSelector((state : RootState) => state.basicInfo.value)
	const idleLocked   : boolean                          = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                    = useSelector((state : RootState) => state.sessionToken.value)
	const currentUser       : any                         = useSelector((state : RootState) => state.userProfile.value)
	const [unchangedRecord, setUnchangedRecord]           = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]             = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus]                         = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [newRecord, setNewRecord]                       = useState<any>(null)
	const [selectedRecord, setSelectedRecord]             = useState<any>(null)
	const [editableRecord, setEditableRecord]             = useState<any>(null)
	const [section, setSection]                           = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                   = useState<boolean>(false)
	const [saveError, setSaveError]                       = useState<any>(null)
	const [recordsSelection, setRecordsSelection]         = useState<any>([])
	const count        : number[]                         = [-1, 1]
	const allowed      : any                              = getAccessPermissions(currentUser)
	let   recordFormRef                                   = useRef<HTMLFormElement >(null)
	let   nameRef                                         = useRef<HTMLInputElement>(null)
	let   codeRef                                         = useRef<HTMLInputElement>(null)
	let   saveButtonRef                                   = useRef<HTMLButtonElement>(null)
	let   searchButtonRef                                 = useRef<HTMLButtonElement>(null)
	let   descriptionRef                                  = useRef<HTMLTextAreaElement>(null)

	const hidePrompt = ()=>
	{
		if(editableRecord && editableRecord.id == null)
		{
			setSelectedRecord(null)
		}

		setEditableRecord(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setEditableRecord(recordData)
		setSelectedRecord(recordData)
	}

	const saveRecord = ()=>
	{
		if(!recordFormRef?.current?.checkValidity())
		{
			if(!unfilledFields)
			{
				setUnfilledFields(true)
				recordFormRef?.current?.reportValidity()

				setTimeout
				(
					()=>
					{
						setUnfilledFields(false)
					},
					3000
				)
			}
		}
		else
		{
			let errorPrompt : boolean = false

			if(saveError)
			{
				setSaveError(null)
			}

			setUIStatus(UIState.LOCKED)
			dispatch(enableIdleLockSwitch())

			SimpleDataService.saveRecord(APISuffix, editableRecord).then
			(
				()=>
				{
					setSaveSuccess(true)
					setUIStatus(UIState.SUCCESS)

					setTimeout
					(
						()=>
						{
							setCustomSearch(defaultSearch)
							setSelectedRecord(null)
							setEditableRecord(null)
							setSaveSuccess(false)
							setUIStatus(UIState.NORMAL)
							search(true)
						},
						tunning.MODAL_DISMISS_DELAY
					)
				},
				(error : any)=>
				{
					console.log(error)
					setUIStatus(UIState.ERROR)
					dispatch(disableIdleLockSwitch())
					setSaveError
					(
						error.response.status == 409
								?
							"Hay conflictos en el nombre proporcionado. " +
								"Revise que los valores sean únicos en comparación con los registros existentes"
								:
							"La información no pudo ser guardada"
					)
				},
			)
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch
			({
				...customSearch,
				[e.target.name] : e.target.value
			})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableRecord
			({
				...editableRecord,
				[e.target.name] : e.target.value
			})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				heading:
					<h3 style={{color : "#FF0000", display : "inline-block"}}>
						Error
					</h3>,
				message     : message,
				cornerClose : false
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				const startingDate = new Date()
				const year         = startingDate.getFullYear()

				setNewRecord
				({
					name        : "",
					description : ""
				})

				search()
			}
		},
		[]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async (basicInfoRenewalCheck? : boolean)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result           = await SimpleDataService.getRecords(APISuffix)
			let normalData   : any = []
			let disabledData : any = []

			if(result.status == 200 || result.status == 204)
			{
				if(basicInfoRenewalCheck && APISuffix == "tags")
				{
					dispatch(setBasicInfo({...basicInfo, tags : result.data}))
				}

				result.data.map
				(
					(record : any, index : number)=>
					{
						if(record.bool_read_only)
						{
							disabledData.push(record)
						}
						else
						{
							normalData.push
							({
								selected : false,
								data     : record
							})
						}
					}
				)

				setData(normalData)
				setReadOnlyData(disabledData)
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected}
				}
			)
		)

		setSelectedRecords(totalRecords * (+selected))
	}

	const closePrompt = ()=>
	{
		setSelectedRecord(null)
		setEditableRecord(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar resgistro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string = "No se pudieron eliminar algunos registros. " +
						"Puede que información en otra sección dependa de estos datos, por lo que no será posible realizar por completo la operación"

					const recordIds : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any) => item.selected).map((item : any) => item.data.id)


					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await SimpleDataService.removeRecords(APISuffix, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search(true)
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded ? <></>: <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newRecord)}
		  saveButtonRef={saveButtonRef}
		>{
			(data.length < 1 && readOnlyData.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					Sin resultados.
				</div>
				<hr />
			</>
				:
			<>
				<div className="underlineHeadingContainer"  style={{marginBottom : "unset"}}>
					{
						(data.length < 1 || !allowed.delete) ? "" :
						<div style={{display : "table-row"}}>
							<button className="btn btn-default" onClick={toggleSelectAll} type="button"
							  disabled={idleLocked || saveSuccess} id="selectAll" style={{display : "table-cell"}}
							  name="selectAll"
							>
								{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
							</button>
							<div style={{display : "table-cell"}}>
								<label htmlFor="selectAll" style={{position : "relative", top : "5px"}}>
									<span className="d-none d-sm-inline-block">
										Seleccionar
									</span>
									&nbsp;
									<span>
										todo
									</span>
								</label>
							</div>
						</div>
					}
					<hr/>
				</div>
				<div>
					{
						readOnlyData.filter((record : any) => isEmpty(customSearch.q) || record.name.indexOf(customSearch.q) >= 0).map
						(
							(record : any, index : number )=> <div key={"T_" + index} style={{display : "inline-grid", padding : "5px"}}>
								<div className="badge rounded-pill bg-primary" style={{padding : "7px"}}><div
								  style={{display : "grid", margin : "0px 5px"}}
								><div className="prompt">
									{record.name}
								</div></div></div>
							</div>
						)
					}
					{
						data.filter((record : any) => isEmpty(customSearch.q) || record.data.name.indexOf(customSearch.q) >= 0).map
						(
							(record : any, index : number) => <div style={{display : "inline-grid", padding : "5px"}}
							  key={"T_" + index}
							><table className="badge rounded-pill bg-primary" style={{padding : "0px"}}><tbody><tr>
								{
									!allowed.delete ? "" : <td style={{padding : "5px"}}> <button type="button"
									  className="btn btn-default to check" onClick={() => toggleRecordSelection(index)}
									  disabled={idleLocked}
									>{
										!record.selected ? "" : <FontAwesomeIcon icon={solid("check")} />
									}</button></td>
								}
								<td style={{display : "grid", margin : "0px 5px"}}><button disabled={idleLocked}
								  onClick={() => allowed.update && showPrompt(record.data)} type="button"
								  className="btn btn-default prompt"
								>
									{record.data.name}
								</button></td>
								{
									!allowed.delete ? "" :<td style={{paddingRight : "5px"}}><button type="button"
									  onClick={() => {confirmRemoval(record.data.id)}} className="btn btn-default to"
									  disabled={idleLocked}
									>
										<FontAwesomeIcon icon={solid("times")} />
									</button></td>
								}
							</tr></tbody></table></div>
						)
					}
				</div>
			</>
		}</SectionContainer>
		{
			selectedRecord == null ? "" : <DataDisplayModal
			  dataEditInit={() => {setEditableRecord(selectedRecord)}}
			  editableRecord={editableRecord}
			  unfilledFields={unfilledFields}
			  saveLock={state.isButtonDisabled}
			  headIcon={icon}
			  saveSuccess={saveSuccess}
			  promptCloseFn={closePrompt}
			  entityName={entityName}
			  allowedUpdate={allowed.update}
			  UIStatus={UIStatus}
			  saveDataFn={saveRecord}
			  processingError={saveError}
			  unchangedRecord={unchangedRecord}
			  saveButtonRef={saveButtonRef}
			  unrequiredEmphasis
			><form ref={recordFormRef} className="form"><div className="inputContainer" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			>
				<label htmlFor="name">
					Nombre
				</label>
				{
					editableRecord == null
						?
					<div className="roView">
						<br />
						{selectedRecord.name}
					</div>
						:
					<input onFocus={validateField} style={{width : "100%"}} id="name" maxLength={50} disabled={idleLocked}
					  value={editableRecord.name} placeholder="Nombre" name="name" type="text" onChange={handleRegistryChange}
					  onKeyPress={(event : any) => keyHandling(!state.isButtonDisabled && event, saveButtonRef)} ref={nameRef}
					  onBlur={validateField} required
					/>
				}
			</div></form></DataDisplayModal>
		}
	</>
}

export const tags = ()=>
{
	return <SimpleDataSection
	  APISuffix="tags"
	  entityName="Etiqueta"
	  icon={<FontAwesomeIcon icon={solid("tag")} />}
	/>
}

export const articleClassifications = ()=>
{
	return <SimpleDataSection
	  APISuffix="articleClassifications"
	  entityName="Clasificación de artículo"
	  icon={<FontAwesomeIcon icon={solid("bookmark")} />}
	/>
}

export const categories = ()=>
{
	return <SimpleDataSection
	  entityName="Categoría"
	  APISuffix="categories"
	  icon={<FontAwesomeIcon icon={solid("folder")} />}
	/>
}

export const assignmentTypes = ()=>
{
	return <SimpleDataSection
	  icon={<FontAwesomeIcon
	  icon={solid("icons")} />}
	  APISuffix="assignmentTypes"
	  entityName="Tipo de actividad"
	/>
}
