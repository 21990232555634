import axios
	from "axios"
import store
	from "./../globals"

const API_URL = process.env.REACT_APP_API_URL + "/admin/assessments";

class AlumniService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getCurrentEnrollmentInfo(sessionToken : string | null)
	{
		return axios.get
		(
			process.env.REACT_APP_API_URL + "/scholar/_enrollmentInfo",
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		)
	}
}

export default new AlumniService()
