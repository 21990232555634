import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	useCallback,
	FormEvent
}
	from "react"
import {useNavigate}
	from "react-router-dom"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {Popover}
	from "react-tiny-popover"
import _debounce
	from "lodash/debounce"
import {useDispatch, useSelector}
	from "react-redux"
import Multiselect
	from "multiselect-react-dropdown"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,
	enableIdleLockSwitch,
	disableIdleLockSwitch
}
	from "./../../globals"
import CustomIcon
	from "./../../components/customIcon"
import UnderlineHeading
	from "./../../components/underlineHeading"
import {DataDisplayModal}
	from "./../../components/sectionContainer"
import
{
	UIState,
	weekDays,
	dayHours,
	checkIcon,
	revertStringDate,
	isNotEmpty
}
	from "./../../common"
import
{
	scheduleStatus,
	studentStatus,
	tunning
}
	from "./../../constants"
import SchedulesService
	from "./../../services/campus/schedules.service"
import courseImage
	from "./../../assets/images/course.png"
import userImage
	from "./../../assets/images/user.png"
	
import "./../../components/dropdown/style.css"
import "./style.css"

type State                 = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action=
{
	type    : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch(action.type){case "setIsButtonDisabled" : return{...state, isButtonDisabled : action.payload}}
}

const EnrollmentPrompt = (props : any)=>
{
	const enrollStatus : any=
	[
		solid("circle-plus"),
		solid("circle-check"),
		solid("trash-arrow-up"),
	]

	const enrollView=
	[
		"Información",
		"Inscritos"
	]

	const studentUI=
	[
		"btn btn-default",
		"btn btn-primary",
		"btn btn-default bg-danger",
	]

	const [state, trigger]                            = useReducer(reducer, initialState)
	const dispatch           : any                    = useDispatch()
	const navigate           : any                    = useNavigate()
	const [studentsToEnroll, setStudentsToEnroll]     = useState<any>([])
	const [edittingList, toggleEdittingList]          = useState<boolean>(false)
	const [enrollmentCheck, setEnrollmentCheck]       = useState<any>({})
	const [enrollmentDeletion, setEnrollmentDeletion] = useState<any>({})
	const [enrollmentAdding, setEnrollmentAdding]     = useState<any>({})
	const [enrollmentInit, setEnrollmentInit]         = useState<any>({})
	const [aSearchInProgress, setASearchInProgress]   = useState<boolean>(false)
	const userProfile       : any                     = useSelector((state : RootState) => state.userProfile.value)
	const idleLocked        : boolean                 = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null           = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean                 = useSelector((state : RootState) => state.themeToggle.value)
	const currentUserCampus : any                     = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any                     = useSelector((state : RootState) => state.campusRef.value)
	const [UIStatus, setUIStatus]                     = useState<number>(UIState.NORMAL)
	const [selectableRecords, setSelectableRecords]   = useState<number>(0)
	const [listSearch, setListSearch]                 = useState<any>("")
	const [unchangedRecord, setUnchangedRecord]       = useState<boolean>(false)
	const [isPopoverOpen, setIsPopoverOpen]           = useState<boolean>(false)
	const [editableEnrollment, setEditableEnrollment] = useState<any>(null)
	const [saveSuccess, setSaveSuccess]               = useState<boolean>(false)
	const [saveError, setSaveError]                   = useState<any>(null)
	const [kitRef, setKitRef]                         = useState<any>(null)
	let   nameRef                                     = useRef<HTMLInputElement>(null)
	let   recordFormRef                               = useRef<HTMLFormElement>(null)

	const hideEnrollment = ()=>
	{
		if(editableEnrollment)
		{
			setEditableEnrollment(null)
		}

		props.closeFn()
	}

	const unedit = ()=>
	{
		setEditableEnrollment(null)
	}

	const enrollStudent = (student : any)=>
	{
		let formattedStudent : any = {...student, editableKits : []}

		if(enrollmentInit[formattedStudent.refIndex] == null)
		{
			setEditableEnrollment({...editableEnrollment, students: [...editableEnrollment.students, formattedStudent]})

			let adding                        = enrollmentAdding
			adding[formattedStudent.refIndex] = formattedStudent

			setEnrollmentAdding(adding)
		}
		else
		{
			let deletion = enrollmentDeletion

			delete deletion[formattedStudent.refIndex]

			setEnrollmentDeletion(deletion)

			setEditableEnrollment
			({
				...editableEnrollment, students : editableEnrollment.students.map
				(
					(enrolled : any)=>
					({
						...enrolled, deletion : enrolled.refIndex == formattedStudent.refIndex ? false : formattedStudent.refIndex
					})
				)
			})
		}
	}

	const studentsEnrollment = (students : any)=>
	{
		setStudentsToEnroll
		(
			students.map
			(
				(student : any)=>
				{
					if(student.id != userProfile.id && student.id != props.selectedEnrollment.mentor_id)
					{
						let record : any = {...student, refIndex : "S_" + student.id}

						record.lessons.map
						(
							(session : any)=>
							{
								if(session.schedule_id != props.selectedEnrollment.id)
								{
									let currentHours = session.start_hours

									while(currentHours < session.end_hours)
									{
										if
										(
											session.schedule_id != props.selectedEnrollment.id &&
												props.selectedEnrollment.hours[sprintf("DH%d%02d", session.week_day, currentHours)]
										)
										{
											record.unavailable = true
										}

										currentHours++
									}
								}
							}
						)

						delete record.sessions

						return record
					}
				}
			)
		)
	}

	const editEnrollment = async()=>
	{
		let enrollInit      : any    = {}
		let editableRecords : number = 0

		props.selectedEnrollment.students.map
		(
			(student : any)=>
			{
				enrollInit[student.refIndex] = student

				if((student.tuitionInvoiceIds.length + student.invoicedKits.length) < 1)
				{
					editableRecords++
				}
			}
		)

		dispatch(enableIdleLockSwitch())
		setUIStatus(UIState.LOCKED)

		try
		{
			let result : any = await SchedulesService.searchEnrollableStudents
				(campusRef[currentUserCampus].id, props.selectedEnrollment.id, "", 100)

			if(result.status == 200 || result.status == 204)
			{
				studentsEnrollment(result.data)

				setListSearch("")
				setEnrollmentCheck({})
				setEnrollmentDeletion({})
				setEnrollmentAdding({})
				setEnrollmentInit(enrollInit)
				setSelectableRecords(editableRecords)
				setEditableEnrollment(props.selectedEnrollment)
				toggleEdittingList(true)
				setUIStatus(UIState.NORMAL)
			}
			else
			{
				setSaveError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}

			dispatch(disableIdleLockSwitch())
		}
		catch(error)
		{
			setSaveError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
			dispatch(disableIdleLockSwitch())
		}
	}

	const debounceFn : any = useCallback(_debounce(handleDebounceFn, 1000), [])

	async function handleDebounceFn(campusId : number, enrollmentId : number, term : string)
	{
		setASearchInProgress(true)

		try
		{
			setSaveError(null)
			setUIStatus(UIState.NORMAL)

			let result : any = await SchedulesService.searchEnrollableStudents
				(campusId, enrollmentId, term, 100)

			if(result.status == 200 || result.status == 204)
			{
				studentsEnrollment(result.data)
			}
			else
			{
				setSaveError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			setSaveError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setASearchInProgress(false)
		}
	}


	const searchStudents = (term : string)=>
	{
		setListSearch(term)
		debounceFn(campusRef[currentUserCampus].id, editableEnrollment.id, term)
	}

	const saveEnrollment = async()=>
	{
		let studentUpdateRefs : any = []
		let studentAddingRefs : any = []
		let allKits           : any
		let extraKits         : any
		let joinedKits        : any

		editableEnrollment.students.map
		(
			(item : any)=>
			{
				if(item && (enrollmentInit[item.refIndex] && enrollmentDeletion[item.refIndex] == null))
				{
					allKits    = []
					extraKits  = item.extraKits.length < 1 ? [] : item.extraKits.filter((kit : any) => kit.toBeDeleted != true)
					joinedKits = [item.invoicedKits, extraKits, item.editableKits]

					joinedKits.map
					(
						(dataSet : any)=>
						{
							if(dataSet.length > 0)
							{
								allKits = [...allKits, ..._.map(dataSet, "id")]
							}
						}
					)

					studentUpdateRefs.push(item.id + (allKits .length < 1 ? "" : "," + allKits.join(",")))
				}
			}
		)

		editableEnrollment.students.map
		(
			(item : any)=>
			{
				if(item && (enrollmentAdding[item.refIndex]))
				{
					studentAddingRefs.push(item.id + (item.editableKits.length < 1 ? "" : "," + _.map(item.editableKits, "id").join(",")))
				}
			}
		)

		if(saveError != null)
		{
			setSaveError(null)
		}

		setUIStatus(UIState.LOCKED)
		dispatch(enableIdleLockSwitch())

		try
		{
			const result = await SchedulesService.updateEnrollment
			(
				campusRef[currentUserCampus].id, editableEnrollment,
					(studentUpdateRefs.length < 1 && studentAddingRefs.length < 1) ? "" : {studentWithKitIds : [...studentUpdateRefs, ...studentAddingRefs].join(";")}
			)

			if(result.status == 200 || result.status == 204)
			{
				setSaveSuccess(true)
				setUIStatus(UIState.SUCCESS)

				setTimeout
				(
					()=>
					{
						props.closeFn()
						setEditableEnrollment(null)
						setSaveSuccess(false)
						setUIStatus(UIState.NORMAL)
						props.afterCloseFn()
					},
					tunning.MODAL_DISMISS_DELAY
				)
			}
			else
			{
				setSaveError("La información no pudo ser guardada")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			console.log(error)
			setSaveError("La información no pudo ser guardada")
			setUIStatus(UIState.ERROR)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			let agenda   : any = {}
			let kitIndex : any = {}

			props.selectedEnrollment.level.kits.map
			(
				(kit: any)=>
				{
					kitIndex["K_" + kit.id] = kit
				}
			)

			setKitRef(kitIndex)
			toggleEdittingList(false)
			setEnrollmentInit({})
			setSelectableRecords(0)
		},
		[]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const toggleListSelection = (item : any)=>
	{
		let check = enrollmentCheck

		if(check[item.refIndex])
		{
			delete check[item.refIndex]
		}
		else
		{
			check[item.refIndex] = true
		}

		setEnrollmentCheck(check)
		setEditableEnrollment({...editableEnrollment})
	}

	const toggleAllInList = ()=>
	{
		let enrollCheck = enrollmentCheck
		const allCheck  = !(
			Object.keys(enrollmentCheck).length ==
			(
				selectableRecords - Object.keys(enrollmentDeletion).length + Object.keys(enrollmentAdding).length
			)
		)

		if(!allCheck)
		{
			setEnrollmentCheck({})
		}
		else
		{
			editableEnrollment.students.map
			(
				(student : any)=>
				{
					if
					(
						(student.deletion != true && enrollCheck[student.refIndex] == null) &&
							(student.tuitionInvoiceIds.length < 1 || student.invoicedKits.lenght < 1)
					)
					{
						enrollCheck[student.refIndex] = true
					}
				}
			)

			setEnrollmentCheck(enrollCheck)
		}

		setEditableEnrollment({...editableEnrollment})
	}

	const undoListRemoval = (key : string)=>
	{
		let deletion : any = enrollmentDeletion

		delete deletion[key]
		setEnrollmentDeletion(deletion)
		setEditableEnrollment
		({
			...editableEnrollment, students : editableEnrollment.students.map
			(
				(student : any) => ({...student, deletion : student.refIndex == key ? false : student.deletion})
			)
		})
	}

	const listRemoval = (target? : any)=>
	{
		let deletion : any = enrollmentDeletion
		let adding   : any = enrollmentAdding
		let check    : any = enrollmentCheck

		if(isNaN(target))
		{
			setEditableEnrollment
			({
				...editableEnrollment, students : editableEnrollment.students.filter
				(
					(student : any)=>
					{
						if(check[student.refIndex])
						{
							if(adding[student.refIndex])
							{
								delete adding[student.refIndex]
							}
						}

						return student.stocked || (adding[student.refIndex] && !check[student.refIndex])
					}
				).map
				(
					(student : any)=>
					{
						if(student.stocked && check[student.refIndex])
						{
							deletion[student.refIndex] = true
						}

						return {...student, deletion : check[student.refIndex] == true}
					}
				)
			})

			setEnrollmentAdding(adding)
			setEnrollmentDeletion(deletion)
			setEnrollmentCheck({})
		}
		else
		{
			let refIndex = editableEnrollment.students[target].refIndex

			if(check[refIndex])
			{
				delete(check[refIndex])
				setEnrollmentCheck(check)
			}

			if(adding[refIndex])
			{
				delete adding[refIndex]
				setEnrollmentAdding(adding)
			}
			else
			{
				deletion[refIndex] = true

				setEnrollmentDeletion(deletion)
			}

			if(editableEnrollment.students[target].stocked)
			{
				setEditableEnrollment
				({
					...editableEnrollment, students : editableEnrollment.students.map
					(
						(student : any, index : number)=>
						({
							...student, deletion : index == target ? true : student.deletion
						})
					)
				})
			}
			else
			{
				setEditableEnrollment
				({
					...editableEnrollment, students : editableEnrollment.students.filter((student : any, index : number) => index != target)
				})
			}
		}
	}

	return <DataDisplayModal saveSuccess={saveSuccess} promptCloseFn={hideEnrollment} unchangedRecord={unchangedRecord}
	  edittingRefusal={"Este horario no permite matricular alumnos debido a su estatus (Inactivo)"} saveDataFn={saveEnrollment}
	  allowedUpdate={props.allowedUpdate && props.selectedEnrollment.status_id == scheduleStatus.ACTIVE} UIStatus={UIStatus}
	  uneditFn={unedit} processingError={saveError} saveLock={state.isButtonDisabled} editableRecord={editableEnrollment}
	  dataEditInit={editEnrollment} entityName="Matrícula" headIcon={<FontAwesomeIcon icon={solid("clipboard-list")} />}
	  recordFormRef={recordFormRef} unrequiredEmphasis
	><form ref={recordFormRef} className="form" onSubmit=
	  {
		(event : FormEvent)=>
		{
			event.preventDefault()
			saveEnrollment()
		}
	  }
	><div className="container" ><fieldset>
		{
			editableEnrollment == null ? "" : <>
				<legend style={{display : "flex"}}>
					<button onClick={()=>{toggleEdittingList(false)}} className="btn btn-default" disabled={!edittingList}>
						<FontAwesomeIcon icon={solid("circle-info")} size="2x" />
						<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
							Información
						</span>
					</button>
					<button disabled={edittingList} onClick={()=>{toggleEdittingList(true)}}
					  className="btn btn-default"
					>
						<FontAwesomeIcon icon={solid("clipboard-list")} size="2x" />
						<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
							Inscritos
						</span>
					</button>
				</legend>
				<h3 className="d-block d-xl-none d-lg-none d-md-none d-sm-none">
					{enrollView[ +edittingList ]}
				</h3>
			</>
		}
		{
			(editableEnrollment && edittingList) ? "" : <>
				<div className="row">
					<div className="col-md-4 text-center">
						<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
							<img className="relatedPicture" src=
							{
								(
									(editableEnrollment == null && props.selectedEnrollment.course.image)
										|| (editableEnrollment && editableEnrollment.course.image)
								) || courseImage
							}
							/>
						</div>
						<br />
						<br />
						<div style={{textAlign : "left"}}>
							<label style={{position : "relative", textTransform : "uppercase", textAlign : "left"}}>
								código curso
							</label>
							<div style={{textAlign : "left"}}>
								{props.selectedEnrollment.course.code || "-"}
							</div>
						</div>
						<div style={{textAlign : "left"}}>
							<label style={{position : "relative", textTransform : "uppercase", textAlign : "left"}}>
								código módulo
							</label>
							<div style={{textAlign : "left"}}>
								{props.selectedEnrollment.level.code || "-"}
							</div>
						</div>
						<div style={{textAlign : "left"}}>
							<label style={{position : "relative", textTransform : "uppercase", textAlign : "left"}}>
								código horario
							</label>
							<div style={{textAlign : "left"}}>
								{props.selectedEnrollment.code || "-"}
							</div>
						</div>
						<div className="card card-default" style={{padding : "15px", marginTop : "15px"}}>
							<label style={{padding : "unset", position : "relative", textAlign : "left"}}>
								Periodo
							</label>
							<div className="roView" style={{textAlign : "left"}}>
								{props.selectedEnrollment.period.name}
							</div>
							<label style={{padding : "unset", position : "relative", textAlign : "left"}}>
								Curso
							</label>
							<div className="roView" style={{textAlign : "left"}}>
								{props.selectedEnrollment.course.name}
							</div>
							<label style={{padding : "unset", position : "relative", textAlign : "left"}}>
								Módulo
							</label>
							<div className="roView" style={{textAlign : "left"}}>
								{props.selectedEnrollment.level.name}
							</div>
							<label style={{padding : "unset", position : "relative", textAlign : "left"}}>
								Horario
							</label>
							<div className="roView" style={{textAlign : "left"}}>
								{props.selectedEnrollment.name}
							</div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="row">
							{
								(editableEnrollment == null && props.selectedEnrollment.classroom == null) ? "" :
								<div className="col-md-6">
									<label htmlFor="classroom" style={{position : "unset"}}>
										Aula
									</label>
									{
										props.selectedEnrollment == null || props.selectedEnrollment.classroom == null ? "-" :
										<div className="roView">
											{props.selectedEnrollment.classroom.name}
										</div>
									}
								</div>
							}
							<div className="col-md-6"><div className="inputContainer">
								<label htmlFor="students_nimimum">
									Mínimo de estudiantes
								</label>
								<div className="roView">
									<br />
									{props.selectedEnrollment.students_minimum}
								</div>
							</div></div>
							<div className="col-md-6"><div className="inputContainer">
								<label htmlFor="students_limit">
									Máximo de estudiantes
								</label>
								<div className="roView">
									<br />
									{props.selectedEnrollment.students_limit}
								</div>
							</div></div>
							<div className="col-md-6">
								<label htmlFor="period_id" style={{position : "relative"}}>
									Profesor
								</label>
								<div className="roView">{
									(
										(props.selectedEnrollment && props.selectedEnrollment.mentor) &&
										(
											props.selectedEnrollment.mentor.first_name + " " + 
												props.selectedEnrollment.mentor.last_name
										)
									)
										||
									"-"
								}</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px", textAlign : "left"}}>
								<label htmlFor="end" style={{position : "relative", bottom : "22px"}}>
									Inicio
								</label>
								<div style={{position : "relative"}}>
									{props.selectedEnrollment.start_date == null ? "-" : revertStringDate(props.selectedEnrollment.start_date)}
								</div>
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px", textAlign : "left"}}>
								<label htmlFor="end" style={{position : "relative", bottom : "22px"}}>
									Fin
								</label>
								<div style={{position : "relative"}}>
									{props.selectedEnrollment.end_date == null ? "-" : revertStringDate(props.selectedEnrollment.end_date)}
								</div>
							</div></div>
						</div>
						<div className="row">
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="billable_months">
									Meses a facturar
								</label>
								<br />
								{props.selectedEnrollment.billable_months}
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="subscription_price">
									Precio de inscripción
								</label>
								<br />
								<div style={{display : "flex", alignItems : "stretch"}}>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.symbol}
									</div>
									<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}><div className="roView"
									  style={{textAlign : "right"}}
									>
											{sprintf("%.02f", props.selectedEnrollment.subscription_price)}
										</div>
									</div>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.code}
									</div>
								</div>
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="monthly_billing_price">
									Precio de mensualidad
								</label>
								<br />
								<div style={{display : "flex", alignItems : "stretch"}}>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.symbol}
									</div>
									<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}><div className="roView"
									  style={{textAlign : "right"}}
									>
										{sprintf("%.02f", props.selectedEnrollment.monthly_billing_price)}
									</div></div>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.code}
									</div>
								</div>
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="subscription_surcharge_cost">
									Recargo de inscripción
								</label>
								<br />
								<div style={{display : "flex", alignItems : "stretch"}}>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.symbol}
									</div>
									<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}><div className="roView"
									  style={{textAlign : "right"}}
									>
										{sprintf("%.02f", props.selectedEnrollment.subscription_surcharge_cost)}
									</div></div>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.code}
									</div>
								</div>
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="monthly_billing_surcharge_cost">
									Recargo de mensualidad
								</label>
								<br />
								<div style={{display : "flex", alignItems : "stretch"}}>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.symbol}
									</div>
									<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}><div className="roView"
									  style={{textAlign : "right"}}
									>
										{sprintf("%.02f", props.selectedEnrollment.monthly_billing_surcharge_cost)}
									</div></div>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.code}
									</div>
								</div>
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="monthly_billing_surcharge_cost">
									Segundo recargo
								</label>
								<br />
								<div style={{display : "flex", alignItems : "stretch"}}>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.symbol}
									</div>
									<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}><div className="roView"
									  style={{textAlign : "right"}}
									>
										{sprintf("%.02f", props.selectedEnrollment.second_surcharge_cost)}
									</div></div>
									<div style={{flexGrow : 0}}>
										{campusRef[currentUserCampus].company.currency.code}
									</div>
								</div>
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="first_surcharge_month_day">
									Día de primer recargo
								</label>
								<br />
								{props.selectedEnrollment.first_surcharge_month_day}
							</div></div>
							<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
								<label htmlFor="first_surcharge_month_day">
									Día de segundo recargo
								</label>
								<br />
								{props.selectedEnrollment.second_surcharge_month_day}
							</div></div>
						</div>
						<UnderlineHeading name={"Gestión"} style={{paddingTop : "15px"}} />
						<table style={{width : "100%", fontSize : "12px"}}>
							<tbody><tr style={{color : "#FFFFFF", fontWeight : "200"}}>
								<td className="text-end" />
								<td className="d-none d-sm-table-cell" />
								<td className="d-none d-lg-table-cell" />
								{
									weekDays.map
									(
										(item :  any, index : number) => <td key={"d" + index} style=
										  {{textAlign : "center", borderWidth : "1px", borderColor : "#FFFFFF"}}
										><div style={{background : "#8F91DA", borderRadius : "3px", display : "flex"}}>
											<span>
												{item.substr(0, 2)}
											</span>
											<span className="d-none d-lg-inline-block">
												{item.substr(2)}
											</span>
										</div></td>
									)
								}
							</tr></tbody>
							{
								dayHours.map
								(
									(hour : number) => <tbody key={"h" + hour}><tr>
										<td className="text-end scheduleHeading" style=
										  {{borderWidth : "1px 0px 1px 1px", color : "#4A4B80", padding : "5px 0px 0px 5px"}}
										><div style={{borderRadius : "5px 0px 0px 5px"}}>	
											{sprintf("%02d", hour)}
										</div></td>
										<td className="d-none d-sm-table-cell scheduleHeading" style=
										  {{borderWidth : "1px 0px 1px 0px", color : "#4A4B80", paddingTop : "5px", width : "1px"}}
										><div>
											:00
										</div></td>
										<td className="d-none d-lg-table-cell scheduleHeading" style=
										  {{borderWidth : "1px 1px 1px 0px", color : "#4A4B80", padding : "5px 5px 0px 0px"}}
										><div style={{borderRadius : "0px 5px 5px 0px"}}>
											{sprintf("-%02d:00", hour + 1)}
										</div></td>
										{
											weekDays.map
											(
												(day : string, dayIndex : number)=>
												{
													let timeMarks : any=
													[
														sprintf("DH%d%03d", dayIndex,  hour * 10),
														sprintf("DH%d%03d", dayIndex, (hour * 10) + 5)
													]

													return <td style={{border : "1px solid #F9F9F9", width : "13%"}}
													  key={"x" + timeMarks[0]}
													>
													{
														timeMarks.map
														(
															(timeMark : string, half : number)=> <div key={"d" + timeMark}  style=
															  {{
																display : "inline-block", width : "50%", 
																height : "25px", verticalAlign : "bottom",
																borderRadius : (half ? "0px 5px 5px 0px" : "5px 0px 0px 5px"),
																background:
																(
																	props.selectedEnrollment.hours[timeMark]
																		?
																	"var(--main-color)"
																		:"unset"
																)
															  }}
															/>
														)
													}
													</td>
												}
											)
										}
									</tr></tbody>
								)
							}
						</table>
					</div>
				</div>
				<UnderlineHeading name={"Notas"} style={{paddingTop : "15px"}} />
				<div><p>
					{props.selectedEnrollment.notes || "-"}
				</p></div>
			</>
		}
		{
			(editableEnrollment && !edittingList) ? "" : <div style={{overflow : "auto"}}>{
				editableEnrollment == null
						?
					(
						<>
							<UnderlineHeading name={"Listado de alumnos"} style={{paddingTop : "15px"}} />
							{
								props.selectedEnrollment.enrollments.length < 1 ? "(Sin registros)" : <table className="commonTable">
									<thead>
										<tr>
											<th style={{color : "#ABACE3", fontSize : 14}} ref=
											  {el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}
											>
												Matrícula
											</th>
											<th style={{color : "#ABACE3", fontSize : 14}}>
												Nombre
											</th>
											<th style={{color : "#ABACE3", fontSize : 14}}>
												Teléfonos
											</th>
											<th style={{color : "#ABACE3", fontSize : 14}}>
												Saldo mensualidades
											</th>
											<th style={{color : "#ABACE3", fontSize : 14}}>
												Saldo kits
											</th>
											<th />
										</tr>
									</thead>
									<tbody>
									{
										props.selectedEnrollment.enrollments.map
										(
											(enrollment : any)=>
											{
												const kitsBalance     : number = +enrollment.kit_debt     - +enrollment.kit_debt_settlement
												const tuitionsBalance : number = +enrollment.tuition_debt - +enrollment.tuition_debt_settlement

												return <tr
												  key={"IS_" + enrollment.id}
												  style={{color : ((kitsBalance + tuitionsBalance) > 0 ? "#BA0000" : "#000000")}}
												>
													<td ref={el=>{if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
														{enrollment.id}
													</td>
													<td>
														{enrollment.student.first_name + " " + enrollment.student.last_name}
													</td>
													<td>
														{[enrollment.student.phone1, enrollment.student.phone2].filter((phone : string) => isNotEmpty(phone)).join(", ") || "-"}
													</td>
													<td>
														<div style={{display : "flex", alignItems : "stretch", whiteSpace : "nowrap"}}>
															<span style={{paddingRight : "5px"}}>{
																(tuitionsBalance > 0 ? "-" : "") +
																campusRef[currentUserCampus].company.currency.symbol
															}</span>
															<span style={{flexGrow : 1}}>{
																sprintf
																(
																	" %.02f " + campusRef[currentUserCampus].company.currency.code,
																	tuitionsBalance
																)
															}</span>
														</div>
													</td>
													<td>
														<div style={{display : "flex", alignItems : "stretch", whiteSpace : "nowrap"}}>
															<span style={{paddingRight : "5px"}}>{
																(kitsBalance > 0 ? "-" : "") +
																campusRef[currentUserCampus].company.currency.symbol
															}</span>
															<span style={{flexGrow : 1}}>{
																sprintf
																(
																	" %.02f " + campusRef[currentUserCampus].company.currency.code,
																	kitsBalance
																)
															}</span>
														</div>
													</td>
													<td><div style={{display : "flex"}}>
														<button type="button" disabled={idleLocked} className="btn btn default"
														  onClick=
														  {
															()=>
															{
																props.profileViewingFn
																({
																	...enrollment.student, ...enrollment.student.student,
																	status_id    : !enrollment.student.bool_enabled ? studentStatus.DISABLED : enrollment.student.student.status_id,
																	imagePreview : enrollment.student.image
																})
															}
														  }
														>
															<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
														</button>
														<button onClick={() => navigate("/statement", {state : enrollment.student})}
														  disabled={idleLocked} className="btn btn default" type="button"
														>
															<FontAwesomeIcon icon={solid("money-bills")} />
														</button>
													</div></td>
												</tr>
											}
										)
									}
									</tbody>
								</table>
							}
						</>
					)
						:
					<>
						<Popover isOpen={isPopoverOpen} onClickOutside={() => setIsPopoverOpen(false)} positions={["bottom"]}
						  content=
						  {<div style={{overflowY : "auto", maxHeight : "40vh", background : "var(--main-bg)", width : "85vw"}}>
							{
								(studentsToEnroll == null|| studentsToEnroll.length < 1) ? "" : studentsToEnroll.filter((student : any) => student).map
								(
									(student : any, index : number) => <div key={"T_" + index}
									  style={{display : "inline-grid", padding : "5px", width : "100%"}}
									><div style={{display : "grid", margin : "0px 5px"}}><button key={"T_" + index} type="button"
									  onClick={() => enrollStudent(student)} className=
									  {
										student.unavailable != null ? "btn-unstyled" : studentUI
										[
											( +(enrollmentInit[student.refIndex] != null))
												+
											( +(enrollmentAdding[student.refIndex] != null))
												+
											( +(enrollmentDeletion[student.refIndex] != null))
										]
									  }
									  disabled=
									  {
										(student.unavailable)
											||
										(idleLocked || enrollmentAdding[student.refIndex])
											||
										(enrollmentInit[student.refIndex] && enrollmentDeletion[student.refIndex] == null)
									  }
									  style=
									  {{
										whiteSpace   : "nowrap",
										overflow     : "hidden",
										textOverflow : "ellipsis",
										textAlign    : "left"
									  }}
									>
										<FontAwesomeIcon icon=
										  {
											student.unavailable ? solid("calendar-xmark") : enrollStatus
												[
													( +(enrollmentInit[student.refIndex] != null))
														+
													( +(enrollmentAdding[student.refIndex] != null))
														+
													( +(enrollmentDeletion[student.refIndex] != null))
												]
										  }
										/>
										<span style={{paddingLeft : "10px"}}>
											{student.first_name + " " + student.last_name}
										</span>
									</button></div></div>
								)
							}
							<div className="text-center" style={{color : "#C7C7C7"}}>
								No hay más resultados.
							</div>
						  </div>}
						><div className="inputContainer" style={{position : "sticky", left : "0"}}>
							<label htmlFor="listSearch" style={{position : "relative", display : "block"}}>
								Nombres, apellidos, correo, ...
								<div style={{height : "5px"}}>
									{!aSearchInProgress ? "" : <div className="loader" style={{flex : 1}} />}
								</div>
							</label>
							<input id="listSearch" onClick={() => setIsPopoverOpen(!isPopoverOpen)} ref={nameRef} type="text"
							  style={{width : "100%"}} value={listSearch} placeholder="Nombres, apellidos, correo" maxLength={50}
							  onChange={(e : any) => searchStudents(e.target.value)} name="listSearch" disabled={idleLocked}
							/>
						</div></Popover>
						{
							props.selectedEnrollment.level.kits && props.selectedEnrollment.level.kits.length > 0 ? "" :
							<table style={{width : "100%", position : "sticky", left : "0px	"}} className="callout callout-warning">
								<tbody><tr>
									<td style={{padding : "16px", verticalAlign : "middle"}}>
										<FontAwesomeIcon icon={solid("triangle-exclamation")} />
									</td>
									<td style={{verticalAlign : "middle"}}>
										No hay kits vinculados a este módulo para ser asignados a los alumnos
									</td>
								</tr></tbody>
							</table>
						}
						{
							(editableEnrollment == null || editableEnrollment.students.length < 1) ? "" : <>
								<div className="text-end" style={{position : "sticky", left : "0"}}><button type="button"
								  disabled={idleLocked || Object.keys(enrollmentCheck).length < 1} onClick={listRemoval}
								  className="btn btn-warning" style={{borderRadius : "25px"}}
								>
									<CustomIcon name="bTrash" />
									<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
										Eliminar seleccionados
									</span>
								</button></div>
								<table className="standardTable">
									<thead><tr key="usersHeader">
										<th>{
											selectableRecords < 1 ? "" : <button disabled={idleLocked || saveSuccess} className="btn btn-default"
											  type="button" onClick={() => toggleAllInList()}
											>{<CustomIcon
													name=
													{
														Object.keys(enrollmentCheck).length ==
														(
															selectableRecords
															- Object.keys(enrollmentDeletion).length
															+ Object.keys(enrollmentAdding).length
														) ? "bCheck" : "bUncheck"
													}
												/>
											}</button>
										}</th>
										<th style={{width : "1px"}}
										  ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}
										>
											id
										</th>
										<th>
											<FontAwesomeIcon icon={solid("image")} />
										</th>
										<th>
											Nombres
										</th>
										<th>
											Apellidos
										</th>
										<th>
											Código de identidad
										</th>
										<th>
											Email
										</th>
										<th style={{minWidth : "235px"}}>
											Kits
										</th>
										<th />
									</tr></thead>
									<tbody>{/*Students previously added*/
										editableEnrollment.students.filter((student : any) => student).map
										(
											(listedStudent : any, index : number) => (enrollmentInit[listedStudent.refIndex] == null || enrollmentDeletion[listedStudent.refIndex])
													? ""
														: <tr key={"user" + index}>
												<td>{
													(listedStudent.tuitionInvoiceIds.length > 0 || listedStudent.invoicedKits.lenght > 0)
															?
														""
															:
													<button disabled={idleLocked} className="btn btn-default" type="button"
													  onClick={() => toggleListSelection(listedStudent)}
													>
														<CustomIcon name={checkIcon[+(enrollmentCheck[listedStudent.refIndex] != null)]} />
													</button>
												}</td>
												<td>
													{listedStudent.id}
												</td>
												<td style={{width : "1px"}}>
													<img src= {listedStudent.image != null ? listedStudent.image : userImage}
													  className="detailPicture"
													/>
												</td>
												<td>
													{listedStudent.first_name || "-"}
												</td>
												<td>
													{listedStudent.last_name || "-"}
												</td>
												<td>
													{listedStudent.student?.identity_code || "-"}
												</td>
												<td>
													{listedStudent.email}
												</td>
												<td>
													{
														listedStudent.invoicedKits.lenght < 1
															?
														""
															:
														listedStudent.invoicedKits.map
														(
															(kit : any) => <div className="badge rounded-pill bg-primary"
															  key={"IK" + listedStudent.id + "_" + kit.id}
															>
																{kit.name}
															</div>
														)
													}
													{
														listedStudent.extraKits.lenght < 1 ? "" : listedStudent.extraKits.map
														(
															(kit : any, kitIndex : number) => <div key={"EXK" + listedStudent.id + "_" + kit.id}
															  className=
															  {
																"badge rounded-pill " +
																(kit.toBeDeleted ? "btn-danger" : "btn-primary")
															  }
															>
																<button disabled={idleLocked} className={"btn btn-default"}
																  type="button" onClick=
																  {
																	()=>
																	{
																		setEditableEnrollment
																		({
																			...editableEnrollment, students : editableEnrollment.students.map
																			(
																				(student : any)=>
																				({
																					...student, extraKits:
																					(
																						student.id != student.id ? student.extraKits : student.extraKits.map
																						(
																							(extraKit : any, extraKitIndex : number)=>
																							({
																								...extraKit, toBeDeleted :
																								(
																									extraKitIndex != kitIndex ? extraKit.toBeDeleted :
																										(extraKit.toBeDeleted == null ? true : !extraKit.toBeDeleted)
																								)
																							})
																						)
																					)
																				})
																			)
																		})
																	}
																  }
																>
																	<FontAwesomeIcon icon=
																	  {kit.toBeDeleted != true ? solid("times") : solid("undo")}
																	/>
																</button>
																{kit.name}
															</div>
														)
													}
													{
														listedStudent.availableKits.length < 1 ? "(Sin registros)" : <Multiselect
														  displayValue="name"
														  disable={idleLocked}
														  placeholder="Seleccione"
														  options={listedStudent.availableKits}
														  selectedValues={listedStudent.editableKits}
														  onSelect=
														  {
															(kits)=>
															{
																setEditableEnrollment
																({
																	...editableEnrollment, students : editableEnrollment.students.map
																	(
																		(student : any)=>
																		({
																			...student, editableKits : student.id != listedStudent.id 
																				? student.editableKits
																					: kits
																		})
																	)
																})
															}
														  } onRemove=
														  {
															(kits)=>
															{
																setEditableEnrollment
																({
																	...editableEnrollment, students : editableEnrollment.students.map
																	(
																		(student : any)=>
																		({
																			...student, editableKits : student.id != listedStudent.id 
																				? student.editableKits
																					: kits
																		})
																	)
																})
															}
														  }
														  emptyRecordMsg="No hay más opciones"
														/>
													}
												</td>
												<td>{
													(listedStudent.tuitionInvoiceIds.length > 0 || listedStudent.invoicedKits.lenght > 0)
															?
														""
															:
														<div style={{display : "flex"}}><button className="button btn btn-default"
														  style={{display : "table-column"}} onClick={() => listRemoval(index)}
														  disabled={idleLocked} type="button"
														>
															<FontAwesomeIcon icon={solid("times")} />
														</button></div>
												}</td>
											</tr>
										)
									}</tbody>
									<tbody className="uncommonTable">{/*Newly added students*/
										editableEnrollment.students.filter((student : any) => student).map
										(
											(listedStudent : any, index : number) => enrollmentAdding[listedStudent.refIndex] == null ? "" : <tr key={"user" + index}>
												<td><button disabled={idleLocked} onClick={() => toggleListSelection(listedStudent)}
												  className="btn btn-default" type="button"
												>
													<CustomIcon name=
													  {checkIcon[ +(enrollmentCheck[listedStudent.refIndex] != null)]}
													/>
												</button></td>
												<td>
													{listedStudent.id}
												</td>
												<td style={{width : "1px"}}>
													<img className="detailPicture" src= {listedStudent.image != null ? listedStudent.image : userImage}/>
												</td>
												<td>
													{listedStudent.first_name || "-"}
												</td>
												<td>
													{listedStudent.last_name || "-"}
												</td>
												<td>
													{listedStudent.student?.identity_code || "-"}
												</td>
												<td>
													{listedStudent.email}
												</td>
												<td>
													{
														props.selectedEnrollment.level.kits.length < 1 ? "(Sin registros)" :
														  <Multiselect placeholder="Seleccione" displayValue="name"
														  emptyRecordMsg="No hay más opciones" selectedValues={listedStudent.editableKits}
														  options={props.selectedEnrollment.level.kits} onSelect=
														  {
															(kits)=>
															{
																setEditableEnrollment
																({
																	...editableEnrollment, students : editableEnrollment.students.map
																	(
																		(student : any)=>
																		({
																			...student, editableKits : student.id != listedStudent.id 
																				? student.editableKits
																					: kits
																		})
																	)
																})
															}
														  }
														  onRemove=
														  {
															(kits)=>
															{
																setEditableEnrollment
																({
																	...editableEnrollment, students : editableEnrollment.students.map
																	(
																		(student : any)=>
																		({
																			...student, editableKits : student.id != listedStudent.id
																				? student.editableKits
																					: kits
																		})
																	)
																})
															}
														  }
														/>
													}
												</td>
												<td><div style={{display : "flex"}}><button disabled={idleLocked}
													  style={{display : "table-column"}} className="button btn btn-default"
													  type="button" onClick={() => listRemoval(index)}
													>
														<FontAwesomeIcon icon={solid("times")}/>
												</button></div></td>
											</tr>
										)
									}</tbody>
									<tbody className="bg-danger tableDanger">{/*students marked for deletion*/
										editableEnrollment.students.filter((student :  any) => student).map
										(
											(student : any, index : number) => enrollmentDeletion[student.refIndex] == null ? "" : <tr key={"user" + index}>
												<td />
												<td>
													{student.id}
												</td>
												<td style={{width : "1px"}}>
													<img className="detailPicture" src={student.image != null ? student.image : userImage} />
												</td>
												<td>
													{student.first_name || "-"}
												</td>
												<td>
													{student.last_name || "-"}
												</td>
												<td>
													{student.student?.identity_code || "-"}
												</td>
												<td>
													{student.email}
												</td>
												<td>
													{
														student.invoicedKits.lenght < 1 ? "" : student.invoicedKits.map
														(
															(kit : any) => <div className="badge rounded-pill bg-primary"
															  key={"IK" + student.id + "_" + kit.id}
															>
																{kit.name}
															</div>
														)
													}
													{
														student.extraKits.lenght < 1 ? "" : student.extraKits.map
														(
															(kit : any) => <div key={"EXK" + student.id + "_" + kit.id}
															  className=
															  {
																"badge rounded-pill " +
																	(kit.toBeDeleted ? "bg-primary" : "bg-danger")
															  }
															>
																{kit.name}
															</div>
														)
													}
													{
														student.editableKits.lenght < 1 ? "" : student.editableKits.map
														(
															(kit : any) => <div className="badge rounded-pill bg-primary"
															  key={"EK" + student.id + "_" + kit.id}
															>
																{kit.name}
															</div>
														)
													}
												</td>
												<td><div style={{display : "flex"}}><button className="button btn btn-default"
												  onClick={() => undoListRemoval(student.refIndex)} style={{display : "table-column"}}
												  disabled={idleLocked} type="button"
												>
													<FontAwesomeIcon icon={solid("trash-arrow-up")} />
												</button></div></td>
											</tr>
										)
									}</tbody>
								</table>
							</>
						}
					</>
			}</div>
		}
	</fieldset></div></form></DataDisplayModal>
}

export default EnrollmentPrompt
