import axios
	from "axios"
import store
	from "./../../globals"

import {isNotEmpty}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class InvoicesService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchInvoices(campusId : number, search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/invoices" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	updateInvoice(campusId : number, studentId : number, conceptListing? : any, invoice? : any)
	{
		let payload  : FormData = new FormData()
		let mainData : any      = {}

		if(invoice)
		{
			if(conceptListing)
			{
				for(let concepts of ["owedTuitions", "owedArticles"])
				{
					if(conceptListing[concepts] && conceptListing[concepts].length > 0)
					{
						mainData[concepts] = JSON.stringify(conceptListing[concepts])
					}
				}
			}

			if(isNotEmpty(invoice.notes))
			{
				mainData.notes = invoice.notes
			}
		}

		Object.keys(mainData).map((key : string) => {payload.append(key, mainData[key])})

		if(invoice.file_ref)
		{
			Object.keys(invoice.file_ref).map
				((key : string) => {payload.append("fileRef[" + key + "]", invoice.file_ref[key])})
		}

		return axios.post
		(
			RESOURCE_URL + campusId + "/students/" + studentId + "/invoices/" + invoice.id + "?_method=PUT",
			payload,
			{
				headers:
				{
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then(response => (response));
	}

	downloadAttachmentFile
		(campusId : number,studentId : number, invoiceId : any, filename : string)
	{
		return axios.get
		(
			RESOURCE_URL + campusId + "/students/" + studentId + "/invoices/" + invoiceId + "/_fileAccess/" + filename,
			{
				responseType : "arraybuffer",
				headers:
				{
					Authorization : "Bearer " + this.sessionToken,
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async refundInvoice
	(
		campusId : number, studentId : number, conceptListing? : any, refundReason? : string,
		invoice? : any
	)
	{
		let payload  : FormData = new FormData()
		let mainData : any      = {}

		if(invoice)
		{
			if(conceptListing)
			{
				for(let concepts of ["owedTuitions", "owedArticles"])
				{
					if(conceptListing[concepts] && conceptListing[concepts].length > 0)
					{
						mainData[concepts] = JSON.stringify(conceptListing[concepts])
					}
				}
			}

			if(isNotEmpty(invoice.notes))
			{
				mainData.notes = invoice.notes
			}
		}

		Object.keys(mainData).map((key : string) => {payload.append(key, mainData[key])})

		payload.append("reason", refundReason ?? "")

		if(invoice.file_ref)
		{
			Object.keys(invoice.file_ref).map
				((key : string) => {payload.append("fileRef[" + key + "]", invoice.file_ref[key])})
		}

		const response = await axios.post
		(
			RESOURCE_URL + campusId + "/students/" + studentId + "/invoices/" + invoice.id + "/_cancelling",
			payload,
			{
				headers:
				{
					Authorization  : "Bearer " + this.sessionToken,
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data"
				}
			}
		)

		return response
	}

	async invoicePDF(campusId : number, studentId : number, invoice? : any)
	{
		try
		{
			const response = await axios.get
			(
				RESOURCE_URL + campusId + "/students/" + studentId + "/invoices/" + invoice.id + "/_pdf",
				{
					responseType : "blob",// Set the responseType to 'blob' to receive a binary file
					headers:
					{
						Accept        : "application/pdf",
						Authorization : "Bearer " + this.sessionToken,
					}
				}
			)

			return response
		}
		catch(error)
		{
			console.error("Error downloading PDF:", error)

			return null
		}
	}
}

export default new InvoicesService()