import axios
	from "axios"
import store
	from "./../globals"

import {isNotEmpty, removeRecords}
	from "./../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campuses";

class CampusesService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getCampuses(expand? : string | null)
	{
		let params = [];

		if(expand)
		{
			params.push("expand=" + expand)
		}

		return axios.get
		(
			RESOURCE_URL + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveCampus(campus : any)
	{
		let payload : FormData = new FormData()
		const saveFunction     = campus.id == null ? this.registerCampus : this.updateCampus

		let record : any=
		{
			cityId              : campus.city.id,
			statusId            : campus.status_id,
			name                : campus.name,
			code                : campus.code,
			studentIdPrefix     : campus.student_id_prefix,
			morningShiftName    : campus.morning_shift_name,
			afternoonShiftName  : campus.afternoon_shift_name,
			eveningShiftName    : campus.evening_shift_name,
			afternoonShiftStart : campus.afternoonInterval[0],
			eveningShiftStart   : campus.afternoonInterval[1],
			links               : JSON.stringify
			({
				web        : campus.web        || "",
				googleMaps : campus.googleMaps || "",
				facebook   : campus.facebook   || "",
				instagram  : campus.instagram  || ""
			})
		}

		for(let field of ["address", "observations", "zipcode", "email", "phone1", "phone2"])
		{
			if(isNotEmpty(campus[field]))
			{
				record[field] = campus[field] + ""
			}
		}

		Object.keys(record).map((key : string) => {payload.append(key, record[key])})

		if(campus.image)
		{
			payload.append("image", campus.image == campus.imagePreview ? "" :campus.image)
		}

		return saveFunction
		(
			payload,
			{
				headers:
				{
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			campus.id
		)
	}

	async registerCampus(registry : any, options : any)
	{
		return axios.post(RESOURCE_URL, registry, options)
	}

	async updateCampus(registry : any, options : any, id : number)
	{
		return axios.post(RESOURCE_URL + "/" + id + "?_method=PUT", registry, options)
	}

	async removeCampus(recordId : number)
	{
		return removeRecords(RESOURCE_URL, [recordId])
	}
}

export default new CampusesService()