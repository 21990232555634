import React, {useEffect, useState}
	from "react"
import {Link}
	from "react-router-dom"
import Chart
	from "react-apexcharts"
import {ApexOptions}
	from "apexcharts"
import {useDispatch, useSelector}
	from "react-redux"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid, regular}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {sprintf}
	from "sprintf-js"
import Hashids
	from "hashids"

import {hashCode, monthRef, weekDays, retardWeekDayOffset, attendanceStatus}
	from "./../../common"
import StatusCard
	from "./../../components/statusCard"
import Table
	from "./../../components/table"
import Badge
	from "./../../components/badge"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice, dismissNotice
}
	from "./../../globals"

import AlumniService
	from "./../../services/alumni.service"

import loginLogoA
	from "./../../assets/images/loginLogoA.png"
import loginLogoB
	from "./../../assets/images/loginLogoB.png"

interface GenericKV
{
	[key : string] : any;
}

const chartOptions : {series : {name : string; data : number[]}[]; options : ApexOptions}=
{
	series:
	[
		{
			name : "Clientes en línea",
			data : [40, 70, 20, 90, 36, 80, 30, 91, 60, 0]
		},
		{
			name : "Clientes de tienda",
			data : [40, 30, 70, 80, 40, 16, 40, 20, 51, 10]
		}
	],
	options:
	{
		colors     : ["#6AB04C", "#2980B9"],
		chart      : {background : "transparent"},
		dataLabels : {enabled    : false},
		stroke     : {curve      : "smooth"},
		xaxis      : {categories : ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct"]},
		legend     : {position   : "bottom"},
		grid       : {show       : true}
	}
};

const topCustomers=
{
	head:
	[
		"Usuario",
		"Total de órdenes",
		"Total gastado"
	],
	body:
	[
		{
			"username" : "john doe",
			"order"    : "490",
			"price"    : "$15,870"
		},
		{
			"username" : "frank iva",
			"order"    : "250",
			"price"    : "$12,251"
		},
		{
			"username" : "anthony baker",
			"order"    : "120",
			"price"    : "$10,840"
		},
		{
			"username" : "frank iva",
			"order"    : "110",
			"price"    : "$9,251"
		},
		{
			"username" : "anthony baker",
			"order"    : "80",
			"price"    : "$8,840"
		}
	]
};

const renderCustomerHead = (item : string, index : number)=>
(
	<th key={index}>
		{item}
	</th>
);

const renderCustomerBody = ({username, order, price} : {username : string; order : number; price : number}, index : number)=>
(
	<tr key={index}>
		<td style={{width : "100%"}}>
			{username}
		</td>
		<td style={{textAlign : "right"}}>
			{order}
		</td>
		<td style={{textAlign : "right"}}>
			{price}
		</td>
	</tr>
);

const latestOrders=
{
	header:
	[
		"Id orden",
		"Usuario",
		"Precio total",
		"Fecha",
		"Estatus"
	],
	body:
	[
		{
			id     : "#OD1711",
			user   : "john doe",
			date   : "17 Jun 2021",
			price  : "$900",
			status : "Enviando"
		},
		{
			id     : "#OD1712",
			user   : "frank iva",
			date   : "1 Jun 2021",
			price  : "$400",
			status : "Pagado"
		},
		{
			id     : "#OD1713",
			user   : "anthony baker",
			date   : "27 Jun 2021",
			price  : "$200",
			status : "Pendiente"
		},
		{
			id     : "#OD1712",
			user   : "frank iva",
			date   : "1 Jun 2021",
			price  : "$400",
			status : "Pagado"
		},
		{
			id     : "#OD1713",
			user   : "anthony baker",
			date   : "27 Jun 2021",
			price  : "$200",
			status : "Devolución"
		}
	]
}

const orderStatus : GenericKV=
{
	"Enviando"   : "primary",
	"Pendiente"  : "warning",
	"Pagado"     : "success",
	"Devolución" : "danger"
}

const renderOrderHead = (item : string, index : number)=>
(
	<th key={index}>
		{item}
	</th>
);

const renderOrderBody=
(
	{id, user, price, date, status} : {id : number; user : string; price : number; date : string; status : string},
	index : number
)=>
(
	<tr key={index}>
		<td>
			{id}
		</td>
		<td>
			{user}
		</td>
		<td style={{textAlign : "right"}}>
			{price}
		</td>
		<td style={{textAlign : "right"}}>
			{date}
		</td>
		<td>
			<Badge type={orderStatus[status]} content={status}/>
		</td>
	</tr>
);

const Dashboard = ()=>
{
	const dispatch                     = useDispatch()
	const themeToggle : boolean        = useSelector((state : RootState) => state.themeToggle.value);
	const idleLock    : boolean        = useSelector((state : RootState) => state.idleLockSwitch.value);
	const userProfile : any            = useSelector((state : RootState) => state.userProfile.value)
	const sessionToken : string | null = useSelector((state : RootState) => state.sessionToken.value)
	const [schedules, setSchedules]    = useState<any>([])

	const statusCards=
	[
		{
			"icon"  : solid("bag-shopping"),
			"count" : "1,995",
			"title" : "Ventas totales"
		},
		{
			"icon"  : solid("cart-shopping"),
			"count" : "2,001",
			"title" : "Visitas diarias"
		},
		{
			"icon"  : solid("sack-dollar"),
			"count" : "$2,632",
			"title" : "Ingreso total"
		},
		{
			"icon"  : solid("file-invoice"),
			"count" : "1,711",
			"title" : "Órdenes totales"
		}
	];
	
	const initError = ()=>
	{
		dispatch
		(
			displayNotice
			({
				heading:
					<h3 style={{color : "#FA0000", display : "inline-block"}}>
						Error
					</h3>,
				message     : "Tu información no pudo ser cargada",
				cornerClose : false
			})
		)
	}

	useEffect
	(
		()=>
		{
			const init = async()=>
			{
				if(userProfile.currentRole == "student")
				{
					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await AlumniService.getCurrentEnrollmentInfo(sessionToken)

						if(result.status == 200 || result.status == 204)
						{
							let   datePart          : string
							let   ymd               : any
							let   monthDateCounting : any
							let   formattedSchedule : any
							let   monthPart         : string
							let   attendanceIndex   : any    = {}
							let   dateListing       : any    = {}
							let   attendanceCount   : number = 0
							const hashids                    = new Hashids()

							setSchedules
							(
								result.data.map
								(
									(schedule : any)=>
									{
										attendanceCount   = 0
										monthDateCounting = {}

										formattedSchedule=
										{
											...schedule, color      : sprintf
											(
												"#%02X%02X%02X",
												(hashCode(hashids.encode(schedule.id)) & 255),
												(hashCode(schedule.level.name        ) & 255),
												(hashCode(schedule.level.course.name ) & 255)
											),
											attendances : schedule.attendances.map
											(
												(attendance : any)=>
												{
													datePart  = attendance.datetime.split(" ")[0]
													ymd       = datePart.split("-")
													monthPart = monthRef[parseInt(ymd[1]) - 1]

													dateListing[datePart]=
														weekDays[retardWeekDayOffset[(new Date(attendance.datetime)).getDay()]]
															.substring(0, 2) + ymd[2]

													if(monthDateCounting[monthPart] == null)
													{
														monthDateCounting[monthPart] = 0
													}

													monthDateCounting[monthPart]++

													if(attendance.status_id != attendanceStatus.ABSENT)
													{
														attendanceCount++
													}

													attendanceIndex[datePart + "S_" + schedule.id] = attendance.status_id

													return{
														...attendance, datePart : datePart,
														day                     : weekDays[retardWeekDayOffset[(new Date(attendance.datetime)).getDay()]]
															.substring(0, 2) + ymd[2]
													}
												}
											)
										}

										return {
											...formattedSchedule, attendanceCount : attendanceCount,
											monthDaycount                         : monthDateCounting,
										}
									}
								)
							)
						}
						else
						{
							initError()
						}
					}
					catch(error)
					{
						console.log(error)
						initError()
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			init()
		},
		[]
	)

	return <div style={{padding : "30px"}}>{
		userProfile.currentRole == "student"
				?
			(
				schedules.length < 1
					?
				<div style={{padding : "25px", textAlign : "center"}}>
					<div style={{display : "inline-block"}}>
						<div style={{display : "inline-block", width : "100%"}}>
							<img src={loginLogoA} style={{maxWidth : "100%"}} />
						</div>
						&emsp;
						<div style={{display : "inline-block", width : "100%"}}>
							<img src={loginLogoB} style={{maxWidth : "100%"}} />
						</div>
					</div>
					<div>
						<br />
						<br />
						<br />
						Te damos la bienvenida.
					</div>
				</div>
					:
				<>
					<h3>
						Horarios
					</h3>
					{
						schedules.map
						(
							(schedule : any, index : number) => <div className="card card-default" style={{paddingTop : "unset"}} key={"S_" + index}
							>
								<div style={{background : schedule.color, width : "100%", height : "30px", borderRadius : "0px 0px 15px 15px"}} />
								<div className="row" style={{paddingTop : "15px"}}><div className="col-sm-7"><div style={{display : "flex", flexWrap : "wrap"}}>
									<div style={{borderBottom : "1px solid #FA0000", flex : 1}}><table style={{width : "100%"}}>
										<tbody>
											<tr>
												<td rowSpan={2} style={{color : "#8F91DA"}}><div
												  style={{width : "60px", height : "60px"}}
												><div style={{position : "absolute", width : "60px", height: "75px", background : "#FFFFFF"}}>
													<FontAwesomeIcon size="3x" icon={solid("list-check")}/>
												</div></div></td>
												<td style={{height : "25px"}}><label style={{color : "#8F91DA"}}>
													Asistencias
												</label></td>
											</tr>
											<tr><td><div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
												<div style={{flex : 1}}><h5 style={{fontWeight : "unset", color : "#8F91DA"}}>
													{schedule.level.course.name}
													&nbsp;
													{schedule.level.name}
												</h5></div>
												<div>
													<div style={{display : "inline-flex"}}>
														<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
															Horario
														</label>
														{schedule.name}
													</div>
													<div style={{display : "inline-flex"}}>
														<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
															Aula
														</label>
														{schedule.classroom.name}
													</div>
												</div>
											</div></td></tr>
										</tbody>
									</table></div>
								</div></div></div>
								{
									schedule.attendances.length < 1
											?
										<>
											<br />
											- Sin registro de asistencias -
										</>
											:
										<div style={{overflow : "auto"}}>
											<table className="specialTable">
												<thead><tr>
													{
														schedule.attendances.map
														(
															(attendance : any, index : number)=> <th key={"S_" + attendance.date} style={{width : "1px"}} colSpan={1}>
																{attendance.day}
															</th>
														)
													}
													<th style={{width : "1px"}}>
														Total
													</th>
													<th style={{width : "1px", textAlign : "center"}} colSpan={1}>
														%
													</th>
												</tr></thead>
												<tbody><tr>
													{
														schedule.attendances.map
														(
															(attendance : any)=>
															<td key={"SA_" + attendance.day} className="text-center">
																<FontAwesomeIcon
																  icon=
																  {
																	attendance.status_id == attendanceStatus.COMPENSATION
																			?
																		solid("bandage")
																			:
																		(
																			attendance.status_id == attendanceStatus.ABSENT
																					?
																				regular("square-minus")
																					:
																				regular("square-check")
																		)
																  }
																/>
															</td>
														)
													}
													<td className="text-end"><div style={{display : "flex"}}>
														<div style={{flex : 1}}>
															{schedule.attendanceCount}
														</div>
														<div style={{padding : "0px 5px"}}>
															/
														</div>
														<div>
															{schedule.attendances.length}
														</div>
													</div></td>
													<td className="text-end" style={{textAlign : "right"}}
													  ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}
													><div style={{display : "flex"}}>
														<div style={{flex : 1}}>
															{~~((schedule.attendanceCount / schedule.attendances.length) * 100)}
														</div>
														<div style={{paddingLeft : "5px"}}>
															%
														</div>
													</div></td>
												</tr></tbody>
											</table>
										</div>
								}
							</div>
						)
					}
				</>
			)
				:
		<div style={{textAlign : "center"}}><div style={{display : "inline-block"}}>
			<div style={{display : "inline-block", width : "100%"}}>
				<img src={loginLogoA} style={{maxWidth : "100%"}} />
			</div>
			&emsp;
			<div style={{display : "inline-block", width : "100%"}}>
				<img src={loginLogoB} style={{maxWidth : "100%"}} />
			</div>
		</div></div>
	}</div>
};

export default Dashboard;
