import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"

import {isNotEmpty, removeRecords}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/";

class CoursesService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchCourses(campus : any, search? : any, expand? : number, page? : number)
	{
		let params = [];

		if(search)
		{
			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(search.levelStatusId >= 0)
			{
				params.push("levelStatusId=" + search.levelStatusId)
			}

			if(search.scheduleStatusId >= 0)
			{
				params.push("scheduleStatusId=" + search.scheduleStatusId)
			}

			if(search.modeId >= 0)
			{
				params.push("modeId=" + search.modeId)
			}

			if(isNotEmpty(search.tagIds))
			{
				params.push("tagIds=" + search.tagIds.join(","))
			}

			if(search.categoryId > 0)
			{
				params.push("categoryId=" + search.categoryId)
			}

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}
		}

		if(expand && expand > 0)
		{
			params.push("expand=" + expand)
		}

		if(page)
		{
			params.push("page=" + page)
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + "campus/" +campus.id + "/courses" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveCourse(campus : any, course : any)
	{
		let payload : FormData = new FormData()
		let record  : any=
		{
			name       : course.name,
			code       : course.code,
			modeId     : course.mode_id,
			categoryId : course.category_id,
			statusId   : course.status_id,
			tagIds     : _.map(course.tags, "id").join(",")
		}

		if(isNotEmpty(course.description))
		{
			record.description = course.description
		}

		for(let field of ["description"])
		{
			if(isNotEmpty(course[field]))
			{
				record[field] = course[field] + ""
			}
		}

		Object.keys(record).map((key : string) => {payload.append(key, record[key])})

		if(course.image)
		{
			payload.append("image", course.image == course.imagePreview ? "" : course.image)
		}

		return axios.post
		(
			RESOURCE_URL + "campus/" +campus.id + "/courses" + (course.id == null ? "" : "/" + course.id + "?_method=PUT"),
			payload,
			{
				headers:
				{
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
	}

	async bindCoursesToCampus(campusId : number, recordIds : any)
	{		
		return axios.put
		(
			RESOURCE_URL+ "campus/" + campusId + "/courses/_link",
			{ids : recordIds.join(",")},
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async removeCourses(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL+ "campus/" + campusId + "/courses", recordIds)
	}
}

export default new CoursesService()