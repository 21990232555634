import axios
	from "axios"
import store
	from "./../../globals"

import {isNotEmpty, removeRecords}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class TaxesService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getTaxes(campusId : number)
	{
		return axios.get
		(
			RESOURCE_URL + campusId + "/taxes",
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	saveTax(campusId : number, tax : any)
	{
		const saveFunction = tax.id == null ? this.registerTax : this.updateTax

		let record : any=
		{
			name : tax.name + "",
			code : tax.code + ""
		}

		if(isNotEmpty(tax.description))
		{
			record.description = tax.description
		}

		return saveFunction
		(
			record,
			campusId,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			tax.id
		)
	}

	async registerTax(registry : any, campusId : number, options : any)
	{
		return axios.post(RESOURCE_URL+ campusId + "/taxes", registry, options)
	}

	async updateTax(registry : any, campusId : number, options : any, id : number)
	{
		return axios.put(RESOURCE_URL+ campusId + "/taxes" + "/" + id, registry, options)
	}

	async removeTaxes(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL + campusId + "/taxes", recordIds)
	}
}

export default new TaxesService()