import
{
	useState,
	useRef,
	useEffect
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"
import
{
	UIState,
	pageGrouping,
	getAccessPermissions,
	isEmpty
}
	from "./../../common"
import Constants,
{
	classroomStatus,
	tunning
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer
	from "./../../components/sectionContainer"
import ClassroomPrompt
	from "./../../components/classroomPrompt"
import CatalogService
	from "./../../services/catalog.service"
import SimpleDataService
	from "./../../services/simpleData.service"
import BuildingsService
	from "./../../services/campus/buildings.service"
import ClassroomsService
	from "./../../services/campus/classrooms.service"

import "./../../components/dropdown/style.css"

const Classrooms = ()=>
{
	const NO_SETUP           = 0
	const CATEGORIES         = 1
	const CLASSROOM_STATUSES = 2
	const ENDING             = 3

	const classrooms     : any[]                          = []
	const [categories, setCategories]                     = useState<any[]>([])
	const dispatch                                        = useDispatch()
	const [classroomStatuses, setClassroomStatuses]       = useState<any>([])
	const [initSetup, setInitSetup]                       = useState<number | null>(null)
	const records      : any[]                            = classrooms.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [data, setData]                                 = useState<any[]>(records)
	const idleLocked   : boolean                          = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                    = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean                          = useSelector((state : RootState) => state.themeToggle.value)
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value)
	const currentUser       : any                         = useSelector((state : RootState) => state.userProfile.value)
	const [userCampus, setUserCampus]                     = useState<number>(currentUserCampus)
	const [buildings, setBuildings]                       = useState<any>([])
	const [noCategoriesProvided, setNoCategoriesProvided] = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]             = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus]                         = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [newClassroom, setNewClassroom]                 = useState<any>(null)
	const [selectedClassroom, setSelectedClassroom]       = useState<any>(null)
	const [editableClassroom, setEditableClassroom]       = useState<any>(null)
	const [pagination, setPagination]                     = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                   = useState<boolean>(false)
	const [saveError, setSaveError]                       = useState<any>(null)
	const [recordsSelection, setRecordsSelection]         = useState<any>([])
	const count        : number[]                         = [-1, 1]
	const allowed      : any                              = getAccessPermissions(currentUser)
	let   recordFormRef                                         = useRef<HTMLFormElement >(null)

	const unedit = ()=>
	{
		if(editableClassroom && editableClassroom.id == null)
		{
			setSelectedClassroom(null)
		}

		setEditableClassroom(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setSaveError(null)

		if(recordData.id == null)
		{
			setEditableClassroom(recordData)
		}

		setSelectedClassroom(recordData)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if(editableClassroom && editableClassroom.categories.length < 1)
				{
					if(!unfilledFields && !noCategoriesProvided)
					{
						setUnfilledFields(true)
						setNoCategoriesProvided(true)

						setTimeout
						(
							() =>
							{
								setUnfilledFields(false)
								setNoCategoriesProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(saveError != null)
					{
						setSaveError(null)
					}

					setUIStatus(UIState.LOCKED)
					dispatch(enableIdleLockSwitch())

					ClassroomsService.saveClassroom(campusRef[currentUserCampus].id, editableClassroom).then
					(
						()=>
						{
							setSaveSuccess(true)
							setUIStatus(UIState.SUCCESS)

							setTimeout
							(
								()=>
								{
									setSelectedClassroom(null)
									setSaveSuccess(false)
									setUIStatus(UIState.NORMAL)
									search()
								},
								tunning.MODAL_DISMISS_DELAY
							)
						},
						(error : any)=>
						{
							console.log(error)
							setUIStatus(UIState.ERROR)
							dispatch(disableIdleLockSwitch())
							setSaveError
							(
								error.response.status != 409 ? "La información no pudo ser guardada" : "Hay conflictos en la información proporcionada (nombre). " +
									"Revise que los valores sean únicos en comparacióncon los registros existentes"
							)
						}
					)
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const catalogRetrievalError : string = "El catálogo de categorías de aulas no pudo ser extraído"

					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await SimpleDataService.getRecords("categories")

						if(result.status == 200 || result.status == 204)
						{
							setCategories(result.data.sort())

							setInitSetup(CATEGORIES)
						}
						else
						{
							procedureComplaint(catalogRetrievalError)
						}
					}
					catch(error)
					{
						procedureComplaint(catalogRetrievalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case CATEGORIES:
						if(categories.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint("No hay categorías para registro, por lo que el acceso a este módulo permanecerá deshabilitado")
						}
						else
						{
							const statusRetrievalError : string = "El catálogo de estatuses de aulas no pudo ser extraído"

							try
							{
								const result = await CatalogService.getClassroomStatuses(sessionToken)

								if(result.status == 200 || result.status == 204)
								{
									setClassroomStatuses(result.data)
									setInitSetup(CLASSROOM_STATUSES)
								}
								else
								{
									procedureComplaint(statusRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(statusRetrievalError)
							}
							finally
							{
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case CLASSROOM_STATUSES:
						if(classroomStatuses.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint("No hay estatuses de aula registrados, por lo que el acceso a este módulo permanecerá deshabilitado")
						}
						else
						{
							const buildingRetrievalError : string = "El catálogo de edificios no pudo ser extraído"

							try
							{
								const query  : string = ""
								const expand : string = "classrooms"
								const result : any    = await BuildingsService.searchBuildings(campusRef[currentUserCampus].id, query, expand)

								if(result.status == 200 || result.status == 204)
								{
									if(result.data.length < 1)
									{
										dispatch(disableIdleLockSwitch())
										procedureComplaint("No hay edificios registrados, por lo que el acceso a este módulo permanecerá deshabilitado")
										setInitSetup(NO_SETUP)
									}
									else
									{
										setBuildings(result.data)
										setInitSetup(ENDING)
									}
								}
								else
								{
									procedureComplaint(buildingRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(buildingRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ENDING:
						setNewClassroom
						({
							name              : "",
							building_id       : buildings[0].id,
							status_id         : classroomStatus.ACTIVE,
							session_min_hours : 1,
							students_limit    : 0,
							categories        : [],
							description       : ""
						})

						search()
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if(currentUserCampus && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setCustomSearch(defaultSearch)
				setSectionLoaded(false)
				setPagination(null)
				setBuildings([])
				localStorage.setItem("currentCampus", currentUserCampus)
				setSectionLoaded(false)
				setUserCampus(currentUserCampus)
				setInitSetup(CLASSROOM_STATUSES)
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo ser completada"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await ClassroomsService.searchClassrooms(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setData(result.data.data.map((record : any, index : number)=>{return {selected : false, data : record}}))
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		);
	};

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		);

		setSelectedRecords(totalRecords * (+selected))
	};

	const closePrompt = ()=>
	{
		setSelectedClassroom(null)
		setEditableClassroom(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string = "No se pudieron eliminar algunos registros. " +
						"Puede que la información en otra sección dependa de estos datos, por lo que no será posible realizar por completo la operación"

					const recordIds : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await ClassroomsService.removeClassrooms(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  allowed={allowed}	
		  saveDataFn={saveRecord}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  allowedUpdate={allowed.update}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  searchFn={search} pagination={pagination}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newClassroom)}
		>{
			(data.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					{Constants.noCampusSearchResults}
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="campusesHeader">
					{
						!allowed.delete ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll} type="button"
						  disabled={idleLocked || saveSuccess}
						>
							{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
						</button></th>
					}
					<th>
						id
					</th>
					<th>
						Nombre
					</th>
					<th>
						Edificio
					</th>
					<th>
						Categorías
					</th>
					<th>
						Horas mínimas/sesión
					</th>
					<th>
						Cupo límite
					</th>
					<th>
						Descripción
					</th>
					<th />
				</tr></thead>
				<tbody>{
					data.map
					(
						(record : any, index : number) => <tr key={"campus" + index}>
							{
								!allowed.delete ? "" : <td><button className="btn btn-default" disabled={idleLocked} type="button"
								  onClick={() => toggleRecordSelection(index)}
								>
									{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
								</button></td>
							}
							<td>
								{record.data.id}
							</td>
							<td>
								{record.data.name}
							</td>
							<td>
								{(record.data.building && record.data.building.name) || "-"}
							</td>
							<td>{
								isEmpty(record.data.categories) ? "-" : record.data.categories.map
								(
									(item : any, index : number) => <div className="badge rounded-pill bg-primary" key={"crc_" + item.id}>
										{item.name}
									</div>
								)
							}</td>
							<td>
								{record.data.session_min_hours}
							</td>
							<td>
								{record.data.students_limit}
							</td>
							<td>
								{record.data.description || "-"}
							</td>
							<td><div style={{display : "flex"}}>
								<button className="button btn btn-default" disabled={idleLocked}
								  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
								  type="button" onClick={() => showPrompt(record.data)}
								>
									<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
								</button>
								{
									!allowed.delete ? "" : <button className="button btn btn-default" disabled={idleLocked}
									  onClick={() => confirmRemoval(record.data.id)} style={{display : "table-column"}}
									>
										<CustomIcon name="bTrash" />
									</button>
								}
							</div></td>
						</tr>
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			selectedClassroom == null ? "" : <ClassroomPrompt closeFn={() => setSelectedClassroom(null)} categories={categories}
			  buildings={buildings} selectedClassroom={selectedClassroom} classroomStatuses={classroomStatuses} allowed={allowed}
			  resetFn=
			  {
				()=>
				{
					setSelectedClassroom(null)
					setSaveSuccess(false)
					setUIStatus(UIState.NORMAL)
					search()
				}
			  }
			/>
		}
	</>
}

export default Classrooms
