import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"

import {isNotEmpty, removeRecords}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/"

class BuildingsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchBuildings(campusId : number, search? : any, expand? : string | null, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(expand)
		{
			params.push("expand=" + expand)
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/buildings" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveBuilding(campusId : number, building : any)
	{
		const saveFunction = building.id == null ? this.registerBuilding : this.updateBuilding

		let record : any = {name : building.name + ""}

		if(isNotEmpty(building.description))
		{
			record.description = building.description + ""
		}

		return saveFunction
		(
			campusId,
			record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			building.id
		)
	}

	async registerBuilding(campusId: number, registry : any, options : any)
	{
		return axios.post(RESOURCE_URL + campusId + "/buildings", registry, options)
	}

	async updateBuilding(campusId: number, registry : any, options : any, id : number)
	{
		return axios.put(RESOURCE_URL  + campusId + "/buildings/" + id, registry, options)
	}

	async removeBuildings(campusId: number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL + campusId + "/buildings", recordIds)
	}
}

export default new BuildingsService()