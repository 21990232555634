import axios
	from "axios"
import store
	from "./../../globals"

import {isNotEmpty, removeRecords, toAcceptableDate}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class PeriodsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchPeriods(campusId : number, search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(search)
		{
			if(search.startValue)
			{
				params.push("start=" + toAcceptableDate(search.startValue))
				params.push("end="   + toAcceptableDate(search.endValue))
			}
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/periods" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	savePeriod(campusId : number, period : any)
	{
		const saveFunction = period.id == null ? this.registerPeriod : this.updatePeriod

		let record : any=
		{
			name  : period.name + "",
			end   : toAcceptableDate(period.endValue),
			start : toAcceptableDate(period.startValue)
		}

		if(isNotEmpty(period.description))
		{
			record.description = period.description
		}

		return saveFunction
		(
			campusId,
			record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			period.id
		)
	}

	async registerPeriod(campusId : number, registry : any, options : any)
	{
		return axios.post(RESOURCE_URL + campusId + "/periods" , registry, options)
	}

	async updatePeriod(campusId : number, registry : any, options : any, id : number)
	{
		return axios.put(RESOURCE_URL + campusId + "/periods/" + id, registry, options)
	}

	async removePeriods(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL + campusId + "/periods", recordIds)
	}
}

export default new PeriodsService()