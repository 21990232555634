import {useState}
	from "react"

import CustomIcon
	from "../customIcon"

import "./style.css"

const Table = (props: any)=>
{
	const hasCheckBox             = props.hasCheckBox || false
	const styles                  = props.styles || { tableRow: null, tableHeader: null }
	const initDataShow            = props.limit && props.bodyData ? props.bodyData.slice(0, Number(props.limit)) : props.bodyData
	const [dataShow, setDataShow] = useState(initDataShow)

	let pages : number   = 1
	let range : number[] = []

	if(props.limit !== undefined)
	{
		let page = Math.floor(props.bodyData.length / Number(props.limit));
		pages    = props.bodyData.length % Number(props.limit) === 0 ? page : page + 1;
		range    = [...Array(pages).keys()];
	}

	const [currPage, setCurrPage] = useState(0)
	const selectPage              = (page : number) =>
	{
		const start               = Number(props.limit) * page;
		const end                 = start + Number(props.limit);

		setDataShow(props.bodyData.slice(start, end));
		setCurrPage(page);
	}

	return<div>
		<div className="tableWrapper">
			<table className="customTable" style={styles.tableData}>
				{
					props.headData && props.renderHead ? (
						<thead>
							<tr >
								{hasCheckBox ?
									<th>
										<button className="btn btn-default"
											disabled={false} type="button" >
											{<CustomIcon name = {"bUncheck"} />}
										</button>
									</th>
									: null}
								{props.headData.map((item : any, index : number) => props.renderHead(item, index))}
							</tr>
						</thead>
					) : null
				}
				{
					props.bodyData && props.renderBody ? (
						<tbody>{
							dataShow.map
							(
								(item: any, index: number)=>
								{ 
									// console.log(index);
									return props.renderBody(item, index)
								}
							)
						}</tbody>
					) : null
				}
			</table>
		</div>
		{
			pages < 2 ? null : <div className="tablePagination">{
				range.map((item: number, index: number) => (
					<div key={index} className={`tablePaginationItem ${currPage === index ? 'active' : ''}`} onClick={() => selectPage(index)}>
						{item + 1}
					</div>
				))
			}</div>
		}
	</div>
}

export default Table