import axios
	from "axios"
import store
	from "./../../globals"

import { isNotEmpty, removeRecords }
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/"

class FinancesService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	config       = ()=>
	{
		return{
			headers :
			{
				"Content-Type" : "application/json",
				Authorization  : "Bearer " + this.sessionToken
			}
		};
	}

	async searchOwedTuitions(campusId : number, search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(search)
		{
			if(isNotEmpty(search.scheduleId))
			{
				params.push("scheduleId=" + search.scheduleId)
			}
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/owedTuitions" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type": "application/json",
					Authorization: "Bearer " + this.sessionToken
				}
			}
		).then(response => (response));
	}

	async searchOwedArticles(campusId : number, search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(search)
		{
			if(isNotEmpty(search.q))
			{
				params.push("q=" + search.q)
			}

			if (isNotEmpty(search.scheduleId)) {
				params.push("scheduleId=" + search.scheduleId)
			}
		}

		return axios.get
			(
				RESOURCE_URL + campusId + "/owedArticles" + (params.length > 0 ? "?" + params.join("&") : ""),
				{
					headers:
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + this.sessionToken
					}
				}
			)
			.then(response => (response));
	}

	async getStudentGeneralBalance(campusId : number, studentId : number)
	{
		// let headers = {
		// 	"Content-Type": "application/json",
		// 	Authorization: "Bearer " + this.sessionToken
		// };
		let uri            = RESOURCE_URL + campusId + "/students/" + studentId + "/_generalBalance";
		let generalBalance = await axios.get(uri, this.config());
		return generalBalance;
	}

	async removeOwedArticles(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL + campusId + "/owedArticles", recordIds)
	}

	async removeOwedTuitions(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL + campusId + "/owedTuitions", recordIds)
	}
}

export default new FinancesService()