import Constants from "./../../constants"

const boldText=
{
	font         : Constants.Styles.text.fonts.robotoBold16,
	marginBottom : 0
}

const boldInfoText=
{
	font         : Constants.Styles.text.fonts.robotoBold28,
	color        : Constants.Styles.text.colors.gray,
	marginBottom : 0,
	marginRight  : 10
}

const boldPendientPaymentText=
{
	font         : Constants.Styles.text.fonts.robotoBold28,
	color        : Constants.Styles.text.colors.red,
	marginBottom : 0,
	marginRight  : 10
}

const header = {paddingLeft: 10}

const subHeader=
{
	display      : "flex",
	alignItems   : "flex-start",
	marginBottom : 10,
	paddingLeft  : 20
}

const subtitle=
{
	font         : Constants.Styles.text.fonts.robotoNormal12,
	color        : Constants.Styles.text.colors.gray,
	opacity      : 1,
	marginBottom : 0
};

const generalSubtitle=
{
	font         : Constants.Styles.text.fonts.robotoBold16,
	color        : Constants.Styles.text.colors.gray,
	opacity      : 1,
	marginBottom : 0
}

const pendientPaymentText=
{
	color        : Constants.Styles.text.colors.red,
	font         : Constants.Styles.text.fonts.robotoBold16,
	padding      : 0,
	borderBottom : 0
}

const generalBalanceContainer=
{
	width          : "28%",
	display        : "flex",
	alignItems     : "baseline",
	justifyContent : "center",
	gap            : 20
}

const generalSubContainer=
{
	width          : "100%",
	display        : "flex",
	alignItems     : "baseline",
	justifyContent : "space-between",
	gap            : 10
}

const generalBalanceText=
{
	color        : Constants.Styles.text.colors.blackPrimary,
	font         : Constants.Styles.text.fonts.robotoBold20,
	marginBottom : 0
}

const subtitleContainer=
{
	display        : "flex",
	alignItems     : "center",
	justifyContent : "flex-start",
	gap            : 10
}

const checkBoxDisabled=
{
	padding : 0,
	border  : "none",
	opacity : 0.3
}

const checkBoxEnabled=
{
	padding : 0,
	border  : "none",
	opacity : 1
}

const itemText=
{
	color: Constants.Styles.text.colors.blackPrimary,
	font : Constants.Styles.text.fonts.robotoNormal16
}

const checkContainer=
{
	display        : "flex",
	justifyContent : "center",
	alignItems     : "baseline"
}

const separator=
{
	width           : "100%",
	height          : 1,
	backgroundColor : Constants.Styles.text.colors.blackPrimary,
	opacity         : 1,
	margin          : 0
}

const descriptionContainer=
{
	display        : "flex",
	justifyContent : "center",
	alignItems     : "center"
}

const courseInformation=
{
	width          : "100%",
	display        : "flex",
	justifyContent : "flex-start",
	padding        : 10
}

const columnTextContainer=
{
	display        : "flex",
	width          : "100%",
	justifyContent : "space-between",
	paddingLeft    : 35
}

const columnText=
{
	font         : Constants.Styles.text.fonts.robotoBold16,
	color        : Constants.Styles.text.colors.gray,
	opacity      : 1,
	marginBottom : 0
}

const titleSection=
{
	font         : Constants.Styles.text.fonts.robotoBold16,
	color        : Constants.Styles.text.colors.darkBlue,
	opacity      : 1,
	marginBottom : 0
}

const mainBtnContainer=
{
	height         : "40%",
	width          : "100%",
	display        : "flex",
	justifyContent : "center"
}

const buttonContainer=
{
	width          : 220,
	marginTop      : 30,
	display        : "flex",
	justifyContent : "center",
	alignItems     : "center",
	gap            : 20,
}

const totalContainer=
{
	display    : "flex",
	alignItems : "center"
}

const warningTextContainer=
{
	display   : "flex",
	alignItems: "center"
}

const buttonsContainer=
{
	display : "flex",
	width   : "100%",
	gap     : 10
}

const totalPayment=
{
	background   : Constants.Styles.background.darkRed,
	width        : "100%",
	borderRadius : 20,
	border       : "2px solid " + Constants.Styles.text.colors.redLine,
	padding      : "5px 40px",
	font         : Constants.Styles.text.fonts.robotoMedium16,
	color        : Constants.Styles.text.colors.white
}

const totalPaymentHover=
{
	background   : Constants.Styles.background.red,
	width        : "100%",
	borderRadius : 20,
	border       : "2px solid " + Constants.Styles.text.colors.redLine,
	padding      : "5px 40px",
	font         : Constants.Styles.text.fonts.robotoMedium16,
	color        : Constants.Styles.text.colors.white
}

const partialPayment=
{
	background   : Constants.Styles.background.white,
	width        : "100%",
	borderRadius : 20,
	border       : "2px solid " + Constants.Styles.text.colors.redLine,
	padding      : "5px 40px",
	font         : Constants.Styles.text.fonts.robotoMedium16,
	color        : Constants.Styles.text.colors.redLine
}

const partialPaymentHover=
{
	background   : Constants.Styles.background.red,
	width        : "100%",
	borderRadius : 20,
	border       : "2px solid " + Constants.Styles.text.colors.redLine,
	padding      : "5px 40px",
	font         : Constants.Styles.text.fonts.robotoMedium16,
	color        : Constants.Styles.text.colors.white
}

const warningText=
{
	width  : "100%",
	color  : Constants.Styles.text.colors.redLine,
	font   : Constants.Styles.text.fonts.robotoNormal12,
	margin : 0
}

const showModal=
{
	/*width     : 500,*/
	height    : "100vh",
	boxShadow : "-5px 0px 6px 0px rgba(61,61,61,1)"
}

const hideModal = {}

const modalBody=
{
	height       : "100%",
	padding      : 20,
	borderRadius : 0
}

const btnCloseModal=
{
	top          : 15,
	right        : 15,
	borderRadius : "100%",
	width        : 20,
	height       : 20,
	border       : "1px solid " + Constants.Styles.background.redButtonClose,
	color        : Constants.Styles.text.colors.redButtonClose,
	background   : Constants.Styles.background.transparent
}

const tableHeader=
{
	color        : Constants.Styles.text.colors.gray,
	font         : Constants.Styles.text.fonts.robotoBold16,
	borderBottom : 0,
	width        : "auto",
	marginBottom : 5
}

const tableRow=
{
	color : Constants.Styles.text.colors.gray,
	font  : Constants.Styles.text.fonts.robotoMedium16
}

const tableDataContainer=
{
	borderBottom : 0,
	padding      : 0
}

const tableData=
{
	color        : Constants.Styles.text.colors.blackPrimary,
	font         : Constants.Styles.text.fonts.robotoNormal12,
	borderBottom : 0,
	padding      : 0
}

export const styles=
{
	boldText, subtitle, subHeader, boldInfoText, generalSubtitle, subtitleContainer, pendientPaymentText, generalBalanceText,
	generalSubContainer, generalBalanceContainer, boldPendientPaymentText, checkBoxEnabled, checkBoxDisabled, itemText,
	checkContainer, separator, courseInformation, columnText, descriptionContainer, header, columnTextContainer, mainBtnContainer,
	buttonContainer, totalContainer, warningTextContainer, buttonsContainer, totalPayment, totalPaymentHover, partialPayment,
	partialPaymentHover, warningText, showModal, hideModal, modalBody, btnCloseModal, tableDataContainer, tableData, tableRow,
	tableHeader, titleSection
}