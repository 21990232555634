import axios
	from "axios"
import store
	from "./../globals"
import {isNotEmpty}
	from "./../common"
import _
	from "lodash"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/assignments";

class AssignmentsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchAssignments(search? : any, page? : number, resultsLimit? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}
		else
		{
			if(resultsLimit)
			{
				params.push("limit=" + resultsLimit)
			}
		}

		if(search)
		{
			if(search.tagIds && search.tagIds.length > 0)
			{
				params.push("tagIds=" +  search.tagIds.join(","))
			}
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveAssignment(course : any)
	{
		const saveFunction = course.id == null ? this.registerAssignment : this.updateAssignment

		let record : any=
		{
			title                        : course.title,
			points                       : course.points,
			statusId                     : course.status_id,
			typeId                       : course.type_id,
			generalDescription           : course.general_description,
			boolAnswerAttachmentRequired : course.bool_answer_attachment_required,
			tagIds                       : _.map(course.tags, "id").join(",")
		}

		if(isNotEmpty(course.description))
		{
			record.description = course.description
		}

		return saveFunction
		(
			record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			course.id
		)
	}

	async registerAssignment(registry : any, options : any)
	{
		return axios.post
		(
			RESOURCE_URL,
			registry,
			options
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async updateAssignment(registry : any, options : any, id : number)
	{
		return axios.put
		(
			RESOURCE_URL + "/" + id,
			registry,
			options
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async removeAssignments(recordIds : any)
	{
		return axios.delete
		(
			RESOURCE_URL + "/" + (recordIds.length < 2 ? recordIds[0] : ""),
			{
				data : {ids : recordIds.join(",")},
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}
}

export default new AssignmentsService()