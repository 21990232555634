import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash";
import { sprintf }
	from "sprintf-js"

import { isEmpty, isNotEmpty, toAcceptableDate }
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/"

class PaymentsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchPayments(campusId: number, search?: any, page?: number, reporting?: boolean)
	{
		let params = [];

		if(!reporting && page)
		{
			params.push("page=" + page)
		}

		if(search)
		{
			if (search.student && search.student.id)
			{
				params.push("studentId=" + search.student.id)
			}

			if (search.employee && search.employee.id)
			{
				params.push("employeeId=" + search.employee.id)
			}

			if (search.method && search.method.id)
			{
				params.push("methodId=" + search.method.id)
			}

			if (search.account && search.account.id)
			{
				params.push("accountId=" + search.account.id)
			}

			if (isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if (isNotEmpty(search.amountBalanceA) && !isNaN(search.amountQuantityA))
			{
				params.push("amountBalanceA=" + search.amountBalanceA)
				params.push("amountQuantityA=" + search.amountQuantityA)

				if (isNotEmpty(search.amountBalanceB) && !isNaN(search.amountQuantityB))
			{
					params.push("amountBalanceB=" + search.amountBalanceB)
					params.push("amountQuantityB=" + search.amountQuantityB)
				}
			}

			if (isNotEmpty(search.creationValueA) || isNotEmpty(search.creationValueB))
			{
				params.push
					(
						"creationDate=" +
						(
							(search.creationValueA == search.creationValueB || isEmpty(search.creationValueB))
								?
								toAcceptableDate(search.creationValueA)
								:
								(
									isEmpty(search.creationValueA) ? toAcceptableDate(search.creationValueB) :
										(
											search.creationValueA > search.creationValueB
												?
												toAcceptableDate(search.creationValueA) + "," + toAcceptableDate(search.creationValueB)
												:
												toAcceptableDate(search.creationValueB) + "," + toAcceptableDate(search.creationValueA)
										)
								)
						)
					)
			}

			if (isNotEmpty(search.paymentValueA) || isNotEmpty(search.paymentValueB))
			{
				params.push
				(
					"paymentDate=" +
					(
						(search.paymentValueA == search.creationValueB || isEmpty(search.paymentValueB))
								?
							toAcceptableDate(search.paymentValueA)
								:
							(
								isEmpty(search.paymentValueA) ? toAcceptableDate(search.paymentValueB) :
								(
									search.paymentValueA > search.paymentValueB
											?
										toAcceptableDate(search.paymentValueB) + "," + toAcceptableDate(search.paymentValueA)
											:
										toAcceptableDate(search.paymentValueA) + "," + toAcceptableDate(search.paymentValueB)
								)
							)
					)
				)
			}
		}

		if (isNotEmpty(search?.q)) {
			params.push("q=" + search.q)
		}

		const heading: any =
		{
			headers:
			{
				"Content-Type": "application/json",
				Authorization: "Bearer " + this.sessionToken
			}
		}

		if (reporting) {
			heading.responseType = "blob"
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/payments" + (!reporting ? "" : "/_report") + (params.length > 0 ? "?" + params.join("&") : ""),
			heading
		)
		.then(response => (response));
	}

	processPayment
		(campusId: number, studentId: number, payment: any, conceptListing: any, invoice: any, onlyInvoice: boolean = false)
	{
		let payload: FormData = new FormData()
		let mainData: any = !onlyInvoice ? {
			invoiceListing: JSON.stringify(conceptListing),
			amount: sprintf("%.02f", payment.amount),
			referenceNumber: payment.reference_number,
			methodId: payment.method.id,
			accountId: payment.account.id
		}
			: {
				invoiceListing: JSON.stringify(conceptListing)
			}

		if (!onlyInvoice) {
			if (!payment.method.bool_payment_date_unmodifiable) {
				mainData.date = payment.id ? payment.payment_date : toAcceptableDate(payment.dateValue)
			}

			if (!payment.method.bool_payment_reference_nullified) {
				mainData.referenceNumber = payment.reference_number
			}

			if (isNotEmpty(payment.notes)) {
				mainData.paymentNotes = payment.notes
			}
		}

		if (invoice && isNotEmpty(invoice.notes)) {
			mainData.invoiceNotes = invoice.notes
		}

		Object.keys(mainData).map((key: string) => { payload.append(key, mainData[key]) })

		if (payment.file_ref) {
			Object.keys(payment.file_ref).map
				((key: string) => { payload.append("paymentFileRef[" + key + "]", payment.file_ref[key]) })
		}

		if (invoice.file_ref) {
			Object.keys(invoice.file_ref).map
				((key: string) => { payload.append("invoiceFileRef[" + key + "]", invoice.file_ref[key]) })
		}

		return axios.post
			(
				RESOURCE_URL + campusId + "/students/" + studentId +
				((invoice == null || invoice.id == null) ? "" : "/invoices/" + invoice.id) + "/payments" +
				(onlyInvoice ? "?onlyInvoice=" + true : ""),
				payload,
				{
					headers:
					{
						Accept: "application/json, text/plain, */*",
						"Content-Type": "multipart/form-data",
						Authorization: "Bearer " + this.sessionToken
					}
				}
			)
			.then(response => (response));
	}

	removePayment(campusId: number, studentId: number, payment: any, conceptListing?: any, invoice?: any)
	{
		let payload  : FormData = new FormData()
		let mainData : any = {}

		if(invoice)
		{
			if(conceptListing)
			{
				for(let concepts of ["owedTuitions", "owedArticles"])
				{
					if(conceptListing[concepts] && conceptListing[concepts].length > 0)
					{
						mainData[concepts] = JSON.stringify(conceptListing[concepts])
					}
				}
			}

			if(isNotEmpty(invoice.notes))
			{
				mainData.notes = invoice.notes
			}
		}

		Object.keys(mainData).map((key: string) => { payload.append(key, mainData[key]) })

		if(invoice && invoice.file_ref)
		{
			Object.keys(invoice.file_ref).map((key: string) => {payload.append("fileRef[" + key + "]", invoice.file_ref[key])})
		}

		return axios.post
		(
			RESOURCE_URL + campusId + "/students/" + studentId +
			((invoice == null || invoice.id == null) ? "" : "/invoices/" + invoice.id) + "/payments/" + payment.id + "?_method=DELETE",
			payload,
			{
				headers:
				{
					Accept: "application/json, text/plain, */*",
					"Content-Type": "multipart/form-data",
					Authorization: "Bearer " + this.sessionToken
				}
			}
		).then(response => (response))
	}

	downloadAttachmentFile(campusId: number, studentId: number, payment: any, filename: string)
	{
		return axios.get
		(
			RESOURCE_URL + campusId + "/students/" + studentId + "/invoices/" + payment.invoice_id + "/payments/" + payment.id +
			"/_fileAccess/" + filename,
			{
				responseType: "arraybuffer",
				headers:
				{
					Authorization: "Bearer " + this.sessionToken,
				}
			}
		).then(response => { return response })
	}
}

export default new PaymentsService()
