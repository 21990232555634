import React,
	{
		useState,
		useRef,
		useReducer,
		useEffect,
		FormEvent
	}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import
{
	solid,
	regular
}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import {Tooltip}
	from "react-bootstrap"
import OverlayTrigger
	from "react-bootstrap/OverlayTrigger"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"
import
{
	UIState,
	accessMode,
	checkIcon,
	pageGrouping,
	validateField,
	getAccessPermissions,
	baseRole
}
	from "./../../common"
import Constants,
{
	tunning,
	grant,
	mgmtLimit
}
	from "./../../constants"
import UnderlineHeading
	from "./../../components/underlineHeading"
import
	SectionContainer,
	{DataDisplayModal}
	from "./../../components/sectionContainer"
import CustomIcon
	from "./../../components/customIcon"
import RolesService
	from "./../../services/roles.service"

import "./../../components/dropdown/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action  = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{...state, isButtonDisabled : action.payload}
		break;
	}
}

const Roles = ()=>
{
	const periods     : any[] = []
	const [state, trigger]                                    = useReducer(reducer, initialState)
	const dispatch                                            = useDispatch()
	const records      : any[]                                = periods.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                     = useState<number>(records.length)
	const [data, setData]                                     = useState<any[]>([])
	const [readOnlyData, setReadOnlyData]                     = useState<any[]>([])
	const idleLocked   : boolean                              = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                        = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean                              = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any                                  = useSelector((state : RootState) => state.basicInfo.value)
	const currentUser  : any                                  = useSelector((state : RootState) => state.userProfile.value)
	const navigation   : any                                  = useSelector((state : RootState) => state.navigation.value)
	const [sectionLoaded, setSectionLoaded]                   = useState<boolean>(false)
	const [permissionEmphasis, setPermissionEmphasis]         = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]               = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                 = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested]     = useState<boolean>(false)
	const [UIStatus, setUIStatus]                             = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]               = useState<number>(0)
	const [defaultSearch, setDefaultSearch]                   = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                     = useState<any>(defaultSearch)
	const [newRole, setNewRole]                               = useState<any>(null)
	const [selectedRole, setSelectedRole]                     = useState<any>(null)
	const [editableRole, setEditableRole]                     = useState<any>(null)
	const [pagination, setPagination]                         = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                       = useState<boolean>(false)
	const [saveError, setSaveError]                           = useState<any>(null)
	const [recordsSelection, setRecordsSelection]             = useState<any>([])
	const count        : number[]                             = [-1, 1]
	let   navigationRef : any                                 = {}
	let   recordFormRef                                       = useRef<HTMLFormElement >(null)
	let   nameRef                                             = useRef<HTMLInputElement>(null)
	let   descriptionRef                                      = useRef<HTMLTextAreaElement>(null)
	const allowed      : any                                  = getAccessPermissions(currentUser)

	navigation.map((section : any) => navigationRef[section.path] = true)

	const unedit = ()=>
	{
		if(editableRole && editableRole.id == null)
		{
			setSelectedRole(null)
		}

		setEditableRole(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setSaveError(null)

		let permitMap : any = {};

		recordData.permissions.map
		(
			(permission : any)=>
			{
				permitMap[permission.section.code] = {section : permission.section_id}

				Object.keys(accessMode).map
				(
					(key : string)=>
					{
						permitMap[permission.section.code][key] = (permission.binary_quintet_RCUDN_permissions & accessMode[key]) > 0
					}
				)
			}
		)

		let record=
		{
			...recordData, permitMap : permitMap,
			description              : recordData.description || ""
		}

		if(record.id == null)
		{
			setEditableRole(record)
		}

		setSelectedRole(record)
	}

	const toggleSectionPermission = (section : any, mode : string)=>
	{
		let role = editableRole

		if(mode == "READ" && (editableRole.permitMap[section.code] && editableRole.permitMap[section.code][mode]))
		{
			delete role.permitMap[section.code]
		}
		else
		{
			if(role.permitMap[section.code] == null)
			{
				role.permitMap[section.code] = {section : section.id, [mode] : true}

				if(mode == "READ")
				{
					role.permitMap[section.code].NAVIGATE = true
				}
			}
			else
			{
				role.permitMap[section.code][mode] = !role.permitMap[section.code][mode]
			}
		}

		setEditableRole({...editableRole, permitMap : role.permitMap})
	}

	const saveRecord = ()=>
	{
		if(!recordFormRef?.current?.checkValidity())
		{
			if(!unfilledFields)
			{
				setUnfilledFields(true)
				recordFormRef?.current?.reportValidity()

				setTimeout(() => setUnfilledFields(false),3000)
			}
		}
		else
		{
			if(Object.keys(editableRole.permitMap).length < 1)
			{
				if(!unfilledFields && !permissionEmphasis)
				{
					setUnfilledFields(true)
					setPermissionEmphasis(true)

					setTimeout
					(
						()=>
						{
							setPermissionEmphasis(false)
							setUnfilledFields(false)
						},
						3000
					)
				}
			}
			else
			{
				let errorPrompt : boolean = false

				if(saveError)
				{
					setSaveError(null)
				}

				setUIStatus(UIState.LOCKED)
				dispatch(enableIdleLockSwitch())

				RolesService.saveRole(editableRole).then
				(
					()=>
					{
						setSaveSuccess(true)
						setUIStatus(UIState.SUCCESS)

						setTimeout
						(
							()=>
							{
								setCustomSearch(defaultSearch)
								setSelectedRole(null)
								setEditableRole(null)
								setSaveSuccess(false)
								setUIStatus(UIState.NORMAL)
								search()
							},
							tunning.MODAL_DISMISS_DELAY
						)
					},
					(error : any)=>
					{
						console.log(error)
						setUIStatus(UIState.ERROR)
						dispatch(disableIdleLockSwitch())
						setSaveError
						(
							error.response.status == 409
									?
								"Hay conflictos en la información proporcionada (nombre). " +
									"Revise que los valores sean únicos en comparación con los registros existentes"
									:
								"La información no pudo ser guardada"
						)
					},
				)
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch
			({
				...customSearch,
				[e.target.name] : e.target.value
			})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableRole({...editableRole, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				setNewRole
				({
					name               : "",
					code               : "",
					description        : "",
					permissions        : []
				})
			}

			search()
		},
		[]
	)

	useEffect(()=>{setTotalRecords(data.length)}, [data])

	useEffect
	(
		() =>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=> trigger
		({
			type    : "setIsButtonDisabled",
			payload : (idleLocked || UIStatus == UIState.SUCCESS)
		}),
		[UIStatus, idleLocked]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		)
	}

	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await RolesService.searchRoles(customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))

				let normalData       : any     = []
				let disabledData     : any     = []
				let atLeastOneMentor : boolean = false

				result.data.data.map
				(
					(record : any)=>
					{
						if(record.management_limitation > 0)
						{
							disabledData.push(record)
						}
						else
						{
							normalData.push
							({
								selected : false,
								data     : record
							})
						}
					}
				)

				setReadOnlyData(disabledData)
				setData(normalData)
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		);

		setSelectedRecords(totalRecords * (+selected))
	};

	const closePrompt = ()=>
	{
		setSelectedRole(null)
		setEditableRole(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string = "El comando no pudo ser procesado"
					const recordIds : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await RolesService.removeRoles(recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  addingPromptFn={() => showPrompt(newRole)}
		  clearSearchRequested={clearSearchRequested}
		>{
			(data.length < 1 && readOnlyData.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					{Constants.noSearchResults}
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="periodsHeader">
					{
						data.length < 1 || !allowed.delete ? "" : <th><button onClick={toggleSelectAll} type="button"
						  disabled={idleLocked || saveSuccess} className="btn btn-default"
						>
							{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
						</button></th>
					}
					<th>
						id
					</th>
					<th>
						Nombre
					</th>
					<th>
						Descripción
					</th>
					<th />
				</tr></thead>
				<tbody>
					{
						readOnlyData.map
						(
							(record : any, index : number) => <tr key={"user" + index}>
								{data.length < 1 || !allowed.delete ? "" : <td />}
								<td ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}>
									{record.id}
								</td>
								<td>
									{record.name}
								</td>
								<td>
									{record.description || "-"}
								</td>
								<td>
									<div style={{display : "flex"}}>
										<button
										  type="button"
										  disabled={idleLocked}
										  className="button btn btn-default"
										  onClick={() => showPrompt({...record, description : record.description || ""})}
										  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
										>
											<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
										</button>
									</div>
								</td>
							</tr>
						)
					}
					{
						data.map
						(
							(record : any, index : number) => <tr key={"user" + index}>
								{
									!allowed.delete ? "" : <td><button className="btn btn-default" disabled={idleLocked} type="button"
									  onClick={() => toggleRecordSelection(index)}
									>
										{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
									</button></td>
								}
								<td>
									{record.data.id}
								</td>
								<td>
									{record.data.name}
								</td>
								<td>
									{record.data.description || "-"}
								</td>
								<td><div style={{display : "flex"}}>
									<button disabled={idleLocked} onClick={() => showPrompt(record.data)}
									  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
									  className="button btn btn-default" type="button"
									>
										<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
									</button>
									{
										!allowed.delete ? "" : <button className="button btn btn-default" style={{display : "table-column"}}
										  onClick={() => confirmRemoval(record.data.id)} disabled={idleLocked}
										>
											<CustomIcon name="bTrash" />
										</button>
									}
								</div></td>
							</tr>
						)
					}
				</tbody>
			</table>
		}</SectionContainer>
		{
			selectedRole == null ? "" : <DataDisplayModal saveDataFn={saveRecord} uneditFn={unedit} unfilledFields={unfilledFields}
			  dataEditInit={() => setEditableRole(selectedRole)} saveLock={state.isButtonDisabled} processingError={saveError}
			  allowedUpdate={!selectedRole.bool_read_only && allowed.update} editableRecord={editableRole} UIStatus={UIStatus}
			  headIcon={<CustomIcon name="role" />} saveSuccess={saveSuccess} promptCloseFn={closePrompt} className="form"
			  form={recordFormRef} entityName="Rol" unchangedRecord={unchangedRecord}
			><form ref={recordFormRef} onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			>
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="inputContainer">
								<label htmlFor="name">
									{editableRole == null ? "" : "*"}
									Nombre
								</label>
								{
									editableRole == null || editableRole.id == baseRole.MENTOR
										?
									<div className="roView">
										<br />
										{selectedRole.name}
									</div>
										:
									<input onChange={handleRegistryChange} placeholder="Nombre" ref={nameRef} type="text" maxLength={50}
									  style={{width : "100%"}} value={editableRole.name} disabled={idleLocked} onBlur={validateField}
									  onFocus={validateField} id="name" name="name" required
									/>
								}
							</div>
						</div>
						{
							(selectedRole == null || selectedRole.id == null) ? "" : <div className="col-md-6">
								<div className="inputContainer">
									<label htmlFor="name">
										Código
									</label>
									<div className="roView">
										<br />
										{selectedRole.id}
									</div>
								</div>
							</div>
						}
					</div>
				</div>
				<br />
				<UnderlineHeading name={"Permisos"} />
				<label style={{position : "relative"}}>
					Habilite al menos un permiso
				</label>
				<div style={{position : "relative"}}>
					{
						!permissionEmphasis ? "" : <div className="usageEmphasis">
							<h4 style={{color : "#FFFFFF", marginTop : "45%"}}>
								[!] Requerido:
								<br />
								Otorgue al menos un permiso.
							</h4>
						</div>
					}
					<table style={{width : "100%"}}>
						<tbody><tr style={{borderBottom : "1px solid #000000"}}>
							<th className="firstColumn">
								Módulo
							</th>
							<th style={{textAlign : "center"}}>
								Ver
							</th>
							<th style={{textAlign : "right"}}>
								Crear
							</th>
							<th style={{textAlign : "center"}}>
								Actualizar
							</th>
							<th>
								Eliminar
							</th>
							<th>
								Acceso
							</th>
						</tr></tbody>
						{
							basicInfo.groups.map
							(
								(group : any) => <tbody key={"g_" + group.id}>
									<tr>
										<th colSpan={6}>
											{group.name}
										</th>
									</tr>
									{
										group.sections.filter((section : any) => navigationRef[section.code]).map
										(
											(section : any) => <React.Fragment key={"s_" + section.id}>
												<tr className="descriptiveRow">
													<td colSpan={6} >
														{section.name}
													</td>
												</tr>
												<tr>
													<td className="firstColumn">
														{section.name}
													</td>
													{
														Object.keys(accessMode).map
														(
															(mode : any, index : number) => <td
															  style={{textAlign : "center"}}
															  key={"g_" + group.id + "s_" + section.id + "mode_" + accessMode[mode]}
															>{
																editableRole == null
																		?
																	(
																		(
																			selectedRole.permitMap[section.code] == null ||
																			!selectedRole.permitMap[section.code][mode]
																		)
																			? "" : <FontAwesomeIcon icon={solid("check")}/>
																	)
																		:
																	(
																		section.management_limitation > mgmtLimit.NONE
																			&&
																		(
																			(
																				section.management_limitation == mgmtLimit.DISPLAY_ONLY
																					&&
																				(index == grant.CREATE || index == grant.UPDATE || index == grant.DELETE)
																			)
																				||
																			(
																				section.management_limitation == mgmtLimit.DISPLAY_AND_UPDATE
																					&&
																				(index == grant.CREATE || index == grant.DELETE)
																			)
																		)
																				?
																			""
																				:
																			<button
																			  type="button"
																			  className="btn btn-default"
																			  onClick={() => toggleSectionPermission(section, mode)}
																			  disabled=
																			  {
																				idleLocked ||
																				(
																					index > 0 &&
																					(
																						editableRole.permitMap[section.code] == null ||
																							editableRole.permitMap[section.code]["READ"] != true
																					)
																				)
																			  }
																			>
																				<CustomIcon name=
																				  {
																					checkIcon
																					[
																						+
																						(
																							editableRole.permitMap[section.code] != null &&
																							editableRole.permitMap[section.code][mode] == true
																						)
																					]
																				  }
																				/>
																			</button>
																	)
																}</td>
														)
													}
												</tr>
											</React.Fragment>
										)
									}
								</tbody>
							)
						}
					</table>
				</div>
				<div style={{paddingTop : "25px"}}>
					<UnderlineHeading name={"Descripción"} />
					<div style={{paddingTop : "3px"}}>{
						editableRole == null ? (selectedRole.description || "-") : <textarea
						  maxLength={255}
						  id="description"
						  name="description"
						  ref={descriptionRef}
						  disabled={idleLocked}
						  className="notesField"
						  onBlur={validateField}
						  placeholder="Descripción"
						  onChange={handleRegistryChange}
						  value={editableRole.description}
						/>
					}</div>
				</div>
			</form></DataDisplayModal>
		}
	</>
}

export default Roles
