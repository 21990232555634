import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import Multiselect
	from "multiselect-react-dropdown"
import {Popover}
	from "react-tiny-popover"
import
{
	Tab,
	Tabs
}
	from "react-bootstrap"
import LeDatePicker
	from "../../components/leDatePicker"
import {SelectReact}
	from "../../components/select"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch
}
	from "./../../globals"
import
{
	buttonClickHandling,
	isEmpty,
	imageTypes,
	monthRef,
	toAscendingDate,
	UIState,
	validateField,
	weekDays,
	getErrorMessage
}
	from "./../../common"
import {tunning}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import {DataDisplayModal}
	from "./../../components/sectionContainer"
import UnderlineHeading
	from "./../../components/underlineHeading"
import StudentsService
	from "./../../services/campus/students.service"
import defaultUserImage
	from "./../../assets/images/user.png"
import defaultCourseImage
	from "./../../assets/images/course.png"

import "./../../components/dropdown/style.css"
import "./../../components/input/style.css"

type  State               = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action=
{
	type    : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch (action.type){case "setIsButtonDisabled" : return{...state, isButtonDisabled : action.payload}}
}

const StudentInfoModal = (props : any)=>
{
	const {selectedStudent, allowedUpdate, setSelectedStudent, academicDegrees, closeFn, afterCloseFn} = props

	let   fileReader : any
	const [state, trigger]                                  = useReducer(reducer, initialState)
	const dispatch                                          = useDispatch()
	const idleLocked        : boolean                       = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null                 = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean                       = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo         : any                           = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                           = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                           = useSelector((state : RootState) => state.campusRef.value)
	const [editableStudent, setEditableStudent]             = useState<any>(null)
	const studentStatuses  : any                            = Object.values(basicInfo.studentStatusRef)
	const [unfilledMainData, setUnfilledMainData]           = useState<boolean>(false)
	const [wrongValueOnFields, setWrongValueOnFields]       = useState<boolean>(false)
	const [noCityProvided, setNoCityProvided]               = useState<boolean>(false)
	const [noContactsProvided, setNoContactsProvided]       = useState<boolean>(false)
	const [wrongContactsProvided, setWrongContactsProvided] = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]               = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]             = useState<boolean>(false)
	const [moduleReady, setModuleReady]                     = useState<boolean>(false)
	const [saveSuccess, setSaveSuccess]                     = useState<boolean>(false)
	const [saveError, setSaveError]                         = useState<any>(null)
	const [currentTab, setCurrentTab]                       = useState<string | null>("mainInfo")
	const [UIStatus, setUIStatus]                           = useState<number>(UIState.NORMAL)
	let   recordFormRef               : any                 = useRef<HTMLFormElement>(null)
	let   contactFormRef         : any                      = useRef<HTMLFormElement>(null)
	let   correlativoClase365Ref : any                      = useRef<HTMLInputElement>(null)
	let   firstNameRef           : any                      = useRef<HTMLInputElement>(null)
	let   lastNameRef            : any                      = useRef<HTMLInputElement>(null)
	let   zipcodeRef             : any                      = useRef<HTMLInputElement>(null)
	let   imageRef               : any                      = useRef<HTMLInputElement>(null)
	let   observationsRef        : any                      = useRef<HTMLTextAreaElement>(null)
	let   countrySelRef          : any                      = useRef<HTMLSelectElement>(null)
	let   stateSelRef            : any                      = useRef<HTMLSelectElement>(null)
	let   stateNameRef           : any                      = useRef<HTMLInputElement>(null)
	let   cityNameRef            : any                      = useRef<HTMLInputElement>(null)
	let   nationalitySelRef      : any                      = useRef<HTMLSelectElement>(null)
	let   addressRef             : any                      = useRef<HTMLInputElement>(null)
	let   emailRef               : any                      = useRef<HTMLInputElement>(null)
	let   identificationCodeRef  : any                      = useRef<HTMLInputElement>(null)
	let   passwordRef            : any                      = useRef<HTMLInputElement>(null)
	let   phone1Ref              : any                      = useRef<HTMLInputElement>(null)
	let   phone2Ref              : any                      = useRef<HTMLInputElement>(null)
	let   lastSchoolNameRef      : any                      = useRef<HTMLInputElement>(null)
	let   currentOccupationRef   : any                      = useRef<HTMLInputElement>(null)
	let   tabDivRef              : any                      = useRef<HTMLDivElement>(null)
	const linkInfo               : any=
	{
		facebook  : "Facebook",
		instagram : "Instagram"
	}

	const unedit = ()=>
	{
		if(editableStudent && editableStudent.id == null)
		{
			closeFn()
		}

		setEditableStudent(null)
	}

	const editStudent = ()=>
	{
		setEditableStudent
		({
			...selectedStudent, nationality_id : selectedStudent.nationality_id || basicInfo.countries[0].id,
			academic_degree_id                 : selectedStudent.academic_degree_id || academicDegrees[0].id
		})
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)

					if(currentTab == "mainInfo")
					{
						recordFormRef.current.reportValidity()
					}
					else
					{
						tabDivRef?.current?.focus()
						setUnfilledMainData(true)
					}

					setTimeout
					(
						() =>
						{
							setUnfilledFields(false)
							setUnfilledMainData(false)
						},
						3000
					)
				}
			}
			else
			{
				if
				(
					(
						basicInfo.countryRef["C_" + editableStudent.country_id].states.length > 0 &&
						basicInfo.countryRef["C_" + editableStudent.country_id].stateRef["S_" + editableStudent.state_id].cities.length > 0
					)
						&&
					(editableStudent.city == null || editableStudent.city.id == null)
				)
				{
					if(!unfilledFields)
					{
						setUnfilledFields(true)

						if(currentTab == "mainInfo")
						{
							setNoCityProvided(true)
						}
						else
						{
							tabDivRef?.current?.focus()
							setUnfilledMainData(true)
						}

						setTimeout
						(
							()=>
							{
								setUnfilledFields(false)
								setNoCityProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(editableStudent.contacts.length < 1)
					{
						if(!unfilledFields)
						{
							if(currentTab == "mainInfo")
							{
								tabDivRef?.current?.focus()
							}

							setUnfilledFields(true)
							setNoContactsProvided(true)

							setTimeout
							(
								()=>
								{
									setUnfilledFields(false)
									setNoContactsProvided(false)
								},
								3000
							)
						}
					}
					else
					{
						if(contactFormRef && contactFormRef.current)
						{
							if(!contactFormRef.current.checkValidity())
							{
								if(!unfilledFields)
								{
									setUnfilledFields(true)
									setWrongValueOnFields(true)

									if(currentTab == "mainInfo")
									{
										tabDivRef?.current?.focus()
										setWrongContactsProvided(true)
									}
									else
									{
										contactFormRef.current.reportValidity()
									}

									setTimeout
									(
										()=>
										{
											setUnfilledFields(false)
											setWrongValueOnFields(false)
											setWrongContactsProvided(false)
										},
										3000
									)
								}
							}
							else
							{
								if(saveError != null)
								{
									setSaveError(null)
								}

								setUIStatus(UIState.LOCKED)
								dispatch(enableIdleLockSwitch())

								StudentsService.saveStudent(campusRef[currentUserCampus].id, editableStudent).then
								(
									()=>
									{
										setSaveSuccess(true)
										setUIStatus(UIState.SUCCESS)

										setTimeout
										(
											()=>
											{
												closeFn()
												afterCloseFn()
												setSaveSuccess(false)
												setUIStatus(UIState.NORMAL)
												setEditableStudent(null)
											},
											tunning.MODAL_DISMISS_DELAY
										)
									},
									(error : any)=>
									{
										console.log(error)
										setUIStatus(UIState.ERROR)
										dispatch(disableIdleLockSwitch())
										setSaveError(getErrorMessage(error))
									}
								)
							}
						}
					}
				}
			}
		}
	}

	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(
					<>
						Sólo se permiten imágenes con las siguientes características:
						<br />
						&emsp;Tamaño &lt;= 5MB, &nbsp;Formato : [BMP, GIF, JPEG, PNG, WEBP]
					</>
				)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let student : any    = {...editableStudent, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any) =>
				{
					setEditableStudent({...student, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if (imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		setEditableStudent
		({
			...editableStudent, image : null,
			imagePreview              : null,
		})
	}

	const removeContact = (index : number)=>
	{
		var contacts = editableStudent.contacts

		contacts.splice(index, 1)

		setEditableStudent({...editableStudent, contacts : contacts})
	}

	const changeContactData = (index : number, field : string, event : any)=>
	{
		if(event && event.target)
		{
			var contacts = editableStudent.contacts

			contacts.splice(index, 1, {...editableStudent.contacts[index], [field] : event.target.value})
			setEditableStudent({...editableStudent, contacts : contacts})
		}
	}

	const handleRegistryChange = (event : any)=>
	{
		if(event && event.target)
		{
			setEditableStudent({...editableStudent, [event.target.name] : event.target.value})
		}
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect(()=> {if(selectedStudent.id == null) setEditableStudent(selectedStudent)}, [])
	//-----------------------------------------------------------------------------------------------------------------------
	return <DataDisplayModal saveSuccess={saveSuccess} wrongValueOnFields={wrongValueOnFields} unchangedRecord={unchangedRecord}
	  form={recordFormRef} headIcon={<CustomIcon name="student" />} dataEditInit={editStudent} unfilledFields={unfilledFields}
	  saveLock={state.isButtonDisabled} promptCloseFn={closeFn} saveDataFn={saveRecord} entityName="Alumno" uneditFn={unedit}
	  allowedUpdate={!selectedStudent.bool_read_only && allowedUpdate} UIStatus={UIStatus} editableRecord={editableStudent}
	  processingError={saveError}
	><div className="container"><div className="row">
		<div className="col-md-4 text-center"><div style={{position : "sticky", top : "0px"}}>
			<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
				<img className="relatedPicture" src=
				  {
					(editableStudent == null ? selectedStudent.imagePreview : editableStudent.imagePreview)
						|| defaultUserImage
				 }
				/>
				{
					editableStudent == null ? "" : <>
						<input accept="image/*" type="file" style={{display : "none"}} name="image" ref={imageRef}
						  onChange={imageLoad} id="image"
						/>
						{
							editableStudent.image == null ? "" : <button className="btn btn-danger rounded-pill"
							  disabled={idleLocked} type="button" onClick={imageDisposal} style=
							  {{
								position : "relative",
								bottom   : "40px",
								left     : "15%"
							 }}
							>
								<FontAwesomeIcon icon={solid("times")} size="2x" fill="unset" />
							</button>
						}
						<button onClick={() => buttonClickHandling(imageRef)} className="btn rounded-pill" type="button"
						  disabled={idleLocked} style=
						  {{
							position : "relative", background : "#C7C7C7", height : "56px", left : "16%",
							bottom   : "40px"    , border     : "1px solid var(--main-bg-color)",
							color    : "var(--secondary-txt-color)",
						 }}
						>
							<FontAwesomeIcon icon={solid("camera")} style={{color : "#000000"}} size="2x" fill="unset" />
						</button>
					</>
				}
			</div>
			<br />
			<br />
			<div className="inputContainer text-start">
				<label htmlFor="identity_code" style={{position : "relative"}}>
					Correlativo
				</label>
				<div className="roView">
					{selectedStudent.student?.identity_code || "-"}
				</div>
			</div>
			<div className="inputContainer text-start">
				<label htmlFor="correlativo_clase_365">
					Correlativo clase 365
				</label>
				{
					editableStudent == null
							?
						<div className="roView">
							<br />
							{selectedStudent?.correlativo_clase_365 || "-"}
						</div>
							:
						<input onChange={handleRegistryChange} ref={correlativoClase365Ref}
						  onFocus={validateField} placeholder="Correlativo clase 365"
						  onBlur={validateField} style={{width : "100%"}}
						  disabled={idleLocked} value={editableStudent.correlativo_clase_365} name="correlativo_clase_365"
						  id="correlativo_clase_365" maxLength={50} type="text" required
						/>
				}
			</div>
			<div className="selectContainer">
				<label htmlFor="status_id">
					Estatus
				</label>
				{
					editableStudent == null
							?
						<div className="roView">
							<br />
							{basicInfo.studentStatusRef["SS_" + selectedStudent.status_id].name}
						</div>
							:
						<>
							<select disabled={idleLocked} className="btn btn-primary text-start" onChange={handleRegistryChange}
							  value={editableStudent.status_id} id="status_id" style={{height : "30px"}} name="status_id"
							>{
								studentStatuses.map
								(
									(record : any, i : any) => <option key={"SS_" + i} value={record.id}>
										{record.name}
									</option>
								)
							}</select>
							<div className="iconRight" style={{pointerEvents : "none"}}>
								<CustomIcon name="rowDown" />
							</div>
						</>
				}
			</div>
			{
				editableStudent || selectedStudent.campuses== null ? "" : <>
					<div>
						<div style={{textAlign : "left"}}><label style={{position : "relative"}}>
							Saldos pendientes
						</label></div>
						{
							selectedStudent.campuses[0].tuition_debt == selectedStudent.campuses[0].tuition_debt_settlement
								?
							<div style={{borderBottom : "1px solid #8082C3", textAlign : "left"}}>
								<CustomIcon name="finances" style={{fill : "#8F91DA"}}/>
								<span style={{paddingLeft : "5px"}}>
									{
										selectedStudent.campuses[0].tuition_debt_settlement == null ? "-" :
										basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
											+
										sprintf(" %.02f", selectedStudent.campuses[0].tuition_debt_settlement)
									}
										/
									{
										selectedStudent.campuses[0].tuition_debt_settlement == null ? "-" :
										basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
											+
										sprintf(" %.02f", selectedStudent.campuses[0].tuition_debt)
									}
								</span>
							</div>
								:
							<div className="badge bg-danger rounded-pill text-left" style={{width : "100%"}}><table>
								<tbody><tr>
									<td>
										<CustomIcon name="finances" style={{fill : "#FFFFFF"}} />
									</td>
									<td style={{width : "100%"}}>
										{
											+selectedStudent.campuses[0].tuition_debt_settlement == 0 ? "" :
											basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
												+
											sprintf(" %.02f / ", selectedStudent.campuses[0].tuition_debt_settlement)
										}
										{
											basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
												+
											sprintf(" %.02f", selectedStudent.campuses[0].tuition_debt)
										}
									</td>
								</tr></tbody>
							</table></div>
						}
					</div>
					<div>
						<div style={{textAlign : "left"}}><label style={{position : "relative"}}>
							<br />
							Saldos kits
						</label></div>
						{
							selectedStudent.campuses[0].article_debt == selectedStudent.campuses[0].article_debt_settlement
								?
							<div style={{borderBottom : "1px solid #8082C3", textAlign : "left"}}>
								<CustomIcon name="toolbox" style={{fill : "#8F91DA"}}/>
								<span style={{paddingLeft : "5px"}}>
									{
										selectedStudent.campuses[0].article_debt_settlement == null ? "-" :
										basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
											+
										sprintf(" %.02f", selectedStudent.campuses[0].article_debt_settlement)
									}
										/
									{
										selectedStudent.campuses[0].article_debt_settlement == null ? "-" :
										basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
											+
										sprintf(" %.02f", selectedStudent.campuses[0].article_debt)
									}
								</span>
							</div>
								:
							<div className="badge bg-danger rounded-pill text-left" style={{width : "100%"}}>
								<table><tbody><tr>
									<td>
										<CustomIcon name="toolbox" style={{fill : "#FFFFFF"}} />
									</td>
									<td style={{width : "100%", paddingTop : "5px"}}>
										{
											+selectedStudent.campuses[0].article_debt_settlement == 0 ? "" :
											basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
												+
											sprintf(" %.02f / ", selectedStudent.campuses[0].article_debt_settlement)
										}
										{
											basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol
												+
											sprintf(" %.02f", selectedStudent.campuses[0].article_debt)
										}
									</td>
								</tr></tbody></table>
							</div>
						}
					</div>
				</>
			}
			{
				basicInfo.tags.length < 1 ? "" : <>
					<br />
					<div className="card card-default" style={{padding : "15px"}}>
						<div><label style={{position : "relative"}}><b style={{color : "#5154AE"}}>
							Intereses
						</b></label></div>
						{
							editableStudent == null
									?
								<div style={{textAlign : "left", display : "flex", flexFlow : "wrap"}}>{
									selectedStudent.tags.length < 1 ? "(Ninguno)" : selectedStudent.tags.map
									(
										(record : any, index : number) => <div key={"cr_" + record.id}
										  style={{display : "inline-block", padding : "5px", margin : "5px"}}
										  className="badge rounded-pill bg-primary prompt"
										>
											{record.name}
										</div>
									)
								}</div>
									:
								<>
									<label htmlFor="tags" style={{position : "unset"}}>
										Actualice
									</label>
									<Multiselect displayValue="name" placeholder="Seleccione" options={basicInfo.tags}
									  onSelect={(tags) => setEditableStudent({...editableStudent, tags : tags})}
									  onRemove={(tags) => setEditableStudent({...editableStudent, tags : tags})}
									  selectedValues={editableStudent.tags} emptyRecordMsg="No hay más opciones"
									  disable={idleLocked}
									/>
								</>
						}
					</div>
				</>
			}
		</div></div>
		<div className="col-md-8">
			<div ref={tabDivRef} tabIndex={-1} style=
			  {{
				borderBottom : "1px solid var(--main-txt-color)",
				alignItems   : "stretch",
				display      : "flex",
				flexFlow     : "row"
			 }}
			>
				<Popover isOpen={unfilledMainData && currentTab != "mainInfo"} positions={["bottom"]} content=
				  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
					<div className="requirementAlert">
						<FontAwesomeIcon icon={solid("exclamation")} />
					</div>
					&nbsp;
					Requerido : Aún hay campos por llenar
				  </div>}
				><button onClick={() => setCurrentTab("mainInfo")} disabled={idleLocked || currentTab == "mainInfo"}
				  style={{flex : "1", verticalAlign : "top"}} className="btn btn-default"
				><span style={{display : "block", position : "relative", width : "100%", height : "100%", verticalAlign : "top"}}>
					<FontAwesomeIcon icon={solid("user")} />
					<br />
					<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
						Información principal
					</span>
				</span></button></Popover>
				<Popover isOpen={(noContactsProvided || wrongContactsProvided) && currentTab == "mainInfo"}
				  positions={["bottom"]} content=
				  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
					<div className="requirementAlert">
						<FontAwesomeIcon icon={solid("exclamation")} />
					</div>
					&nbsp;
					Requerido:
					{
						noContactsProvided
							?
							"Agreque al menos un contacto de emergencia"
							:
							"Hay campos en los contactos de emergencia sin llenar."
					}
				  </div>}
				><button onClick={() => setCurrentTab("contactInfo")} style={{flex : "1", verticalAlign : "top"}}
				  disabled={idleLocked || currentTab == "contactInfo"} className="btn btn-default"
				><span style={{display : "block", position : "relative", width : "100%", height : "100%", verticalAlign : "top"}}>
					<FontAwesomeIcon icon={solid("address-book")} />
					<br />
					<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
						Información de contacto
					</span>
				</span></button></Popover>
				{
					editableStudent ? "" : <button onClick={() => setCurrentTab("enrollments")} className="btn btn-default"
					  disabled={idleLocked || currentTab == "enrollments"} style={{flex : "1", verticalAlign : "top"}}
					><span style={{display : "block", position : "relative", width : "100%", height : "100%", verticalAlign : "top"}}>
						<CustomIcon name="enrollments" />
						<br />
						<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
							Matrículas
						</span>
					</span></button>
				}
			</div>
			<Tabs defaultActiveKey="mainInfo" activeKey={currentTab!!} onSelect={setCurrentTab} className="mb-3 hidden displayNone"
			  id="studentForm"
			>
				<Tab eventKey="mainInfo" title=""><form ref={recordFormRef} className="form" onSubmit=
				  {
					(event : FormEvent)=>
					{
						event.preventDefault()
						saveRecord()
					}
				 }
				>
					<br />
					<UnderlineHeading name={"Datos personales"} />
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableStudent == null ? "" : "*"}
								Nombres
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.first_name}
									</div>
										:
									<input onBlur={validateField} style={{width : "100%"}}
									  onChange={handleRegistryChange} ref={firstNameRef} maxLength={50} placeholder="Nombres"
									  onFocus={validateField} type="text" id="first_name"
									  disabled={idleLocked} value={editableStudent.first_name} name="first_name" required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableStudent == null ? "" : "*"}
								Apellidos
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.last_name}
									</div>
										:
									<input value={editableStudent.last_name} placeholder="Apellidos" style={{width : "100%"}}
									  onFocus={validateField} name="last_name" maxLength={50}
									  onBlur={validateField} type="text" id="last_name"
									  onChange={handleRegistryChange} disabled={idleLocked} ref={lastNameRef} required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="identification_type_id">
								Tipo de identificación
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{
											(
												(selectedStudent && selectedStudent.identification_type)
													&&
												selectedStudent.identification_type.name
											)
												|| ""
										}
									</div>
										:
									<>
										<select onChange={handleRegistryChange} disabled={idleLocked}
										  value={editableStudent.identification_type_id} className="btn btn-primary text-start"
										  name="identification_type_id" style={{height : "30px"}} id="identification_type_id"
										>{
											basicInfo.identificationTypes.map
											(
												(record : any, i : any) => <option key={i} value={record.id}>
													{record.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableStudent == null ? "" : "*"}
								Código de identificación
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.identification_code || "-"}
									</div>
										:
									<input onFocus={validateField} disabled={idleLocked}
									  onBlur={validateField} placeholder="Código" type="text"
									  value={editableStudent.identification_code} name="identification_code" maxLength={50}
									  onChange={handleRegistryChange} ref={identificationCodeRef} id="identification_code"
									  style={{width : "100%"}} minLength={8} required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="name">
								{editableStudent == null ? "" : "*"}
								Fecha de nacimiento
							</label>
							<div style={{paddingTop : "18px"}}>{
								editableStudent == null
										?
									(
										(selectedStudent == null || isEmpty(selectedStudent.birthdayValue)) ? "-" : toAscendingDate
											(selectedStudent.birthdayValue)
									)
										:
									<LeDatePicker onBlur={validateField} value={editableStudent.birthdayValue} months={monthRef}
									  onFocus={validateField} onChange={handleRegistryChange} years={basicInfo.yearRange}
									  name="birthdayValue" id="birthdayValue" required
									/>
							}</div>
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="gender_id">
								Género
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{
											((selectedStudent && selectedStudent.gender) && selectedStudent.gender.name)
											|| "-"
										}
									</div>
										:
									<>
										<select name="gender_id" disabled={idleLocked} className="btn btn-primary text-start"
										  onChange={handleRegistryChange} style={{height : "30px"}}
										  value={editableStudent.gender_id} id="gender_id"
										>{
											basicInfo.genders.map
											(
												(record : any, i : any) => <option key={i} value={record.id}>
													{record.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Teléfono 1
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.phone1 || "-"}
									</div>
										:
									<input
									  onFocus={validateField}
									  id="phone1" type="tel"
									  placeholder="Teléfono"
									  minLength={8}
									  maxLength={50}
									  onBlur={validateField}
									  ref={phone1Ref}
									  value={editableStudent.phone1}
									  disabled={idleLocked}
									  style={{width : "100%"}}
									  name="phone1"
									  onKeyPress={(event : any) => {if(isNaN(event.key))event.preventDefault()}}
									  onChange=
									  {
										(event : any)=>
										{
											if(isNaN(event.target.value))
											{
												event.preventDefault()
											}
											else
											{
												setEditableStudent({...editableStudent, phone1 : event.target.value})
											}
										}
									  }
									  required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Teléfono 2
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.phone2 || "-"}
									</div>
										:
									<input onFocus={validateField}
									  id="phone2"
									  name="phone2"
									  maxLength={50}
									  onBlur={validateField}
									  ref={phone2Ref}
									  value={editableStudent.phone2}
									  disabled={idleLocked}
									  placeholder="Teléfono"
									  type="tel"
									  minLength={8}
									  style={{width : "100%"}}
									  onKeyPress={(event : any) => {if(isNaN(event.key))event.preventDefault()}}
									  onChange=
									  {
										(event : any)=>
										{
											if(isNaN(event.target.value))
											{
												event.preventDefault()
											}
											else
											{
												setEditableStudent({...editableStudent, phone2 : event.target.value})
											}
										}
									  }
									/>
							}
						</div></div>
					</div>
					<br />
					<UnderlineHeading name={"Último grado de estudios terminado"} />
					<div className="inputContainer">
						<label htmlFor="last_school_name" style={{position : "relative"}}>
							{editableStudent == null ? "" : "*"}
							Último grado de estudios terminado
						</label>
						<div className="fluentBox" style={{position : "relative", bottom : "13px"}}><div className="fluentBlock"
						  style={{padding : "0px 5px"}}><div style={{margin : "0px"}} className="selectContainer"
						>
							<br />
							{
								editableStudent == null
									?
								<div className="roView">
									<br />
									{
										(
											(selectedStudent && selectedStudent.academic_degree)
											&& selectedStudent.academic_degree.name
										)
										|| "-"
									}
								</div>
									:
								<>
									<select className="btn btn-primary text-start" value={editableStudent.academic_degree_id}
									  style={{height : "30px"}} name="academic_degree_id" onChange={handleRegistryChange}
									  disabled={idleLocked} id="academic_degree_id"
									>{
										academicDegrees.map
										(
											(record : any, i : any) => <option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}</select>
									<div className="iconRight" style={{pointerEvents : "none"}}>
										<CustomIcon name="rowDown" />
									</div>
								</>
							}
						</div></div></div>
					</div>
					<br />
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="last_school_name">
								{editableStudent == null ? "" : "*"}
								Última escuela
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.last_school_name || "-"}
									</div>
										:
									<input ref={lastSchoolNameRef} name="last_school_name" style={{width : "100%"}} type="text"
									  onChange={handleRegistryChange} value={editableStudent.last_school_name} id="last_school_name"
									  onFocus={validateField} placeholder="Última escuela"
									  onBlur={validateField} disabled={idleLocked} required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="current_occupation">
								{editableStudent == null ? "" : "*"}
								Ocupación actual
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.current_occupation || "-"}
									</div>
										:
									<input onFocus={validateField} style={{width : "100%"}}
									  placeholder="ocupación actual" disabled={idleLocked} onChange={handleRegistryChange}
									  onBlur={validateField} name="current_occupation"
									  value={editableStudent.current_occupation} ref={currentOccupationRef} type="text"
									  id="current_occupation" required
									/>
							}
						</div></div>
					</div>
					<br />
					<UnderlineHeading name={"Ubicación"} />
					<div className="row">
						<div className="col-md-6">
							<div className="selectContainer"><label htmlFor="nationality_id">
								{editableStudent ? "*" : ""}
								Nacionalidad
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent?.nationality?.name || "-"}
									</div>
										:
									<>
										<select value={editableStudent.nationality_id} ref={nationalitySelRef}
										  onChange={handleRegistryChange} disabled={idleLocked}
										  id="nationality_id" name="nationality_id" className="btn btn-primary text-start"
										  style={{height : "30px"}}
										>{
											basicInfo.countries.map
											(
												(record : any, i : any) => <option key={i} value={record.id}>
													{record.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
							}
						</div></div>
						<div className="col-md-6">
							<div className="selectContainer">
								<label htmlFor="country_id">
									{editableStudent ? "*" : ""}
									País de residencia
								</label>
								{
									editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent?.country?.name || "-"}
									</div>
										:
									<>
										<select
										  className="btn btn-primary text-start"
										  value={editableStudent.country_id}
										  style={{height : "30px"}}
										  disabled={idleLocked}
										  ref={countrySelRef}
										  name="country_id"
										  id="country_id"
										  onChange=
										  {
											(event : any) => setEditableStudent
											({
												...editableStudent, country_id : event.target.value,
												state_id                        : "",
												state_name                      : "",
												city                            : null,
												city_name                       : ""
											})
										  }
										>{
											basicInfo.countries.map
											(
												(country : any, i : any) => <option key={i} value={country.id}>
													{country.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div style={{margin : "unset", width : "100%"}}
							  className=
							  {
								(
									(editableStudent == null && basicInfo.countryRef["C_" + selectedStudent.country_id].states.length < 1)
										||
									(editableStudent && basicInfo.countryRef["C_" + editableStudent.country_id].states.length < 1)
								)
									? "inputContainer"
										: "selectContainer"
							  }
							>
								<label htmlFor="state_id">
									{editableStudent == null ? "" : "*"}
									Estado/Depto./Provincia...
								</label>
								<div className="roView" style={{width : "100%"}}>{
									editableStudent == null
											?
										<>
											<br />
											{
												(
													(selectedStudent && selectedStudent.state)
															?
														selectedStudent.state.name
															:
														selectedStudent.state_name
												)
													|| "-"
											}
										</>
											:
										(
											(
												(editableStudent == null && basicInfo.countryRef["C_" + selectedStudent.country_id].states.length < 1)
													||
												(editableStudent && basicInfo.countryRef["C_" + editableStudent.country_id].states.length < 1)
											)
													?
												<>
													<br />
													<input
													  type="text"
													  maxLength={50}
													  id="state_name"
													  name="state_name"
													  ref={stateNameRef}
													  disabled={idleLocked}
													  onBlur={validateField}
													  onFocus={validateField}
													  style={{position : "relative", width : "100%"}}
													  onChange={handleRegistryChange}
													  value={editableStudent.state_name}
													  required
													/>
												</>
													:
												<>
													<select
													  id="state_id"
													  name="state_id"
													  ref={stateSelRef}
													  disabled={idleLocked}
													  onBlur={validateField}
													  onFocus={validateField}
													  style={{height : "30px"}}
													  className="btn btn-primary text-start"
													  defaultValue={editableStudent.state_id}
													  onChange=
													  {
														(event : any) => setEditableStudent
														({
															...editableStudent, state_id : event.target.value,
															state_name                    : "",
															city                          : null,
															city_name                     : ""
														})
													  }
													  required
													>
														<option key={"SOC_" + editableStudent.country_id} value="">
															&emsp;
															Seleccione...
														</option>
														{
															basicInfo.countryRef["C_" + editableStudent.country_id] &&
															basicInfo.countryRef["C_" + editableStudent.country_id].states.map
															(
																(record : any, i : number) => <option
																  value={record.id}
																  key={record.id}
																>
																	{record.name}
																</option>
															)
														}
													</select>
													<div className="iconRight" style={{pointerEvents : "none"}}>
														<CustomIcon name="rowDown" />
													</div>
												</>
										)
								}</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className=
							  {
								(
									(editableStudent == null && basicInfo.countryRef["C_" + selectedStudent.country_id].states.length < 1)
										||
									(editableStudent && basicInfo.countryRef["C_" + editableStudent.country_id].states.length < 1)
								)
									? "inputContainer"
										: "selectContainer"
							  }
							>
								<label htmlFor="city">
									{editableStudent == null ? "" : "*"}
									Ciudad
								</label>
									<div className="roView" style={{width : "100%", paddingTop : "15px"}}>{
										editableStudent == null
												?
											<div style={{paddingTop : "10px"}}>{
												(
													(
														selectedStudent && selectedStudent.city
															? selectedStudent.city.name
																: selectedStudent.city_name
													) || "-"
												)
											}</div>
												:
											(
												(
													(
														basicInfo.countryRef["C_" + editableStudent.country_id].states.length > 0
															&&
														(editableStudent.state_id == null || basicInfo.countryRef["C_" + editableStudent.country_id].stateRef["S_" + editableStudent.state_id] == null)
													)
														||
													(
														basicInfo.countryRef["C_" + editableStudent.country_id].states.length < 1
															&&
														isEmpty(editableStudent.state_name)
													)
												)
													?
														<div style={{paddingTop : "10px"}}>
															&lt;Proporcione estado &gt;
														</div>
													:
												(
													(
														basicInfo.countryRef["C_" + editableStudent.country_id].states.length < 1
															||
														basicInfo.countryRef["C_" + editableStudent.country_id].stateRef["S_" + editableStudent.state_id].cities.length < 1
													)
															?
														<input
														  type="text"
														  maxLength={50}
														  id="city_name"
														  name="city_name"
														  ref={cityNameRef}
														  disabled={idleLocked}
														  onBlur={validateField}
														  onFocus={validateField}
														  style={{width : "100%"}}
														  onChange={handleRegistryChange}
														  value={editableStudent.city_name}
														  required
														/>
															:
														<Popover
														  positions={["bottom"]}
														  isOpen={noCityProvided}
														  content=
														  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
															<div className="requirementAlert">
																<FontAwesomeIcon icon={solid("exclamation")}/>
															</div>
															&nbsp;
															Requerido: Elija una opción
														  </div>}
														>
															<div style={{width : "100%"}}>
																<SelectReact
																  id="city"
																  name="city"
																  placeholder="Ciudad"
																  disabled={idleLocked}
																  value={editableStudent.city}
																  onChange={handleRegistryChange}
																  children={Object.values(basicInfo.countryRef["C_" + editableStudent.country_id].stateRef["S_" + editableStudent.state_id].cityRef)}
																/>
															</div>
														</Popover>
												)
											)
									}</div>
							</div>
						</div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableStudent == null ? "" : "*"}
								Dirección actual
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.address || "-"}
									</div>
										:
									<input value={editableStudent.address} placeholder="Dirección actual" disabled={idleLocked}
									  id="address" onFocus={validateField} name="address" maxLength={255} onBlur={validateField}
									  onChange={handleRegistryChange} style={{width : "100%"}} type="text" ref={addressRef}
									  required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Código postal
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.zipcode || "-"}
									</div>
										:
									<input onBlur={validateField} max={999999999} step={1} name="zipcode" type="number"
									  onChange={handleRegistryChange} placeholder="Código postal" min={10000} id="zipcode"
									  value={editableStudent.zipcode} onFocus={validateField} style={{width : "100%"}}
									  ref={zipcodeRef}
									/>
							}
						</div></div>
					</div>
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="email">
								{editableStudent == null ? "" : "*"}
								Correo electrónico
							</label>
							{
								editableStudent == null
										?
									<div className="roView">
										<br />
										{selectedStudent.email || "-"}
									</div>
										:
									<input onFocus={validateField} maxLength={50}
									  value={editableStudent.email} placeholder="Correo electrónico" type="email" name="email"
									  onBlur={validateField} style={{width : "100%"}}
									  onChange={handleRegistryChange} id="email" disabled={idleLocked} ref={emailRef}
									  required
									/>
							}
						</div></div>
						{
							editableStudent == null ? "" : <div className="col-sm-6"><div className="inputContainer">
								<label htmlFor="name">
									Contraseña
								</label>
								{
									editableStudent == null
											?
										<div className="roView">
											<br />
											{selectedStudent.password}
										</div>
											:
										<input value={editableStudent.password} placeholder="Contraseña" id="password"
										  type="password" onChange={handleRegistryChange}
										  style={{width : "100%"}} ref={passwordRef} maxLength={50} minLength={6}
										  onFocus={(event : any) => {if(editableStudent.id == null)validateField(event)}}
										  onBlur={(event : any) => {if(editableStudent.id == null)validateField(event)}}
										  disabled={idleLocked} name="password"
										/>
								}
							</div></div>
						}
					</div>
					<br />
					<>
						<UnderlineHeading name={"Observaciones"} />
						{
							editableStudent == null ? (selectedStudent.observations || "-") : <textarea id="observations"
							  onChange={handleRegistryChange} value={editableStudent.observations}
							  className="notesField form-control" disabled={idleLocked} ref={observationsRef}
							  onBlur={validateField} name="observations"
							/>
						}
					</>
				</form></Tab>
				<Tab eventKey="contactInfo" title=""><form ref={contactFormRef} className="form">
					<div style={{width : "inherit", display : "inline-block", position : "sticky", left : "0px"}}>
						<UnderlineHeading name={"Enlaces"} />
					</div>
					<div className="row">{
						Object.keys(linkInfo).map
						(
							(record : any, index : number) => <div className="col-md-6" key={record}><div className="inputContainer"
							>
								<label htmlFor={record}>
									{linkInfo[record]}
								</label>
								{
									editableStudent == null
											?
										<div className="roView">
											<br />
											{selectedStudent[record] || "-"}
										</div>
											:
										<input id={record} style={{width : "100%"}} value={editableStudent[record]}
										  onChange={handleRegistryChange} name={record} type="text"
										  disabled={idleLocked} placeholder={linkInfo[record]} maxLength={50}
										/>
								}
							</div></div>
						)
					}</div>
					<div style={{width : "inherit", display : "inline-block", position : "sticky", left : "0px"}}>
						<br />
						<div>
							<UnderlineHeading name={(editableStudent != null ? "*" : "") + "Contactos de emergencia"} />
						</div>
					</div>
					<div className="fluentBox">{
						editableStudent != null ? editableStudent.contacts.map
						(
							(contact : any, index : number) => <div className="fluentBlock" key={"sC_" + index}
							  style={{borderRadius : "15px", background : "#E7E7E7", margin : "15px"}}
							>
								<div style={{padding : "0px 15px"}}><div className="inputContainer">
									<label>
										*Nombres
									</label>
									<input style={{background : "var(--main-bg)"}} placeholder="nombres" maxLength={50}
									  onChange={(event : any) => changeContactData(index, "name", event)} type="text"
									  disabled={idleLocked} onBlur={validateField}
									  onFocus={validateField} value={contact.name}
									  id={"contacts[" + index + "].name"} name={"contacts[" + index + "].name"}
									  required
									/>
								</div></div>
								<div style={{padding : "0px 15px"}}><div className="inputContainer">
									<label>
										*Relación/Parentesco
									</label>
									<input onChange={(event : any) => changeContactData(index, "relation", event)}
									  style={{background : "var(--main-bg)"}} name={"contacts[" + index + "].relation"}
									  onBlur={validateField} value={contact.relation}
									  onFocus={validateField} disabled={idleLocked}
									  id={"contacts[" + index + "].relation"} placeholder="parentesco, amistad..."
									  type="text" maxLength={50} required
									/>
								</div></div>
								<div style={{padding : "0px 15px"}}><div className="inputContainer">
									<label style={{position : "relative"}}>
										*Información para contactar
									</label>
									<textarea style={{height : "50px", resize : "none", background : "var(--main-bg)"}}
									  onFocus={validateField} disabled={idleLocked}
									  onBlur={validateField} className="form-control"
									  name={"contacts[" + index + "].contactMedia"} placeholder="teléfonos, emails"
									  onChange={(event : any) => changeContactData(index, "contactMedia", event)}
									  id={"contacts[" + index + "].contactMedia"} value={contact.contactMedia}
									  minLength={8} required
									></textarea>
								</div></div>
								<div className="text-end" style={{padding : "25px 5px 0px"}}><button type="button"
								  className="btn btn-outline-danger rounded-pill" onClick={() => removeContact(index)}
								  disabled={idleLocked}
								>
									<FontAwesomeIcon icon={solid("trash")}/>
								</button></div>
							</div>
						)
							:
						(
							selectedStudent.contacts.length < 1 ? "(ninguno)" : selectedStudent.contacts.map
							(
								(contact : any, index : number) => <div key={"sC_" + index} className="fluentBlock"
								  style={{borderRadius : "15px", background : "#E7E7E7", margin : "15px"}}
								>
									<div style={{padding : "0px 15px"}}><div className="inputContainer">
										<label>
											Nombres
										</label>
										<br />
										{contact.name}
									</div></div>
									<div style={{padding : "0px 15px"}}><div className="inputContainer">
										<label>
											Relación/Parentesco
										</label>
										<br />
										{contact.relation}
									</div></div>
									<div style={{padding : "0px 15px"}}><div className="inputContainer">
										<label style={{position : "relative"}}>
											*Información para contactar
										</label>
										<br />
										{contact.contactMedia}
									</div></div>
								</div>
							)
						)
					}</div>
					<br />
					{
						editableStudent == null ? "" : <div style={{width : "100%", position : "sticky", left : "0px"}}>
						<Popover positions={["bottom"]} isOpen={noContactsProvided && currentTab != "mainInfo"} content=
						  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
							<div className="requirementAlert">
								<FontAwesomeIcon icon={solid("exclamation")} />
							</div>
							&nbsp;
								Requerido : Agreque al menos un contacto de emergencia.
						  </div>}
						><button className="btn btn-outline-success" disabled={idleLocked} style={{width : "100%"}}
						  type="button" onClick=
						  {
							()=>
							{
								setEditableStudent
								({
									...editableStudent, contacts:
									[
										...editableStudent.contacts,
										{
											name     : "",
											relation : ""
										}
									]
								})
							}
						 }
						>
							<FontAwesomeIcon icon={solid("plus")} />
							<span style={{padding : "10px"}}>
								Agregar contacto
							</span>
							<FontAwesomeIcon icon={solid("users")} />
						</button></Popover></div>
					}
				</form></Tab>
				<Tab eventKey="enrollments" title="">{
					selectedStudent.enrollments.length < 1 ? "-Sin registros-" : <div className="row">{
						selectedStudent.enrollments.map
						(
							(enrollment : any) => <div className="col-xl-6 text-start" key={enrollment.refIndex}
							  style={{background : enrollment.color, borderRadius : "10px", padding : "5px 5px 5px 30px", margin : "5px", flex : 1}}
							><p style={{background : "#FFFFFF", borderRadius : "10px", marginBottom : "unset", padding : "5px", display : "block", height : "100%"}}>
								<span style={{display : "inline-flex", position : "relative", width : "100%", marginBottom : "10px"}}>
									<img src={enrollment.level.course.image != null ? enrollment.level.course.image : defaultCourseImage}
									  className="detailPicture" style={{borderColor : "#000000", position : "absolute", top : "12px"}}
									/>
									<span style={{marginLeft : "55px", paddingLeft : "5px", flex : "1", display : "inline-block"}}>
										<b>
											{enrollment.level.course.name}
										</b>
										<br />
										{enrollment.level.name}
										<br />
										{enrollment.name}
									</span>
								</span>
								<span style={{marginBottom : "unset", display : "inline-block", width : "100%"}}>{
									enrollment.sessions.map
									(
										(session : any, index : number)=>
										{
											let startMinutes = (session.start_hours % 10)
											let startHours   = (session.start_hours - startMinutes) / 10
											let endMinutes   = (session.end_hours % 10)
											let endHours     = (session.end_hours - endMinutes) / 10

											return <span key={"S_" + enrollment.id + "_" + index} style={{display : "block"}}>{
												weekDays[session.week_day].substr(0, 2) + sprintf
													("%02d:%02d-%02d:%02d", startHours, startMinutes, endHours  , endMinutes * 6)
											}</span>
										}
									)
								}</span>
								<span style={{marginBottom : "unset", display : "inline-block", width : "100%"}}>
									{enrollment.classroom.building.name}
									&nbsp;
									<span style={{display : "inline-block"}}>
										{enrollment.classroom.name}
									</span>
									<br />
									{enrollment.period.name}
									&nbsp;
									<span style={{display : "inline-block"}}>
										<span style={{display : "inline-block"}}>
											({enrollment.period.start}
										</span>
										<span style={{display : "inline-block"}}>
											- {enrollment.period.end})
										</span>
									</span>
								</span>
							</p></div>
						)
					}</div>
				}</Tab>
			</Tabs>
		</div>
	</div></div></DataDisplayModal>
}

export default StudentInfoModal