import axios
	from "axios"
import store
	from "./../../globals"

import {removeRecords}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class AttendancesService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getScheduleAttendances(campusId : number, scheduleId :  number)
	{
		return axios.get
		(
			RESOURCE_URL + campusId + "/schedules/" + scheduleId + "/attendances",
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		)
	}

	async getCompensationStudent(scheduleId :  number, compensationStudentId : number)
	{
		return axios.get
		(
			RESOURCE_URL + "/schedules/" + scheduleId + "/_compensationStudents/" + compensationStudentId,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		)
	}

	async updateScheduleAttendances(campusId : number, scheduleId : number, day : any)
	{
		let data : any=
		{
			attendanceStatuses : Object
				.keys(day.attendanceRef)
				.map
				(
					(key : string)=>
					{
						return key.split("_")[1] + "," + day.attendanceRef[key]
					}
				)
				.join(";")
		}

		if(day.compensationDayRef && Object.keys(day.compensationDayRef).length > 0)
		{
			data.compensatingStudentIds = Object
				.keys(day.compensationDayRef)
				.map((key : string)=>key.split("_")[1])
				.join(",")
		}

		return axios.put
		(
			RESOURCE_URL + campusId + "/schedules/" + scheduleId + "/attendances/" + day.date,
			data,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response
			}
		)
	}

	async removeDayAttendances(campusId : number,scheduleId : number,  datetime :  string)
	{
		return removeRecords(RESOURCE_URL + campusId + "/schedules/" + scheduleId + "/attendances", [datetime])
	}
}

export default new AttendancesService()