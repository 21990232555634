import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"

import {isNotEmpty, removeRecords}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/"

class ClassroomsService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchClassrooms(campusId : number, search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(search)
		{
			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(isNotEmpty(search.tagIds))
			{
				params.push("tagIds=" + search.tagIds.join(","))
			}

			if(isNotEmpty(search.categoryIds))
			{
				params.push("categoryIds=" + search.categoryIds.join(","))
			}

			if(isNotEmpty(search.buildingIds))
			{
				params.push("buildingIds=" + search.buildingIds.join(","))
			}
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId + "/classrooms" +(params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveClassroom(campusId : number, classroom : any)
	{
		const saveFunction = classroom.id == null ? this.registerClassroom : this.updateClassroom

		let record : any=
		{
			buildingId      : classroom.building_id,
			statusId        : classroom.status_id,
			name            : classroom.name,
			studentsLimit   : classroom.students_limit,
			sessionMinHours : classroom.session_min_hours,
			weekMinHours    : classroom.week_min_hours,
			categoryIds     : _.map(classroom.categories, "id").join(",")
		}

		if(isNotEmpty(classroom.description))
		{
			record.description = classroom.description
		}

		return saveFunction
		(
			campusId,
			record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			classroom.id
		)
	}

	async registerClassroom(campusId : number, registry : any, options : any)
	{
		return axios.post(RESOURCE_URL + campusId + "/classrooms", registry, options)
	}

	async updateClassroom(campusId : number, registry : any, options : any, id : number)
	{
		return axios.put(RESOURCE_URL + campusId + "/classrooms/" + id, registry, options)
	}

	async removeClassrooms(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL  + campusId + "/classrooms", recordIds)
	}
}

export default new ClassroomsService()