import axios
	from "axios"
import store
	from "./../globals"
import _
	from "lodash"

import {isNotEmpty, toAcceptableDate}
	from "./../common"

const API_URL = process.env.REACT_APP_API_URL

class AuthService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	login(email : string, password : string)
	{
		return axios.post
		(
			API_URL + "/access/_login",
			{
				email,
				password
			}
		);
	}

	register(email : string, password : string, firstName : string, lastName : string)
	{
		return axios.post
		(
			API_URL + "/access/_register",
			{
				email,
				password,
				firstName,
				lastName
			}
		);
	}

	logout(sessionToken : string | null)
	{
		return axios.post
		(
			API_URL + "/access/_logout",
			{},
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response.data;
			}
		);
	}

	getInitData()
	{
		return axios.get
		(
			API_URL + "/user/_initData",
			{
				headers:
				{
					"Content-Type"  : "application/json",
					"Authorization" : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	getCurrentUser()
	{
		return axios.get
		(
			API_URL + "/user/_profile",
			{
				headers:
				{
					"Content-Type"  : "application/json",
					"Authorization" : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	saveCurrentUser(userProfile : any)
	{
		let payload : FormData = new FormData()
		let profile : any=
		{
			nationalityId        : userProfile.nationality_id,
			cityId               : userProfile.city.id,
			location             : userProfile.location || "",
			birthday             : toAcceptableDate(userProfile.birthdayValue),
			genderId             : userProfile.gender_id,
			identificationTypeId : userProfile.identification_type_id,
			identificationCode   : userProfile.identification_code,
			firstName            : userProfile.first_name,
			lastName             : userProfile.last_name,
			email                : userProfile.email,
			password             : userProfile.password,
			links                : JSON.stringify
			({
				web       : userProfile.web || "",
				facebook  : userProfile.facebook || "",
				twitter   : userProfile.twitter || "",
				instagram : userProfile.instagram || "",
				tiktok    : userProfile.tiktok || ""
			})
		}

		for(let field of ["password", "observations", "zipcode", "address", "phone"])
		{
			if(isNotEmpty(userProfile[field]))
			{
				profile[field] = userProfile[field] + ""
			}
		}

		if(userProfile.tags.length > 0)
		{
			profile.tagIds = _.map(userProfile.tags, "id").join(",")
		}

		Object.keys(profile).map((key : string) => {payload.append(key, profile[key])})

		if(userProfile.image)
		{
			payload.append("image", userProfile.image == userProfile.imagePreview ? "" : userProfile.image)
		}

		return axios.post
		(
			API_URL + "/user/_profile?_method=PUT",
			payload,
			{
				headers:
				{
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
	}
}

export default new AuthService()