import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"
import
{
	UIState,
	validateField,
	pageGrouping,
	getAccessPermissions
}
	from "./../../common"
import Constants, {tunning}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import UnderlineHeading
	from "./../../components/underlineHeading"
import BuildingsService
	from "./../../services/campus/buildings.service"

import "./../../components/dropdown/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action   = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{
				...state,
				isButtonDisabled : action.payload
			}
		break;
	}
}

const Buildings = ()=>
{
	const buildings     : any[]                           = []
	const [state, trigger]                                = useReducer(reducer, initialState)
	const dispatch                                        = useDispatch()
	const [campuses, setCampuses]                         = useState<any>([])
	const records            : any[]                      = buildings.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [data, setData]                                 = useState<any[]>(records)
	const idleLocked        : boolean                     = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null               = useSelector((state : RootState) => state.sessionToken.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const currentUser       : any                         = useSelector((state : RootState) => state.userProfile.value)
	const [userCampus, setUserCampus]                     = useState<number>(currentUserCampus)
	const [unfilledFields, setUnfilledFields]             = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]           = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus]                         = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [newBuilding, setNewBuilding]                   = useState<any>(null)
	const [selectedBuilding, setSelectedBuilding]         = useState<any>(null)
	const [editableBuilding, setEditableBuilding]         = useState<any>(null)
	const [pagination, setPagination]                     = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                   = useState<boolean>(false)
	const [saveError, setSaveError]                       = useState<any>(null)
	const [recordsSelection, setRecordsSelection]         = useState<any>([])
	const count        : number[]                         = [-1, 1]
	const allowed          : any                          = getAccessPermissions(currentUser)
	let   recordFormRef                                         = useRef<HTMLFormElement >(null)
	let   nameRef                                         = useRef<HTMLInputElement>(null)
	let   descriptionRef                                  = useRef<HTMLTextAreaElement>(null)

	const unedit = ()=>
	{
		if(editableBuilding && editableBuilding.id == null)
		{
			setSelectedBuilding(null)
		}

		setEditableBuilding(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setSaveError(null)

		if(recordData.id == null)
		{
			setEditableBuilding(recordData)
		}

		setSelectedBuilding(recordData)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if(saveError != null)
				{
					setSaveError(null)
				}

				setUIStatus(UIState.LOCKED)
				dispatch(enableIdleLockSwitch())

				BuildingsService.saveBuilding(campusRef[currentUserCampus].id, editableBuilding).then
				(
					()=>
					{
						setSaveSuccess(true)
						setUIStatus(UIState.SUCCESS)

						setTimeout
						(
							()=>
							{
								setSelectedBuilding(null)
								setEditableBuilding(null)
								setSaveSuccess(false)
								setUIStatus(UIState.NORMAL)
								search()
							},
							tunning.MODAL_DISMISS_DELAY
						)
					},
					(error : any)=>
					{
						console.log(error)
						setUIStatus(UIState.ERROR)
						dispatch(disableIdleLockSwitch())
						setSaveError
						(
							error.response.status == 409
									?
								"Hay conflictos en la información proporcionada (nombre). " +
									"Revise que los valores sean únicos en comparación con los registros existentes"
									:
								"La información no pudo ser guardada"
						)
					}
				)
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch,[e.target.name] : e.target.value})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableBuilding({...editableBuilding, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					setNewBuilding
					({
						name        : "",
						description : ""
					})

					search()
				}
			}
		},
		[]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setCustomSearch(defaultSearch)
				setSectionLoaded(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				search()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)
//---------------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const expand      : string = "classrooms"
			const result      : any    = await BuildingsService.searchBuildings
				(campusRef[currentUserCampus].id, customSearch, expand, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setData(result.data.data.map((record : any, index : number) => {return {selected : false, data : record}}))
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		);
	};

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		);

		setSelectedRecords(totalRecords * (+selected))
	};

	const closePrompt = ()=>
	{
		setSelectedBuilding(null)
		setEditableBuilding(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string=
						"No se pudieron eliminar algunos registros. Puede que la información en otra sección dependa de estos datos (aulas), por lo que no será posible realizar por completo la operación"

					const recordIds : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await BuildingsService.removeBuildings(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  clearSearchFn={clearSearch}
		  customSearch={customSearch}
		  defaultSearch={defaultSearch}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newBuilding)}
		>{
			(data.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					{Constants.noCampusSearchResults}
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="campusesHeader">
					{
						!allowed.delete ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
						  disabled={idleLocked || saveSuccess} type="button"
						>
							{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
						</button></th>
					}
					<th>
						id
					</th>
					<th>
						Nombre
					</th>
					<th>
						Descripción
					</th>
					<th>
					</th>
				</tr></thead>
				<tbody>{
					data.map
					(
						(record : any, index : number) => <tr key={"campus" + index}>
							{
								!allowed.delete ? "" : <td><button className="btn btn-default" disabled={idleLocked} type="button"
								  onClick={() => toggleRecordSelection(index)}
								>
									{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
								</button></td>
							}
							<td>
								{record.data.id}
							</td>
							<td>
								{record.data.name}
							</td>
							<td>
								{record.data.description || "-"}
							</td>
							<td><div style={{display : "flex"}}>
								<button style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
								  onClick={() => showPrompt(record.data)} className="button btn btn-default" type="button"
								  disabled={idleLocked}
								>
									<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
								</button>
								{
									!allowed.delete ? "" : <button className="button btn btn-default" style={{display : "table-column"}}
									  onClick={() => confirmRemoval(record.data.id)} disabled={idleLocked}
									>
										<CustomIcon name="bTrash" />
									</button>
								}
							</div></td>
						</tr>
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			selectedBuilding == null ? "" : <DataDisplayModal saveLock={state.isButtonDisabled} processingError={saveError}
			  saveSuccess={saveSuccess} unchangedRecord={unchangedRecord} unfilledFields={unfilledFields} form={recordFormRef}
			  dataEditInit={() => {setEditableBuilding(selectedBuilding)}} uneditFn={unedit} allowedUpdate={allowed.update}
			  headIcon={<FontAwesomeIcon icon={solid("building")} />} promptCloseFn={closePrompt} saveDataFn={saveRecord}
			  editableRecord={editableBuilding} UIStatus={UIStatus} entityName="Edificio"
			><form ref={recordFormRef} className="form"><div className="container" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			>
				<div className="row"><div className="col-md-6"><div className="inputContainer">
					<label htmlFor="name">
						{editableBuilding == null ? "" : "*"}
						Nombre
					</label>
					{
						editableBuilding == null
							?
						<div className="roView">
							<br />
							{selectedBuilding.name}
						</div>
							:
						<input onChange={handleRegistryChange} name="name" id="name" ref={nameRef} value={editableBuilding.name}
						  onFocus={validateField} maxLength={50} style={{width : "100%"}} onBlur={validateField} type="text"
						  placeholder="Nombre" disabled={idleLocked} required
						/>
					}
				</div></div></div>
				<br />
				<UnderlineHeading name={"Descripción"} />
				<div style={{paddingTop : "3px"}}>{
					editableBuilding == null ? (selectedBuilding.description || "-") : <textarea id="description" maxLength={255}
					  disabled={idleLocked} name="description" className="notesField form-control" ref={descriptionRef}
					  onBlur={validateField} value={editableBuilding.description} onChange={handleRegistryChange}
					/>
				}</div>
			</div></form></DataDisplayModal>
		}
	</>
}

export default Buildings
