export const finances = {
    GET_COURSES: "GET_COURSES",
    GET_KITS: "GET_KITS",
    GET_OTHERS: "GET_OTHERS",
    GET_INVOICES: "GET_INVOICES",
    GET_DATA_HISTORY: "GET_DATA_HISTORY",
    SET_SELECTED_COURSE: "SET_SELECTED_COURSE",
    SET_SELECTED_ARTICLE: "SET_SELECTED_ARTICLE",
    SET_MODAL_TOTAL: "SET_MODAL_TOTAL",
    SET_MODAL_ITEMS_TOTAL: "SET_MODAL_ITEMS_TOTAL"
}

export const actions = {
    finances
}
