import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice
}
	from "./../../globals"
import
{
	UIState,
	isEmpty,
	getErrorMessage,
	validateField,
	getAccessPermissions
}
	from "./../../common"
import Constants, {tunning}
	from "../../constants"
import CustomIcon
	from "./../../components/customIcon"
import UnderlineHeading
	from "./../../components/underlineHeading"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import PaymentMethodsService
	from "./../../services/paymentMethods.service"
import RecipientAccountsService
	from "./../../services/recipientAccounts.service"

import "./../../components/dropdown/style.css"
import Multiselect from "multiselect-react-dropdown"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action  = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{...state, isButtonDisabled : action.payload}
		break
	}
}

export const PaymentMethods = (props : any)=>
{
	const dataRecords     : any[] = []
	const [state, trigger]                                = useReducer(reducer, initialState)
	const dispatch                                        = useDispatch()
	const records      : any[]                            = dataRecords.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [data, setData]                                 = useState<any[]>([])
	const [readOnlyData, setReadOnlyData]                 = useState<any[]>([])
	const basicInfo    : any                              = useSelector((state : RootState) => state.basicInfo.value)
	const idleLocked   : boolean                          = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                    = useSelector((state : RootState) => state.sessionToken.value)
	const currentUser       : any                         = useSelector((state : RootState) => state.userProfile.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const [userCampus, setUserCampus]                     = useState<number>(currentUserCampus)
	const [unchangedRecord, setUnchangedRecord]           = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]             = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus]                         = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [newRecord, setNewRecord]                       = useState<any>(null)
	const [selectedRecord, setSelectedRecord]             = useState<any>(null)
	const [editableRecord, setEditableRecord]             = useState<any>(null)
	const [section, setSection]                           = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                   = useState<boolean>(false)
	const [saveError, setSaveError]                       = useState<any>(null)
	const [recordsSelection, setRecordsSelection]         = useState<any>([])
	const [recipientAccounts, setRecipientAccounts]       = useState<any>([])
	const count        : number[]                         = [-1, 1]
	const allowed      : any                              = getAccessPermissions(currentUser)
	let   recordFormRef                                   = useRef<HTMLFormElement >(null)
	let   nameRef                                         = useRef<HTMLInputElement>(null)

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setEditableRecord({
			...recordData, 
			accountIds : recordData.recipientAccounts ? recordData.recipientAccounts.map((account: any) => account.id).join(',') : null
		})
		setSelectedRecord({
			...recordData, 
			accountIds : recordData.recipientAccounts ? recordData.recipientAccounts.map((account: any) => account.id).join(',') : null
		})
	}

	const saveRecord = ()=>
	{
		if(!recordFormRef?.current?.checkValidity())
		{
			if(!unfilledFields)
			{
				setUnfilledFields(true)
				recordFormRef?.current?.reportValidity()

				setTimeout
				(
					()=>
					{
						setUnfilledFields(false)
					},
					3000
				)
			}
		}
		else
		{
			let errorPrompt : boolean = false

			if(saveError)
			{
				setSaveError(null)
			}

			setUIStatus(UIState.LOCKED)
			dispatch(enableIdleLockSwitch())

			PaymentMethodsService.saveRecord(editableRecord).then
			(
				()=>
				{
					setSaveSuccess(true)
					setUIStatus(UIState.SUCCESS)

					setTimeout
					(
						()=>
						{
							setCustomSearch(defaultSearch)
							setSelectedRecord(null)
							setEditableRecord(null)
							setSaveSuccess(false)
							setUIStatus(UIState.NORMAL)
							search(true)
						},
						tunning.MODAL_DISMISS_DELAY
					)
				},
				(error : any)=>
				{
					console.log(error)
					setUIStatus(UIState.ERROR)
					dispatch(disableIdleLockSwitch())
					setSaveError
					(
						getErrorMessage(error)
					)
				},
			)
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableRecord({...editableRecord, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				const startingDate = new Date()
				const year         = startingDate.getFullYear()

				setNewRecord
				({
					name                             : "",
					description                      : "",
					bool_payment_date_unmodifiable   : false,
					bool_payment_reference_nullified : false,
					accountIds                       : []
				})

				search()
				getRecipientAccounts(campusRef[currentUserCampus].company.country_id)
			}
		},
		[]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()				
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setCustomSearch(defaultSearch)
				setSectionLoaded(false)
				setData([])
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				search()
				getRecipientAccounts(campusRef[currentUserCampus].company.country_id)
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const getRecipientAccounts = async (campusId? : number)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result           = await RecipientAccountsService.getRecipientAccounts(campusId)
			let normalData   : any = []
			let disabledData : any = []

			if(result.status == 200 || result.status == 204)
			{
				setRecipientAccounts(result.data)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(`${searchError}, ${getErrorMessage(error)}`)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const search = async (basicInfoRenewalCheck? : boolean)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result           = await PaymentMethodsService.getRecords()
			let normalData   : any = []
			let disabledData : any = []

			if(result.status == 200 || result.status == 204)
			{
				result.data.map
				(
					(record : any, index : number)=>
					{
						if(record.bool_read_only)
						{
							disabledData.push(record)
						}
						else
						{
							normalData.push
							({
								selected : false,
								data     : record
							})
						}
					}
				)

				setData(normalData)
				setReadOnlyData(disabledData)
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(`${searchError}, ${getErrorMessage(error)}`)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value}
					}

					return record
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected}
				}
			)
		)

		setSelectedRecords(totalRecords * (+selected))
	}

	const closePrompt = ()=>
	{
		setSelectedRecord(null)
		setEditableRecord(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar resgistro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const recordIds : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any) => item.selected).map((item : any) => item.data.id)


					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await PaymentMethodsService.removeRecords(recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : {...record.data, bool_payment_date_unmodifiable : record.data.bool_payment_date_unmodifiable > 0}}}))
							setSelectedRecords(0)
							search(true)
						}
					}
					catch(error: any)
					{
						console.log(error)
						procedureComplaint(getErrorMessage(error))
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded ? <></>: <>
		<SectionContainer clearSearchRequested={clearSearchRequested} selectedRecords={selectedRecords} title={"Métodos de pago"}
		  customSearch={customSearch} removalFn={confirmRemoval} searchFn={search} clearSearchFn={clearSearch} allowed={allowed}
		  defaultSearch={defaultSearch} addingPromptFn={() => showPrompt(newRecord)} searchChangeFn={handleSearchChange}
		>{
			(data.length < 1 && readOnlyData.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					{Constants.noSearchResults}
				</div>
				<hr />
			</>
				:
			<>
				<div className="underlineHeadingContainer"  style={{marginBottom : "unset"}}>
					{
						(data.length < 1 || !allowed.delete) ? "" :
						<div style={{display : "table-row"}}>
							<button className="btn btn-default" onClick={toggleSelectAll} type="button"
							  disabled={idleLocked || saveSuccess} id="selectAll" style={{display : "table-cell"}}
							  name="selectAll"
							>
								{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
							</button>
							<div style={{display : "table-cell"}}>
								<label htmlFor="selectAll" style={{position : "relative", top : "5px"}}>
									<span className="d-none d-sm-inline-block">
										Seleccionar
									</span>
									&nbsp;
									<span>
										todo
									</span>
								</label>
							</div>
						</div>
					}
					<hr/>
				</div>
				<div style={{display : "inline-flex", flexWrap : "wrap", borderColor : "#000000", borderStyle : "solid", borderWidth : "0px 1px", borderRadius : "15px", padding : "8px 15px 0px 15px"}}>
					<div><label style={{position : "relative"}}><b>
						Restricciones para pagos:
					</b></label></div>
					<div><label style={{position : "relative", paddingLeft : "5px"}}>
						<FontAwesomeIcon icon={solid("lock")} /> Fecha no editable
					</label></div>
					<div><label style={{position : "relative", paddingLeft : "5px"}}>
						<FontAwesomeIcon icon={solid("link-slash")} /> Referencia nula
					</label></div>
				</div>
				<div>
					{
						readOnlyData.filter((record : any) => isEmpty(customSearch.q) || record.name.indexOf(customSearch.q) >= 0).map
						(
							(record : any, index : number) => <div style={{display : "inline-grid", padding : "5px"}}
							  key={"T_" + index}><div  className="badge rounded-pill bg-primary" style={{padding : "7px"}}
							><div style={{display : "grid", margin : "0px 5px"}}><div className="prompt">
								{record.name}
							</div></div></div></div>
						)
					}
					{
						data.filter((record : any) => isEmpty(customSearch.q) || record.data.name.indexOf(customSearch.q) >= 0).map
						(
							(record : any, index : number) => <div style={{display : "inline-grid", padding : "5px"}}
							  key={"T_" + index}
							><table className="badge rounded-pill bg-primary" style={{padding : "0px"}}><tbody><tr>
								{
									!allowed.delete ? "" : <td style={{padding : "5px"}}>
										<button type="button" className="btn btn-default to check"
										  disabled={idleLocked} onClick={() => toggleRecordSelection(index)}
										>{
											!record.selected ? "" :
												<FontAwesomeIcon icon={solid("check")} />
										}</button>
									</td>
								}
								{
									!record.data.bool_payment_date_unmodifiable ? "" :<td style={{paddingLeft : "5px"}}>
										<FontAwesomeIcon icon={solid("lock")} />
									</td>
								}
								{
									!record.data.bool_payment_reference_nullified ? "" : <td style={{paddingLeft : "5px"}}>
										<FontAwesomeIcon  icon={solid("link-slash")} />
									</td>
								}
								<td style={{display : "grid", margin : "0px 5px"}}><button disabled={idleLocked} type="button"
								  onClick={() => allowed.update && showPrompt(record.data)} className="btn btn-default prompt"
								>
									{record.data.name}
								</button></td>
								{
									!allowed.delete ? "" : <td style={{paddingRight : "5px"}}><button disabled={idleLocked}
									  onClick={() => {confirmRemoval(record.data.id)}} className="btn btn-default to" type="button"
									>
										<FontAwesomeIcon icon={solid("times")} />
									</button></td>
								}
							</tr></tbody></table></div>
						)
					}
				</div>
			</>
		}</SectionContainer>
		{
			selectedRecord == null ? "" : <DataDisplayModal dataEditInit={() => setEditableRecord(selectedRecord)}
			  UIStatus={UIStatus} saveDataFn={saveRecord} processingError={saveError} unchangedRecord={unchangedRecord}
			  headIcon={<FontAwesomeIcon icon={solid("money-bill-transfer")} />} saveSuccess={saveSuccess} form={recordFormRef}
			  promptCloseFn={closePrompt} entityName={"Método de pago"} saveLock={state.isButtonDisabled}
			  editableRecord={editableRecord} unfilledFields={unfilledFields} allowedUpdate={allowed.update} unrequiredEmphasis
			><form ref={recordFormRef} className="form" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			>
				<div className="inputContainer">
					<label htmlFor="name">
						Nombre
					</label>
					{
						editableRecord == null
							?
						<div className="roView">
							<br />
							{selectedRecord.name}
						</div>
							:
						<input onFocus={validateField} style={{width : "100%"}} ref={nameRef} placeholder="Nombre" id="name"
						  maxLength={50} onBlur={validateField} name="name" disabled={idleLocked} value={editableRecord.name}
						  onChange={handleRegistryChange} type="text" required
						/>
					}
				</div>
				<div className="col-md-6">
				<div>
					<label htmlFor="tags" style={{position : "unset"}}>
						Cuenta Receptora
					</label>
					{
						editableRecord == null
								?
							<div>{
								selectedRecord.recipientAccounts.length < 1 ? "-" : selectedRecord.recipientAccounts.map
								(
									(account : any) => <div className="badge badge-pill bg-primary" key={"cr_" + account.id}>
										{account.name}
									</div>
								)
							}</div>
								:
							<Multiselect options={recipientAccounts} displayValue="name" emptyRecordMsg="No hay más opciones"
							  onSelect={(recipientAccounts) => setEditableRecord({...editableRecord, accountIds : recipientAccounts.map((account: any) => account.id).join(',')})}
							  onRemove={(recipientAccounts) => setEditableRecord({...editableRecord, accountIds : recipientAccounts.map((account: any) => account.id).join(',')})}
							  selectedValues={editableRecord.recipientAccounts}
							/>
					}
					</div>
				</div>
				<br /><br />
				<UnderlineHeading name={"Restricciones para pagos"} />
				<div style={{display : "flex", flexWrap: "wrap"}}>
					<button type="button" disabled={idleLocked} style={{border : "1px solid var(--main-txt-color)"}}
					  onClick={() => setEditableRecord({...editableRecord, bool_payment_date_unmodifiable : !editableRecord.bool_payment_date_unmodifiable})}
					  className="btn btn-default"
					>
						<CustomIcon name={editableRecord.bool_payment_date_unmodifiable ?  "bCheck" : "bUncheck"} />
						<span style={{paddingLeft : "5px"}}>
							Fecha no editable
						</span>
					</button>
					<button type="button" disabled={idleLocked} style={{border : "1px solid var(--main-txt-color)"}}
					  onClick={() => setEditableRecord({...editableRecord, bool_payment_reference_nullified : !editableRecord.bool_payment_reference_nullified})}
					  className="btn btn-default"
					>
						<CustomIcon name={editableRecord.bool_payment_reference_nullified ?  "bCheck" : "bUncheck"} />
						<span style={{paddingLeft : "5px"}}>
							Referencia nula
						</span>
					</button>
				</div>
			</form></DataDisplayModal>
		}
	</>
}

export default PaymentMethods
