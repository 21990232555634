const UnderlineHeading = (props : any)=>
{
	return <div className="underlineHeadingContainer" style={props.style}>
		<span style={{paddingLeft : "15px"}}>
			{props.name}
		</span>
		<hr/>
	</div>
}

export default UnderlineHeading