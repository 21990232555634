import {useState, useEffect, useRef}
	from "react"
import {default as SelectR}
	from "react-select"
/*
	SelectReact component
	recives (value : string, children : {value : string, id : string}[], onChange?:Function)
		and returns the SelectReact component

	value(optional)       = the start value in the SelectReact
	placeholder(optional) = the placeholder in SelectReact
	onChangue(optional)   = callback function. this function is executed when the date value changes
	children(required)    = object array for SelectReact options
	{
		id:string,
		label:string
	}

	Usage:
	<SelectReact value={{id : 1, label : México}} onChange={(country : {id : string, label : string}) => console.log(e)
	  placeholder="País" children={[{ id: 1, label: México }, { id: 2, label: USA }]}
	>
	</SelectReact>
*/
export const SelectReact = (props : any)=>
{
	//change value in select
	useEffect(() => setValue(props.value), [props.value])
	//init value in select---------------------------------------------------------------------------------------------------
	const [value, setValue]               = useState(props.value)
	const [defaultValue, setDefaultValue] = useState(props.defaultValue)
	//your custom styles-------------------------------------------------------------------------------------------------------
	const customStyles=
	{
		menuPortal : (base : any) => ({ ...base, zIndex: 9999 }),
		option     : (provided : any, state : any)=>
		({
			...provided, color : state.isSelected ? "inherit" : "inherit",
			backgroundColor    : state.isSelected ? "inherit" : "inherit",
			"&:hover"          : {backgroundColor : state.isSelected ? "#8F91DA" : "#8F91DA"},
		})
	}

	return<SelectR className="reactSelectContainer" value={value} defaultValue={defaultValue} id={props.id} name={props.name}
	  onChange={(e) => props.onChange({target : {name : props.name, value : e}})} options={props.children} onFocus={props.onFocus}
	  placeholder={props.placeholder ? props.placeholder : ""} blurInputOnSelect={true} classNamePrefix="react-select"
	  styles={customStyles} isDisabled={props.disabled} isClearable={props.clearable} menuPortalTarget={document.body}
	/>
}

export default SelectReact
