import React
	from "react"
import ReactDOM
	from "react-dom/client"
import {BrowserRouter}
	from "react-router-dom"
import {Provider}
	from "react-redux"
import store
	from "./globals"
import App
	from "./app"
import reportWebVitals
	from "./reportWebVitals"

import "bootstrap/dist/css/bootstrap.min.css"
import "typeface-roboto"
import "./assets/css/theme.css"
import "./assets/css/style.css"
import "./style.css"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render
(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
)

reportWebVitals()
