import axios
	from "axios"
import store
	from "./../globals"
import _
	from "lodash"

import {removeRecords}
	from "./../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/";

class SimpleDataService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async getRecords(APISuffix : string)
	{
		return axios.get
		(
			RESOURCE_URL + APISuffix,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveRecord(APISuffix : string, entity : any)
	{
		const saveFunction = entity.id == null ? this.registerRecord : this.updateRecord

		return saveFunction
		(
			APISuffix,
			{name : entity.name + ""},
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			entity.id
		)
	}

	async registerRecord(APISuffix : string, registry : any, options : any)
	{
		return axios.post(RESOURCE_URL + APISuffix, registry, options)
	}

	async updateRecord(APISuffix : string, registry : any, options : any, id : number)
	{
		return axios.put(RESOURCE_URL + APISuffix + "/" + id, registry, options)
	}

	async removeRecords(APISuffix : string, recordIds : any)
	{
		return removeRecords(RESOURCE_URL + APISuffix, recordIds)
	}
}

export default new SimpleDataService()