import {useMemo}
	from "react"

/**
 * Custom hook that calculates styles based on current capacity
 * and maximum capacity, rounding the percentage up.
 * @param {number} currentCapacity - The current capacity.
 * @param {number} maxCapacity - The maximum capacity.
 * @returns {Object} - Object containing the calculated styles.
 */
export const useCapacityStyles = (currentCapacity : number, maxCapacity : number) : object =>
{
	const styles = useMemo
	(
		() =>
		{
			// Calculate the percentage and round up
			const percentage = Math.ceil((currentCapacity / maxCapacity) * 100)
			let color        = "#000000" // Default text color
			let fontWeight   = "normal"  // Default font weight
			let background   = "none"    // Default background

			if(percentage > 125)
			{
				color      = "#FF0000" // Red
				fontWeight = "bold"
			}
			else
			{
				if(percentage >= 100)
				{
					color      = "#FFA500" // Yellow
					fontWeight = "bold"
				}
				else
				{
					if(percentage >= 70)
					{
						color = "#0000FF" // Blue
						// fontWeight remains 'normal'
					}
					else
					{
						color      = "#008000" // Green
						fontWeight = "bold"
					}
				}
			}

			return {color, fontWeight, background}
		},
		[currentCapacity, maxCapacity]
	)// Dependencies: currentCapacity and maxCapacity

	return styles
}
/**
 * Custom hook that calculates styles based on the pending balances,
 * applying a specific style if there is at least one pending balance.
 * @param {number} pendingBalancesCount - The number of pending balances.
 * @returns {Object} - Object containing the calculated styles.
 */
export const usePendingBalancesStyles = (pendingBalancesCount : number) : object=>
{
	const styles = useMemo
	(
		()=>
		{
			let color           = "#000000" // Default text color
			let fontWeight      = "bold"    // Default font weight
			let backgroundColor = "inherit" // Default background color

			if(pendingBalancesCount >= 1)
			{
				color           = "#ffffff" // white
				backgroundColor = "#ff0000" // Red background
			}

			return {color, fontWeight, backgroundColor};
		},
		[pendingBalancesCount]
	) // Dependency: pendingBalancesCount

	return styles
}

/**
 * Custom hook that calculates styles based on the pending balances,
 * applying a specific style if there is at least one pending balance.
 * @param {number} pendingKitsBalancesCount - The number of pending balances.
 * @returns {Object} - Object containing the calculated styles.
 */
export const usePendingKitsBalancesStyles = (pendingKitsBalancesCount : number) : object=>
{
	const styles = useMemo
	(
		()=>
		{
			let color           = "#000000" // Default text color
			let fontWeight      = "bold"    // Default font weight
			let backgroundColor = "inherit" // Default background color

			if(pendingKitsBalancesCount >= 1)
			{
				color           = "#000000"; // black
				backgroundColor = "#FF0000"; // Red background
			}

			return {color, fontWeight, backgroundColor}
		},
		[pendingKitsBalancesCount]
	) // Dependency: pendingKitsBalancesCount

	return styles
}

/**
 * Custom hook that calculates styles based on the current attendance
 * and the ideal total attendance, following specific rules.
 * @param {number} currentAttendance - The current attendance.
 * @param {number} idealAttendance - The ideal total attendance.
 * @returns {Object} - Object containing the calculated styles.
 */
export const useAttendanceStyles = (currentAttendance : number, idealAttendance : number) : object=>
{
	const styles = useMemo
	(
		()=>
		{
			// Calculate the percentage of attendance
			const percentage = idealAttendance > 0 ? (currentAttendance / idealAttendance) * 100 : 100

			let color      = "#000000" // Default text color
			let fontWeight = "bold"    // Default font weight
			let background = "none"    // Default background

			if(percentage >= 100)
			{
				color = "#008000" // Green
			}
			else
			{
				if(percentage >= 75 && percentage < 100)
				{
					color = "#0000FF" // Blue
				}
				else
				{
					color = (percentage >= 51 && percentage < 75)
						? "#FFA500"     // Yellow
							: "#FF0000" // Red
				}
			}

			return {color, fontWeight, background}
		},
		[currentAttendance, idealAttendance]
	)// Dependencies: currentAttendance and idealAttendance

	return styles
}

/**
 * Custom hook that calculates styles based on the number of enrollments
 * and the minimum required enrollment, following specific rules.
 * @param {number} currentEnrollments - The current number of enrollments.
 * @param {number} minEnrollments - The minimum required enrollment.
 * @returns {Object} - Object containing the calculated styles.
 */
export const useEnrollmentStyles = (currentEnrollments : number, minEnrollments : number) : object=>
{
	const styles = useMemo
	(
		()=>
		{
			// Calculate the percentage of enrollments compared to the minimum
			const percentage = (currentEnrollments / minEnrollments) * 100;
			let   color      = "#000000" // Default text color
			let   fontWeight = "bold"    // Default font weight
			let   background = "none"    // Default background

			if (currentEnrollments < minEnrollments)
			{
				color = "#ff0000" // Red
			}
			else
			{
				color = (currentEnrollments >= minEnrollments && currentEnrollments < (minEnrollments + (0.25 * minEnrollments)))
					? "#FFA500"     // Yellow
						: "#0000FF" // Blue
			}

			return {color, fontWeight, background}
		},
		[currentEnrollments, minEnrollments]
	)// Dependencies: currentEnrollments and minEnrollments

	return styles
}