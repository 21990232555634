import React, {useState, useRef, useReducer, useEffect, forwardRef, ForwardedRef}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid, regular}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import _
	from "lodash"
import {getDay}
	from "date-fns"
import {Popover}
	from "react-tiny-popover"
import {sprintf}
	from "sprintf-js"
import Hashids
	from "hashids"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice
}
	from "./../../globals"
import
{
	UIState, validateField, weekDays, dayHours, hashCode, monthRef, retardWeekDayOffset, toAcceptableDate, attendanceStatus,
	dayHalf, isNotEmpty   , getAccessPermissions
}

	from "./../../common"
import CustomIcon
	from "./../../components/customIcon"
import UnderlineHeading
	from "./../../components/underlineHeading"
import LeDatePicker
	from "./../../components/leDatePicker"
import CatalogService
	from "./../../services/catalog.service"
import MentorsService
	from "./../../services/campus/mentors.service"
import AttendancesService
	from "./../../services/campus/attendances.service"
import SchedulesService
	from "./../../services/campus/schedules.service"
import defaultCourseImage
	from "./../../assets/images/course.png"

import "./../../components/dropdown/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action  = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{
				...state,
				isButtonDisabled : action.payload
			}
		break;
	}
}

const Attendances = ()=>
{
	const [state, trigger]                                                  = useReducer(reducer, initialState)
	const dispatch                                                          = useDispatch()
	const userProfile       : any                                           = useSelector((state : RootState) => state.userProfile.value)
	const idleLocked        : boolean                                       = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null                                 = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean                                       = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo         : any                                           = useSelector((state : RootState) => state.basicInfo.value)
	const campusRef         : any                                           = useSelector((state : RootState) => state.campusRef.value)
	const currentUserCampus : any                                           = useSelector((state : RootState) => state.currentUserCampus.value);
	const currentUser       : any                                           = useSelector((state : RootState) => state.userProfile.value)
	const [userCampus, setUserCampus]                                       = useState<number>(currentUserCampus)
	const [noStudentsEnrolledForSchedule, setNoStudentsEnrolledForSchedule] = useState<number>(0)
	const [unchangedRecord, setUnchangedRecord]                             = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                               = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]                                 = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested]                   = useState<boolean>(false)
	const [compensateStudAddComplaint, setCompensateStudAddComplaint]       = useState<string | undefined>(undefined)
	const [UIStatus, setUIStatus]                                           = useState<number>(UIState.NORMAL)
	const [dateCount, setDateCount]                                         = useState<number>(0)
	const [defaultSearch, setDefaultSearch]                                 = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                                   = useState<any>(defaultSearch)
	const [scheduleAttendances, setScheduleAttendances]                     = useState<any>({})
	const [assessmentDateValue, setAssessmentDateValue]                     = useState<any>(null)
	const [newTag, setNewTag]                                               = useState<any>(null)
	const [selectedSchedule, setSelectedSchedule]                           = useState<any>(null)
	const [selectedTag, setSelectedTag]                                     = useState<any>(null)
	const [editableTag, setEditableTag]                                     = useState<any>(null)
	const [hourRef, setHourRef]                                             = useState<any>(null)
	const [currentDate, setCurrentDate]                                     = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                                     = useState<boolean>(false)
	const [selectedDay, setSelectedDay]                                     = useState<any>(null)
	const [editableDay, setEditableDay]                                     = useState<any>(null)
	const [newCompensationStudent, setNewCompensationStudent]               = useState<any>(null)
	const [attendanceStatuses, setAttendanceStatuses]                       = useState<any>([])
	const [scheduleDates, setScheduleDates]                                 = useState<any>([])
	const [yearsRange, setYearsRange]                                       = useState<any>([])
	const [recordsSelection, setRecordsSelection]                           = useState<any>([])
	const [schedules, setSchedules]                                         = useState<any>([])
	const [scheduleRef, setScheduleRef]                                     = useState<any>([])
	const [attendanceStudentRef, setAttendanceStudentRef]                   = useState<any>({})
	const [attendanceCountRef, setAttendanceCountRef]                       = useState<any>({})
	const [dateList, setDateList]                                           = useState<any>([])
	const [dateRef, setDateRef]                                             = useState<any>({})
	const [monthDateCount, setMonthDateCount]                               = useState<any>({})
	const [compensationStudentRef, setCompensationStudentRef]               = useState<any>({})
	const [compensationDateRef, setCompensationDateRef]                     = useState<any>({})
	const [compensationDayStudentRef, setCompensationDayStudentRef]         = useState<any>({})
	const count        : number[]                                           = [-1, 1]
	const allowed      : any                                                = getAccessPermissions(currentUser)
	let   recordFormRef                                                           = useRef<HTMLFormElement >(null)
	let   nameRef                                                           = useRef<HTMLInputElement>(null)
	let   codeRef                                                           = useRef<HTMLInputElement>(null)
	let   descriptionRef                                                    = useRef<HTMLTextAreaElement>(null)

	const hidePrompt = ()=>
	{
		if(editableTag && editableTag.id == null)
		{
			setSelectedTag(null)
		}

		setEditableTag(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)
		setEditableTag(recordData)
		setSelectedTag(recordData)
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch
			({
				...customSearch,
				[e.target.name] : e.target.value
			})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableTag
			({
				...editableTag,
				[e.target.name] : e.target.value
			})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					procedureComplaint("No hay planteles registrados o asignados al rol en uso por el usuario, por lo que el acceso a este módulo permanecerá deshabilitado.")
				}
				else
				{
					const initError : string = "La búsqueda iniclal de datos no pudo ser completada"

					try
					{
						dispatch(enableIdleLockSwitch())

						const result = await CatalogService.getAttendanceStatuses(sessionToken)

						if(result.status == 200 || result.status == 204)
						{
							if(result.data.length < 1)
							{
								procedureComplaint("No hay estatuses de asistencia registrados en el sistema")
							}
							else
							{
								setAttendanceStatuses(result.data)

								initFn()
							}
						}
						else
						{
							procedureComplaint(initError)
							dispatch(disableIdleLockSwitch())
						}
					}
					catch(error)
					{
						procedureComplaint(initError)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	const initFn = async()=>
	{
		const initError = "La búsqueda iniclal de datos no pudo ser completada"

		try
		{
			dispatch(enableIdleLockSwitch())

			const lecturesRequest : any = userProfile.currentRole == "mentor" ? MentorsService.getLectures : SchedulesService.getLectures
			const result          : any = await lecturesRequest(campusRef[currentUserCampus].id)

			if(result.status == 200 || result.status == 204)
			{
				let formattedData           : any
				let refIndex                : any
				let sessionIndex            : any
				//To restrict acces in case of classrooms with no linked building, period, or with a period not linked to any campus is found
				let agenda                  : any     = {}
				let schedIndex              : any     = {}
				let unlinkedClassroomsFound : boolean = false
				const hashIds                         = new Hashids()

				setSchedules
				(
					result.data.data.map
					(
						(schedule : any)=>
						{
							sessionIndex = {}
							refIndex     = "S_" + schedule.id

							if(schedule.classroom.building == null || schedule.period == null || schedule.period.campus_id == null)
							{
								unlinkedClassroomsFound = true

								return
							}

							schedule.sessions.map
							(
								(session : any)=>
								{
									let currentHours = session.start_hours

									sessionIndex["S_" + session.week_day] = session

									while(currentHours < session.end_hours)
									{
										agenda[sprintf("DH%d%03d", session.week_day, currentHours)] = refIndex

										currentHours += 5
									}
								}
							)

							formattedData=
							{
								...schedule, refIndex   : refIndex,
								sessionRef              : sessionIndex,
								//RGB code generation based on hashing the distinct parenting ids----------------------------
								//(schedule, level and course)---------------------------------------------------------------
								color                   : sprintf
								(
									"#%02X%02X%02X",
									(hashCode(hashIds.encode(schedule.id)) & 255),
									(hashCode(schedule.level.name        ) & 255),
									(hashCode(schedule.level.course.name ) & 255)
								)
							}

							schedIndex[formattedData.refIndex] = formattedData

							return formattedData
						}
					)
				)

				if(unlinkedClassroomsFound)
				{
					procedureComplaint
					(
						"Algunos horarios pertenecen a salones sin edificio o periodo asociado, o el periodo asociado no pertenece a ningún plantel, " +
								"por lo que no se podrá acceder a esta sección."
					)
				}
				else
				{
					//Adding a (null) time part avoids discrepancies converting the date string into a datetype value--------
					setCurrentDate(new Date(result.data.date + " 23:00:00"))
					setScheduleRef(schedIndex)
					setHourRef(agenda)
					setSectionLoaded(true)
				}
			}
			else
			{
				procedureComplaint(initError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(initError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}
	
	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setSelectedSchedule(null)
				setSchedules([])
				initFn()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS) || (unchangedRecord || unfilledFields)
			})
		},
		[UIStatus, unchangedRecord, unfilledFields]
	)
//---------------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const closePrompt = ()=>
	{
		setSelectedTag(null)
		setEditableTag(null)
	}

	//What's being used to make things work----------------------------------------------------------------------------------
	//A map with all attendance values referenced by a date_studenId key-----------------------------------------------------
	//Another map for just the day selected, still referring all the schedule students if they have an asistance value.------
	//Another map listing the compensation students--------------------------------------------------------------------------
	//Once a schedule is selected, all mentor lecture sessions are retrieved along with their respective info.---------------
	//A date is selected to be editable first, considering the actual date---------------------------------------------------
	const selectSchedule = async (schedule : any, index? : number)=>
	{
		const startYear : number = parseInt
		(
			schedule
				.period
				.start
				.split("/")[0]
		)

		const endYear   : number = parseInt
		(
			schedule
				.period
				.end
				.split("/")[0]
		)

		setYearsRange
		(
			startYear == endYear
					?
				[startYear]
					:
				Array(endYear - startYear).fill(undefined).map((element, index) => index + startYear)
		)

		let validDate    : boolean = false
		let today        : Date    = new Date(currentDate)
		let weekDay      : number  = retardWeekDayOffset[today.getDay()]

		let daySelection : number | undefined

		if(index != null)
		{
			daySelection = index
		}
		else
		{
			if(schedule.sessionRef["S_" + weekDay] != null)
			{
				daySelection = weekDay
			}
			else
			{
				daySelection = parseInt(Object.keys(schedule.sessionRef).sort()[0].split("_")[1])
			}
		}

		let   dateSelected        : Date     = currentDate
		let   dateSelection       : string   = toAcceptableDate(dateSelected)
		let   ymdSelection        : string[] = dateSelection.split("-")
		let   monthSelection      : string   = monthRef[parseInt(ymdSelection[1]) - 1]
		let   hours               : number
		let   minutesPart         : number
		let   dateSelectedWeekDay : number
		const searchError         : string = "La consulta de horarios de asistencia solicitada no pudo ser ejecutada"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await AttendancesService.getScheduleAttendances(campusRef[currentUserCampus].id, schedule.id)

			if(result.status == 200|| result.status == 204)
			{
				let dateListing              : any = {}
				let monthDateCounting        : any = {}
				let attendanceStudentIndex   : any = {}
				let studentIndex             : any = {}
				let compensationStudentIndex : any = {}
				let compensationDayList      : any = {}
				let dayAttendanceIndex       : any = {}
				let compensationRef          : any = {}
				let datePart                 : string
				let monthPart                : string
				let dayPart                  : string
				let dateKeys                 : string[]
				let ymd                      : any
				let compensateDayStudIndex   : any = {}
				let   attendanceCountIndex   : any = {}

				result.data.regularAttendances.map
				(
					(attendance : any)=>
					{
						datePart  = attendance.attendance_datetime.split(" ")[0]
						ymd       = datePart.split("-")
						monthPart = monthRef[parseInt(ymd[1]) - 1]

						if(dateListing[datePart] == null)
						{
							dateListing[datePart] = weekDays
								[retardWeekDayOffset[(new Date(attendance.attendance_datetime)).getDay()]].substring(0, 2) + ymd[2]

							if(monthDateCounting[monthPart] == null)
							{
								monthDateCounting[monthPart] = 0
							}

							monthDateCounting[monthPart]++
						}

						if(attendanceCountIndex["S_" + attendance.student_id] == null)
						{
							attendanceCountIndex["S_" + attendance.student_id] = 0
						}

						if(attendance.status_id != attendanceStatus.ABSENT)
						{
							attendanceCountIndex["S_" + attendance.student_id]++
						}

						attendanceStudentIndex[datePart + "S_" + attendance.student_id] = attendance.status_id
					}
				)

				schedule.enrollments.map
				(
					(enrollment : any)=>
					{
						if(attendanceCountIndex["S_" + enrollment.student_id] == null)
						{
							attendanceCountIndex["S_" + enrollment.student_id] = 0
							studentIndex["S_" + enrollment.student_id]         = enrollment.student
						}
					}
				)

				result.data.compensationAttendances.map
				(
					(attendance : any)=>
					{
						datePart  = attendance.attendance_datetime.split(" ")[0]
						ymd       = datePart.split("-")
						monthPart = monthRef[parseInt(ymd[1]) - 1]

						if(dateListing[datePart] == null)
						{
							dateListing[datePart] = weekDays[retardWeekDayOffset[(new Date(attendance.attendance_datetime)).getDay()]].substring(0, 2) + ymd[2]

							if(monthDateCounting[monthPart] == null)
							{
								monthDateCounting[monthPart] = 0
							}

							monthDateCounting[monthPart]++
						}

						attendanceStudentIndex[datePart + "S_" + attendance.student_id] = attendance.status_id

						if(compensationDayList[datePart] == null)
						{
							compensationDayList[datePart] = []
						}

						compensationDayList[datePart].push("S_" + attendance.student_id)
					}
				)
				//If actual date is outside the limits of the period, it will use the closest date on the extremes---
				if(dateSelection < schedule.period.start || dateSelection > schedule.period.end)
				{
					if(dateSelection < schedule.period.start)
					{
						dateSelected        = new Date(schedule.period.start + " 23:00:00")
						dateSelectedWeekDay = retardWeekDayOffset[dateSelected.getDay()]

						while(dateSelectedWeekDay != daySelection)
						{
							dateSelected.setDate(dateSelected.getDate() + 1)

							dateSelectedWeekDay = retardWeekDayOffset[dateSelected.getDay()]
						}
					}
					else
					{
						dateSelected        = new Date(schedule.period.end + " 23:00:00")
						dateSelectedWeekDay = retardWeekDayOffset[dateSelected.getDay()]

						while(dateSelectedWeekDay != daySelection)
						{
							dateSelected.setDate(dateSelected.getDate() - 1)
							dateSelectedWeekDay = retardWeekDayOffset[dateSelected.getDay()]
						}
					}

					dateSelection = toAcceptableDate(dateSelected)
				}
				else
				{
					if(schedule.sessionRef["S_" + weekDay] == null)
					{
						dateSelected = new Date(currentDate)

						while(schedule.sessionRef["S_" + retardWeekDayOffset[dateSelected.getDay()]] == null)
						{
							dateSelected.setDate(dateSelected.getDate() - 1)
						}

						if(toAcceptableDate(dateSelected) < schedule.period.start)
						{
							dateSelected = new Date(currentDate)

							while(schedule.sessionRef["S_" + retardWeekDayOffset[dateSelected.getDay()]] == null)
							{
								dateSelected.setDate(dateSelected.getDate() + 1)
							}
						}

						dateSelection = toAcceptableDate(dateSelected)
					}
				}

				if(dateListing[dateSelection] == null)
				{
					ymd                        = dateSelection.split("-")
					monthSelection             = monthRef[parseInt(ymd[1]) - 1]
					dateListing[dateSelection] = weekDays[retardWeekDayOffset[dateSelected.getDay()]].substring(0, 2) + ymd[2]

					if(monthDateCounting[monthSelection] == null)
					{
						monthDateCounting[monthSelection] = 0
					}

					monthDateCounting[monthSelection]++
				}

				weekDay     = retardWeekDayOffset[dateSelected.getDay()]
				hours       = schedule.sessionRef["S_" + weekDay].start_hours
				minutesPart = hours % 10

				if(compensationDayList[dateSelection])
				{
					compensateDayStudIndex[dateSelection] = {}

					compensationDayList[dateSelection].map
					(
						(refIndex : any)=>
						{
							compensateDayStudIndex[dateSelection][refIndex] = result.data.compensationStudentRef[refIndex]
						}
					)
				}

				schedule.enrollments.map
				(
					(enrollment : any)=>
					{
						if(attendanceStudentIndex[dateSelection + "S_" + enrollment.student_id] != null)
						{
							dayAttendanceIndex["S_" + enrollment.student_id] = attendanceStudentIndex[dateSelection + "S_" + enrollment.student_id]
						}
					}
				)

				let day : any=
				{
					isNew               : Object.keys(dayAttendanceIndex).length < 1 &&
						(compensateDayStudIndex[dateSelection] == null || Object.keys(compensateDayStudIndex[dateSelection]).length < 1),
					date                : dateSelection,
					month               : monthSelection,
					attendanceRef       : dayAttendanceIndex,
					attendanceCountRef  : attendanceCountIndex,
					compensationDayList : compensationDayList[dateSelection],
					compensationDayRef  : compensateDayStudIndex[dateSelection],
					datetime            : dateSelection + sprintf
					(
						" %02d:%02d:00",
						(hours - minutesPart) / 10,
						minutesPart * 6
					),
					hour                : sprintf
					(
						"%02d:%02d " + dayHalf[ +(hours > 115) ],
						(hours - (12 * (+(hours > 115)) - minutesPart)) / 10,
						minutesPart * 6
					)
				}

				dateKeys = Object.keys(dateListing).sort()

				setSelectedDay(day)
				setEditableDay(day)
				setDateRef(dateListing)
				setAssessmentDateValue(currentDate)
				setMonthDateCount(monthDateCounting)
				setAttendanceCountRef(attendanceCountIndex)
				setDateList(dateKeys)
				setDateCount(dateKeys.length)
				setAttendanceStudentRef(attendanceStudentIndex)
				setCompensationDayStudentRef(compensateDayStudIndex)
				setCompensationStudentRef(result.data.compensationStudentRef)

				setSelectedSchedule
				({
					...schedule,
					studentRef           : studentIndex,
					compensationStudents : result.data.compensationstudents
				})
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}
	//All lecture are retrieved, adding a new date if not related records are present on database----------------------------
	const getScheduleAttendances = async(date : Date)=>
	{
		let dateSelection = toAcceptableDate(date)
		const searchError = "La consulta de horarios de asistencia solicitada no pudo ser ejecutada"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await AttendancesService.getScheduleAttendances
				(campusRef[currentUserCampus].id, selectedSchedule.id)

			if(result.status == 200 || result.status == 204)
			{
				let   dateListing            : any = {}
				let   monthDateCounting      : any = {}
				let   attendanceStudentIndex : any = {}
				let   dayAttendanceIndex     : any = {}
				let   compensationDayList    : any = {}
				let   datePart               : string
				let   monthPart              : string
				let   dayPart                : string
				let   dateKeys               : string[]
				let   ymd                    : any
				const weekDay                : number = retardWeekDayOffset[date.getDay()]
				const hours                  : number = selectedSchedule.sessionRef["S_" + weekDay].start_hours
				const minutesPart            : number = hours % 10
				let   attendanceCountIndex   : any = {}
				let   compensateDayStudIndex : any = {}

				result.data.regularAttendances.map
				(
					(attendance : any)=>
					{
						datePart  = attendance.attendance_datetime.split(" ")[0]
						ymd       = datePart.split("-")
						monthPart = monthRef[parseInt(ymd[1]) - 1]

						if(dateListing[datePart] == null)
						{
							dateListing[datePart] = weekDays[retardWeekDayOffset[(new Date(attendance.attendance_datetime)).getDay()]].substring(0, 2) + ymd[2]

							if(monthDateCounting[monthPart] == null)
							{
								monthDateCounting[monthPart] = 0
							}

							monthDateCounting[monthPart]++
						}

						if(attendanceCountIndex["S_" + attendance.student_id] == null)
						{
							attendanceCountIndex["S_" + attendance.student_id] = 0
						}

						if(attendance.status_id != attendanceStatus.ABSENT)
						{
							attendanceCountIndex["S_" + attendance.student_id]++
						}

						attendanceStudentIndex[datePart + "S_" + attendance.student_id] = attendance.status_id
					}
				)

				selectedSchedule.students.map
				(
					(student : any)=>
					{
						if(attendanceCountIndex["S_" + student.id] == null)
						{
							attendanceCountIndex["S_" + student.id] = 0
						}
					}
				)

				result.data.compensationAttendances.map
				(
					(attendance : any)=>
					{
						datePart  = attendance.attendance_datetime.split(" ")[0]
						ymd       = datePart.split("-")
						monthPart = monthRef[parseInt(ymd[1]) - 1]

						if(dateListing[datePart] == null)
						{
							dateListing[datePart] = weekDays[retardWeekDayOffset[(new Date(attendance.attendance_datetime)).getDay()]].substring(0, 2) + ymd[2]

							if(monthDateCounting[monthPart] == null)
							{
								monthDateCounting[monthPart] = 0
							}

							monthDateCounting[monthPart]++
						}

						attendanceStudentIndex[attendance.date + "S_" + attendance.student_id] = attendance.status_id

						if(compensationDayList[datePart] == null)
						{
							compensationDayList[datePart] = []
						}

						compensationDayList[datePart].push("S_" + attendance.student_id)
					}
				)

				if(dateListing[dateSelection] == null)
				{
					ymd                        = dateSelection.split("-")
					monthPart                  = monthRef[parseInt(ymd[1]) - 1]
					dateListing[dateSelection] = weekDays[retardWeekDayOffset[date.getDay()]].substring(0, 2) + ymd[2]

					if(monthDateCounting[monthPart] == null)
					{
						monthDateCounting[monthPart] = 0
					}

					monthDateCounting[monthPart]++
				}

				selectedSchedule.students.map
				(
					(student : any)=>
					{
						if(attendanceStudentIndex[dateSelection + "S_" + student.id] != null)
						{
							dayAttendanceIndex["S_" + student.id] = attendanceStudentIndex[dateSelection + "S_" + student.id]
						}
					}
				)

				if(compensationDayList[dateSelection])
				{
					compensateDayStudIndex[dateSelection] = {}

					compensationDayList[dateSelection].map
					(
						(refIndex : any)=>
						{
							compensateDayStudIndex[dateSelection][refIndex] = result.data.compensationStudentRef[refIndex]
						}
					)
				}

				let day =
				{
					isNew               : Object.keys(dayAttendanceIndex).length < 1 &&
						(compensateDayStudIndex[dateSelection] == null || Object.keys(compensateDayStudIndex[dateSelection]).length < 1),
					date                : dateSelection,
					attendanceRef       : dayAttendanceIndex,
					attendanceCountRef  : attendanceCountIndex,
					month               : monthRef[date.getMonth()],
					compensationDayList : compensationDayList[dateSelection],
					compensationDayRef  : compensateDayStudIndex[dateSelection],
					datetime            : dateSelection + sprintf
					(
						" %02d:%02d:00",
						(hours - minutesPart) / 10,
						minutesPart * 6
					),
					hour                : sprintf
					(
						"%02d:%02d " + dayHalf[ +(hours > 115) ],
						(hours - (12 * (+(hours > 115)) - minutesPart)) / 10,
						minutesPart * 6
					)
				}

				setSelectedDay(day)
				setEditableDay(day)

				dateKeys = Object.keys(dateListing).sort()

				setMonthDateCount(monthDateCounting)
				setDateRef(dateListing)
				setAssessmentDateValue(date)
				setDateList(dateKeys)
				setDateCount(dateKeys.length)
				setAttendanceStudentRef(attendanceStudentIndex)
				setAttendanceCountRef(attendanceCountIndex)
				setCompensationStudentRef(result.data.compensationStudentRef)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const removeDay = async()=>
	{
		if(selectedDay.isNew)
		{
			procedureComplaint
			(
				"El registro de este día es completamente nuevo. " +
					"Para descartarlo simplememte seleccione otra fecha u horario sin utilizar la opción de guardado."
			)
		}
		else
		{
			dispatch
			(
				displayNotice
				({
					heading:
						<h3 style={{color : "#0000FF", display : "inline-block"}}>
							Confirme
						</h3>,
					cornerClose : true,
					message     : "¿Eliminar registros de este día?",
					procedure   : async ()=>
					{
						const removalError : string = "Los registros del día no pudieron ser eliminados"

						dispatch(enableIdleLockSwitch())

						try
						{
							const result = await AttendancesService.removeDayAttendances
								(campusRef[currentUserCampus].id, selectedSchedule.id, selectedDay.datetime)

							if(result.status == 200 || result.status == 204)
							{
								selectSchedule(selectedSchedule)
							}
							else
							{
								procedureComplaint(removalError)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(removalError)
							dispatch(disableIdleLockSwitch())
						}
					}
				})
			)
		}
	}

	const saveDay = async(callbackFn? : any)=>
	{
		if(Object.keys(editableDay.attendanceRef).length < 1)
		{
			procedureComplaint("No se ingresó ningún estatus de asistencia para los alumnos regulares en el día seleccionado.")
		}
		else
		{
			if(Object.keys(editableDay.attendanceRef).length < selectedSchedule.enrollments.length)
			{
				procedureComplaint("Todos los alumnos del curso deben tener asignado un valor de asistencia para la sesión elegida.")
			}
			else
			{
				const saveError : string = "La información no pudo ser procesada"

				try
				{
					dispatch(enableIdleLockSwitch())

					const result = await AttendancesService.updateScheduleAttendances
						(campusRef[currentUserCampus].id, selectedSchedule.id, editableDay)

					if(result.status == 200 || result.status == 204)
					{
						let day=
						{
							...editableDay,
							saved : true
						}

						setEditableDay(day)
						setSelectedDay(day)

						if(callbackFn)
						{
							callbackFn()
						}
						else
						{
							selectSchedule(selectedSchedule)
						}
					}
					else
					{
						if(result.status == 404)
						{
							procedureComplaint("Hubo un error al procesar los datos. Verifique que los alumnos extra tengan una ausencia reciente para compensar en este horario")
						}
						else
						{
							procedureComplaint(saveError)
						}
						
					}
				}
				catch(error)
				{
					console.log(error)
					procedureComplaint(saveError)
				}
				finally
				{
					dispatch(disableIdleLockSwitch())
				}
			}
		}
	}

	const toggleStudentAttendance = (studentId : number)=>
	{
		let edittingDay : any    = {...editableDay}
		let refIndex    : string = "S_" + studentId
		let status      : number = edittingDay.attendanceRef[refIndex] == null
					?
				attendanceStatus.ON_TIME
					:
			(
				editableDay.attendanceRef[refIndex] == attendanceStatus.ON_TIME
						?
					attendanceStatus.ABSENT
						:
					attendanceStatus.ON_TIME
			)

		setEditableDay
		({
			...editableDay, attendanceRef : {...editableDay.attendanceRef, [refIndex] : status},
			attendanceCountRef            : {...editableDay.attendanceCountRef, [refIndex] : editableDay.attendanceCountRef[refIndex] + count[status]}
		})
	}

	const addCompensationStudent = (event : React.MouseEvent<HTMLButtonElement>)=>
	{
		setNewCompensationStudent({id : 0})
	}

	const linkCompensationStudent = async(event : React.MouseEvent<HTMLButtonElement>)=>
	{
		let complaint : string | null = null

		if(isNaN(newCompensationStudent.id) || newCompensationStudent.id <= 0)
		{
			complaint = "Proporcione un valor adecuado"

			setCompensateStudAddComplaint("Proporcione un valor adecuado")
		}
		else
		{
			if(selectedSchedule.studentRef["S_" + newCompensationStudent.id])
			{
				complaint = "Ya existe un alumno regular de este horario con este código"
			}
			else
			{
				if(selectedSchedule.compensationDayStudentRef && selectedSchedule.compensationDayStudentRef["S_" + newCompensationStudent.id])
				{
					complaint = "Ya existe un alumno compensando la asistencia de su horario con este código"
				}
			}
		}

		if(complaint)
		{
			setCompensateStudAddComplaint(complaint)

			setTimeout
			(
				()=>
				{
					setCompensateStudAddComplaint(undefined)
				},
				3000
			)
		}
		else
		{
			try
			{
				dispatch(enableIdleLockSwitch())

				const result = await AttendancesService.getCompensationStudent
					(selectedSchedule.id, newCompensationStudent.id)

				if(result.status == 200 || result.status == 204)
				{
					if(result.data == null)
					{
						setCompensateStudAddComplaint("No se encontró ningún estudiante con ese código")

						setTimeout
						(
							()=>
							{
								setCompensateStudAddComplaint(undefined)
							},
							3000
						)
					}
					else
					{
						setNewCompensationStudent(null)

						setEditableDay
						({
							...editableDay,
							compensationDayRef:
							{
								...editableDay.compensationDayRef,
								["S_" + result.data.id] : result.data
							}
						})
					}
				}
				else
				{
					setCompensateStudAddComplaint("No se encontró ningún estudiante con ese código")

					setTimeout
					(
						()=>
						{
							setCompensateStudAddComplaint(undefined)
						},
						3000
					)
				}
			}
			catch(error)
			{
				setCompensateStudAddComplaint("No se encontró ningún estudiante con ese código")

				setTimeout
				(
					()=>
					{
						setCompensateStudAddComplaint(undefined)
					},
					3000
				)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
	}

	const setCompensationStudentId = (event: React.ChangeEvent<HTMLInputElement>)=>
	{
		let value : number = parseInt(event.target.value)

		setNewCompensationStudent({id : isNaN(value) ? "" : value})
	}

	const removeCompensationStudent = (refIndex : string)=>
	{
		let compensationDayIndex = editableDay.compensationDayRef

		delete compensationDayIndex[refIndex]

		setEditableDay
		({
			...editableDay,
			compensationDayRef : compensationDayIndex
		})
	}

	const checkPoint = (callbackFn : any)=>
	{
		if(selectedDay && !_.isEqual(selectedDay, editableDay))
		{
			dispatch
			(
				displayNotice
				({
					optOutProcedure : callbackFn,
					procedure       : async () => {saveDay(callbackFn)},
					message         : "¿Desea registrar los cambios realizados en el registro de este día?",
					heading         : <h3 style={{color : "#0000FF", display : "inline-block"}}>
						Confirme
					</h3>
				})
			)
		}
		else
		{
			callbackFn()
		}
	}

	const DateInput = forwardRef
	(
		({value, onClick} : any, ref : ForwardedRef<HTMLButtonElement>)=>
		(
			((selectedDay && selectedDay.month) && (selectedDay.date && selectedDay.hour)) &&
				<button className="text-center btn btn-default" ref={ref} disabled={idleLocked} onClick={onClick} type="button"
				  style={{paddingTop : "15px", width : "100%", height : "100%", border : "1px solid #C7C7C7"}}
				>
					<h6 style={{display : "inline-block", color : "#8F91DA", paddingRight : "10px"}}>
						{selectedDay.month.substr(0, 3)}
					</h6>
					<h4 style={{display : "inline-block", color : "#8F91DA", paddingRight : "25px"}}>
						{dateRef[selectedDay.date]}
					</h4>
					<h2 style={{display : "inline-block", color : "#5154AE"}}>
						{selectedDay.hour}
						&nbsp;
						<FontAwesomeIcon icon={solid("square-caret-down")} style={{color : "#000000"}} />
					</h2>
				</button>
		)
	)

	return !sectionLoaded ? "" : <div style={{padding : "30px"}}>
		<h2 className="pageHeader">
			Asistencias
		</h2>
		{
			Object.keys(hourRef).length < 1 ? "sin horarios asignados" : <>
				<UnderlineHeading name={"Agenda"} />
				<div className="row">{
					schedules.map
					(
						(schedule : any) => <React.Fragment key={schedule.refIndex}><Popover positions={["bottom"]}
						  isOpen={noStudentsEnrolledForSchedule == schedule.id} content=
						  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
							<div className="requirementAlert">
								<FontAwesomeIcon icon={solid("exclamation")}/>
							</div>
							&nbsp;
							No hay estudiantes registrados para este horario
						  </div>}
						><div  className="col-sm-6 col-md-4 col-xl-3"><button disabled={idleLocked || (selectedSchedule && selectedSchedule.id == schedule.id)}
						  style={{background : schedule.color, borderRadius : "10px", padding : "5px 5px 5px 30px", margin : "5px", height : "100%"}}
						  className="text-start" type="button" onClick=
						  {
							()=>
							{
								if(schedule.enrollments.length < 1)
								{
									setNoStudentsEnrolledForSchedule(schedule.id)
									setTimeout(() => setNoStudentsEnrolledForSchedule(0), 3000)
								}
								else
								{
									checkPoint(() => selectSchedule(schedule))
								}
							}
						  }
						><p style={{background : "#FFFFFF", borderRadius : "10px", marginBottom : "unset", padding : "5px", display : "block", height : "100%"}}>
							<span style={{display : "inline-flex", position : "relative", width : "100%", marginBottom : "10px"}}>
								<img src={schedule.level.course.image != null ? schedule.level.course.image : defaultCourseImage}
								  className="detailPicture" style={{borderColor : "#000000", position : "absolute", top : "12px"}}
								/>
								<span style={{marginLeft : "55px", paddingLeft : "5px", flex : "1", display : "inline-block"}}>
									<b>
										{schedule.level.course.name}
									</b>
									<br />
									{schedule.level.name}
									<br />
									{schedule.name}
								</span>
							</span>
							<span style={{marginBottom : "unset", display : "inline-block", width : "100%"}}>{
								schedule.sessions.map
								(
									(session : any, index : number)=>
									{
										let startMinutes = (session.start_hours % 10)
										let startHours   = (session.start_hours - startMinutes) / 10
										let endMinutes   = (session.end_hours % 10)
										let endHours     = (session.end_hours - endMinutes) / 10

										return <span key={"S_" + schedule.id + "_" + index} style={{display : "block"}}>{
											weekDays[session.week_day].substr(0, 2) + sprintf
												("%02d:%02d-%02d:%02d", startHours, startMinutes, endHours  , endMinutes * 6)
										}</span>
									}
								)
							}</span>
							<span style={{marginBottom : "unset", display : "inline-block", width : "100%"}}>
								{schedule.classroom.building.name}
								&nbsp;
								<span style={{display : "inline-block"}}>
									{schedule.classroom.name}
								</span>
								<br />
								{schedule.period.name}
								&nbsp;
								<span style={{display : "inline-block"}}>
									<span style={{display : "inline-block"}}>
										({schedule.period.start}
									</span>
									<span style={{display : "inline-block"}}>
										- {schedule.period.end})
									</span>
								</span>
							</span>
						</p></button></div></Popover></React.Fragment>
					)
				}</div>
				<br />
				{
					(selectedSchedule == null || dateList.length < 1) ? "" : <div className="card card-default" style={{paddingTop : "unset"}}>
						<div style={{background : selectedSchedule.color, width : "100%", height : "30px", borderRadius : "0px 0px 15px 15px"}} />
						<div className="row" style={{paddingTop : "15px"}}>
							<div className="col-sm-7"><div style={{display : "flex", flexWrap : "wrap"}}>
								<div style={{borderBottom : "1px solid #FA0000", flex : 1}}><table style={{width : "100%"}}>
									<tbody>
										<tr>
											<td rowSpan={2} style={{color : "#8F91DA"}}><div style={{width : "60px", height : "60px"}}>
												<div style={{position : "absolute", width : "60px", height: "75px", background : "#FFFFFF"}}>
													<FontAwesomeIcon size="3x" icon={solid("list-check")}/>
												</div>
											</div></td>
											<td style={{height : "25px"}}><label style={{color : "#8F91DA"}}>
												Lista de asistencias
											</label></td>
										</tr>
										<tr><td><div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
											<div style={{flex : 1}}><h5 style={{fontWeight : "unset", color : "#8F91DA"}}>
												{selectedSchedule.level.course.name}
												&nbsp;
												{selectedSchedule.level.name}
											</h5></div>
											<div>
												<div style={{display : "inline-flex"}}>
													<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
														Horario
													</label>
													{selectedSchedule.name}
												</div>
												<div style={{display : "inline-flex"}}>
													<label style={{display : "inline-block", position : "relative", color : "#8F91DA", padding : "0px 15px"}}>
														Aula
													</label>
													{selectedSchedule.classroom.name}
												</div>
											</div>
										</div></td></tr>
									</tbody>
								</table></div>
							</div></div>
							<div className="col-sm-5 verticalStretching">
								<LeDatePicker onBlur={validateField} years={yearsRange}
								  maxDate={new Date(selectedSchedule.period.end   + " 23:00:00")} value={assessmentDateValue}
								  minDate={new Date(selectedSchedule.period.start + " 23:00:00")} customInput={<DateInput />}
								  onFocus={validateField} disabled={idleLocked} months={monthRef}
								  name="birthdayValue" id="birthdayValue" filter=
								  {
									(e : any) => _.map(selectedSchedule.sessions, "week_day")
										.indexOf(retardWeekDayOffset[getDay(e.target.value)]) >= 0
								  }
								  onChange=
								  {
									(date : Date)=>
									{
										checkPoint(() => {getScheduleAttendances(date)})
									}
								  } required
								/>
							</div>
						</div>
						<div style={{overflow : "auto"}}>
							<table className="specialTable">
								<thead>
									<tr>
										<td colSpan={4} className="text-center">
											Alumno
										</td>
										{
											monthRef.map
											(
												(month : string)=>
												{
													if(monthDateCount[month])
													{
														return <td colSpan={monthDateCount[month]} key={month}>
															{month}
														</td>
													}
												}
											)
										}
										<td colSpan={2} className="text-center">
											Asistencia
										</td>
									</tr>
									<tr>
										<th />
										<th style={{width : "1px"}}>
											Matrícula
										</th>
										<th>
											Nombres
										</th>
										<th>
											Apellidos
										</th>
										{
											dateList.map
											(
												(date : string) => <th key={date} style={{width : "1px"}}>
													<button type="button" disabled={idleLocked || (editableDay.date == date)}
													  className="btn btn-default" onClick=
													  {
														() => checkPoint(() => getScheduleAttendances(new Date(date + " 23:00:00")))
													  }
													  style=
													  {{
														color:
														(
															editableDay.date != date ? "#000000" :
																(!_.isEqual(selectedDay, editableDay) ? "#007BFF" : "#8F91DA")
														)
													  }}
													>
														{dateRef[date]}
														{(editableDay.isNew && editableDay.date == date)? "*" : ""}
													</button>
												</th>
											)
										}
										<th style={{width : "1px"}}>
											Total
										</th>
										<th style={{width : "1px", textAlign : "center"}}>
											%
										</th>
									</tr>
								</thead>
								<tbody>{
									selectedSchedule.enrollments.map
									(
										(enrollment : any) => <tr key={"S_" + enrollment.student.id}>
											<td />
											<td style={{borderBottom : "1px solid #C7C7C7"}}>
												{enrollment.student.id}
											</td>
											<td>
												{enrollment.student.first_name}
											</td>
											<td>
												{enrollment.student.last_name}
											</td>
											{
												dateList.map
												(
													(date : string) => <td key={date} className="text-center">{
														editableDay.attendanceRef["S_" + enrollment.student.id] == attendanceStatus.COMPENSATION
																?
															<FontAwesomeIcon icon={solid("bandage")} />
																:
															(
																editableDay.date == date
																		?
																	<button type="button" className="btn btn-default" disabled={idleLocked}
																	  onClick={() => {toggleStudentAttendance(enrollment.student.id)}}
																	>{
																		editableDay.attendanceRef["S_" + enrollment.student.id] == null
																			? <FontAwesomeIcon icon={regular("square")} style={{color : "#8F91DA"}} />
																				:
																		(
																			editableDay.attendanceRef["S_" + enrollment.student.id]
																				== attendanceStatus.ON_TIME
																						?
																			<FontAwesomeIcon icon={regular("square-check")} style={{color : "#8F91DA"}} />
																							:
																			(
																				editableDay.attendanceRef["S_" + enrollment.student.id]
																					== attendanceStatus.COMPENSATION
																						?
																					<FontAwesomeIcon icon={solid("bandage")} />
																						:
																					<FontAwesomeIcon icon={regular("square-minus")} style={{color : "#FA0000"}} />
																			)
																		)
																	}</button>
																		:
																	(
																		<FontAwesomeIcon
																		  icon=
																		  {
																			attendanceStudentRef[date + "S_" + enrollment.student.id] == null
																					?
																				regular("square")
																					:
																				(
																					attendanceStudentRef[date + "S_" + enrollment.student.id] == attendanceStatus.COMPENSATION
																							?
																						solid("bandage")
																							:
																						(
																							attendanceStudentRef[date + "S_" + enrollment.student.id] == attendanceStatus.ABSENT
																									?
																								regular("square-minus")
																									:
																								regular("square-check")
																						)
																				)
																		  }
																		/>
																	)
															)
													}</td>
												)
											}
											<td className="text-end"><div style={{display : "flex"}}>
												<div style={{flex : 1}}>
													{editableDay.attendanceCountRef["S_" + enrollment.student.id]}
												</div>
												<div style={{padding : "0px 5px"}}>
													/
												</div>
												<div>
													{dateCount}
												</div>
											</div></td>
											<td className="text-end" style={{textAlign : "right"}}
											  ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}
											><div style={{display : "flex"}}>
												<div style={{flex : 1}}>{
													~~(
														(
															editableDay.attendanceCountRef["S_" + enrollment.student.id]
																/ dateCount
														)
														* 100
													)
												}</div>
												<div style={{paddingLeft : "5px"}}>
													%
												</div>
											</div></td>
										</tr>
									)
								}</tbody>
								<tbody>
									{
										(
											(
												editableDay.compensationDayRef
													&& Object.keys(editableDay.compensationDayRef).length > 0
											)
												|| newCompensationStudent
										)
											&&
										<tr><td colSpan={6 + dateList.length}>
											<label style={{color : "#8F91DA", paddingRight : "25px", borderBottom : "1px solid #FA0000"}}>
												Asistentes extras
											</label>
											<br />
										</td></tr>
									}
									{
										editableDay.compensationDayRef == null ? "" : Object.values(editableDay.compensationDayRef).map
										(
											(student : any) => <tr key={"SS" + student.id}>
												<td><button disabled={idleLocked} type="button"
												  onClick={()=>{removeCompensationStudent("S_" + student.id)}}
												>
													<FontAwesomeIcon icon={solid("times-circle")}/>
												</button></td>
												<td>
													{student.id}
												</td>
												<td>
													{student.first_name}
												</td>
												<td>
													{student.last_name}
												</td>
												{
													dateList.map
													(
														(date : string) => <th key={date} className="text-center">
															<FontAwesomeIcon icon=
															  {
																editableDay.date == date
																		?
																	regular("square-check")
																		:
																	regular("square")
															  }
															/>
														</th>
													)
												}
											</tr>
										)
									}
									{
										newCompensationStudent ? "" : <tr><td colSpan={6 + dateList.length}><button
										  type="button" className="btn btn-default" onClick={addCompensationStudent}
										  disabled={idleLocked || isNotEmpty(compensateStudAddComplaint)}
										  style={{color : "#8F91DA"}}
										>
											+
											&nbsp;
											Agregar asistente.
										</button></td></tr>
									}
								</tbody>
							</table>
							{
								newCompensationStudent == null ? "" : <div>
									<Popover positions={["bottom"]} isOpen={isNotEmpty(compensateStudAddComplaint)}
									  content=
									  {
										<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
											<div className="requirementAlert">
												<FontAwesomeIcon icon={solid("exclamation")}/>
											</div>
											&nbsp;
											{compensateStudAddComplaint}
										</div>}
									>
										<div />
									</Popover>
									<div style={{display : "flex"}}>
										<div style={{paddingRight : "15px"}}>
											<label style={{position : "relative", top : "12px"}}>
												Código
											</label>
											<div className="inputContainer" style={{margin : "unset"}}>
												<input value={newCompensationStudent.id} onChange={setCompensationStudentId}
												  disabled={idleLocked || isNotEmpty(compensateStudAddComplaint)} min={0}
												  style={{width : "100%", textAlign : "right", position : "relative"}}
												  step={1} type="number"
												/>
											</div>
										</div>
										<div style={{paddingTop : "15px"}}><div style={{display : "flex"}}>
											<div><button type="button" className="btn btn-default" disabled={idleLocked}
											  onClick={()=>setNewCompensationStudent({id : 0})}
											  style={{display : "table-row", border : "1px solid #C7C7C7", width : "100%"}}
											>
												<span style={{display : "table-cell"}}>
													<FontAwesomeIcon icon={solid("times-circle")} />
												</span>
												<span className="d-none d-md-table-cell" style={{paddingLeft : "10px"}}>
													Cancelar
												</span>
											</button></div>
											<div style={{}}><button onClick={linkCompensationStudent} className="btn btn-default"
											  style={{display : "table-row", border : "1px solid #C7C7C7", width : "100%"}}
											  disabled={idleLocked || isNotEmpty(compensateStudAddComplaint)} type="button"
											>
												<span style={{display : "table-cell"}}>
													<FontAwesomeIcon icon={solid("magnifying-glass-plus")} />
												</span>
												<span className="d-none d-md-table-cell" style={{paddingLeft : "10px"}}>
													Vincular por código
												</span>
											</button></div>
										</div></div>
									</div>
								</div>
							}
						</div>
						<div className="text-end" style={{paddingTop : "25px"}}>
							<div style={{display : "inline-grid", margin : "0px 5px", width : "fit-content"}}><button
							  style={{display : "table-row"}} onClick={removeDay} type="button" disabled={idleLocked}
							  className="rounded-pill btn btn-warning" id="mainAction"
							>
								<span style={{display : "table-cell"}}>
									<CustomIcon name={"bTrash"} />
								</span>
								<span className="d-none d-md-table-cell" style={{paddingLeft : "10px"}}>
									Eliminar día
								</span>
							</button></div>
							<div style={{display : "inline-grid", margin : "0px 5px", width : "fit-content"}}><button
							  style={{display : "table-row"}} onClick={saveDay} type="button" id="mainAction"
							  className="rounded-pill btn btnPurple" disabled={idleLocked}
							>
								<span style={{display : "table-cell"}}>
									<FontAwesomeIcon icon={solid("check-circle")} />
								</span>
								<span className="d-none d-md-table-cell" style={{paddingLeft : "10px"}}>
									Guardar
								</span>
							</button></div>
						</div>
					</div>
				}
			</>
		}
	</div>
}

export default Attendances
