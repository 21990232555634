import axios
	from "axios"
import _
	from "lodash"
import store
	from "./../../globals"
import {isNotEmpty}
	from "./../../common"

class StoreService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchArticles(campusId : number, search? : any, page? : number, resultsLimit? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}
		else
		{
			if(resultsLimit)
			{
				params.push("limit=" + resultsLimit)
			}
		}

		if(search)
		{
			if(search.categoryId > 0)
			{
				params.push("categoryId=" + search.categoryId)
			}

			if(search.classification && search.classification.id)
			{
				params.push("classificationId=" + search.classification.id)
			}

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if(search.onlyKits == true)
			{
				params.push("onlyKits")
			}
			else
			{
				if(search.typeId > 0)
				{
					params.push("typeId=" + search.typeId)
				}
			}

			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(isNotEmpty(search.priceComparisonA) && !isNaN(search.priceValueA))
			{
				params.push("priceComparisonA=" + search.priceComparisonA)
				params.push("priceValueA=" + search.priceValueA)

				if(isNotEmpty(search.priceComparisonB) && !isNaN(search.priceValueB))
				{
					params.push("priceComparisonB=" + search.priceComparisonB)
					params.push("priceValueB=" + search.priceValueB)
				}
			}
			
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			process.env.REACT_APP_API_URL + "/admin/campus/" + campusId + "/store" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async addOwedArticlesToStudent(campusId : number, studentId : number, articles : any)
	{
		let params = [];

		return axios.post
		(
			process.env.REACT_APP_API_URL + "/admin/campus/" + campusId + "/store",
			{
				studentId : studentId,
				articles  : articles.map
				(
					(article : any)=>
					{
						return article.id + "," + article.quantity
					}
				).join(";")
			},
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}
}

export default new StoreService()