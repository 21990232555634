import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"

import {isNotEmpty, removeRecords}
	from "./../../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/campus/";

class InventoryService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async countArticles(campusId : number, search? : any)
	{
		let params = []

		if(search)
		{
			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if(search.categoryId > 0)
			{
				params.push("categoryId=" + search.categoryId)
			}

			if(search.typeId > 0)
			{
				params.push("typeId=" + search.typeId)
			}
		}

		return axios.get
		(
			RESOURCE_URL + campusId +  "/inventory/_count" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async searchArticles(campusId : number, search? : any, page? : number, resultsLimit? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}
		else
		{
			if(resultsLimit)
			{
				params.push("limit=" + resultsLimit)
			}
		}

		if(search)
		{
			if(search.statusId >= 0)
			{
				params.push("statusId=" + search.statusId)
			}

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if(search.categoryId > 0)
			{
				params.push("categoryId=" + search.categoryId)
			}

			if(search.classification && search.classification.id)
			{
				params.push("classificationId=" + search.classification.id)
			}

			if(search.onlyKits == true)
			{
				params.push("onlyKits")
			}
			else
			{
				if(search.typeId > 0)
				{
					params.push("typeId=" + search.typeId)
				}
			}

			if(search.classification && search.classification.id)
			{
				params.push("classificationId=" + search.classification.id)
			}

			if(isNotEmpty(search.priceComparisonA) && !isNaN(search.priceValueA))
			{
				params.push("priceComparisonA=" + search.priceComparisonA)
				params.push("priceValueA=" + search.priceValueA)

				if(isNotEmpty(search.priceComparisonB) && !isNaN(search.priceValueB))
				{
					params.push("priceComparisonB=" + search.priceComparisonB)
					params.push("priceValueB=" + search.priceValueB)
				}
			}
		}

		if(isNotEmpty(search?.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + campusId +  "/inventory" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveArticle(campusId : number, article : any)
	{
		let payload : FormData = new FormData()

		let record : any=
		{
			statusId         : article.status_id,
			typeId           : article.type_id,
			classificationId : article.classification.id,
			categoryId       : article.category_id,
			basePrice        : Number.parseFloat(sprintf("%.02f", article.base_price)),
			name             : article.name,
			sku              : article.sku,
			stockUnits       : article.stock_units,
			countableUnits   : article.countable_units,
			tagIds           : _.map(article.tags, "id").join(",")
		}

		if(isNotEmpty(article.description))
		{
			record.description = article.description
		}

		for(let field of ["description"])
		{
			if(isNotEmpty(article[field]))
			{
				record[field] = article[field] + ""
			}
		}

		Object.keys(record).map((key : string) => {payload.append(key, record[key])})

		if(article.image)
		{
			payload.append("image", article.image == article.imagePreview ? "" : article.image)
		}

		return axios.post
		(
			RESOURCE_URL + campusId +  "/inventory" + (article.id == null ? "" : "/" + article.id + "?_method=PUT"),
			payload,
			{
				headers:
				{
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
	}

	async removeArticles(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL + campusId + "/inventory", recordIds)
	}
}

export default new InventoryService()