import {useState, useEffect}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import _
	from "lodash"
import {paymentStatus}
	from "./../../constants"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice, dismissNotice
}
	from "./../../globals"
import {pageGrouping, toAscendingDate, getAccessPermissions}
	from "./../../common"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer
	from "../../components/sectionContainer"
import FinancePrompt
	from "./financePrompt"
import SimpleDataService
	from "./../../services/simpleData.service"
import TaxesService
	from "./../../services/campus/taxes.service"
import InvoicesService
	from "./../../services/campus/invoices.service"
import CatalogService
	from "./../../services/catalog.service"
import RecipientAccountsService
	from "./../../services/recipientAccounts.service"
import PaymentMethodsService
	from "./../../services/paymentMethods.service"

import "./../../components/dropdown/style.css"

const Invoices = ()=>
{
	const TAXES              = 0
	const PAYMENT_STATUSES   = 1
	const PAYMENT_METHODS    = 2
	const RECIPIENT_ACCOUNTS = 3
	const ENDING             = 4

	const promptWidth : any=
	{
		checkout : "446px",
		payment  : "583px",
		invoice  : "648px"
	}

	const invoices     : any[]                            = []
	const dispatch                                        = useDispatch()
	const records      : any[]                            = invoices.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                 = useState<number>(records.length)
	const [data, setData]                                 = useState<any[]>(records)
	const [warningReport, listWarnings]                   = useState<any>({})
	const idleLocked   : boolean                          = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                    = useSelector((state : RootState) => state.sessionToken.value)
	const basicInfo    : any                              = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                         = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                         = useSelector((state : RootState) => state.campusRef.value)
	const [currencySymbol, setCurrencySymbol]             = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
	const currentUser  : any                              = useSelector((state : RootState) => state.userProfile.value)
	const [promptDisplayQueue, setPromptDisplayQueue]     = useState<string[]>([])
	const [sectionLoaded, setSectionLoaded]               = useState<boolean>(false)
	const [userCampus, setUserCampus]                     = useState<number>(currentUserCampus)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [selectedRecords, setSelectedRecords]           = useState<number>(0)
	const [defaultSearch, setDefaultSearch]               = useState<any>({q : ""})
	const [customSearch, setCustomSearch]                 = useState<any>(defaultSearch)
	const [selectedInvoice, setSelectedInvoice]           = useState<any>(null)
	const [pagination, setPagination]                     = useState<any>(null)
	const [defaultDate, setDefaultDate]                   = useState<any>(null)
	const [taxRef, setTaxRef]                             = useState<any>(null)
	const [recipientAccountRef, setRecipientAccountRef]   = useState<any>(null)
	const [paymentStatusRef, setPaymentStatusRef]         = useState<any>(null)
	const [invoiceStatusRef, setInvoiceStatusRef]         = useState<any>(null)
	const [availableAccounts, setAvailableAccounts]       = useState<any>(null)
	const [paymentMethodRef, setPaymentMethodRef]         = useState<any>(null)
	const [initSetup, setInitSetup]                       = useState<any>(null)
	const allowed    : any                                = getAccessPermissions(currentUser)

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const mapAvailableAccountsRefs = (response: any) => {
		const mappedAccounts: any = {};

		response.forEach((paymentMethod: any) => {
			paymentMethod.accounts.forEach((account: any) => {
				mappedAccounts[`RA_${account.id}`] = {
					...account,
					label: `${account.name} (${account.account_number})`
				};
			});
		});

		return mappedAccounts;
	};
	
	const showInvoicePrompt = (invoiceToDisplay : any)=>
	{
		let amount               : number
		let taxId                : number
		let taxInPercentage      : boolean
		let discountInPercentage : boolean
		let taxValue             : number
		let discountValue        : number
		let taxAmount            : number
		let discountAmount       : number
		let subTotal             : number
		let pendingPaidTotal     : number = 0
		let approvedPaidTotal    : number = 0
		let total                : number = 0
		let recordKey            : string
		let nextRef              : string | null
		let prevRef              : string | null
		let disabled             : boolean

		let invoice : any=
		{
			...invoiceToDisplay, notes : invoiceToDisplay.notes || "",
			total                    : +invoiceToDisplay.total,
			paid_amount              : +invoiceToDisplay.paid_amount,
			owed_tuitions            : invoiceToDisplay.owed_tuitions.length < 1 ? [] : invoiceToDisplay.owed_tuitions.map
			(
				(tuition : any, index: number)=>
				{
					amount               = +tuition.amount
					taxId                = tuition.tax_id || 0
					taxInPercentage      = tuition.tax_value.substr(-1) == "%"
					discountInPercentage = tuition.discount.substr(-1) == "%"
					taxValue             = taxId < 1 ? 0 : +(tuition.tax_value.replace("%", ""))
					discountValue        = +(tuition.discount.replace("%", ""))
					taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
					discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
					subTotal             = tuition.amount + taxAmount - discountAmount
					total               += subTotal
					recordKey            = "OT_" + tuition.id
					nextRef              = index === invoiceToDisplay.owed_tuitions.length - 1 ? null : "OT_" + invoiceToDisplay.owed_tuitions[index + 1].id
					prevRef              = index === 0 ? null : "OT_" + invoiceToDisplay.owed_tuitions[index - 1].id
					disabled             = invoiceToDisplay.owed_tuitions.length === 0 ? false 
						: index === invoiceToDisplay.owed_tuitions.length - 1 ? false : true 

					return {
						...tuition, tax_id : taxId,
						amount                                        : amount,
						taxInPercentage                               : taxInPercentage,
						discountInPercentage                          : discountInPercentage,
						taxValue                                      : taxValue,
						discountValue                                 : discountValue,
						taxAmount                                     : taxAmount,
						discountAmount                                : discountAmount,
						subTotal                                      : subTotal,
						quantity                                      : 1,
						price                                         : amount,
						refIndex                                      : recordKey,
						nextRef                                       : nextRef,
						prevRef                                       : prevRef,
						disabled                                      : disabled,
						invoice_item:
						{
							invoice_id      : tuition.invoice_id,
							owed_tuition_id : tuition.id,
							owed_article_id : null,
							title           : tuition.title,
							description     : tuition.description,
							units           : 1,
							price           : amount,
							total           : subTotal,
							refunded_amount : 0,
							currency_id     : tuition.currency_id
						}
					}
				}
			),
			owed_articles             : invoiceToDisplay.owed_articles.length < 1 ? [] : invoiceToDisplay.owed_articles.map
			(
				(article : any)=>
				{
					amount               = +article.amount
					taxId                = article.tax_id || 0
					taxInPercentage      = article.tax_value.substr(-1) == "%"
					discountInPercentage = article.discount.substr(-1) == "%"
					taxValue             = taxId < 1 ? 0 : +(article.tax_value.replace("%", ""))
					discountValue        = +(article.discount.replace("%", ""))
					taxAmount            = taxValue == 0 ? 0 : (!taxInPercentage ? taxValue : amount * (taxValue / 100))
					discountAmount       = (!discountInPercentage ? discountValue : amount * (discountValue / 100))
					subTotal             = article.amount + taxAmount - discountAmount
					total               += subTotal

					return {
						...article, tax_id : taxId,
						amount               : amount,
						taxInPercentage      : taxInPercentage,
						discountInPercentage : discountInPercentage,
						taxValue             : taxValue,
						discountValue        : discountValue,
						taxAmount            : taxAmount,
						discountAmount       : discountAmount,
						subTotal             : subTotal,
						invoice_item:
						{
							invoice_id      : article.invoice_id,
							owed_tuition_id : null,
							owed_article_id : article.id,
							title           : article.title,
							description     : article.description,
							units           : article.quantity,
							price           : article.price,
							total           : subTotal,
							refunded_amount : 0,
							currency_id     : article.currency_id
						}
					}
				}
			),
			payments : invoiceToDisplay.payments.map
			(
				(payment : any)=>
				{
					if(payment.status_id != paymentStatus.REJECTED)
					{
						if(payment.status_id == paymentStatus.APPROVED)
						{
							approvedPaidTotal += +payment.amount
						}
						else
						{
							pendingPaidTotal += +payment.amount
						}
					}

					return payment
				}
			)
		}

		setSelectedInvoice
		({
			...invoice, total : total,
			approvedPaidTotal : approvedPaidTotal,
			pendingPaidTotal  : pendingPaidTotal
		})

		setPromptDisplayQueue(["invoice"])
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const initFn = async()=>
					{
						const taxRetrievalError : string = "Los impuestos no pudieron ser extraídos," +
							"por lo que el acceso a este modulo quedará deshabilitado"

						try
						{
							dispatch(enableIdleLockSwitch)

							const result : any = await TaxesService.getTaxes(campusRef[currentUserCampus].id)
							let   taxMap : any = {}

							if(result.status == 200 || result.status == 204)
							{
								result.data.map
								(
									(record : any)=>
									{
										taxMap["T_" + record.id] = record
									}
								)

								setTaxRef(taxMap)
								setInitSetup(TAXES)
							}
							else
							{
								procedureComplaint(taxRetrievalError)
								dispatch(disableIdleLockSwitch)
							}
						}
						catch(error : any)
						{
							console.log(error)
							procedureComplaint(taxRetrievalError)
							dispatch(disableIdleLockSwitch)
						}
					}

					initFn()
				}
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case TAXES:
						if(Object.keys(taxRef).length < 1)
						{
							procedureComplaint
							(
								"No se encontraron impuestos en el registro, " +
									"por lo que el acceso a este módulo quedará deshabilitado"
							)
						}
						else
						{
							const statusRetrievalError : string = "Los estatuses de pago no pudieron ser extraídos," + 
								"por lo que no se podrán emitir pagos para las facturas."

							try
							{
								dispatch(enableIdleLockSwitch)

								const invoiceStatuses: any = await CatalogService.getInvoiceStatuses(sessionToken)

								if (invoiceStatuses.status == 200 || invoiceStatuses.status == 204) {
									let invoiceStatusRef: any = {}
			
									invoiceStatuses.data.map
										((record: any) => invoiceStatusRef["IS_" + record.id] = { ...record, label: record.name })
									setInvoiceStatusRef(invoiceStatusRef);
								}

								const paymentMethods: any = await PaymentMethodsService.getPaymentMethodsByCountry(campusRef[currentUserCampus].company.country_id)

								if (paymentMethods.status == 200 || paymentMethods.status == 204) {
									let paymentMethodRef: any = {};
									let availableAccounts;

									paymentMethods.data.map((record: any) => paymentMethodRef["PM_" + record.id] = { ...record, label: record.name })
									// let recipientAccounts = paymentMethods.data[0];
									let recipientAccountRef: any = []
									if (paymentMethods.data.length > 0)
									{
										paymentMethods.data[0].accounts.map((record: any) => 
											recipientAccountRef["RA_" + record.id] = { ...record, label: record.name + " (" + record.account_number + ")" })
										availableAccounts = mapAvailableAccountsRefs(paymentMethods.data)
										setAvailableAccounts(availableAccounts)
									}
								}

								const result           : any = await CatalogService.getPaymentStatuses(sessionToken)
								let   paymentStatusMap : any = {}

								if(result.status == 200 || result.status == 204)
								{
									result.data.map
									(
										(record : any)=>
										{
											paymentStatusMap["PS_" + record.id] = record
										}
									)

									setPaymentStatusRef(paymentStatusMap)
									setInitSetup(PAYMENT_STATUSES)
								}
								else
								{
									listWarnings({...warningReport, paymentStatuses : statusRetrievalError})
									setInitSetup(ENDING)
								}
							}
							catch(error : any)
							{
								console.log(error)
								listWarnings({...warningReport, paymentStatuses : statusRetrievalError})
									setInitSetup(ENDING)
							}
						}
					break;
					case PAYMENT_STATUSES:
						const methodRetrievalError : string = "No se pudieron extraer los métodos de pago, por lo que no se podrán emitir pagos para las facturas"

						try
						{
							const result           : any = await SimpleDataService.getRecords("paymentMethods")
							let   paymentMethodMap : any = {}

							if(result.status == 200 || result.status == 204)
							{
								result.data.map
								(
									(record : any)=>
									{
										paymentMethodMap["PM_" + record.id] = {...record, label : record.name}
									}
								)

								setPaymentMethodRef(paymentMethodMap)
								setInitSetup(PAYMENT_METHODS)
							}
							else
							{
								listWarnings({...warningReport, paymentMethods : methodRetrievalError})
								setInitSetup(ENDING)
							}
						}
						catch(error : any)
						{
							console.log(error)
							listWarnings({...warningReport, paymentMethods : methodRetrievalError})
							setInitSetup(ENDING)
						}
					break;
					case PAYMENT_METHODS:
						if(Object.keys(paymentMethodRef).length < 1)
						{
							listWarnings
							({
								...warningReport, paymentMethods : "No hay métodos de pago en el registro, " + 
									"por lo que no se podrán emitir pagos para las facturas "
							})

							setInitSetup(ENDING)
						}
						else
						{
							try
							{
								const result              : any = await RecipientAccountsService.getRecipientAccounts()
								let   recipientAccountMap : any = {}

								if(result.status == 200 || result.status == 204)
								{
									result.data.map
									(
										(record : any)=>
										{
											recipientAccountMap["RA_" + record.id] = {...record, label : record.name + " [" +
												record.account_number + "]"}
										}
									)

									setRecipientAccountRef(recipientAccountMap)
									setInitSetup(RECIPIENT_ACCOUNTS)
								}
								else
								{
									listWarnings
									({
										...warningReport, recipientAccounts : "No se pudueron extraer las cuentas receptoras " + 
											"por lo que no se podrán emitir pagos para las facturas "
									})

									setInitSetup(ENDING)
								}
							}
							catch(error : any)
							{
								console.log(error)

								listWarnings
								({
									...warningReport, recipientAccounts : "No se pudueron extraer los métodos de pago " + 
										"por lo que no se podrán emitir pagos para las facturas "
								})

								setInitSetup(ENDING)
							}
						}
					break;
					case RECIPIENT_ACCOUNTS:
						if(Object.keys(recipientAccountRef).length < 1)
						{
							listWarnings
							({
								...warningReport, paymentMethods : "No hay cuentas receptoras en el registro, " + 
									"por lo que no se podrán emitir pagos para las facturas "
							})
						}

						setInitSetup(ENDING)
					break;
					case ENDING:
						setSectionLoaded(true)
						search()
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(()=>{setTotalRecords(data ? data.length : 0)}, [data])

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setCurrencySymbol(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
				setPromptDisplayQueue([])
				setSelectedInvoice(null)
				clearSearch()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)
	//---------------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await InvoicesService.searchInvoices(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.data.current_page, result.data.data.last_page))
				setDefaultDate(result.data.date)
				setData(result.data.data.data)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
			
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer managementOverride={promptDisplayQueue[0] == null ? <div className="fluentBlockSm"></div> : <></>}
		  searchFn={search}
		  pagination={pagination}
		  clearSearchFn={clearSearch}
		  customSearch={customSearch}
		  defaultSearch={defaultSearch}
		  searchChangeFn={handleSearchChange}
		  clearSearchRequested={clearSearchRequested}
		  additionalClasses={"compactableSection" + (promptDisplayQueue[0] == null ? "" : " sidePrompt")}
		  additionalStyle=
		  {{
			float : "left", height : "100%", overflow : "auto", width : "calc(100%" + 
				(promptDisplayQueue[0] == null ? ")" : " - " + promptWidth[promptDisplayQueue[0]] + ")")
		  }}
		>{
			(data == null || data.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					Sin resultados para este plantel
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead><tr key="periodsHeader">
					<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
						id
					</th>
					<th>
						Alumno
					</th>
					<th>
						Fecha
					</th>
					<th>
						Subtotal
					</th>
					<th>
						Saldado
					</th>
					<th>
						Balance
					</th>
					<th>
						Estatus
					</th>
					<th>
						Notas
					</th>
					<th />
				</tr></thead>
				<tbody>{
					data.map
					(
						(record : any, index : number) => <tr key={"period" + index}>
							<td ref=
							  {
								el=>
								{
									if(el)
									{el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}
								}
							  }>
								{record.id}
							</td>
							<td>{
								record.student.first_name + " " + record.student.last_name
								|| "-"
							}</td>
							<td>
								{toAscendingDate(new Date(record.invoice_date + " 00:00:00"))}
							</td>
							<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
								{sprintf(currencySymbol + " %.02f", record.total)}
							</td>
							<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
								{sprintf(currencySymbol + " %.02f", record.paid_amount)}
							</td>
							<td style={{textAlign : "right", whiteSpace : "nowrap"}}>
								{sprintf(currencySymbol + " %.02f", (record.total - record.paid_amount))}
							</td>
							<td>
								{invoiceStatusRef["IS_" + record.status_id].name}
							</td>
							<td>
								{record.notes || "-"}
							</td>
							<td><div style={{display : "flex"}}>
								<button disabled={idleLocked} className="button btn btn-default"
								  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
								  type="button" onClick={() => showInvoicePrompt(record)}
								>
									<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
								</button>
							</div></td>
						</tr>
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			((promptDisplayQueue == null || promptDisplayQueue.length < 1) || selectedInvoice == null) ? "***" : <FinancePrompt
			  promptDisplayQueue={promptDisplayQueue} selectedInvoice={selectedInvoice} recipientAccountRef={recipientAccountRef}
			  student={selectedInvoice.student} defaultDate={defaultDate} setPromptDisplayQueue={setPromptDisplayQueue}
			  invoiceStatusRef={invoiceStatusRef} availableAccounts={availableAccounts}
			  paymentMethodRef={paymentMethodRef} paymentStatusRef={paymentStatusRef} taxRef={taxRef} resetFn=
			  {
				()=>
				{
					setCustomSearch(defaultSearch)
					setPromptDisplayQueue([])
					search()
				}
			  }
			  promptDismissal=
			  {
				()=>
				{
					setPromptDisplayQueue([])
					setSelectedInvoice(null)
				}
			  }
			/>
		}
	</>
}

export default Invoices
