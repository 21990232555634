import {useState, useRef, useReducer, useEffect, FormEvent}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import _
	from "lodash"

import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice, dismissNotice
}
	from "./../../globals"
import
{
	UIState, monthRef, pageGrouping, validateField, getAccessPermissions, revertStringDate, isNotEmpty,
	isEmpty, toAscendingDate
}
	from "./../../common"
import {tunning}
	from "./../../constants"
import UnderlineHeading
	from "./../../components/underlineHeading"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer, {DataDisplayModal}
	from "./../../components/sectionContainer"
import LeDatePicker
	from "./../../components/leDatePicker"
import PeriodsService
	from "./../../services/campus/periods.service"

import "./../../components/dropdown/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action   = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{
				...state,
				isButtonDisabled : action.payload
			}
		break;
	}
}

const Periods = ()=>
{
	const periods     : any[] = []
	const [state, trigger]                                    = useReducer(reducer, initialState)
	const dispatch                                            = useDispatch()
	const records      : any[]                                = periods.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                     = useState<number>(records.length)
	const [data, setData]                                     = useState<any[]>(records)
	const idleLocked   : boolean                              = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                        = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean                              = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any                                  = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                             = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                             = useSelector((state : RootState) => state.campusRef.value)
	const currentUser       : any                             = useSelector((state : RootState) => state.userProfile.value)
	const [wrongValueDetail, setWrongValueDetail]             = useState<string | null>(null)
	const [wrongValueOnFields, setWrongValueOnFields]         = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]               = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                 = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]                   = useState<boolean>(false)
	const [extendedFilterShown, setExtendedFilterShown]       = useState<boolean>(false)
	const [wrongSearchValue, setWrongSearchValue]             = useState<boolean>(false)
	const [userCampus, setUserCampus]                         = useState<number>(currentUserCampus)
	const [clearSearchRequested, setClearSearchRequested]     = useState<boolean>(false)
	const [UIStatus, setUIStatus]                             = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]               = useState<number>(0)
	const [newPeriod, setNewPeriod]                           = useState<any>(null)
	const [selectedPeriod, setSelectedPeriod]                 = useState<any>(null)
	const [editablePeriod, setEditablePeriod]                 = useState<any>(null)
	const [pagination, setPagination]                         = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                       = useState<boolean>(false)
	const [saveError, setSaveError]                           = useState<any>(null)
	const [searchMisconfiguration, setSearchMisconfiguration] = useState<any>(null)
	const [recordsSelection, setRecordsSelection]             = useState<any>([])
	const count        : number[]                             = [-1, 1]
	const allowed      : any                                  = getAccessPermissions(currentUser)
	let   searchFormRef                                       = useRef<HTMLFormElement>(null)
	let   recordFormRef                                       = useRef<HTMLFormElement>(null)
	let   descriptionRef                                      = useRef<HTMLTextAreaElement>(null)
	const [defaultSearch, setDefaultSearch]                   = useState<any>
	({
		q          : "",
		startValue : "",
		endValue   : ""
	})

	const [customSearch, setCustomSearch] = useState<any>(defaultSearch)

	const editPeriod = ()=>
	{
		setEditablePeriod({...selectedPeriod, description : selectedPeriod.description || ""})
	}

	const hidePrompt = ()=>
	{
		if(editablePeriod && editablePeriod.id == null)
		{
			setSelectedPeriod(null)
		}

		setEditablePeriod(null)
	}

	const unedit = ()=>
	{
		if(editablePeriod && editablePeriod.id == null)
		{
			setSelectedPeriod(null)
		}

		setEditablePeriod(null)
	}

	const showPrompt = (recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)

		if(recordData.id == null)
		{
			setEditablePeriod(recordData)
		}

		setSelectedPeriod(recordData)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				const dateDiff : number = (editablePeriod.endValue - editablePeriod.startValue) / 86400000

				if(editablePeriod.startValue >= editablePeriod.endValue || dateDiff < 6)
				{
					if(!unfilledFields && wrongValueDetail == null)
					{
						setWrongValueOnFields(true)
						setWrongValueDetail
						(
							dateDiff < 6
									?
								"Un periodo válido debe contener al menos 6 días de diferencia entre inicio y fin"
									: "La fecha de Inicio no puede ser mayor a la fecha final"
						)

						setTimeout
						(
							()=>
							{
								setWrongValueOnFields(false)
								setWrongValueDetail(null)
							},
							3000
						)
					}
				}
				else
				{
					if(saveError != null)
					{
						setSaveError(null)
					}

					setUIStatus(UIState.LOCKED)
					dispatch(enableIdleLockSwitch())

					PeriodsService.savePeriod(campusRef[currentUserCampus].id, editablePeriod).then
					(
						()=>
						{
							setSaveSuccess(true)
							setUIStatus(UIState.SUCCESS)

							setTimeout
							(
								()=>
								{
									setCustomSearch(defaultSearch)
									setSelectedPeriod(null)
									setEditablePeriod(null)
									setSaveSuccess(false)
									setUIStatus(UIState.NORMAL)
									search()
								},
								tunning.MODAL_DISMISS_DELAY
							)
						},
						(error : any)=>
						{
							console.log(error)
							setUIStatus(UIState.ERROR)
							dispatch(disableIdleLockSwitch())
							setSaveError
							(
								error.response.status == 409
										?
									"Hay conflictos en la información proporcionada (nombre). " +
										"Revise que los valores sean únicos en comparación con los registros existentes"
										:
									"La información no pudo ser guardada"
							)
						}
					)
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditablePeriod({...editablePeriod, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect(() => setTotalRecords(data.length), [data])
	useEffect
	(
		() => trigger
		({
			type    : "setIsButtonDisabled",
			payload : (idleLocked || UIStatus == UIState.SUCCESS)
		}),
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				clearSearch()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if(sessionToken)
			{
				if(currentUserCampus == null)
				{
					dispatch(disableIdleLockSwitch())
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const startingDate = new Date()
					const year         = startingDate.getFullYear()

					setNewPeriod
					({
						name        : "",
						code        : "",
						start       : year + "/01/01",
						end         : year + "/12/31",
						startValue  : new Date(year + "/01/01"),
						endValue    : new Date(year + "/12/31"),
						description : ""
					})

					setSectionLoaded(true)
					search()
				}
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			if(clearSearchRequested)
			{
				if(_.isEqual(defaultSearch, customSearch))
				{
					setCustomSearch(defaultSearch)
					search()
				}
			}
			else
			{
				if
				(
					((customSearch.startValue && customSearch.endValue) && (customSearch.startValue >= customSearch.endValue)) ||
					(
						(isEmpty(customSearch.startValue) && customSearch.endValue) ||
							(customSearch.startValue && isEmpty(customSearch.endValue))
					)
				)
				{
					if(isEmpty(searchMisconfiguration) && extendedFilterShown)
					{
						setSearchMisconfiguration("Es necesario proporcionar ambos valores apropiados y diferentes en las fechas de inicio y fin para filtrar por intervalo")
						setUIStatus(UIState.WARNING)
					}
				}
				else
				{
					if(isNotEmpty(searchMisconfiguration))
					{
						setSearchMisconfiguration(null)
						setUIStatus(UIState.NORMAL)
					}
				}
			}
		},
		[customSearch, clearSearchRequested, extendedFilterShown]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = (avoidsearchTrigger? : boolean)=>
	{
		setCustomSearch(defaultSearch)

		if(avoidsearchTrigger != true)
		{
			setClearSearchRequested(true)
		}
	}

	const search = async(page? : any)=>
	{
		let proceedingSearch : boolean = true

		if(searchFormRef && searchFormRef.current)
		{
			if(!searchFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					proceedingSearch = false

					setUnfilledFields(true)
					searchFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if
				(
					(
						(isEmpty(customSearch.startValue) && isNotEmpty(customSearch.endValue)) ||
							(isNotEmpty(customSearch.startValue) && isEmpty(customSearch.endValue))
					) || customSearch.startValue > customSearch.endValue
				)
				{
					proceedingSearch = false

					if(wrongValueDetail == null)
					{
						proceedingSearch = false

						setWrongValueDetail("La fecha de inicio debe ser menor a la fecha de fin")
						searchFormRef.current.reportValidity()

						setTimeout
						(
							() => setWrongValueDetail(null),
							3000
						)
					}
				}
			}
		}

		if(proceedingSearch)
		{
			const searchError : string = "La búsqueda no pudo completarse"

			if(extendedFilterShown)
			{
				setExtendedFilterShown(false)
			}

			dispatch(enableIdleLockSwitch())

			try
			{
				const result = await PeriodsService.searchPeriods(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

				if(result.status == 200 || result.status == 204)
				{
					setSectionLoaded(true)
					setPagination(pageGrouping(result.data.current_page, result.data.last_page))
					setData
					(
						result.data.data.map
						(
							(record : any)=>
							({
								selected : false,
								data:
								{
									...record, startValue : new Date(record.start + " 23:00:00"),
									endValue              : new Date(record.end + " 23:00:00")
								}
							})
						)
					)

					setSelectedRecords(0)
				}
				else
				{
					procedureComplaint(searchError)
				}
			}
			catch(error)
			{
				console.log(error)

				procedureComplaint(searchError)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
				setClearSearchRequested(false)
			}
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						setSelectedRecords(selectedRecords + count[ +!record.selected ])

						return {...record, selected : !record.selected};
					}

					return record;
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		)

		setSelectedRecords(totalRecords * (+selected))
	};

	const closePrompt = ()=>
	{
		setSelectedPeriod(null)
		setEditablePeriod(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string   = "No se pudieron eliminar algunos registros. " +
						"Puede que la información en otra sección dependa de estos datos, por lo que no será posible realizar por completo la operación"
					const recordIds    : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await PeriodsService.removePeriods(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	return !sectionLoaded ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  UIStatus={UIStatus}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  searchFormRef={searchFormRef}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  searchError={searchMisconfiguration}
		  extendedFilterShown={extendedFilterShown}
		  generalPlaceHolder={"Nombre, descripción"}
		  clearSearchRequested={clearSearchRequested}
		  addingPromptFn={() => showPrompt(newPeriod)}
		  setExtendedFilterShown={setExtendedFilterShown}
		  searchIcon={<FontAwesomeIcon icon={solid("calendar-days")} />}
		  filterSummary=
		  {
			[
				(
					(isEmpty(customSearch.endValue) || isEmpty(customSearch.startValue)) ||
					(customSearch.startValue >= customSearch.endValue)
				) ? null :
				{
					label   : "Intérvalo",
					display : toAscendingDate(customSearch.startValue) + " - " + toAscendingDate(customSearch.endValue)
				}
			].filter((setting) => setting)
		  } extendedFilterViewClosing=
		  {
			()=>
			{
				if
				(
					customSearch.startValue >= customSearch.endValue ||
					(
						(isEmpty(customSearch.startValue) && customSearch.endValue) ||
							(customSearch.startValue && isEmpty(customSearch.endValue))
					)
				)
				{
					setCustomSearch({...customSearch, startValue : "", endValue : ""})
				}

				setExtendedFilterShown(false)
			}
		  } advancedSearchForm=
		  {<>
			<UnderlineHeading name={"Intérvalo"} style={{marginBottom : "unset"}} />
			<div className="container"><div className="row">
				<div className="col-sm-6"><div className="inputContainer" style={{paddingTop : "23px"}}>
					<label htmlFor="start" style={{position : "relative"}}>
						Inicio
					</label>
					<Popover positions={["bottom"]} isOpen={wrongValueDetail != null} content=
					  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
						<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")}/>
						</div>
						&nbsp;
						{wrongValueDetail}
					  </div>}
					><div>
						<LeDatePicker id="startValue" months={monthRef} value={customSearch.startValue} portalId="start"
						  name="startValue" required={customSearch.endValue} onChange={(e : any) => handleSearchChange(e)}
						  years={basicInfo.futureRange}
						/>
					</div></Popover>
				</div></div>
				<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px"}}>
					<label htmlFor="end" style={{position : "relative"}}>
						Fin
					</label><div>
						<LeDatePicker value={customSearch.endValue} years={basicInfo.futureRange} months={monthRef} id="endValue"
						  name="endValue" required={customSearch.startValue} onChange={(e : any) => handleSearchChange(e)}
						  portalId="end"
						/>
					</div>
				</div></div>
			</div></div>
		  </>}
		>{
			(data.length < 1)
				?
			<>
				<hr />
				<div style={{padding : "25px"}}>
					Sin resultados para este plantel.
				</div>
				<hr />
			</>
				:
			<table className="commonTable">
				<thead>
					<tr key="periodsHeader">
						{
							!allowed.delete ? "" : <th><button onClick={toggleSelectAll} className="btn btn-default" type="button"
							  disabled={idleLocked || saveSuccess}
							>
								<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />
							</button></th>
						}
						<th>
							id
						</th>
						<th>
							Nombre
						</th>
						<th>
							Inicio
						</th>
						<th>
							Fin
						</th>
						<th>
							Descripción
						</th>
						<th />
					</tr>
				</thead>
				<tbody>{
					data.map
					(
						(record : any, index : number) => <tr key={"period" + index}>
							{
								!allowed.delete ? "" : <td><button disabled={idleLocked} className="btn btn-default" type="button"
								  onClick={() => toggleRecordSelection(index)}
								>
									{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
								</button></td>
							}
							<td>
								{record.data.id}
							</td>
							<td>
								{record.data.name}
							</td>
							<td>
								{revertStringDate(record.data.start)}
							</td>
							<td>
								{revertStringDate(record.data.end)}
							</td>
							<td>
								{record.data.description || "-"}
							</td>
							<td><div style={{display : "flex"}}>
								<button onClick={() => showPrompt(record.data)} type="button" className="button btn btn-default"
								  style={{display : "table-column", color : "var(--txt-color)", paddingTop : "10px"}}
								  disabled={idleLocked}
								>
									<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
								</button>
								{
									!allowed.delete ? "" : <button className="button btn btn-default" disabled={idleLocked}
									  onClick={() => confirmRemoval(record.data.id)} style={{display : "table-column"}}
									  type="button"
									>
										<CustomIcon name="bTrash" />
									</button>
								}
							</div></td>
						</tr>
					)
				}</tbody>
			</table>
		}</SectionContainer>
		{
			selectedPeriod == null ? "" : <DataDisplayModal headIcon={<FontAwesomeIcon icon={solid("calendar-days")} />}
			  unchangedRecord={unchangedRecord} saveLock={state.isButtonDisabled} form={recordFormRef} UIStatus={UIStatus}
			  saveDataFn={saveRecord} wrongValueOnFields={wrongValueOnFields} allowed={allowed} entityName="Periodo"
			  dataEditInit={editPeriod} processingError={saveError} uneditFn={unedit} saveSuccess={saveSuccess}
			  promptCloseFn={closePrompt} unfilledFields={unfilledFields} editableRecord={editablePeriod}
			  allowedUpdate={allowed.update}
			><form ref={recordFormRef} className="form" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			>
				<div className="container">
					<div className="row">
						<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px"}}>
							<label htmlFor="name" style={{position : "relative", bottom : "22px"}}>
								Nombre
							</label>
							{
								editablePeriod == null
									?
								<div className="roView">
									{selectedPeriod.name}
								</div>
									:
								<div>
									<input onChange={handleRegistryChange} id="name" disabled={idleLocked}
									  onFocus={validateField} placeholder="Nombre" name="name"
									  onBlur={validateField} value={editablePeriod.name}
									  style={{width : "100%", top : "40px", height : "25px"}} type="text" required
									/>
								</div>
							}
						</div></div>
					</div>
					<div className="row">
						<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px"}}>
							<label htmlFor="start" style={{position : "relative", bottom : "22px"}}>
								Inicio
							</label>
							{
								editablePeriod == null
									?
								<div className="roView">
									{revertStringDate(selectedPeriod.start)}
								</div>
									:
								<Popover positions={["bottom"]} isOpen={wrongValueDetail != null} content=
								  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
									<div className="requirementAlert">
										<FontAwesomeIcon icon={solid("exclamation")}/>
									</div>
									&nbsp;
									{wrongValueDetail}
								  </div>}
								><div>
									<LeDatePicker onFocus={validateField} name="startValue"
									  onBlur={validateField} years={basicInfo.futureRange}
									  id="startValue" months={monthRef} value={editablePeriod.startValue}
									  onChange={handleRegistryChange} required
									/>
								</div></Popover>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer" style={{paddingTop : "23px"}}>
							<label htmlFor="end" style={{position : "relative", bottom : "22px"}}>
								Fin
							</label>
							{
								editablePeriod == null
									?
								<div className="roView">
									{revertStringDate(selectedPeriod.end)}
								</div>
									:
								<LeDatePicker onFocus={validateField} name="endValue"
								  id="endValue" years={basicInfo.futureRange} onChange={handleRegistryChange}
								  onBlur={validateField} value={editablePeriod.endValue}
								  months={monthRef} required
								/>
							}
						</div></div>
					</div>
				</div>
				<div style={{paddingTop : "25px"}}>
					<UnderlineHeading name={"Descripción"} />
					<div style={{paddingTop : "3px"}}>{
						editablePeriod == null ? (selectedPeriod.description || "-") : <textarea value={editablePeriod.description}
						  className="notesField form-control" onChange={handleRegistryChange} name="description"
						  id="description"  onBlur={validateField} disabled={idleLocked}
						  ref={descriptionRef}
						/>
					}</div>
				</div>
				<br />
				<br />
				<br />
				<br />
				<br />
			</form></DataDisplayModal>
		}
	</>
}

export default Periods
