import React,
{
	useState,
	useRef,
	useReducer,
	useEffect,
	useCallback
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import
{
	solid,
	regular
}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import Box
	from "@mui/material/Box"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import _debounce
	from "lodash/debounce"
import _
	from "lodash"
import Multiselect
	from "multiselect-react-dropdown"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice
}
	from "./../../globals"
import
{
	UIState,
	pageGrouping,
	getAccessPermissions,
	operators,
	operatorList,
	validateField
}
	from "./../../common"
import Constants, {commonStatus}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer
	from "./../../components/sectionContainer"
import {SelectReact}
	from "../../components/select"
import CatalogService
	from "./../../services/catalog.service"
import StoreService
	from "./../../services/campus/store.service"
import StudentsService
	from "./../../services/campus/students.service"
import SimpleDataService
	from "./../../services/simpleData.service"
import defaultArticleImage
	from "./../../assets/images/article.png"
import {styles}
	from "./style"

import "./../../components/dropdown/style.css"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type  Action = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{
				...state,
				isButtonDisabled : action.payload
			}
		break;
	}
}

const Catalog = ()=>
{
	const ARTICLE_STATUSES        = 0
	const ARTICLE_CLASSIFICATIONS = 1
	const ARTICLE_TYPES           = 2

	const classrooms     : any[]                                  = []
	const [state, trigger]                                        = useReducer(reducer, initialState)
	const dispatch                                                = useDispatch()
	const [articleClassifications, setArticleClassifications]     = useState<any>([])
	const [articleClassificationRef, setArticleClassificationRef] = useState<any>({})
	const [articleTypes, setArticleTypes]                         = useState<any>([])
	const [articleTypeRef, setArticleTypeRef]                     = useState<any>([])
	const [articleStatuses, setArticleStatuses]                   = useState<any>([])
	const [articleStatusRef, setArticleStatusRef]                 = useState<any>([])
	const [categories, setCategories]                             = useState<any>([])
	const [categoryRef, setCategoryRef]                           = useState<any>({})
	const [initSetup, setInitSetup]                               = useState<number | null>(null)
	const records      : any[]                                    = classrooms.map((record : any, index : number) => {return {selected : false, data : record}})
	const [totalRecords, setTotalRecords]                         = useState<number>(records.length)
	const [data, setData]                                         = useState<any[]>(records)
	const [aSearchInProgress, setASearchInProgress]               = useState<boolean>(false)
	const idleLocked   : boolean                                  = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                            = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle  : boolean                                  = useSelector((state : RootState) => state.themeToggle.value)
	const basicInfo    : any                                      = useSelector((state : RootState) => state.basicInfo.value)
	const campusRef         : any                                 = useSelector((state : RootState) => state.campusRef.value)
	const currentUserCampus : any                                 = useSelector((state : RootState) => state.currentUserCampus.value)
	const currentUser       : any                                 = useSelector((state : RootState) => state.userProfile.value)
	const [userCampus, setUserCampus]                             = useState<number>(currentUserCampus)
	const [noCategoriesProvided, setNoCategoriesProvided]         = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                     = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]                   = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]                       = useState<boolean>(false)
	const [noItemsInCart, setNoItemsInCart]                       = useState<boolean>(false)
	const [cartOnDisplay, setCartOnDisplay]                       = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested]         = useState<boolean>(false)
	const [extendedFilterShown, setExtendedFilterShown]           = useState<boolean>(false)
	const [isPopoverOpen, setIsPopoverOpen]                       = useState<boolean>(false)
	const [gridViewOnDisplay, setGridViewOnDisplay]               = useState<boolean>(false)
	const [studentsList, setStudentsList]                         = useState<any>([])
	const [UIStatus, setUIStatus]                                 = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]                   = useState<number>(0)
	const [totalItems, setTotalItems]                             = useState<number>(0)
	const [subTotal, setSubTotal]                                 = useState<number>(0)
	const [shoppingCart, setShoppingCart]                         = useState<any>({})
	const [listSearch, setListSearch]                             = useState<any>("")
	const [newArticle, setNewArticle]                             = useState<any>(null)
	const [student, setStudent]                                   = useState<any>(null)
	const [pagination, setPagination]                             = useState<any>(null)
	const [saveError, setSaveError]                               = useState<any>(null)
	const allowed      : any                                      = getAccessPermissions(currentUser)
	let   nameRef                                                 = useRef<HTMLInputElement>(null)
	const count        : number[]                                 = [-1, 1]
	const [defaultSearch, setDefaultSearch]                       = useState<any>
	({
		q                : "",
		typeId           : 0,
		categoryId       : 0,
		classificationId : 0,
		priceComparisonA : "",
		priceComparisonB : "",
		tags             : []
	})
	const [customSearch, setCustomSearch]                         = useState<any>(defaultSearch)

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					dispatch(disableIdleLockSwitch())
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					const statusRetrievalError : string = "El catálogo de estatuses de artículo no pudo ser extraído"

					dispatch(enableIdleLockSwitch())

					try
					{
						const result = await CatalogService.getArticleStatuses(sessionToken)

						if(result.status == 200 || result.status == 204)
						{
							if(result.data.length < 1)
							{
								dispatch(disableIdleLockSwitch())
								procedureComplaint
								(
									"No se encontraron estatuses de artículo registrados, " +
										"por lo que el acceso a este módulo permanecerá deshabilitado"
								)
							}
							else
							{
								let articleStatusIndex : any = {}

								setArticleStatuses
								(
									result.data.map
									(
										(record : any)=>
										{
											articleStatusIndex["AS_" + record.id] = record

											return record
										}
									)
								)

								setArticleStatusRef(articleStatusIndex)
								setInitSetup(ARTICLE_STATUSES)
							}
						}
						else
						{
							procedureComplaint(statusRetrievalError)
							dispatch(disableIdleLockSwitch())
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(statusRetrievalError)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case ARTICLE_STATUSES:
						if(articleStatuses.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay estatuses de artículo en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const classificationRetrievalError : string = "El catálogo de clasificaciones de artículo no pudo ser extraído"

							try
							{
								let   classificationIndex : any = {}
								const result              : any = await SimpleDataService.getRecords("articleClassifications")

								if(result.status == 200 || result.status == 204)
								{
									setArticleClassifications
									(
										result.data.map
										(
											(record : any)=>
											{
												classificationIndex["AC_" + record.id] = {...record, label : record.name}

												return classificationIndex["AC_" + record.id]
											}
										)
									)

									setArticleClassificationRef(classificationIndex)
									setInitSetup(ARTICLE_CLASSIFICATIONS)
								}
								else
								{
									procedureComplaint(classificationRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(classificationRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ARTICLE_CLASSIFICATIONS:
						if(articleClassifications.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay clasificaciones de artículo en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const typeRetrievalError : string = "El catálogo de tipos de artículo no pudo ser extraído"

							try
							{
								const result = await SimpleDataService.getRecords("articleTypes")

								if(result.status == 200 || result.status == 204)
								{
									let articleTypeIndex : any = {}

									setArticleTypes
									(
										result.data.map
										(
											(record : any)=>
											{
												articleTypeIndex["AT_" + record.id] = record

												return record
											}
										)
									)

									setArticleTypeRef(articleTypeIndex)
									setInitSetup(ARTICLE_TYPES)
								}
								else
								{
									procedureComplaint(typeRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(typeRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ARTICLE_TYPES:
						if(articleTypes.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No se encontraron tipos de artículo registrados, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const categoryRetrievalError : string = "No se pudo extraer el catélogo de tipos de artículo, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado"

							try
							{
								const result = await SimpleDataService.getRecords("categories")

								if(result.status == 200 || result.status == 204)
								{
									let categoryIndex : any = {}

									setCategories
									(
										result.data.map
										(
											(record : any)=>
											{
												categoryIndex["C_" + record.id] = record
												return categoryIndex["C_" + record.id]
											}
										)
									)

									setCategoryRef(categoryIndex)
									setNewArticle
									({
										name            : "",
										status_id       : commonStatus.ENABLED,
										type_id         : articleTypes[0].id,
										sku             : "",
										base_price      : 0,
										stock_units     : 0,
										countable_units : 0,
										description     : "",
										category_id     : result.data[0].id,
										tags            : [],
									})

									search()
								}
								else
								{
									procedureComplaint(categoryRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(categoryRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(()=>{setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setSectionLoaded(false)
				setListSearch("")
				setCartOnDisplay(false)
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setShoppingCart({})
				setStudentsList([])
				setTotalItems(0)
				setSubTotal(0)
				setStudent(null)
				clearSearch()
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			if(extendedFilterShown)
			{
				setExtendedFilterShown(false)
			}

			dispatch(enableIdleLockSwitch())

			const result = await StoreService.searchArticles
				(campusRef[currentUserCampus].id, customSearch, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setData(result.data.data)
				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (article : any, change : number)=>
	{
		let cart        : any    = {...shoppingCart}
		let amountTotal : number = 0

		if(change > 0)
		{
			cart["A_" + article.id] = {...article, quantity : (cart["A_" + article.id] == null ? 1 : cart["A_" + article.id].quantity + 1)}
		}
		else
		{
			if(cart["A_" + article.id].quantity < 2 || change == 0)
			{
				delete cart["A_" + article.id]
			}
			else
			{
				cart["A_" + article.id] = {...article, quantity : (cart["A_" + article.id] == null ? 1 : cart["A_" + article.id].quantity - 1)}
			}
		}

		setSubTotal
		(
			change != 0
				? (subTotal + (article.base_price * change))
					: subTotal - (shoppingCart["A_" + article.id].quantity * shoppingCart["A_" + article.id].base_price)
		)

		const resultItemCount = totalItems + (change == 0 ? -shoppingCart["A_" + article.id].quantity : change)

		setTotalItems(resultItemCount)
		setShoppingCart(cart)

		if(resultItemCount < 1)
		{
			setCartOnDisplay(false)
		}
		else
		{
			if(!cartOnDisplay)
			{
				setCartOnDisplay(true)
			}
		}
	}

	const debounceFn : any = useCallback(_debounce(handleDebounceFn, 1000), [])

	async function handleDebounceFn(campusId : number, term : string)
	{
		setASearchInProgress(true)

		try
		{
			let result : any = await StudentsService.searchStudents(campusId, {q : term}, undefined, 100)

			if(result.status == 200 || result.status == 204)
			{
				setStudentsList(result.data.reverse())
			}
			else
			{
				setSaveError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			setSaveError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setASearchInProgress(false)
		}
	}

	const searchStudents = (term : string)=>
	{
		setListSearch(term)
		debounceFn(campusRef[currentUserCampus].id, term)
	}

	const addProductsToStudentBill = async ()=>
	{
		const billingError : string = "La información no pudo ser procesada"

		dispatch(enableIdleLockSwitch())

		try
		{
			const result: any = await StoreService.addOwedArticlesToStudent
				(campusRef[currentUserCampus].id, student.id, Object.values(shoppingCart))

			if(result.status == 200 || result.status == 204)
			{
				const addedItems = totalItems

				dispatch
				(
					displayNotice
					({
						cornerClose : false,
						message     : addedItems + " Artículos agregados a la cuenta de " + student.first_name + " " + student.last_name + ".",
						heading     : <h3 style={{color : "#00FF00", display : "inline-block"}}>
							Listo
						</h3>
					})
				)

				setCartOnDisplay(false)
				setStudent(null)
				setShoppingCart({})
				setSubTotal(0)
				setTotalItems(0)
				setListSearch("")
			}
			else
			{
				procedureComplaint(billingError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(billingError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	return !sectionLoaded || pagination == null ? <></> : <><SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  searchChangeFn={handleSearchChange}
		  extendedFilterShown={extendedFilterShown}
		  clearSearchRequested={clearSearchRequested}
		  generalPlaceholder={"Nombre, SKU, descripción"}
		  setExtendedFilterShown={setExtendedFilterShown}
		  searchIcon={<FontAwesomeIcon icon={solid("boxes-packing")} />}
		  extendedFilterViewClosing={() => setExtendedFilterShown(false)}
		  additionalClasses={"compactableSection" + (!cartOnDisplay ? "" : " sidePrompt")}
		  additionalStyle={{float : "left", height : "100%", overflow : "auto", width : "calc(100%" + (!cartOnDisplay ? ")" : " - 446px)")}}
		  additionalOptions=
		  {<button style={{width : "100%", textAlign : "left", color : "#FFFFFF"}} className="btn btn-info" type="button"
			  disabled={idleLocked} onClick={() => setGridViewOnDisplay(!gridViewOnDisplay)}
			>
				<FontAwesomeIcon icon={!gridViewOnDisplay ? solid("table-cells") : solid("table-list")} />
				<span style={{paddingLeft : "10px"}}>
					{!gridViewOnDisplay ? "Vista de cuadrícula" :"Vista de lista"}
				</span>
		  </button>} managementOverride=
		  {
			cartOnDisplay ? "" : <div className="fluentBlockSm"><div
			  style={{display : "flex", float : "right", paddingTop : "5px", position : "sticky"}}
			><Popover reposition={true} positions={["bottom"]} isOpen={noItemsInCart}
			  content={<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
				<div className="requirementAlert">
					<FontAwesomeIcon icon={solid("exclamation")}/>
				</div>
				&nbsp;
				No hay artículos para confirmar
			  </div>}
			><button className="btn btn-outline-success" disabled={idleLocked || noItemsInCart} type="button"
			  style={{borderRadius : "25px", display : "flex", padding : "10px", minWidth : "45px", paddingLeft : "15px", zIndex : 2}}
			  onClick=
			  {
				()=>
				{
					if(totalItems < 1)
					{
						setNoItemsInCart(true)
						setTimeout(() => setNoItemsInCart(false), 3000)
					}
					else
					{
						setCartOnDisplay(true)
					}
				}
			  }
			>
				<FontAwesomeIcon icon={solid("shopping-bag")} style={{marginTop : "4px"}}/>
				<span className="d-xs-none d-sm-none d-md-block" style={{marginLeft : "5px"}}>
					Carrito
				</span>
				{
					totalItems < 1 ? "" : <span style=
					  {{
						  display    : "inline-block", width : "20px", height : "20px", background : "#FF0000", color : "#FFFFFF",
						  marginLeft : "5px",borderRadius : "100%"
					  }}
					>
						{totalItems > 99 ? "*" : totalItems}
					</span>
				}
			</button></Popover></div></div>
		  } filterSummary=
		  {
			[
				customSearch.categoryId < 1 ? null :
				{
					label   : "Categoría",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{categoryRef["C_" + customSearch.categoryId].name}
					</p>
				},
				customSearch.typeId < 1 ? null :
				{
					label   : "Tipo",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{articleTypeRef["AT_" + customSearch.typeId].name}
					</p>
				},
				customSearch.classification == null ? null :
				{
					label   : "Clasificación",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{customSearch.classification.name}
					</p>
				},
				customSearch.priceComparisonA == "" && customSearch.priceComparisonB == "" ? null :
				{
					label   : "Precio",
					display : operators[customSearch.priceComparisonA].display + " " + customSearch.priceValueA +
					(
						customSearch.priceComparisonB == "" ? "" : " & " + operators[customSearch.priceComparisonB].display + " " +
							customSearch.priceValueB
					)
				},
				customSearch.tags.length < 1 ? null : 
				{
					label   : "Etiquetas",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				}
			].filter((setting : any) => setting)
		  } advancedSearchForm=
		  {<div className="container"><div className="row">
			<div className="col-sm-6">
				<fieldset>
					<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
						Precio
					</label></legend>
					<div style={{display : "flex", alignItems : "stretch", flexWrap : "wrap"}}>
						<div style={{flexGrow : 3}}>
							<div style={{display : "flex", padding : "5px"}}>
								<div style={{paddingTop : "15px"}}>
									<div className="operatorOptions" style={{display : "inline-flex"}}><select id="priceComparisonA"
									  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
									  className="btn btnVeryPeri" onChange={handleSearchChange} name="priceComparisonA"
									  disabled={idleLocked} value={customSearch.priceComparisonA}
									>{
										operatorList.map
										(
											(key : any) => <option key={key.code} value={key.code}>
												{key.display}
											</option>
										)
									}</select></div>
								</div>
								<div className="inputContainer" style={{flexGrow : 1}}>
									<input onChange={handleSearchChange} style={{width : "100%", textAlign : "right"}}
									  disabled={idleLocked} onBlur={validateField} type="number"
									  value={customSearch.priceValueA} onFocus={validateField}
									  maxLength={50} placeholder="Monto" name="priceValueA" id="priceValueA" required
									/>
								</div>
							</div>
						</div>
						{
							(customSearch.priceComparisonA == "" || customSearch.priceComparisonA == "equals") ||
								(
									customSearch.priceValueA == 0 &&
										(customSearch.priceComparisonA == "lt" || customSearch.priceComparisonA == "lte")
								) ? "" : <>
									<div style={{flexGrow : 2, textAlign : "center", paddingTop : "15px"}}><div
									  style={{fontWeight : 900, width : "50px", height : "50px", display : "inline-block", borderRadius : "100%", color : "#FFFFFF", background : "#808080", fontSize : "18px", paddingTop : "12px"}}
									>
										&amp;
									</div></div>
									<div style={{flexGrow : 3}}><div style={{display : "flex", padding : "5px"}}>
										<div style={{paddingTop : "15px"}}>
											<div className="operatorOptions" style={{display : "inline-flex"}}><select
											  style={{width : "40px", height : "35px", padding : "5px", appearance : "none", textAlign : "left"}}
											  disabled={idleLocked} value={customSearch.amountComparisonB} id="amountComparisonB"
											  className="btn btnVeryPeri" onChange={handleSearchChange}
											  name="amountComparisonB"
											>{
												operatorList.filter
												(
													(operator : any) => operator.code != "equals" && operator.code.charAt(0)
														!= customSearch.priceComparisonA.charAt(0)
												).map
												(
													(key : any) => <option key={key.code} value={key.code}>
														{key.display}
													</option>
												)
											}</select></div>
										</div>
										<div className="inputContainer" style={{flexGrow : 1}}>
											<input disabled={idleLocked} onBlur={validateField}
											  maxLength={50} placeholder="Monto" name="priceValueB" value={customSearch.priceValueB}
											  onChange={handleSearchChange} style={{width : "100%", textAlign : "right"}}
											  type="number" onFocus={validateField}
											  id="priceValueB"required
											/>
										</div>
									</div></div>
							</>
						}
					</div>
				</fieldset>
			</div>
			<div className="col-sm-6">
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Categoría
					</label></legend>
					<div className="selectContainer" style={{marginBottom : "unset", height : "32px"}}>
						<select value={customSearch.categoryId} disabled={idleLocked} name="categoryId"
						  className="btn btn-primary text-start" onChange={handleSearchChange} id="categoryId"
						  style={{height : "30px", marginTop : "unset"}}
						>
							<option value={-1}>
								Todas
							</option>
							{
								categories.map
								(
									(record : any, i : any) => <option key={i} value={record.id}>
										{record.name}
									</option>
								)
							}
						</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name="rowDown" />
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Tipo
					</label></legend>
					<div className="selectContainer" style={{marginBottom : "unset", height : "32px"}}>
						<select value={customSearch.typeId} onChange={handleSearchChange} id="categoryId"
						  className="btn btn-primary text-start" style={{height : "30px", marginTop : "unset"}} name="typeId"
						  disabled={idleLocked}
						>
							<option value={-1}>
								Todos
							</option>
							{
								categories.map
								(
									(record : any, i : any) => <option key={i} value={record.id}>
										{record.name}
									</option>
								)
							}
						</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name="rowDown" />
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Clasificación
					</label></legend>
					<div>
						<SelectReact value={customSearch.classification} className="btn btn-primary text-start" id="classification"
						  onChange={handleSearchChange} disabled={idleLocked} style={{height : "30px"}} clearable={true}
						  children={articleClassifications} placeholder="Clasificación" name="classification"
						/>
					</div>
				</fieldset>
			</div>
			<fieldset style={{height : "100%"}}>
				<legend><label style={{position : "relative", top : "4px"}}>
					Etiquetas
				</label></legend>
				<Multiselect emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
				  placeholder="Seleccione" onSelect={(tags : any) => setCustomSearch({...customSearch, tags : tags})}
				  onRemove={(tags : any) => setCustomSearch({...customSearch, tags : tags})} displayValue="name"
				/>
			</fieldset>
		  </div></div>}
		>{
			(data.length < 1)
					?
				<>
					<hr />
					<div style={{padding : "25px"}}>
						{Constants.noCampusSearchResults}
					</div>
					<hr />
				</>
					:
				(
					gridViewOnDisplay
							?
						<div className="container">
							<div className="row">{
								data.map
								(
									(record : any, index : number) => <div className="col-xl-3 col-lg-4 col-lg-6 col-md-12">
										<div className="card card-default" style={{height : "100%", margin : "unset"}}>
											<img src={record.image || defaultArticleImage} style=
											  {{
												width        :  "100%",
												borderRadius :  "100%",
												margin       :  "auto",
												maxWidth     : "250px",
												maxHeight    : "250px",
												minHeight    : "125px",
												minWidth     : "125px",
												border       : "3px solid #8F91DA"
											  }}
											/>
											<div style={{display : "flex", width : "100%"}}>
												{
													shoppingCart["A_" + record.id] == null
															?
														""
															:
														<button className="btn btnVeryPeri rounded-pill" type="button"
														  ref={el => {if(el){el.style.setProperty("border-radius", "100% 0% 0% 100%", "important")}}}
														  onClick={() => toggleRecordSelection(record, -1)}
														  disabled={idleLocked}
														  style={{display : "inline-flex", width : "53px"}}
														><span>
															<FontAwesomeIcon icon={solid("circle-minus")} />
														</span></button>
												}
												<button className="btn btnVeryPeri" disabled={idleLocked}
												  onClick={() => toggleRecordSelection(record, 1)} type="button"
												  style={{display : "flex", width : "100%"}}
												  ref={el => {if(el){el.style.setProperty("border-radius", (shoppingCart["A_" + record.id] == null ? "50px" : "0% 50px 50px 0%"), "important")}}}
												>
													<span style={{padding : "0px 10px", textAlign : "center", width : "100%"}}>{
														shoppingCart["A_" + record.id] == null ? "Agregar" : shoppingCart["A_" + record.id].quantity
													}</span>
													<span style={{width : "24px"}}>
														<FontAwesomeIcon icon={solid("circle-plus")}/>
													</span>
												</button>
											</div>
											<h5>{record.name}</h5>
											<b style={{color : "#5154AE"}}>{
												sprintf(campusRef[currentUserCampus].company.currency.symbol + " %.02f " +
													campusRef[currentUserCampus].company.currency.code, record.base_price)
											}</b>
											<label style={{position : "relative", whiteSpace : "unset", textAlign : "left"}}>
												{record.description || "-"}
											</label>
										</div>
									</div>
								)
							}</div>
						</div>
							:
						<table className="commonTable">
							<thead><tr key="catalogHeader">
								<th style={{width : "150px"}} />
								<th style={{borderBottom : "none"}} />
								<th>
									Nombre
								</th>
								<th>
									Tipo
								</th>
								<th>
									Clasificación
								</th>
								<th>
									<div style={{width : "max-content"}}>
										Precio U
									</div>
								</th>
								<th>
									Descriptión
								</th>
								<th>
									Categoría
								</th>
								<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
									SKU
								</th>
							</tr></thead>
							<tbody>{
								data.map
								(
									(record : any, index : number) => <tr key={"campus" + index}>
										<td style={{width : "150px"}}><div style={{display : "flex", width : "100%"}}>
											{
												shoppingCart["A_" + record.id] == null
														?
													""
														:
													<button className="btn btnVeryPeri rounded-pill" type="button"
													  ref={el => {if(el){el.style.setProperty("border-radius", "100% 0% 0% 100%", "important")}}}
													  onClick={() => toggleRecordSelection(record, -1)}
													  disabled={idleLocked}
													  style={{display : "inline-flex", width : "53px"}}
													><span>
														<FontAwesomeIcon icon={solid("circle-minus")} />
													</span></button>
											}
											<button className="btn btnVeryPeri" disabled={idleLocked}
											  onClick={() => toggleRecordSelection(record, 1)} type="button"
											  style={{display : "flex", width : "100%"}}
											  ref={el => {if(el){el.style.setProperty("border-radius", (shoppingCart["A_" + record.id] == null ? "50px" : "0% 50px 50px 0%"), "important")}}}
											>
												<span style={{padding : "0px 10px", textAlign : "center", width : "100%"}}>{
													shoppingCart["A_" + record.id] == null ? "Agregar" : shoppingCart["A_" + record.id].quantity
												}</span>
												<span style={{width : "24px"}}>
													<FontAwesomeIcon icon={solid("circle-plus")}/>
												</span>
											</button>
										</div></td>
										<td style={{borderBottom : "none", width : "1px"}}>
											<img src={record.image != null ? record.image : defaultArticleImage} style=
											  {{
												margin    : "auto",
												maxWidth  : "50px",
												maxHeight : "50px",
												minHeight : "50px",
												minWidth  : "50px"
											  }}
											/>
										</td>
										<td>
											{record.name}
										</td>
										<td>
											{record.type?.name}
										</td>
										<td>
											{record.classification_id == null ? "-" : articleClassificationRef["AC_" + record.classification_id].name}
										</td>
										<td style={{textAlign : "right"}}><div style={{display : "flex", alignItems : "stretch"}}>
											<div style={{flexGrow : 0}}>
												{campusRef[currentUserCampus].company.currency.symbol}
											</div>
											<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "center"}}>
												{sprintf("%.02f", record.base_price)}
											</div>
											<div style={{flexGrow : 0}}>
												{campusRef[currentUserCampus].company.currency.code}
											</div>
										</div></td>
										<td>
											{record.description || "-"}
										</td>
										<td>
											{categoryRef["C_" + record.category_id].name}
										</td>
										<td ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}>
											{record.sku}
										</td>
									</tr>
								)
							}</tbody>
						</table>
				)
		}</SectionContainer>
		{
			!cartOnDisplay ? "" : <div style={{width : "446px", background : "#FFFFFF", height : "100%", overflowY : "auto"}}
			  className="sidePrompt"
			><Box style={{...styles.showModal, width : "100%", height : "100%"}} sx={{ boxShadow: 2 }} role="presentation">
				<div className="card" style={{...styles.modalBody, overflowY : "auto"}}>
					<div style={{display : "flex", alignItems : "stretch"}}>
						<h2 style={{flexGrow : 1}}>
							<CustomIcon name="shoppingCart" style={{fill : "#8F91DA", width : "25px", height : "25px"}} />
							<span style={{paddingLeft : "10px"}}>
								{Constants.Screens.Finances.modalShopping.cart}
							</span>
						</h2>
						<button style={{...styles.btnCloseModal, flexGrow : 0, zIndex : 2}} onClick={() => setCartOnDisplay(false)}
						  type="button"
						>
							<FontAwesomeIcon style={{ height: "100%", position : "relative", bottom : "1px"}} icon={solid("times")} />
						</button>
					</div>
					<hr style={styles.separator} />
					<br />
					{
						Object.keys(shoppingCart).length < 1 ? "" : <table style={{width : "100%"}}>{
							Object.values(shoppingCart).map
							(
								(item : any) => <tbody key={"SPA_" + item.id}>
									<tr>
										<td rowSpan={2} style={{verticalAlign : "top"}}>
											<img src={item.image != null ? item.image : defaultArticleImage} style=
											  {{
												margin    : "auto",
												maxWidth  : "100px",
												maxHeight : "100px",
												minHeight : "100px",
												minWidth  : "100px",
												border    : "1px solid #C7C7C7"
											  }}
											/>
										</td>
										<td style={{width : "100%", padding : "5px"}}>
											{item.name}
											{
												item.description ? 
												<p style={{wordBreak : "break-all", margin : "unset"}}>
													{item.description}
												</p>
													: ""
											}
										</td>
										<td style={{verticalAlign : "bottom"}}>
											<b>
												{campusRef[currentUserCampus].company.currency.symbol}
												<span style={{paddingLeft : "5px"}}>
													{sprintf("%.02f", item.base_price * item.quantity)}
												</span>
											</b>
											<br />
											<label style={{position : "unset"}}>
												{campusRef[currentUserCampus].company.currency.symbol}
												<span style={{paddingLeft : "5px"}}>
													{sprintf("%.02f", item.base_price)}/pza
												</span>
											</label>
										</td>
									</tr>
									<tr>
										<td style={{padding : "10px 20px"}}><label onClick={() => toggleRecordSelection(item, 0)}
										  style={{position : "relative", float : "right", cursor : "pointer"}}
										><u>
											Eliminar
										</u></label></td>
										<td><div className="rounded-pill text-center" style={{background : "#8F91DA", color : "#FFFFFF"}}>
											{item.quantity}
										</div></td>
									</tr>
									<tr><td colSpan={3} style={{height : "25px"}}><label style={{position : "relative"}}>
										<hr />
									</label></td></tr>
								</tbody>
							)
						}</table>
					}
					<hr style={{border : "1px solid #000000", opacity : 1}} />
					<table style={{width : "100%"}}><tbody>
						<tr>
							<td style={{width : "100%", color : "#C0C0C0", textAlign : "right", paddingRight : "10px", fontSize : "13px"}}>
								Descuento
							</td>
							<td style={{textAlign : "right"}}>
								<h5>
									0%
								</h5>
							</td>
						</tr>
						<tr>
							<td style={{width : "100%", color : "#C0C0C0", textAlign : "right", paddingRight : "10px", fontSize : "13px"}}>
								Impuestos
							</td>
							<td style={{textAlign : "right"}}>
								<h5>
									0%
								</h5>
							</td>
						</tr>
						<tr>
							<td style={{width : "100%", color : "#C0C0C0", textAlign : "right", paddingRight : "10px", fontSize : "13px"}}>
								Subtotal
								<br />
								({totalItems} artículos)
							</td>
							<td><h5 style={{display : "flex"}}>
								<span style={{paddingRight: "5px"}}>
									{campusRef[currentUserCampus].company.currency.symbol}
								</span>
								<span>
									{sprintf("%.02f", subTotal)}
								</span>
							</h5></td>
						</tr>
					</tbody></table>
					<hr style={{border : "1px solid #000000", opacity : 1}} />
					<h4 style={{display : "flex", alignItems: "stretch"}}>
						<label style={{flexGrow : 1, position : "relative", textAlign : "right"}}><b>
							Total:
						</b></label>
						<span style={{padding : "0px 10px", flexGrow : 0}}>
							{campusRef[currentUserCampus].company.currency.symbol}
						</span>
						<span style={{flexGrow : 0}}>
							{sprintf("%.02f", subTotal)}
						</span>
					</h4>
					<div className="card">
						<label style={{position : "relative"}}>
							Alumno
						</label>
						{
							student != null
								?
							<div style={{display : "flex", alignItems : "stretch"}}>
								<p style={{flexGrow	 : 1, wordBreak : "break-word"}}>
									{student.first_name + " " + student.last_name}
								</p>
								<button style={{ ...styles.btnCloseModal, flexGrow : 0}} onClick={() => {setStudent(null)}}>
									<FontAwesomeIcon style={{ height: "100%", position : "relative", bottom : "1px"}}
									  icon={solid("times")}
									/>
								</button>
							</div>
								:
							<Popover positions={["top"]} isOpen={isPopoverOpen} onClickOutside={() => setIsPopoverOpen(false)}
							  content=
							  {<div style=
								{{
									overflowY      : "auto",
									maxHeight      : "40vh",
									background     : "var(--main-bg)",
									width          : "440px",
									display        : "flex",
									flexDirection  : "column-reverse",
								  }}
								>
								{
									(studentsList == null || studentsList.length < 1) ? "" : studentsList.filter((student : any) => student).map
									(
										(student : any, index : number) => <div key={"T_" + index}
										  style={{display : "inline-grid", padding : "5px", width : "100%"}}
										><div style={{display : "grid", margin : "0px 5px"}}><button key={"T_" + index}
										  onClick={() => setStudent(student)} type="button" style=
										  {{
											whiteSpace   : "nowrap",
											overflow     : "hidden",
											textOverflow : "ellipsis",
											textAlign    : "left"
										  }}
										><span style={{paddingLeft : "10px"}}>
											{student.first_name + " " + student.last_name}
										</span></button></div></div>
									)
								}
								<div className="text-center" style={{color : "#C7C7C7"}}>
									No hay más resultados.
								</div>
							  </div>}
							><div className="inputContainer" style={{position : "sticky", left : "0"}}>
								<input value={listSearch} onChange={(e : any) => searchStudents(e.target.value)} type="text"
								  style={{width : "100%", position : "relative"}} minLength={3} disabled={idleLocked} ref={nameRef}
								  placeholder="Nombres, apellidos, correo, ..." onClick={() => setIsPopoverOpen(!isPopoverOpen)}
								  name="listSearch" id="listSearch" maxLength={50}
								/>
								{!aSearchInProgress ? "" : <div className="loader"></div>}
							</div></Popover>
						}
					</div>
					{
						student == null ? "" : <button onClick={addProductsToStudentBill} disabled={idleLocked}
						  className="btn btnVeryPeri rounded-pill"
						>
							Agregar a estado de cuenta
						</button>
					}
				</div>
			</Box></div>
		}
	</>
}

export default Catalog
