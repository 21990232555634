import axios
	from "axios"
import store
	from "./../globals"

import {isNotEmpty, accessMode, removeRecords}
	from "./../common"

const RESOURCE_URL = process.env.REACT_APP_API_URL + "/admin/roles";

class RolesService
{
	sessionToken = store.getState().sessionToken.value

	constructor()
	{
		store.subscribe(() => this.sessionToken = store.getState().sessionToken.value)
	}

	async searchRoles(search? : any, page? : number)
	{
		let params = [];

		if(page)
		{
			params.push("page=" + page)
		}

		if(search && isNotEmpty(search.q))
		{
			params.push("q=" + search.q)
		}

		return axios.get
		(
			RESOURCE_URL + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
	}

	saveRole(role : any)
	{
		const saveFunction = role.id == null ? this.registerRole : this.updateRole

		let record : any=
		{
			name        : role.name + "",
			permissions : Object.keys(role.permitMap).map
			(
				(key : string)=>
				({
					sectionId : role.permitMap[key].section,
					RCUDNPermissions:
					(
						(( +role.permitMap[key].READ    ) * accessMode.READ)
							|
						(( +role.permitMap[key].CREATE  ) * accessMode.CREATE)
							|
						(( +role.permitMap[key].UPDATE  ) * accessMode.UPDATE)
							|
						(( +role.permitMap[key].DELETE  ) * accessMode.DELETE)
							|
						(( +role.permitMap[key].NAVIGATE) * accessMode.NAVIGATE)
					)
				})
			)
		}

		if(isNotEmpty(role.description))
		{
			record.description = role.description
		}

		return saveFunction
		(
			record,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			},
			role.id
		)
	}

	async registerRole(registry : any, options : any)
	{
		return axios.post
		(
			RESOURCE_URL,
			registry,
			options
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async updateRole(registry : any, options : any, id : number)
	{
		return axios.put
		(
			RESOURCE_URL + "/" + id,
			registry,
			options
		)
		.then
		(
			response=>
			{
				return response;
			}
		)
	}

	async removeRoles(recordIds : any)
	{
		return removeRecords(RESOURCE_URL, recordIds)
	}
}

export default new RolesService()